import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'
import { ProductContext } from '../../context/context'
import imgIcon from "../../images/assets/report.png";

function MultiGroup({ link, i, route, routeGroup, NotificationClose }) {
    const { lang } = useContext(ProductContext);
    const drop2Items = (id) => {
        // document.getElementById(id).classList.toggle('dropRoted')
    }

    return (
        <React.Fragment>
            <li key={i * Math.random()} onClick={() => drop2Items(`pro2-` + i + "30")} className="sidebar-item pl-3 py-2" data-toggle="collapse" data-target={`.collapseGroupMulti` + i} href={`#collapseExampleGroup` + i} aria-expanded="false" >
                <p className={`nav-link d-flex justify-content-between align-items-center font-ar a-link py-0 mb-2 ${lang == "ar" ? "flex-row-reverse text-right" : ""}`} style={{ cursor: "pointer" }}>
                    <div className="font-ar">
                        <span className='mx-2'>{route.text}</span>
                        {/* <span> <img src={imgIcon} className="iconMax2" />  </span> */}
                    </div>
                    <IoIosArrowForward id={`pro2-` + i + "30"} className="font-weight-bold text-muted" style={{ transition: "0.3s all ease" }} />
                </p>
            </li>
            <div className={`collapse ${`collapseGroupMulti` + i}`} id={`#collapseExampleGroup` + i}>
                {route.group.map((group, idx) =>
                    <li key={Math.random() * idx} className={`sidebar-item pl-3 py-2 font-ar ${window.location.pathname == group.path ? "link-activite" : ""} `} onClick={() => NotificationClose(`collapseGroup` + routeGroup, `collapseGroupMulti` + i, `pro2-` + i)}>
                        <Link to={group.path} className={`nav-link py-1 d-flex a-link multi-group-p ${lang == "ar" ? "flex-row-reverse text-right" : "mohsem en"}`}>
                            {/* <span> <img src={imgIcon} className="iconMax2" /> </span> */}
                            <span className="mx-2"> {group.text} </span>
                        </Link>
                    </li>

                )}
            </div>
        </React.Fragment>
    )
}

export default MultiGroup
