import React from "react";
import { PrivacyData } from "./context/privacyData";
import Layout from "./Layout";

function Privacy() {

    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-11 mx-auto my-5 text-center">
                        {PrivacyData.map(({title, child}) => {
                            return (
                                <div className="text-right font-main my-5" style={{ direction:"rtl" }}>
                                    <h6 className="font-weight-bold mb-4">{ title }</h6>
                                    {child.map(({text}) => {
                                        return <p className="my-3" style={{ wordSpacing:"1px" }}> {text} </p>
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Privacy;
