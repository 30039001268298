import React from "react";

function TableCustomer({ List = [] }) {
    return (
        <div className="row">
            <div className="col-11 mx-auto">
                <table className="table" dir="rtl">
                    <thead>
                        <tr>
                            <th scope="col">م</th>
                            <th scope="col"> رقم الحساب </th>
                            <th scope="col"> اسم العميل </th>
                            <th scope="col"> الرصيد </th>
                            <th scope="col"> من 1-30 </th>
                            <th scope="col"> من 31-60 </th>
                            <th scope="col"> من 61-90 </th>
                            <th scope="col"> من 91-120 </th>
                            <th scope="col"> من 121-150 </th>
                            <th scope="col"> اكثر من 150 يوم </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>Otto</td>
                            <td>Otto</td>
                            <td>Otto</td>
                            <td>Otto</td>
                            <td>Otto</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TableCustomer;
