import Home from "./pages/Home";
import Company from "./pages/company";
import Compaines from "./pages/company/Compaines";
import Branches from "./pages/Branches/Branches";
import AddBranches from "./pages/Branches/Add";
import FiscalYears from "./pages/FiscalYears/FiscalYears";
import AddFiscalYears from "./pages/FiscalYears/AddFiscalYears";
import CustomersGroups from "./pages/CustomersGroups/CustomersGroups";
import AddCustomGroup from "./pages/CustomersGroups/AddCustomGroup";
import Suppliers from "./pages/Suppliers/Suppliers";
import AddSuppliers from "./pages/Suppliers/AddSuppliers";
import Stores from "./pages/Stores/Stores";
import AddStore from "./pages/Stores/AddStore";
import DefinitionUnit from "./pages/DefinitionUnit/DefinitionUnit";
import AddDefinitionUnit from "./pages/DefinitionUnit/AddDefinitionUnit";
import SaleInvoice from "./pages/SaleInvoice/SaleInvoice";
import DefinitionGroup from "./pages/DefinitionGroups/DefinitionGroup";
import EditSup from "./pages/Suppliers/EditSup";
import DefinitionItems from "./pages/DefinitionItems/DefinitionItems";
import AddItem from "./pages/DefinitionItems/AddItem";
import Update from "./pages/company/Update";
import UpdateBranch from "./pages/Branches/UpdateBranch";
import UpdateFiscal from "./pages/FiscalYears/UpdateFiscal";
import GroupSuppliers from "./pages/Suppliers/GroupSuppliers";
import AddGroupSuppliers from "./pages/Suppliers/AddGroupSuppliers";
import Customers from "./pages/Customers/Customers";
import AddCustomers from "./pages/Customers/AddCustomers";
import Employees from "./pages/Employees/Employees";
import AddEmployee from "./pages/Employees/AddEmployees";
import CostCenterDirectory from "./pages/CostCenterDirectory/CostCenterDirectory";
import AddCostCenterDirectory from "./pages/CostCenterDirectory/AddCostCenterDirectory";
import TestFun from "./TestFun";
import Users from "./pages/Users/Users";
import AddUser from "./pages/Users/AddUser";
import EditUser from "./pages/Users/EditUser";
import Edit from "./pages/DefinitionUnit/Edit";
import UpdateItem from "./pages/DefinitionItems/UpdateItem";
import Index from "./pages/CatchReceipt";
import CatchAdd from "./pages/CatchReceipt/CatchAdd";
import AccountStatement from "./pages/Reports/AccountStatement";
import Customer from "./pages/Reports/debit/Customer";
import SuppliersR from "./pages/Reports/debit/SuppliersR";
import CategoryCard from "./pages/Stores/Reports/CategoryCard";
import SalesInvoiceSummary from "./pages/SallesReports/SalesInvoiceSummary";
import PurchaseInvoiceSummary from "./PurchaseReports/PurchaseSummary";
import Purchase from "./pages/Purchase/Purchase";
import PurchaseReturned from "./pages/Purchase/PurchaseReturned";
import SalesReturned from "./pages/Sales/SalesReturned";
import PointSettings from "./pages/SaleInvoice/PointSettings";
import VoucherDaily from "./pages/Accounts/VoucherDaily";
import ReceiptVoucher from "./pages/FinancialTransactions/ReceiptVoucher";
import IndexBank from "./pages/CatchReceipt/Banks/IndexBank";
import CatchAddBank from "./pages/CatchReceipt/Banks/CatchAddBank";
import AccountStatementPrinter from "./Printers/AccountStatementPrinter";
import PrintReceiptVoucher from "./Printers/PrintReceiptVoucher";
import TransferVoucher from "./pages/FinancialTransactions/TransferVoucher/Index";
import BankTransferVoucher from "./pages/FinancialTransactions/BankTransferVoucher/BankIndex";
import BankEdit from "./pages/CatchReceipt/Banks/BankEdit";
import BankTransferVoucherCreateBank from "./pages/FinancialTransactions/BankTransferVoucher/BankTransferVoucherCreateBank";
import CachEdit from "./pages/CatchReceipt/Caches/CachEdit";
import BankTransferVoucherEditBank from "./pages/FinancialTransactions/BankTransferVoucher/BankTransferVoucherEditBank";
import TransferCachIndex from "./pages/FinancialTransactions/TransferCach/TransferCachIndex";
import TransferCachCreate from "./pages/FinancialTransactions/TransferCach/TransferCachCreate";
import TransferCachEdit from "./pages/FinancialTransactions/TransferCach/TransferCachEdit";
import ReciptVoucherGeneral from "./ReciptVoucherGeneral/ReciptVoucherGeneral";
import ReciptVoucherGeneralEdit from "./ReciptVoucherGeneralEdit/ReciptVoucherGeneralEdit";
import SummaryReports from "./SummaryReports/SummaryReports";
import { PrintTableSummaryReports } from "./SummaryReports/TableSummaryReports";
import TransferVoucherGeneral from "./TransferVoucherGeneral/TransferVoucherGeneral";
import TransferGeneralEdit from "./ReciptTransferGeneralEdit/TransferGeneralEdit";
import ItemsBalanceReports from "./Reports/ItemsBalanceReports/ItemsBalanceReports";
import StockValuation from "./pages/StockValuation/StockValuation";
import ItemCategory from "./pages/ItemCard/ItemCategory";
import ItemCategoryPrint from "./pages/ItemCard/ItemCategoryPrint";
import ItemCategoryCost from "./pages/ItemCardNotCost/ItemCategoryCost";
import ItemCategoryPrintCost from "./pages/ItemCardNotCost/ItemCategoryPrintCost";
import Sales from "./pages/SaleInvoice/Sales/Sales";
import CreateSales from "./pages/SaleInvoice/Sales/CreateSales";
import POSTables from "./pages/POSTables/POSTables";
import License from "./pages/License/license";
import Print from "./pages/DefinitionUnit/Print";
import PrintcustomersGroups from "./pages/CustomersGroups/Print";
import customersGroupEdit from "./pages/CustomersGroups/Edit"
import SuppliersGroups from "./pages/SuppliersGroups/SuppliersGroups";
import CreateSupplierGroup from "./pages/SuppliersGroups/CreateSupplierGroup";
import PrintsuppliersGroups from "./pages/SuppliersGroups/Print";
import suppliersGroupsEdit from "./pages/SuppliersGroups/Edit";




const Routes = [
    {
        id: 1,
        path: "/",
        component: Home,
        exact: true,
    },
    {
        id: 2,
        text: "",
        path: "/companies",
        component: Compaines,
        exact: true,
    },
    {
        id: 3,
        path: "/companies/add-company",
        component: Company,
        exact: true,
    },
    {
        id: 4,
        path: "/companies/edit/:id",
        component: Update,
        exact: true,
    },
    {
        id: 5,
        path: "/branches",
        component: Branches,
        exact: true,
    },
    {
        id: 6,
        path: "/branches/add-branche",
        component: AddBranches,
        exact: true,
    },
    {
        id: 7,
        path: "/branch/edit/:id",
        component: UpdateBranch,
        exact: true,
    },
    {
        id: 8,
        path: "/fiscal_years",
        component: FiscalYears,
        exact: true,
    },
    {
        id: 9,
        path: "/fiscal_years/add-fiscal_years",
        component: AddFiscalYears,
        exact: true,
    },
    {
        id: 10,
        path: "/fiscalYear/edit/:id",
        component: UpdateFiscal,
        exact: true,
    },

    {
        id: 11,
        path: "/customers",
        component: Customers,
        exact: true,
    },

    {
        id: 12,
        path: "/customer/add",
        component: AddCustomers,
        exact: true,
    },
    {
        id: 13,
        path: "/customersGroup",
        component: CustomersGroups,
        exact: true,
    },
    {
        id: 14,
        path: "/customersGroup/create",
        component: AddCustomGroup,
        exact: true,
    }, {
        id: 143,
        path: "/customersGroup/print",
        component: PrintcustomersGroups,
        exact: true,
    }, {
        id: 144,
        path: "/customersGroup/edit/:id",
        component: customersGroupEdit,
        exact: true,
    },
    // Supplier

    {
        id: 13,
        path: "/suppliersGroups",
        component: SuppliersGroups,
        exact: true,
    },
    {
        id: 14,
        path: "/suppliersGroups/create",
        component: CreateSupplierGroup,
        exact: true,
    }, {
        id: 143,
        path: "/suppliersGroups/print",
        component: PrintsuppliersGroups,
        exact: true,
    }, {
        id: 144,
        path: "/suppliersGroups/edit/:id",
        component: suppliersGroupsEdit,
        exact: true,
    },
    // End Suplliers

    {
        id: 15,
        path: "/fiscal_years",
        component: FiscalYears,
        exact: true,
    },
    {
        id: 16,
        path: "/suppliers",
        component: Suppliers,
        exact: true,
    },

    {
        id: 17,
        path: "/suppliers_group",
        component: GroupSuppliers,
        exact: true,
    },
    {
        id: 18,
        path: "/supplier/edit/:id",
        component: EditSup,
        exact: true,
    },
    {
        id: 19,
        path: "/suppliers/add-suppliers",
        component: AddSuppliers,
        exact: true,
    },

    {
        id: 20,
        path: "/suppliers/add-group-suppliers",
        component: AddGroupSuppliers,
        exact: true,
    },
    {
        id: 21,
        path: "/stores",
        component: Stores,
        exact: true,
    },
    {
        id: 22,
        path: "/stores/add-store",
        component: AddStore,
        exact: true,
    },
    {
        id: 23,
        path: "/Units",
        component: DefinitionUnit,
        exact: true,
    },
    {
        id: 24,
        path: "/Units/create",
        component: AddDefinitionUnit,
        exact: true,
    },
    {
        id: 142,
        path: "/Units/print",
        component: Print,
        exact: true,
    },
    {
        id: 37,
        path: "/Units/edit/:id",
        component: Edit,
        exact: false,
    },
    {
        id: 25,
        path: "/DefinitionGroups",
        component: DefinitionGroup,
        exact: true,
    },
    {
        id: 26,
        path: "/DefinitionGroups/add-group-units",
        component: AddDefinitionUnit,
        exact: true,
    },
    {
        id: 27,
        path: "/DefinitionItems",
        component: DefinitionItems,
        exact: true,
    },
    {
        id: 28,
        path: "/DefinitionItems/add-item",
        component: AddItem,
        exact: true,
    },
    {
        id: 29,
        path: "/SaleInvoice",
        component: SaleInvoice,
        exact: true,
    },
    {
        id: 30,
        path: "/employees",
        component: Employees,
        exact: true,
    },
    {
        id: 31,
        path: "/employees/addEmployee",
        component: AddEmployee,
        exact: false,
    },
    {
        id: 32,
        path: "/costCenterDirectory",
        component: CostCenterDirectory,
        exact: true,
    },
    {
        id: 33,
        path: "/costCenterDirectory/add",
        component: AddCostCenterDirectory,
        exact: false,
    },
    {
        id: 34,
        path: "/users",
        component: Users,
        exact: true,
    },
    {
        id: 35,
        path: "/user/add",
        component: AddUser,
        exact: false,
    },
    {
        id: 36,
        path: "/user/edit/:id",
        component: EditUser,
        exact: false,
    },
    // {
    //     id: 38,
    //     path: "/DefinitionItems/item/:id",
    //     component: UpdateItem,
    //     exact: false,
    // },
    {
        id: 99,
        path: "/test",
        component: TestFun,
        exact: false,
    },
    // سند قبض نقدي
    {
        id: 100,
        path: "/Cash-Receipt-Voucher",
        component: Index,
        exact: true,
    },
    //  اضافة سند قبض نقدي 
    {
        id: 101,
        path: "/Cash-Receipt-Voucher/add",
        component: CatchAdd,
        exact: false,
    },
    //  تعديل سند قبض نقدي 
    {
        id: 126,
        path: "/Cash-Receipt-Voucher/edit/:id",
        component: CachEdit,
        exact: false,
    },
    {
        id: 102,
        path: "/account-statement",
        component: AccountStatement,
        exact: false,
    },
    {
        id: 103,
        path: "/debt-recovery-customer",
        component: Customer,
        exact: false,
    },
    {
        id: 104,
        path: "/debt-recovery-supplier",
        component: SuppliersR,
        exact: false,
    },
    {
        id: 105,
        path: "/category-card",
        component: CategoryCard,
        exact: false,
    },
    {
        id: 106,
        path: "/sales-invoice-summary",
        component: SalesInvoiceSummary,
        exact: false,
    },
    {
        id: 107,
        path: "/purchase-invoice-summary",
        component: PurchaseInvoiceSummary,
        exact: false,
    },
    {
        id: 108,
        path: "/purchase-invoice",
        component: Purchase,
        exact: false,
    },
    {
        id: 109,
        path: "/sales-invoice",
        component: Sales,
        exact: false,
    },
    {
        id: 110,
        path: "/purchase-returned",
        component: PurchaseReturned,
        exact: false,
    },
    {
        id: 111,
        path: "/sales-returned",
        component: SalesReturned,
        exact: false,
    },
    {
        id: 112,
        path: "/pointSettings",
        component: PointSettings,
        exact: false,
    },
    {
        id: 113,
        path: "/voucherDaily",
        component: VoucherDaily,
        exact: true,
    },

    // سند قبض عام
    {
        id: 115,
        path: "/receipt-voucher",
        component: ReceiptVoucher,
        exact: true,
    },

    // Test
    {
        id: 999,
        path: "/receipt-voucher",
        component: ReciptVoucherGeneral,
        exact: true,
    },
    // تعديل سند قبض عام
    {
        id: 1000,
        path: "/receipt-voucher/edit/:id",
        component: ReciptVoucherGeneralEdit,
        exact: false,
    },
    // اضافة سند قبض عام
    {
        id: 115,
        path: "/receipt-voucher/add",
        component: ReciptVoucherGeneral,
        exact: false,
    },


    // سند قبض بنكي
    {
        id: 116,
        path: "/Bank-Receipt-Voucher",
        component: IndexBank,
        exact: true,
    },
    // اضافة سند قبض بنكي
    {
        id: 117,
        path: "/Bank-Receipt-Voucher/create",
        component: CatchAddBank,
        exact: false,
    },
    // تعديل سند قبض بنكي
    {
        id: 125,
        path: "/Bank-Receipt-Voucher/edit/:id",
        component: BankEdit,
        exact: false,
    }, /////////////
    {
        id: 118,
        path: "/account-statement-printing",
        component: AccountStatementPrinter,
        exact: false,
    },
    {
        id: 119,
        path: "/print-receipt-voucher",
        component: PrintReceiptVoucher,
        exact: false,
    },
    // سند صرف عام
    {
        id: 120,
        path: "/transfer-voucher",
        component: TransferVoucher,
        exact: true,
    },
    // اضافة سند صرف عام
    {
        id: 1221,
        path: "/transfer-voucher/create",
        component: TransferVoucherGeneral,
        exact: false,
    },

    {
        id: 1224,
        path: "/transfer-voucher/edit/:id",
        component: TransferGeneralEdit,
        exact: false,
    },

    // سند صرف بنك
    {
        id: 121,
        path: "/Bank-Payment-Voucher",
        component: BankTransferVoucher,
        exact: true,
    },
    // اضافة سند صرف بنكي
    {
        id: 122,
        path: "/Bank-Payment-Voucher/create",
        component: BankTransferVoucherCreateBank,
        exact: false,
    },
    // تعديل سند صرف بنكي
    {
        id: 128,
        path: "/Bank-Payment-Voucher/edit/:id",
        component: BankTransferVoucherEditBank,
        exact: false,
    },
    // سند صرف نقدي
    {
        id: 121,
        path: "/Cash-Payment-Voucher",
        component: TransferCachIndex,
        exact: true,
    },
    // تعديل صرف نقدي
    // {
    //     id: 127,
    //     path: "/Cash-Payment-Voucher/edit/:id",
    //     component: ReceiptIndex,
    //     exact: true,
    // },
    // صرف نقدي
    {
        id: 122,
        path: "/Cash-Payment-Voucher/create",
        component: TransferCachCreate,
        exact: false,
    },
    {
        id: 129,
        path: "/Cash-Payment-Voucher/edit/:id",
        component: TransferCachEdit,
        exact: false,
    },
    {
        id: 130,
        path: "/SummaryReports",
        component: SummaryReports,
        exact: true,
    },

    {
        id: 131,
        path: "/PrintSummaryReports",
        component: PrintTableSummaryReports,
        exact: true,
    },
    // top 123
    // top 124
    // top 125
    // top 126
    // top 127
    // top 128
    // top 129
    // top 130

    {
        id: 132,
        path: "/ItemsBalanceReports",
        component: ItemsBalanceReports,
        exact: true,
    },
    {
        id: 133,
        path: "/stockValuation",
        component: StockValuation,
        exact: true,
    },
    {
        id: 134,
        path: "/category_card",
        component: ItemCategory,
        exact: true,
    },
    {
        id: 135,
        path: "/ItemCategoryPrint",
        component: ItemCategoryPrint,
        exact: true,
    },
    {
        id: 136,
        path: "/category_card_cost",
        component: ItemCategoryCost,
        exact: true,
    },
    {
        id: 137,
        path: "/ItemCategoryPrintCost",
        component: ItemCategoryPrintCost,
        exact: true,
    },

    // sale_invoice

    {
        id: 138,
        path: "/sale_invoice",
        component: Sales,
        exact: true,
    },

    {
        id: 139,
        path: "/sale_invoice/create",
        component: CreateSales,
        exact: true,
    },
    {
        id: 140,
        path: "/POSTables",
        component: POSTables,
        exact: true,
    },
    // POSTables

    {
        id: 141,
        path: "/license",
        component: License,
        exact: true,
    },


];

export default Routes;
