import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import TablePurchase from './TablePurchase';

function Purchase() {

    const { allRefrence = [] } = useContext(ProductContext);

    let InvoicePurchaseRefrence = {};
    if(allRefrence.length != 0){
        InvoicePurchaseRefrence = allRefrence.find(item => item.mName == "TsmInvoicePurchase") ? allRefrence.find(item => item.mName == "TsmInvoicePurchase") : false; 
    }


    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-11 mx-auto my-3 text-center">
                        <div className="text-right mb-2"> 
                        </div>
                        <TablePurchase Refrencecustomers={InvoicePurchaseRefrence} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Purchase
