import React from 'react'
import Layout from '../../../Layout/index'
function Sales() {
    return (
        <Layout>
            <div className="sales">
                <div className="container">
                    <div className="row">
                        <div className="col-12 px-2 py-3">
                            <div class="table-responsive">
                                <table class="table table-striped caption-top" dir='RTL'>
                                    <div className="text-right mb-2">
                                        <a href="/sale_invoice/create" className="btn btn-success btn-small p-0 px-3 my-2"> اضافة </a>
                                    </div>
                                    <thead>
                                        <tr>
                                            <th className="th_invoice_sale"> م </th>
                                            <th className="th_invoice_sale"> رقم فاتورة العميل </th>
                                            <th className="th_invoice_sale"> التاريخ </th>
                                            <th className="th_invoice_sale"> الحالة </th>
                                            <th className="th_invoice_sale"> الوصف </th>
                                            <th className="th_invoice_sale"> العميل </th>
                                            <th className="th_invoice_sale"> مندوب مبيعات </th>
                                            <th className="th_invoice_sale"> العملة</th>
                                            <th className="th_invoice_sale"> سعر الصرف </th>
                                            <th className="th_invoice_sale"> الاجمالي </th>
                                            <th className="th_invoice_sale"> خصم الاصناف </th>
                                            <th className="th_invoice_sale"> خصم اضافي </th>
                                            <th className="th_invoice_sale"> اجمالي الخصم </th>
                                            <th className="th_invoice_sale"> الصافي </th>
                                            <th className="th_invoice_sale"> المبلغ المدفوع </th>
                                            <th className="th_invoice_sale"> المبلغ المتبقي </th>
                                            <th className="th_invoice_sale"> المصدر </th>
                                            <th className="th_invoice_sale"> رقم المصدر </th>
                                            <th className="th_invoice_sale"> رقم القيد</th>
                                            <th className="th_invoice_sale"> طريقة الدفع </th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Sales