import React, { useContext } from "react";
import { useState } from "react";
import { ProductContext } from "../../context/context";
import { useTranslation } from "react-i18next";

import logoImg from "../../images/assets/afaky.png";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [lang, setLang] = useState(1);
    const { LoginForm,onChangeLanguage } = useContext(ProductContext);
    // http://172.16.16.20:5051
    const { t, i18n } = useTranslation();

    const onChangeLang = (val) => {
        setLang(val);
        let text = "";
        if (val == 1) {
            text = "ar";
        }
        if (val == 2) {
            text = "en";
        }
        if (val == 3) {
            text = "fr";
        }
        // i18n.changeLanguage(text);

        localStorage.setItem('lang', JSON.stringify(text));
        window.location.reload();
        i18n.changeLanguage(text);
    };

    return (
        <div className="container-fluid px-0 mx-0 login-background">
            <div className="row mx-0">
                <div className="col-12 col-md-6 mx-auto d-flex justify-content-center align-items-center" style={{ height: "100vh" }} >
                    <form className="login-form w-100 d-flex justify-content-center align-items-center" autoComplete="off" dir="rtl" >
                        <div className="w-100">
                            <div className="text-center">
                                <img src={logoImg} width="150" alt="" />
                            </div>
                            <div className="text-center my-5">
                                <h4 className="font-ar user-select-none">
                                    {t("nameTag")}
                                </h4>
                                <p className="font-ar">{t("descTag")} </p>
                            </div>
                            <div className={`form-group ${i18n.language == "ar" ? "text-right" : "text-left"} `}>
                                <label htmlFor="email_login" style={{ fontSize: "15px" }} >
                                    {t("username")}
                                </label>
                                <input type="email" className={`form-control ${i18n.language == "ar" ? "text-right" : "text-left"} `}
                                    onChange={(e) => setEmail(e.target.value)}
                                    id="email_login"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                            <div className={`form-group ${i18n.language == "ar" ? "text-right" : "text-left"} `}>
                                <label htmlFor="password_login" style={{ fontSize: "15px" }} >
                                    {t("pass")}
                                </label>
                                <input type="password" className={`form-control ${i18n.language == "ar" ? "text-right" : "text-left"} `}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    id="password_login"
                                />
                            </div>

                            <div className={`row align-items-center mt-3 ${i18n.language == "en" ? "flex-row-reverse" : ""} ${i18n.language == "fr" ? "flex-row-reverse" : ""}`} >
                                <div className="col-12 col-md-6">
                                    <div className={`form-group form-check text-right d-flex align-items-center ${i18n.language == "en" ? "justify-content-end" : ""}`} >
                                        <input type="checkbox" className="form-check-input m-0" id="check-login" />
                                        <label className={`form-check-label mr-3 ${i18n.language == "en" ? "ml-4" : ""}`} htmlFor="check-login">
                                            {t("remember")}
                                        </label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6  mt-3">
                                    {/* <div class="form-group">
                                    <select class="form-control" onChange={e => onChangeLang(e.target.value)}>
                                    { i18n.language == "en" ? 
                                    <>
                                        <option value="1">اللغة العربية</option>
                                        <option value="2"> English </option>
                                    </>   :
                                    <>
                                        <option value="2"> English </option>
                                        <option value="1">اللغة العربية</option>
                                    </>  
                                } 

                                    </select>
                                </div> */}

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend w-100">
                                            <button type="button" className="btn bg-addition text-light" style={{ borderRadius: "3px", width: "85%", }}
                                                onClick={(e) => onChangeLang(i18n.language == "ar" ? "2" : "1")}>
                                                {i18n.language == "ar"
                                                    ? t('english')
                                                    : t('arabic')}
                                            </button>
                                            <button type="button" className="btn bg-addition text-light dropdown-toggle dropdown-toggle-split" style={{ borderRadius: "3px", width: "15%", }} data-toggle="dropdown" aria-expanded="false">
                                                {/* <span className="sr-only"> { i18n.language == "ar" ? "English" : "اللغة العربية"} </span> */}
                                            </button>
                                            <div className="dropdown-menu">
                                            {/* (e) => onChangeLanguage(e, i18n) */}
                                                <a className="dropdown-item" href="#" onClick={(e) => onChangeLang(2)}>
                                                {/* <a className="dropdown-item" href="#" onClick={(e) => onChangeLang(2)}> */}
                                                    {t('english')}
                                                </a>
                                                <a className="dropdown-item" href="#" onClick={(e) => onChangeLang(1)}>
                                                    {t('arabic')}
                                                </a>
                                                <a className="dropdown-item" href="#" onClick={(e) => onChangeLang(3)}>
                                                    {t('french')}
                                                </a>
                                                {/* <a className="dropdown-item" href="#" onClick={e => onChangeLang(1)} >اللغة الفرنسية</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className="btn d-block w-100 text-light py-2 font-ar bg-addition mt-3" onClick={(e) => LoginForm(e, email, password, lang)}>
                                {t("login")}
                            </button>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    );
}

export default Login;
