import text from '../../context/super.txt'
import $ from 'jquery';

const handelTable = () => {
}

// token
const getToken = () => localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : '';
const general = () => localStorage.getItem("ControllAfaky") ? JSON.parse(JSON.stringify(localStorage.getItem("ControllAfaky"))) : '';


const handeltables = ({ fun }) => {
    document.querySelector(".loading").classList.remove("hideloader");
    let MostData = new Headers();
    MostData.append("Content-Type", "application/json");
    MostData.append("Authorization", `Bearer ${getToken()}`);
    let requestOpasdasdtions = {
        method: 'GET',
        headers: MostData,
        redirect: 'follow'
    };

    fetch(text)
        .then(r => r.text())
        .then(text => {
            let isCheck = text.includes("Afaky#");
            if (isCheck) {
                let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                let myHeaders = new Headers();
                myHeaders.append("accept", "text/plain");
                let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(newUrl, requestOptions)
                    .then(response => response.text())
                    .then(resultUrl => {
                        fetch(`${resultUrl}/PosForm/GetTables`, requestOpasdasdtions)
                            .then(response => response.json())
                            .then(result => {
                                fun(result.data)
                                document.querySelector(".loading").classList.add("hideloader");
                            })
                            .catch(error => {
                                document.querySelector(".loading").classList.add("hideloader");
                                console.log('error', error)
                            });
                    })
                    .catch(error => console.log('error', error));
            } else {
                fetch(`${text}/PosForm/GetTables`, requestOpasdasdtions)
                    .then(response => response.json())
                    .then(result => {
                        fun(result.data)
                        document.querySelector(".loading").classList.add("hideloader");
                    })
                    .catch(error => {
                        document.querySelector(".loading").classList.add("hideloader");
                        console.log('error', error)
                    });
            }

        });

}

const handelDeverOrder = ({ fun }) => {
    document.querySelector(".loading").classList.remove("hideloader");
    let MostData = new Headers();
    MostData.append("Content-Type", "application/json");
    MostData.append("Authorization", `Bearer ${getToken()}`);
    let requestOpasdasdtions = {
        method: 'GET',
        headers: MostData,
        redirect: 'follow'
    };

    fetch(text)
        .then(r => r.text())
        .then(text => {

            let isCheck = text.includes("Afaky#");
            if (isCheck) {
                let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                let myHeaders = new Headers();
                myHeaders.append("accept", "text/plain");
                let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(newUrl, requestOptions)
                    .then(response => response.text())
                    .then(resultUrl => {
                        fetch(`${resultUrl}/PosForm/GetDelivery`, requestOpasdasdtions)
                            .then(response => response.json())
                            .then(result => {
                                fun(result.data)
                                document.querySelector(".loading").classList.add("hideloader");
                            })
                            .catch(error => {
                                document.querySelector(".loading").classList.add("hideloader");
                                console.log('error', error)
                            });
                    })
                    .catch(error => console.log('error', error));
            } else {
                fetch(`${text}/PosForm/GetDelivery`, requestOpasdasdtions)
                    .then(response => response.json())
                    .then(result => {
                        fun(result.data)
                        document.querySelector(".loading").classList.add("hideloader");
                    })
                    .catch(error => {
                        document.querySelector(".loading").classList.add("hideloader");
                        console.log('error', error)
                    });
            }



        });

}

const getDeverOrder = (props) => {
    let { id, changeList, oldTdd, changeDatDelevery, changeType, type, items, changeTOD, TOD, mostData = [], dataDelevery = 0, setRunPrint, ClearFunction, list = [] } = props;
    changeTOD(TOD);
    let OrderInvoice = document.querySelector('#OrderNoCounter').value; // orderNum

    if (!OrderInvoice) OrderInvoice = 0;
    if (OrderInvoice != 0) {
        printDeverOrder({ TOD: oldTdd, list, data: mostData, dataDelevery, ClearFunction: ClearFunction, setRunPrint, clearList: false })
    }
    let MostData = new Headers();
    MostData.append("Content-Type", "application/json");
    MostData.append("Authorization", `Bearer ${getToken()}`);
    let requestOpasdasdtions = {
        method: 'POST',
        headers: MostData,
        redirect: 'follow',
        body: JSON.stringify({
            "tableNumber": id
        })
    };
    fetch(text)
        .then(r => r.text())
        .then(text => {
            let isCheck = text.includes("Afaky#");
            if (isCheck) {
                let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                let myHeaders = new Headers();
                myHeaders.append("accept", "text/plain");

                let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(newUrl, requestOptions)
                    .then(response => response.text())
                    .then(resultUrl => {
                        fetch(`${resultUrl}/PosForm/GetDeliveryDetails`, requestOpasdasdtions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.status == 200 && result.data.length != 0) {
                                    $(`#OrderNoCounter`).val(result.data[0].tableNumber);
                                    let list = [...result.data[0].postempDetailList];
                                    list.forEach(row => {
                                        row.qtn = row.quantity ? row.quantity : 1;
                                        row.tatolItem = row.total ? row.total : row.qtn * row.price;
                                        row.itemName = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemName : "";
                                        row.itemCode = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemCode : "";
                                        row.unitName = items.find(el => el.unitId == row.unitId) ? items.find(el => el.unitId == row.unitId).unitName : "";
                                        row.name = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemName : "";
                                        row.Id1 = 0;
                                    })
                                    changeList(list)
                                    changeDatDelevery(result.data[0]);
                                    $(`option#idT1`).attr('selected', false);
                                    $(`option#idT2`).attr('selected', false);
                                    $(`option#idT3`).attr('selected', false);
                                    $(`option#idT4`).attr('selected', false);
                                    $(`option#idT2`).attr('selected', true);
                                }
                            })
                            .catch(error => {
                                console.log('error', error)
                            });
                    })
                    .catch(error => console.log('error', error));
            } else {
                fetch(`${text}/PosForm/GetDeliveryDetails`, requestOpasdasdtions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.status == 200 && result.data.length != 0) {
                            $(`#OrderNoCounter`).val(result.data[0].tableNumber);
                            let list = [...result.data[0].postempDetailList];
                            list.forEach(row => {
                                row.qtn = row.quantity ? row.quantity : 1;
                                row.tatolItem = row.total ? row.total : row.qtn * row.price;
                                row.itemName = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemName : "";
                                row.itemCode = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemCode : "";
                                row.unitName = items.find(el => el.unitId == row.unitId) ? items.find(el => el.unitId == row.unitId).unitName : "";
                                row.name = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemName : "";
                                row.Id1 = 0;
                            })
                            changeList(list)
                            changeDatDelevery(result.data[0]);
                            $(`option#idT1`).attr('selected', false);
                            $(`option#idT2`).attr('selected', false);
                            $(`option#idT3`).attr('selected', false);
                            $(`option#idT4`).attr('selected', false);
                            $(`option#idT2`).attr('selected', true);
                        }
                    })
                    .catch(error => {
                        console.log('error', error)
                    });
            }

        });



}

const getTableOrder = (props) => {
    let { id, changeList, oldTdd, changeDatDelevery, changeType, type, items, changeTOD, TOD, mostData = [], dataDelevery = 0, setRunPrint, ClearFunction, list = [] } = props;
    changeTOD(TOD)
    let OrderInvoice = document.querySelector('#OrderNoCounter').value; // orderNum
    if (!OrderInvoice) OrderInvoice = 0;
    if (OrderInvoice != 0) {
        printDeverOrder({ TOD: oldTdd, list, data: mostData, dataDelevery, ClearFunction: ClearFunction, setRunPrint, clearList: false })
    }
    let MostData = new Headers();
    MostData.append("Content-Type", "application/json");
    MostData.append("Authorization", `Bearer ${getToken()}`);
    let requestOpasdasdtions = {
        method: 'POST',
        headers: MostData,
        redirect: 'follow',
        body: JSON.stringify({
            "tableNumber": id
        })
    };
    fetch(text)
        .then(r => r.text())
        .then(text => {
            let isCheck = text.includes("Afaky#");
            if (isCheck) {
                let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                let myHeaders = new Headers();
                myHeaders.append("accept", "text/plain");
                let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(newUrl, requestOptions)
                    .then(response => response.text())
                    .then(resultUrl => {

                        fetch(`${resultUrl}/PosForm/GetTablesDetails`, requestOpasdasdtions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.status == 200) {
                                    $(`#OrderNoCounter`).val(result.data[0].tableNumber);
                                    $(`option#idT1`).attr('selected', false);
                                    $(`option#idT2`).attr('selected', false);
                                    $(`option#idT3`).attr('selected', false);
                                    $(`option#idT4`).attr('selected', false);
                                    $(`option#idT1`).attr('selected', true);
                                    if (result.data.length != 0) {

                                        let list = [...result.data[0].postempDetailList];
                                        if (list.length == 0) {

                                        }
                                        if (list.length != 0) {
                                            list.forEach(row => {
                                                row.qtn = row.quantity ? row.quantity : 1;
                                                row.tatolItem = row.total ? row.total : row.qtn * row.price;
                                                row.itemName = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemName : "";
                                                row.itemCode = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemCode : "";
                                                row.unitName = items.find(el => el.unitId == row.unitId) ? items.find(el => el.unitId == row.unitId).unitName : "";
                                                row.name = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemName : "";
                                                row.id1 = 0;
                                            })
                                            changeList(list)
                                        }
                                        changeDatDelevery(result.data[0]);

                                    }

                                }
                            })
                            .catch(error => {
                                console.log('getTableOrder Not Success', error)
                            });

                    })
                    .catch(error => console.log('error', error));
            } else {
                fetch(`${text}/PosForm/GetTablesDetails`, requestOpasdasdtions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.status == 200) {
                            $(`#OrderNoCounter`).val(result.data[0].tableNumber);
                            $(`option#idT1`).attr('selected', false);
                            $(`option#idT2`).attr('selected', false);
                            $(`option#idT3`).attr('selected', false);
                            $(`option#idT4`).attr('selected', false);
                            $(`option#idT1`).attr('selected', true);
                            if (result.data.length != 0) {

                                let list = [...result.data[0].postempDetailList];
                                if (list.length == 0) {

                                }
                                if (list.length != 0) {
                                    list.forEach(row => {
                                        row.qtn = row.quantity ? row.quantity : 1;
                                        row.tatolItem = row.total ? row.total : row.qtn * row.price;
                                        row.itemName = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemName : "";
                                        row.itemCode = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemCode : "";
                                        row.unitName = items.find(el => el.unitId == row.unitId) ? items.find(el => el.unitId == row.unitId).unitName : "";
                                        row.name = items.find(el => el.itemId == row.itemId) ? items.find(el => el.itemId == row.itemId).itemName : "";
                                        row.id1 = 0;
                                    })
                                    changeList(list)
                                }
                                changeDatDelevery(result.data[0]);

                            }

                        }
                    })
                    .catch(error => {
                        console.log('getTableOrder Not Success', error)
                    });
            }



        });


}



// TOD = 1 tables
// TOD = 2 delevery
const printDeverOrder = (props) => {


    let printVars = $('#printVars').is(':checked');
    let { list = [], printInvoiceKitchenVar, setPrintInvoiceKitchen, setPrintVars, setPrintInvoice, dataDelevery, ClearFunction, TOD, setRunPrint, clearList = false, changeList, setDataDelevery, defaultType, Dates = [], main = false } = props;
    // if (printVars) {
    //     if (printInvoiceKitchenVar && printVars) {
    //         let Tod = $('#typeIdInvoice').val();
    //         Tod = +Tod;
    //         if (Tod == 1 || Tod == 2) {
    //             TOD = Tod;
    //         }
    //         let OrderInvoice = document.querySelector('#OrderNoCounter').value; // orderNum
    //         if (!OrderInvoice) OrderInvoice = 0;
    //         let numPaper = $('#numPaper').val();
    //         let printInvoice = $('#printInvoice').is(':checked');
    //         let printInvoiceKitchen = $('#printInvoiceKitchen').is(':checked');
    //         let pop = document.getElementById('Setting');
    //         console.log("printVars is ", printVars);
    //         console.log("printInvoiceKitchen is ", printInvoiceKitchen);

    //         if (list.length <= 0 && OrderInvoice == 0) {
    //             alert('تاكد من البيانات');
    //         }

    //         else {
    //             list.forEach(element => {
    //                 element.discountPercent1 = 0;
    //                 element.discountPercent2 = 0;
    //                 element.discountPercent3 = 0;
    //             });
    //             let { branchId, companyId } = JSON.parse(general());
    //             let { tableName, tableNumber, orderNoCounter, posinvoiceTypeId, id, name, type, customerId, delegateId } = dataDelevery;
    //             let MostData = new Headers();
    //             MostData.append("Content-Type", "application/json");
    //             MostData.append("Authorization", `Bearer ${getToken()}`);
    //             let requestOpasdasdtions = {
    //                 method: 'POST',
    //                 headers: MostData,
    //                 redirect: 'follow',
    //                 body: JSON.stringify({
    //                     companyId: companyId,
    //                     branchId: branchId,
    //                     pccode: "string",
    //                     tableNumber: +tableNumber ? tableNumber : 0,
    //                     id: id,
    //                     name: name,
    //                     customerId: customerId,
    //                     delegateId: delegateId,
    //                     type: type,
    //                     source: 0,
    //                     sourceNo: 0,
    //                     isPrint: true,
    //                     tableName: tableName ? tableName : "",
    //                     orderNoCounter: orderNoCounter ? orderNoCounter : 0,
    //                     posinvoiceTypeId: posinvoiceTypeId,
    //                     additionalDiscount: 0,
    //                     postempDetailList: list,
    //                     printInvoice: printInvoice ? printInvoice : false,
    //                     printRestaurantInvoice: printInvoiceKitchen ? printInvoiceKitchen : false,
    //                     printVariables: printVars ? printVars : false,
    //                     invoiceCount: numPaper ? +numPaper : 1
    //                 })
    //             };

    //             let valueHidden = document.querySelector("#valueHidden").value;
    //             let idValue = valueHidden ? +valueHidden : 3;
    //             fetch(text)
    //                 .then(r => r.text())
    //                 .then(text => {
    //                     let isCheck = text.includes("Afaky#");
    //                     if (isCheck) {
    //                         let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
    //                         let myHeaders = new Headers();
    //                         myHeaders.append("accept", "text/plain");
    //                         let requestOptions = {
    //                             method: 'POST',
    //                             headers: myHeaders,
    //                             redirect: 'follow'
    //                         };

    //                         fetch(newUrl, requestOptions)
    //                             .then(response => response.text())
    //                             .then(resultUrl => {


    //                                 if (TOD == 1) {

    //                                     fetch(`${resultUrl}/PosForm/PrintTable`, requestOpasdasdtions)
    //                                         .then(response => response.json())
    //                                         .then(result => {
    //                                             if (result.status == 200) {
    //                                                 changeList([])
    //                                                 document.querySelector('#OrderNoCounter').value = "";
    //                                                 document.querySelector("#valueHidden").value = "";
    //                                                 pop.classList.remove('show');
    //                                                 pop.style.display = 'none';
    //                                                 alert(result.message ? result.message : "تم بنجاح");
    //                                                 setDataDelevery({})
    //                                                 setRunPrint(false)
    //                                                 $(`option#idT1`).attr('selected', false);
    //                                                 $(`option#idT2`).attr('selected', false);
    //                                                 $(`option#idT3`).attr('selected', false);
    //                                                 $(`option#idT4`).attr('selected', false);
    //                                                 $(`option#idT${idValue}`).attr('selected', true);

    //                                                 let parms = {
    //                                                     result,
    //                                                     Dates
    //                                                 };

    //                                                 if (main) {
    //                                                     if (printInvoiceKitchen || printVars) {
    //                                                         let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
    //                                                         handle.window.parameters = JSON.stringify(parms);
    //                                                     }

    //                                                 }
    //                                                 $('#numPaper').val(1)
    //                                                 setPrintInvoiceKitchen(true);
    //                                                 setPrintVars(true);
    //                                                 setPrintInvoice(false);


    //                                             }
    //                                             if (result.status != 200 && result.isSuccess == false) {
    //                                                 alert(result.message);
    //                                             }
    //                                         })
    //                                         .catch(error => {
    //                                             console.log('Print Error', error)
    //                                         });
    //                                 }
    //                                 if (TOD == 2) {

    //                                     fetch(`${resultUrl}/PosForm/PrintDelivery`, requestOpasdasdtions)
    //                                         .then(response => response.json())
    //                                         .then(result => {
    //                                             if (result.status == 200) {
    //                                                 changeList([])
    //                                                 setDataDelevery({})
    //                                                 setRunPrint(false)
    //                                                 document.querySelector('#OrderNoCounter').value = "";
    //                                                 document.querySelector("#valueHidden").value = "";
    //                                                 // alert(result.message ? result.message : "تم بنجاح");
    //                                                 pop.classList.remove('show');
    //                                                 pop.style.display = 'none';
    //                                                 $(`option#idT1`).attr('selected', false);
    //                                                 $(`option#idT2`).attr('selected', false);
    //                                                 $(`option#idT3`).attr('selected', false);
    //                                                 $(`option#idT4`).attr('selected', false);
    //                                                 $(`option#idT${idValue}`).attr('selected', true);

    //                                                 // console.log("Checked 2");


    //                                                 let parms = { result, Dates };
    //                                                 if (main) {
    //                                                     if (printInvoiceKitchen || printVars) {
    //                                                         let handle = window.open(window.location.origin + '/PrintDeveley', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
    //                                                         handle.window.parameters = JSON.stringify(parms);
    //                                                     }
    //                                                 }
    //                                                 $('#numPaper').val(1)
    //                                                 setPrintInvoiceKitchen(true);
    //                                                 setPrintVars(true);
    //                                                 setPrintInvoice(false);
    //                                             }
    //                                             if (result.status != 200 && result.isSuccess == false) {
    //                                                 alert(result.message);
    //                                             }
    //                                         })
    //                                         .catch(error => {
    //                                             console.log('Print Error', error);

    //                                         });
    //                                 }

    //                             })
    //                             .catch(error => console.log('error', error));
    //                     } else {
    //                         if (TOD == 1) {
    //                             fetch(`${text}/PosForm/PrintTable`, requestOpasdasdtions)
    //                                 .then(response => response.json())
    //                                 .then(result => {
    //                                     if (result.status == 200) {
    //                                         changeList([])
    //                                         document.querySelector('#OrderNoCounter').value = "";
    //                                         document.querySelector("#valueHidden").value = "";
    //                                         pop.classList.remove('show');
    //                                         pop.style.display = 'none';
    //                                         // alert(result.message ? result.message : "تم بنجاح");
    //                                         setDataDelevery({})
    //                                         setRunPrint(false)
    //                                         $(`option#idT1`).attr('selected', false);
    //                                         $(`option#idT2`).attr('selected', false);
    //                                         $(`option#idT3`).attr('selected', false);
    //                                         $(`option#idT4`).attr('selected', false);
    //                                         $(`option#idT${idValue}`).attr('selected', true);

    //                                         let parms = {
    //                                             result,
    //                                             Dates
    //                                         };
    //                                         if (main) {
    //                                             if (printInvoiceKitchen || printVars) {
    //                                                 let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
    //                                                 handle.window.parameters = JSON.stringify(parms);
    //                                             }
    //                                         }
    //                                         $('#numPaper').val(1)
    //                                         setPrintInvoiceKitchen(true);
    //                                         setPrintVars(true);
    //                                         setPrintInvoice(false);

    //                                     }
    //                                     if (result.status != 200 && result.isSuccess == false) {
    //                                         alert(result.message);
    //                                     }
    //                                 })
    //                                 .catch(error => {
    //                                     console.log('Print Error', error)
    //                                 });
    //                         }
    //                         if (TOD == 2) {
    //                             fetch(`${text}/PosForm/PrintDelivery`, requestOpasdasdtions)
    //                                 .then(response => response.json())
    //                                 .then(result => {
    //                                     if (result.status == 200) {
    //                                         changeList([])
    //                                         setDataDelevery({})
    //                                         setRunPrint(false)
    //                                         document.querySelector('#OrderNoCounter').value = "";
    //                                         document.querySelector("#valueHidden").value = "";
    //                                         pop.classList.remove('show');
    //                                         pop.style.display = 'none';
    //                                         $(`option#idT1`).attr('selected', false);
    //                                         $(`option#idT2`).attr('selected', false);
    //                                         $(`option#idT3`).attr('selected', false);
    //                                         $(`option#idT4`).attr('selected', false);
    //                                         $(`option#idT${idValue}`).attr('selected', true);
    //                                         let parms = {
    //                                             result,
    //                                             Dates
    //                                         };
    //                                         if (main) {
    //                                             if (printInvoiceKitchen || printVars) {

    //                                                 let handle = window.open(window.location.origin + '/PrintDeveley', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
    //                                                 handle.window.parameters = JSON.stringify(parms);
    //                                             }
    //                                         }
    //                                         $('#numPaper').val(1)
    //                                         setPrintInvoiceKitchen(true);
    //                                         setPrintVars(true);
    //                                         setPrintInvoice(false);
    //                                     }
    //                                     if (result.status != 200 && result.isSuccess == false) {
    //                                         alert(result.message);
    //                                     }
    //                                 })
    //                                 .catch(error => {
    //                                     console.log('Print Error', error);

    //                                 });
    //                         }
    //                     }

    //                 });
    //         }
    //     } else {
    //         alert('لا يمكن طباعة المتغيرات فقط يجب طباعة المطبخ اولا')
    //     }
    // } else {
        
    // }



    let Tod = $('#typeIdInvoice').val();
        Tod = +Tod;
        if (Tod == 1 || Tod == 2) {
            TOD = Tod;
        }
        let OrderInvoice = document.querySelector('#OrderNoCounter').value; // orderNum
        if (!OrderInvoice) OrderInvoice = 0;
        let numPaper = $('#numPaper').val();
        let printInvoice = $('#printInvoice').is(':checked');
        let printInvoiceKitchen = $('#printInvoiceKitchen').is(':checked');
        let pop = document.getElementById('Setting');
        // console.log("printVars is ", printVars);
        // console.log("printInvoiceKitchen is ", printInvoiceKitchen);

        if (list.length <= 0 && OrderInvoice == 0) {
            alert('تاكد من البيانات');
        }

        else {
            list.forEach(element => {
                element.discountPercent1 = 0;
                element.discountPercent2 = 0;
                element.discountPercent3 = 0;
            });
            let { branchId, companyId } = JSON.parse(general());
            let { tableName, tableNumber, orderNoCounter, posinvoiceTypeId, id, name, type, customerId, delegateId } = dataDelevery;
            let MostData = new Headers();
            MostData.append("Content-Type", "application/json");
            MostData.append("Authorization", `Bearer ${getToken()}`);
            let requestOpasdasdtions = {
                method: 'POST',
                headers: MostData,
                redirect: 'follow',
                body: JSON.stringify({
                    companyId: companyId,
                    branchId: branchId,
                    pccode: "string",
                    tableNumber: +tableNumber ? tableNumber : 0,
                    id: id,
                    name: name,
                    customerId: customerId,
                    delegateId: delegateId,
                    type: type,
                    source: 0,
                    sourceNo: 0,
                    isPrint: true,
                    tableName: tableName ? tableName : "",
                    orderNoCounter: orderNoCounter ? orderNoCounter : 0,
                    posinvoiceTypeId: posinvoiceTypeId,
                    additionalDiscount: 0,
                    postempDetailList: list,
                    printInvoice: printInvoice ? printInvoice : false,
                    printRestaurantInvoice: printInvoiceKitchen ? printInvoiceKitchen : false,
                    printVariables: printVars ? printVars : false,
                    invoiceCount: numPaper ? +numPaper : 1
                })
            };

            let valueHidden = document.querySelector("#valueHidden").value;
            let idValue = valueHidden ? +valueHidden : 3;
            fetch(text)
                .then(r => r.text())
                .then(text => {
                    let isCheck = text.includes("Afaky#");
                    if (isCheck) {
                        let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                        let myHeaders = new Headers();
                        myHeaders.append("accept", "text/plain");
                        let requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        fetch(newUrl, requestOptions)
                            .then(response => response.text())
                            .then(resultUrl => {


                                if (TOD == 1) {

                                    fetch(`${resultUrl}/PosForm/PrintTable`, requestOpasdasdtions)
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result.status == 200) {
                                                changeList([])
                                                document.querySelector('#OrderNoCounter').value = "";
                                                document.querySelector("#valueHidden").value = "";
                                                pop.classList.remove('show');
                                                pop.style.display = 'none';
                                                alert(result.message ? result.message : "تم بنجاح");
                                                setDataDelevery({})
                                                setRunPrint(false)
                                                $(`option#idT1`).attr('selected', false);
                                                $(`option#idT2`).attr('selected', false);
                                                $(`option#idT3`).attr('selected', false);
                                                $(`option#idT4`).attr('selected', false);
                                                $(`option#idT${idValue}`).attr('selected', true);

                                                let parms = { result, Dates };

                                                if (main) {
                                                    if (printInvoiceKitchen && printVars) {
                                                        let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                        handle.window.parameters = JSON.stringify(parms);
                                                    }
                                                    if (printInvoice && printVars) {
                                                        let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                        handle.window.parameters = JSON.stringify(parms);
                                                    }
                                                    if (printVars && !printInvoice && !printInvoiceKitchen) {
                                                        let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                        handle.window.parameters = JSON.stringify(parms);
                                                    }

                                                }
                                                $('#numPaper').val(1)
                                                setPrintInvoiceKitchen(true);
                                                setPrintVars(true);
                                                setPrintInvoice(false);


                                            }
                                            if (result.status != 200 && result.isSuccess == false) {
                                                alert(result.message);
                                            }
                                        })
                                        .catch(error => {
                                            console.log('Print Error', error)
                                        });
                                }
                                if (TOD == 2) {

                                    fetch(`${resultUrl}/PosForm/PrintDelivery`, requestOpasdasdtions)
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result.status == 200) {
                                                changeList([])
                                                setDataDelevery({})
                                                setRunPrint(false)
                                                document.querySelector('#OrderNoCounter').value = "";
                                                document.querySelector("#valueHidden").value = "";
                                                // alert(result.message ? result.message : "تم بنجاح");
                                                pop.classList.remove('show');
                                                pop.style.display = 'none';
                                                $(`option#idT1`).attr('selected', false);
                                                $(`option#idT2`).attr('selected', false);
                                                $(`option#idT3`).attr('selected', false);
                                                $(`option#idT4`).attr('selected', false);
                                                $(`option#idT${idValue}`).attr('selected', true);

                                                // console.log("Checked 2");


                                                let parms = { result, Dates };
                                                if (main) {
                                                    // if (printInvoiceKitchen || printVars) {
                                                    //     let handle = window.open(window.location.origin + '/PrintDeveley', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                    //     handle.window.parameters = JSON.stringify(parms);
                                                    // }

                                                    if (printInvoiceKitchen && printVars) {
                                                        let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                        handle.window.parameters = JSON.stringify(parms);
                                                    }
                                                    if (printInvoice && printVars) {
                                                        let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                        handle.window.parameters = JSON.stringify(parms);
                                                    }
                                                    if (printVars && !printInvoice && !printInvoiceKitchen) {
                                                        let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                        handle.window.parameters = JSON.stringify(parms);
                                                    }
                                                }
                                                $('#numPaper').val(1)
                                                setPrintInvoiceKitchen(true);
                                                setPrintVars(true);
                                                setPrintInvoice(false);
                                            }
                                            if (result.status != 200 && result.isSuccess == false) {
                                                alert(result.message);
                                            }
                                        })
                                        .catch(error => {
                                            console.log('Print Error', error);

                                        });
                                }

                            })
                            .catch(error => console.log('error', error));
                    } else {
                        if (TOD == 1) {
                            fetch(`${text}/PosForm/PrintTable`, requestOpasdasdtions)
                                .then(response => response.json())
                                .then(result => {
                                    if (result.status == 200) {
                                        changeList([])
                                        document.querySelector('#OrderNoCounter').value = "";
                                        document.querySelector("#valueHidden").value = "";
                                        pop.classList.remove('show');
                                        pop.style.display = 'none';
                                        // alert(result.message ? result.message : "تم بنجاح");
                                        setDataDelevery({})
                                        setRunPrint(false)
                                        $(`option#idT1`).attr('selected', false);
                                        $(`option#idT2`).attr('selected', false);
                                        $(`option#idT3`).attr('selected', false);
                                        $(`option#idT4`).attr('selected', false);
                                        $(`option#idT${idValue}`).attr('selected', true);

                                        let parms = {
                                            result,
                                            Dates
                                        };
                                        if (main) {
                                            // if (printInvoiceKitchen || printVars) {
                                            //     let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                            //     handle.window.parameters = JSON.stringify(parms);
                                            // }

                                            if (printInvoiceKitchen && printVars) {
                                                let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                handle.window.parameters = JSON.stringify(parms);
                                            }
                                            if (printInvoice && printVars) {
                                                let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                handle.window.parameters = JSON.stringify(parms);
                                            }
                                            if (printVars && !printInvoice && !printInvoiceKitchen) {
                                                let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                handle.window.parameters = JSON.stringify(parms);
                                            }
                                        }
                                        $('#numPaper').val(1)
                                        setPrintInvoiceKitchen(true);
                                        setPrintVars(true);
                                        setPrintInvoice(false);

                                    }
                                    if (result.status != 200 && result.isSuccess == false) {
                                        alert(result.message);
                                    }
                                })
                                .catch(error => {
                                    console.log('Print Error', error)
                                });
                        }
                        if (TOD == 2) {
                            fetch(`${text}/PosForm/PrintDelivery`, requestOpasdasdtions)
                                .then(response => response.json())
                                .then(result => {
                                    if (result.status == 200) {
                                        changeList([])
                                        setDataDelevery({})
                                        setRunPrint(false)
                                        document.querySelector('#OrderNoCounter').value = "";
                                        document.querySelector("#valueHidden").value = "";
                                        pop.classList.remove('show');
                                        pop.style.display = 'none';
                                        $(`option#idT1`).attr('selected', false);
                                        $(`option#idT2`).attr('selected', false);
                                        $(`option#idT3`).attr('selected', false);
                                        $(`option#idT4`).attr('selected', false);
                                        $(`option#idT${idValue}`).attr('selected', true);
                                        let parms = {
                                            result,
                                            Dates
                                        };
                                        if (main) {
                                            // if (printInvoiceKitchen || printVars) {
                                            //     let handle = window.open(window.location.origin + '/PrintDeveley', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                            //     handle.window.parameters = JSON.stringify(parms);
                                            // }
                                            if (printInvoiceKitchen && printVars) {
                                                let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                handle.window.parameters = JSON.stringify(parms);
                                            }
                                            if (printInvoice && printVars) {
                                                let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                handle.window.parameters = JSON.stringify(parms);
                                            }
                                            if (printVars && !printInvoice && !printInvoiceKitchen) {
                                                let handle = window.open(window.location.origin + '/PrintTable', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                                                handle.window.parameters = JSON.stringify(parms);
                                            }
                                        }
                                        $('#numPaper').val(1)
                                        setPrintInvoiceKitchen(true);
                                        setPrintVars(true);
                                        setPrintInvoice(false);
                                    }
                                    if (result.status != 200 && result.isSuccess == false) {
                                        alert(result.message);
                                    }
                                })
                                .catch(error => {
                                    console.log('Print Error', error);

                                });
                        }
                    }

                });
        }







}


function firstModal() {
    let Tod = $('#typeIdInvoice').val();
    let pop = document.getElementById('Setting');
    if (Tod == 3 || Tod == 4) {
        alert('غير مسموح بالاجراء الا في حالة الطاولة او التوصيل')
    } else {

        pop.classList.add('show');
        pop.style.display = 'block';
    }

}

export { handelTable, handeltables, handelDeverOrder, getDeverOrder, printDeverOrder, getTableOrder, firstModal };