import React from 'react'

function CustomerEmployee(props) {
    let { t, currencyList, customersGrid, changePaymentValue } = props;
    return (
        <div>
            <table className="table table-striped customerOnly d-none">
                <thead>
                    <tr className="text-center table-list-costing">
                        <th> {t('type')} </th>
                        <th> {t('numebrInvoice')} </th>
                        <th> {t('dateInvoice')} </th>
                        <th> {t('description')} </th>
                        <th> {t('currenyType')} </th>
                        <th> {t('sy')} </th>
                        <th> {t('amountamount')} </th>
                        <th> {t('amountPaid')}</th>
                    </tr>
                </thead>
                <tbody className="lists text-center" style={{ display: "inherit" }}>
                    {customersGrid.map(({ jvtypeName, invoiceId, invoiceDate, description, currencyId, rate, amount, paymentValue }, idx) => {
                        return (
                            <tr key={idx} className={`row-${idx}`}>
                                <td> {jvtypeName} </td>
                                <td> {invoiceId} </td>
                                <td> {invoiceDate} </td>
                                <td> {description} </td>
                                <td>
                                    <select className={`form-control td-${idx} input-customer-currency-${idx}`} disabled>
                                        {currencyList.map(curr => {
                                            return <option key={curr.currencyId} value={curr.currencyId} selected={curr.currencyId == currencyId ? true : false} > {curr.currencyName} </option>
                                        })}
                                    </select>
                                </td>
                                <td> {rate} </td>
                                <td> {amount} </td>
                                <td> <input type="number" className={`form-control text-center w-100 td-${idx} input-paymentValue-${idx}`} defaultValue={paymentValue} onChange={e => changePaymentValue(e.target.value, invoiceId)} /> </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
        </div>
    )
}


export default CustomerEmployee
