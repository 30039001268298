import React, { useEffect, useState } from 'react';
import * as ItemsBalanceReportsStyles from "./StyleItem.module.css";
import moment from 'moment';
import logo from "../../context/image.png"
import $ from "jquery"

function ItemCategoryPrint() {
    const [Items, setItems] = useState([]);
    const [end, setEnd] = useState([]);
    const [Stores, setSotres] = useState([]);
    const [AllStores, setAllStores] = useState([]);
    const [newData, setNewData] = useState({});
    useEffect(() => {
        if (window.parameters) {
            let data = JSON.parse(window.parameters);
            if (data) {
                setSotres(data.storesSend);
                setAllStores(data.stores);
                setItems(data.data);
                // setStart(data.start);
                setEnd(data.end);
                let dataStore = [...new Set(data.data.map(item => item.storeName))]
                let setN = {};
                dataStore.forEach(ele => {
                    setN = { ...setN, [ele]: data.data.filter(item => item.storeName === ele) };
                });
                if (Object.keys(setN).length > 0) {
                    let title;
                    let head;
                    for (let item of Object.keys(setN)) {
                        let dataStoreGroup = [...new Set(setN[item].map(item => item.itemGroupName))]
                        let netObjs = {};
                        dataStoreGroup.forEach(ele => {
                            netObjs = { ...netObjs, [ele]: setN[item].filter(item => item.itemGroupName === ele) };
                        });
                        title = `<h6 class="text-right py-1  title223"> ${item} - </h6>`
                        $('#itemRowsPrint').append(title);


                        if (Object.keys(netObjs).length > 0) {
                            let title2;
                            let hbody;
                            for (let item2 of Object.keys(netObjs)) {

                                title2 = `<h6 class="text-right px-5 py-1 title223"> ${item2} - </h6>`
                                $('#itemRowsPrint').append(title2);
                                hbody = `
                                <table class="table table-striped text-center px-0 mx-0" dir='rtl'>
                                                <thead>
                                                    <tr  class='${ItemsBalanceReportsStyles.fontTable}'>
                                                        <th style={{ width: "200px" }}> </th>
                                                        <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}' style={{ borderLeft: "1px solid #3d7fa2" }}> </th>
                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'  colspan="2" > متوسط التكلفة </th>
                                                        <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}' colspan="2" style={{ border: "1px solid #3d7fa2" }}> الوارد </th>
                                                        <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}' colspan="2" style={{ border: "1px solid #3d7fa2" }}> الصادر </th>
                                                        <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}' colspan="3" style={{ border: "1px solid #3d7fa2" }}> االرصيد </th>
                                                    </tr>
                                                    <tr class='head-bg text-light ${ItemsBalanceReportsStyles.fontTable}'>
                                                        <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'style={{ width: "200px" }}> النوع </th>
                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> الرقم </th>
                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> التاريخ </th>
                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> اجمالي التكلفة </th>
                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> الكمية </th>
                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> التكلفة </th>
                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> الكمية </th>
                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> التكلفة </th>
                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> الكمية </th>
                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> التكلفة </th>
                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> اجمالي تكلفة </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    ${(netObjs[item2].map(element2 => {
                                    return `
                                                                <tr class='${ItemsBalanceReportsStyles.fontTable}'>
                                                            <td class='${ItemsBalanceReportsStyles.fontTable}' style={{ width: "200px" }}> ${element2.jvName} </td>
                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.sourceCode} </td>
                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.sourceDate || ""} </td>
                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.mTotalCost || 0} </td>

                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.quantityIn.toFixed(4) || 0} </td>
                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.costIn.toFixed(4) || 0} </td>
                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.quantityOut.toFixed(4) || 0} </td>
                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.costOut.toFixed(4) || 0} </td>

                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.mQuantity.toFixed(4) || 0} </td>
                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.mCost.toFixed(4) || 0} </td>

                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.totalCost.toFixed(4) || 0} </td>
                                                        </tr>`
                                }).join(''))
                                    }
                                                </tbody>
                                            </table > `
                                $('#itemRowsPrint').append(hbody);

                            }
                        }

                    }
                }

            }
        }
    }, []);



    useEffect(() => {
        const printData = () => {
            window.focus();
            window.print();
        };
        printData();
    }, []);


    return (
        <div className={`${ItemsBalanceReportsStyles.containerHeigth} p - 0 mx - 0`}>

            <div className="row mx-auto p-0">
                <div className="col-12 mx-auto head my-3 px-0">
                    <div className="text-center">
                        <img src={logo} width="120" height="auto" alt="logo company" />
                    </div>
                    <h1 className={`text-center mb-3 ${ItemsBalanceReportsStyles.h1Font} `} style={{ textDecoration: "underline" }}> كارت الصنف </h1>

                    <div className="d-flex flex-row-reverse mb-3">
                        <h6 className={` ${ItemsBalanceReportsStyles.h6Font} `}> :  الي تاريخ   </h6>
                        <h6 className={`mx-2 ${ItemsBalanceReportsStyles.h6Font} `}> {moment(end).format('DD MM YYYY, h:mm:ss a')} </h6>
                    </div>

                    <div className="d-flex flex-row-reverse">
                        <h6 className={` ${ItemsBalanceReportsStyles.h6Font} `}> :  المخزن  </h6>
                        <h6 className={`mx-2 ${ItemsBalanceReportsStyles.h6Font} `}>
                            {Stores.length !== 0
                                ? Stores.length === AllStores.length
                                    ? "كل المخازن"
                                    :
                                    `(${Stores.map(
                                        (row) => row.storeName
                                    )
                                    })`
                                : "كل المخازن"}
                        </h6>
                    </div>
                </div>
                <div className="col-12 mx-auto px-0" id="itemRowsPrint">

                </div>

            </div>
        </div>
    )
}

export default ItemCategoryPrint;
