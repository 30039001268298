// http://172.16.16.20:5051"
import React, { Component } from "react";
import { Links } from "./Links";
import $ from 'jquery';
import text from "./super.txt";
import { PayCashVoice, PayCashVoicePOS } from "./GlobalFunctions";


const ProductContext = React.createContext();

const getToken = () => localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : '';
let headerMain = new Headers();
headerMain.append("Content-Type", "application/json");
headerMain.append("Authorization", `Bearer ${getToken()}`);


class ProductProvider extends Component {

    state = {
        user: {},
        myControll: {},
        posReportNo: 0,
        token: '',
        ip: '',
        active: true,
        config: "http://172.16.16.20:5051", // http://192.168.1.241 
        lang: 'ar',
        links: Links,
        loading: true,
        customers: [],
        customersGroup: [],
        suppliers: [],
        suppliersGroup: [],
        Companines: [],
        Branches: [],
        BranchesF: [],
        FinancialYear: [],
        FinancialYearF: [],
        BranchesFilter: [],
        test123: "",
        CompaninesFilter: [],
        FinancialYearFilter: [],
        Units: [],
        DefinitionItems: [],
        DefinitionItemsF: [],
        DefinitionItemsT: [],
        mostData: [],
        allRefrence: [],
        GeneralVoucher: [],
        GeneralVoucherTransfer: [],
        AccountFormReport: [],
        ReportsAccounts: [],
        GelAllCompanies: [],
        DataVoucher: [],
    };

    componentDidMount() {
        this.setState({
            active: this.getLogin(),
            token: this.getToken(),
            user: this.getUserData(),
            myControll: this.getMyControll(),
            test123: this.getConfigration()
        }, () => {

            document.querySelector(".loading").classList.add("hideloader");
            if (localStorage.getItem('lang')) {
                let lng = JSON.parse(localStorage.getItem('lang'));
                if (lng == 'en') {
                    this.setState({
                        lang: "en"
                    })
                } else {
                    document.querySelectorAll('.a-link-p').forEach(item => {
                        item.classList.add('flex-row-reverse')
                        item.classList.add('text-right')
                    })
                    document.querySelectorAll('.a-link').forEach(item => {
                        item.classList.add('defaultClass')
                    });
                    this.setState({
                        lang: "ar"
                    })
                }
            } else {
                document.querySelectorAll('.a-link-p').forEach(item => {
                    item.classList.add('flex-row-reverse')
                    item.classList.add('text-right')
                })
                document.querySelectorAll('.a-link').forEach(item => {
                    item.classList.add('defaultClass')
                });
                this.setState({
                    lang: "ar"
                })
            }

        });
    }

    getLogin = () => localStorage.getItem("afakyLogin") ? JSON.parse(localStorage.getItem("afakyLogin")) : false;
    getToken = () => localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : '';
    getUserData = () => localStorage.getItem("afakyUser") ? JSON.parse(localStorage.getItem("afakyUser")) : {};
    getMyControll = () => localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")) : {};

    getConfigration = () => {
        console.log("config is ", this.state.config);

        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            this.setState({
                                config: result
                            }, () => {
                                if (this.state.active) {
                                    this.setState({
                                        allRefrence: this.getAllRefrence(),
                                    });
                                }
                            })
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({
                        config: text
                    }, () => {
                        if (this.state.active) {
                            this.setState({
                                allRefrence: this.getAllRefrence(),
                            });
                        }
                    })
                }





            });


        return 0;
    }
    getAllRefrence = () => {
        let refrences = new Headers();
        refrences.append("Content-Type", "application/json");
        refrences.append("Authorization", `Bearer ${this.state.token}`);

        let requestRefrences = {
            method: 'GET',
            headers: refrences,
            redirect: 'follow'
        };
        document.querySelector(".loading").classList.remove("hideloader");

        fetch(`${this.state.config}/UserPrivilege/GetAll`, requestRefrences)
            .then(response => {
                if (response.statusText == "Unauthorized") {
                    localStorage.removeItem('afakyLogin');
                    localStorage.removeItem('ControllAfaky');
                    localStorage.removeItem('afakyToken');
                    localStorage.removeItem('afakyUser');
                    window.location.reload()
                }
                return response.json()
            })
            .then(result => {
                document.querySelector(".loading").classList.add("hideloader");
                if (result.isSuccess) {
                    this.setState({
                        allRefrence: result.data,
                    })
                }
            })
            .catch(error => {
                console.log('error here', error);
                localStorage.removeItem('afakyLogin');
                localStorage.removeItem('ControllAfaky');
                localStorage.removeItem('afakyToken');
                localStorage.removeItem('afakyUser');
                window.location.reload()
            });
    }

    getIp = () => {
        fetch('https://api.ipify.org?format=json').then(res => {
            return res.json()
        }).then(rs => {
            this.setState({
                ip: rs.ip,
            })
        }).catch(e => {
            this.setState({
                ip: "",
            })
        })
    }

    setConfig = (e, conf) => {
        e.preventDefault()
        this.setState({
            config: conf
        }, () => {
            localStorage.setItem("afakyConfig", JSON.stringify(conf));
            window.location.reload()
        })
    }

    // ---------
    getMostData = () => {
        document.querySelector(".loading").classList.remove("hideloader");
        let MostData = new Headers();
        MostData.append("Content-Type", "application/json");
        MostData.append("Authorization", `Bearer ${this.state.token}`);
        let requestOpasdasdtions = {
            method: 'GET',
            headers: MostData,
            redirect: 'follow'
        };

        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(result2 => {
                            this.setState({
                                config: result2
                            }, () => {
                                if (this.state.active) {
                                    if (window.location.pathname == '/POSTables') {
                                        fetch(`${this.state.config}/PosForm/GetAllR`, requestOpasdasdtions)
                                            .then(response => response.json())
                                            .then(result => {
                                                if (result.isSuccess) {
                                                    this.setState({
                                                        posReportNo: result.data[0].posReportNo,
                                                        mostData: result.data[0],
                                                        DefinitionItems: result.data[0].itemsList,
                                                        DefinitionItemsT: result.data[0].itemsList,
                                                    });
                                                } else {
                                                    alert(result.message)
                                                }
                                                document.querySelector(".loading").classList.add("hideloader");
                                            })
                                            .catch(error => {
                                                document.querySelector(".loading").classList.add("hideloader");
                                                console.log('error', error)
                                            });
                                    } else {
                                        fetch(`${this.state.config}/PosForm/GetAll`, requestOpasdasdtions)
                                            .then(response => response.json())
                                            .then(result => {
                                                if (result.isSuccess) {
                                                    this.setState({
                                                        posReportNo: result.data[0].posReportNo,
                                                        mostData: result.data[0],
                                                        DefinitionItems: result.data[0].itemsList,
                                                        DefinitionItemsT: result.data[0].itemsList,
                                                    });
                                                } else {
                                                    alert(result.message)
                                                }
                                                document.querySelector(".loading").classList.add("hideloader");
                                            })
                                            .catch(error => {
                                                document.querySelector(".loading").classList.add("hideloader");
                                                console.log('error', error)
                                            });
                                    }

                                }
                            })
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({
                        config: text
                    }, () => {
                        if (this.state.active) {
                            if (window.location.pathname == '/POSTables') {
                                fetch(`${this.state.config}/PosForm/GetAllR`, requestOpasdasdtions)
                                    .then(response => response.json())
                                    .then(result => {
                                        if (result.isSuccess) {
                                            this.setState({
                                                posReportNo: result.data[0].posReportNo,
                                                mostData: result.data[0],
                                                DefinitionItems: result.data[0].itemsList,
                                                DefinitionItemsT: result.data[0].itemsList,
                                            });
                                        } else {
                                            alert(result.message)
                                        }
                                        document.querySelector(".loading").classList.add("hideloader");
                                    })
                                    .catch(error => {
                                        document.querySelector(".loading").classList.add("hideloader");
                                        console.log('error', error)
                                    });
                            } else {
                                fetch(`${this.state.config}/PosForm/GetAll`, requestOpasdasdtions)
                                    .then(response => response.json())
                                    .then(result => {
                                        if (result.isSuccess) {
                                            this.setState({
                                                posReportNo: result.data[0].posReportNo,
                                                mostData: result.data[0],
                                                DefinitionItems: result.data[0].itemsList,
                                                DefinitionItemsT: result.data[0].itemsList,
                                            });
                                        } else {
                                            alert(result.message)
                                        }
                                        document.querySelector(".loading").classList.add("hideloader");
                                    })
                                    .catch(error => {
                                        document.querySelector(".loading").classList.add("hideloader");
                                        console.log('error', error)
                                    });
                            }

                        }
                    })
                }


            });

    }

    getCompanies = () => {
        let MostData = new Headers();
        MostData.append("Content-Type", "application/json");
        MostData.append("Authorization", `Bearer ${this.state.token}`);
        let requestOpasdasdtions = {
            method: 'POST',
            headers: MostData,
            body: JSON.stringify({
                "userId": this.state.user.userId
            }),
            redirect: 'follow'
        };
        fetch(`${this.state.config}/UserCbf/GetAll`, requestOpasdasdtions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    GelAllCompanies: result.data,
                });
            })
            .catch(error => {
                console.log('error', error)
            });
    }

    // ----------------
    getGeneralVoucher = (type, num = 1) => {
        if (num == 1) {
            document.querySelector(".loading").classList.remove("hideloader");
            fetch(text)
                .then(r => r.text())
                .then(text => {
                    let isCheck = text.includes("Afaky#");
                    if (isCheck) {
                        let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                        let myHeaders = new Headers();
                        myHeaders.append("accept", "text/plain");

                        let requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        fetch(newUrl, requestOptions)
                            .then(response => response.text())
                            .then(result => {
                                if (this.state.active) {
                                    fetch(`${result}/VoucherForm/GetAll`, {
                                        method: "POST",
                                        headers: {
                                            Authorization: `Bearer ${this.state.token}`,
                                            "Content-Type": "application/json",
                                        },
                                        redirect: "follow",
                                        body: JSON.stringify({
                                            companyId: this.state.myControll.companyId,
                                            branchId: this.state.myControll.branchId,
                                            fyId: this.state.myControll.financialYearId ? this.state.myControll.financialYearId : this.state.myControll.financialYear,
                                            voucherType: type, // سند قبض عام
                                            ftId: 0,
                                        }),
                                    })
                                        .then((response) => response.json())
                                        .then((result) => {
                                            document.querySelector(".loading").classList.add("hideloader");
                                            if (result.isSuccess) {
                                                this.setState({
                                                    GeneralVoucher: result.data[0],
                                                    DataVoucher: result.data[0],
                                                });
                                            } else {
                                                alert(result.message)
                                            }
                                        }).catch((error) => {
                                            document.querySelector(".loading").classList.add("hideloader");
                                            console.log("VoucherForm", error)
                                        });
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        if (this.state.active) {
                            fetch(`${text}/VoucherForm/GetAll`, {
                                method: "POST",
                                headers: {
                                    Authorization: `Bearer ${this.state.token}`,
                                    "Content-Type": "application/json",
                                },
                                redirect: "follow",
                                body: JSON.stringify({
                                    companyId: this.state.myControll.companyId,
                                    branchId: this.state.myControll.branchId,
                                    fyId: this.state.myControll.financialYearId ? this.state.myControll.financialYearId : this.state.myControll.financialYear,
                                    voucherType: type, // سند قبض عام
                                    ftId: 0,
                                }),
                            })
                                .then((response) => response.json())
                                .then((result) => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    if (result.isSuccess) {
                                        this.setState({
                                            GeneralVoucher: result.data[0],
                                            DataVoucher: result.data[0],
                                        });
                                    } else {
                                        alert(result.message)
                                    }
                                }).catch((error) => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    console.log("VoucherForm", error)
                                });
                        }
                    }

                });
        }
    }

    getAccountFormReport = () => {
        document.querySelector(".loading").classList.remove("hideloader");
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(result1 => {
                            if (this.state.active) {
                                fetch(`${result1}/AccountFormReport/GetDefault`, {
                                    method: "POST",
                                    headers: {
                                        Authorization: `Bearer ${this.state.token}`,
                                        "Content-Type": "application/json",
                                    },
                                    redirect: "follow",
                                    body: JSON.stringify({
                                        companyId: this.state.user.companyId,
                                        formName: "TsmAccountStatement"
                                    }),
                                })
                                    .then((response) => response.json())
                                    .then((result) => {
                                        document.querySelector(".loading").classList.add("hideloader");
                                        if (result.isSuccess) {
                                            this.setState({
                                                AccountFormReport: result.data,
                                            });
                                        }
                                    }).catch((error) => {
                                        document.querySelector(".loading").classList.add("hideloader");
                                        console.log("AccountFormReport", error)
                                    });
                            }
                        })
                        .catch(error => console.log('error', error));
                } else {
                    if (this.state.active) {
                        fetch(`${text}/AccountFormReport/GetDefault`, {
                            method: "POST",
                            headers: {
                                Authorization: `Bearer ${this.state.token}`,
                                "Content-Type": "application/json",
                            },
                            redirect: "follow",
                            body: JSON.stringify({
                                companyId: this.state.user.companyId,
                                formName: "TsmAccountStatement"
                            }),
                        })
                            .then((response) => response.json())
                            .then((result) => {
                                document.querySelector(".loading").classList.add("hideloader");
                                if (result.isSuccess) {
                                    this.setState({
                                        AccountFormReport: result.data,
                                    });
                                }
                            }).catch((error) => {
                                document.querySelector(".loading").classList.add("hideloader");
                                console.log("AccountFormReport", error)
                            });
                    }
                }



            });

    }

    getGeneralVoucherTransfer = () => {
        fetch(`${this.state.config}/VoucherForm/GetAll`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${this.state.token}`,
                "Content-Type": "application/json",
            },
            redirect: "follow",
            body: JSON.stringify({
                companyId: this.state.user.companyId,
                branchId: this.state.user.branchId,
                fyId: this.state.user.financialYear,
                voucherType: 7, // سند قبض عام
                ftId: 0,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.isSuccess) {
                    console.log(result);
                    this.setState({
                        GeneralVoucherTransfer: result.data[0],
                        // AccountFormReport: this.getAccountFormReport()
                    });
                }
            }).catch((error) => console.log("error", error));
    }


    getUnits = (check = 1) => {
        let token = localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : 'no Token'
        if (check == 1) {
            let MostData = new Headers();
            MostData.append("Content-Type", "application/json");
            MostData.append("Authorization", `Bearer ${this.getToken()}`);

            document.querySelector(".loading").classList.remove("hideloader");
            fetch(text)
                .then(r => r.text())
                .then(text => {
                    let isCheck = text.includes("Afaky#");



                    let myHeadersData = new Headers();
                    myHeadersData.append("accept", "*/*");
                    myHeadersData.append("Authorization", `Bearer ${token}`);
                    myHeadersData.append("Content-Type", "application/json");




                    if (isCheck) {
                        let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                        let myHeaders = new Headers();
                        myHeaders.append("accept", "text/plain");
                        let requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            redirect: 'follow'
                        };



                        fetch(newUrl, requestOptions)
                            .then(response => response.text())
                            .then(resultUrl => {
                                // fetch(`${resultUrl}/Unit/GetAll`)
                                fetch(`${resultUrl}/Unit/GetAll`, {
                                    method: 'POST',
                                    headers: myHeadersData,
                                    body: JSON.stringify({
                                        "companyId": 1
                                    }),
                                    redirect: 'follow'
                                })
                                    .then(response => response.json())
                                    .then(result => {
                                        document.querySelector(".loading").classList.add("hideloader");
                                        this.setState({
                                            Units: result.data
                                        })
                                    })
                                    .catch(error => {
                                        document.querySelector(".loading").classList.add("hideloader");
                                        console.log('error', error)
                                    });
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        fetch(`${text}/Unit/GetAll`, {
                            method: 'POST',
                            headers: myHeadersData,
                            body: JSON.stringify({
                                "companyId": 1
                            }),
                            redirect: 'follow'
                        })
                            .then(response => response.json())
                            .then(result => {
                                document.querySelector(".loading").classList.add("hideloader");
                                this.setState({
                                    Units: result.data
                                })
                            })
                            .catch(error => {
                                document.querySelector(".loading").classList.add("hideloader");
                                console.log('error', error)
                            });

                    }

                });







        }

    }
    SaveUnits = (code, name_ar, name_en, notes, texCode, setName_EN, setName_Ar, setNotes, setTexCode, history) => {
        let { companyId } = this.state.user;
        let MostData = new Headers();
        MostData.append("Content-Type", "application/json");
        MostData.append("Authorization", `Bearer ${this.getToken()}`);

        document.querySelector(".loading").classList.remove("hideloader");

        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/Unit/Add`, {
                                method: 'POST',
                                headers: MostData,
                                redirect: 'follow',
                                body: JSON.stringify({
                                    id: 0,
                                    code: code,
                                    nameA: name_ar,
                                    nameE: name_en,
                                    notes: notes,
                                    companyId: companyId,
                                    taxCode: texCode
                                }),
                            })
                                .then(response => response.json())
                                .then(result => {
                                    if (result.isSuccess == true) {
                                        this.setState({
                                            Units: [...this.state.Units, result.data]
                                        });
                                        setName_Ar('')
                                        setName_EN('')
                                        setNotes('')
                                        setTexCode('')
                                        history.push({ pathname: "/Units", params: { status: 2 } })
                                    }
                                    document.querySelector(".loading").classList.add("hideloader");
                                })
                                .catch(error => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    console.log('error', error)
                                });
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${text}/Unit/Add`, {
                        method: 'POST',
                        headers: MostData,
                        redirect: 'follow',
                        body: JSON.stringify({
                            id: 0,
                            code: code,
                            nameA: name_ar,
                            nameE: name_en,
                            notes: notes,
                            companyId: companyId,
                            taxCode: texCode
                        }),
                    })
                        .then(response => response.json())
                        .then(result => {
                            if (result.isSuccess == true) {
                                this.setState({
                                    Units: [...this.state.Units, result.data]
                                });
                                setName_Ar('')
                                setName_EN('')
                                setNotes('')
                                setTexCode('')
                                history.push({ pathname: "/Units", params: { status: 2 } })
                            }
                            document.querySelector(".loading").classList.add("hideloader");
                        })
                        .catch(error => {
                            document.querySelector(".loading").classList.add("hideloader");
                            console.log('error', error)
                        });
                }

            });
    }
    DeleteUnit = row => {
        let questionF = window.confirm(`هل تريد حذف الوحدة ${row.nameA}`);
        if (questionF) {
            document.querySelector(".loading").classList.remove("hideloader");
            let { companyId } = this.state.user;
            let MostData = new Headers();
            MostData.append("Content-Type", "application/json");
            MostData.append("Authorization", `Bearer ${this.getToken()}`);


            fetch(text)
                .then(r => r.text())
                .then(text => {
                    let isCheck = text.includes("Afaky#");
                    if (isCheck) {
                        let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                        let myHeaders = new Headers();
                        myHeaders.append("accept", "text/plain");
                        let requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        fetch(newUrl, requestOptions)
                            .then(response => response.text())
                            .then(resultUrl => {
                                fetch(`${resultUrl}/Unit/Delete`, {
                                    method: 'DELETE',
                                    headers: MostData,
                                    redirect: 'follow',
                                    body: JSON.stringify({
                                        "companyId": companyId,
                                        "id": row.id
                                    })
                                })
                                    .then(response => response.json())
                                    .then(result => {
                                        if (result.isSuccess == true) {
                                            let newUnits = [...this.state.Units];
                                            newUnits = newUnits.filter(el => el.id != row.id);
                                            this.setState({
                                                Units: newUnits
                                            });
                                            alert(result.message)
                                        } else {
                                            alert(result.message)
                                        }
                                        document.querySelector(".loading").classList.add("hideloader");
                                    })
                                    .catch(error => {
                                        document.querySelector(".loading").classList.add("hideloader");
                                        console.log('error', error)
                                    });
                            })
                            .catch(error => {
                                console.log('error', error)
                            });
                    } else {
                        fetch(`${text}/Unit/Delete`, {
                            method: 'DELETE',
                            headers: MostData,
                            redirect: 'follow',
                            body: JSON.stringify({
                                "companyId": companyId,
                                "id": row.id
                            })
                        })
                            .then(response => response.json())
                            .then(result => {
                                if (result.isSuccess == true) {
                                    let newUnits = [...this.state.Units];
                                    newUnits = newUnits.filter(el => el.id != row.id);
                                    this.setState({
                                        Units: newUnits
                                    });
                                    alert(result.message)
                                } else {
                                    alert(result.message)
                                }
                                document.querySelector(".loading").classList.add("hideloader");
                            })
                            .catch(error => {
                                document.querySelector(".loading").classList.add("hideloader");
                                console.log('error', error)
                            });
                    }

                });
        }

    }
    UpdateUnit = (id, code, name_ar, name_en, notes, texCode, setName_EN, setName_Ar, setNotes, setTexCode, history) => {


        let MostData = new Headers();
        MostData.append("Content-Type", "application/json");
        MostData.append("Authorization", `Bearer ${this.getToken()}`);
        document.querySelector(".loading").classList.remove("hideloader");
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/Unit/Edit`, {
                                method: 'PUT',
                                headers: {
                                    "Authorization": `Bearer ${this.getToken()}`,
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    companyId: this.state.user.companyId,
                                    id: id,
                                    code: code,
                                    nameA: name_ar,
                                    nameE: name_en,
                                    notes: notes,
                                    taxCode: texCode
                                }),
                                redirect: 'follow'
                            })
                                .then(response => response.json())
                                .then(result => {
                                    if (result.isSuccess == true) {
                                        let Units = [...this.state.Units];
                                        let unit = Units.find(item => item.id == id);
                                        unit.nameA = result.data.nameA;
                                        unit.nameE = result.data.nameE;
                                        unit.taxCode = result.data.taxCode;
                                        unit.notes = result.data.notes;
                                        this.setState({
                                            Units: [...Units]
                                        });
                                        setName_Ar('')
                                        setName_EN('')
                                        setNotes('')
                                        setTexCode('')
                                        history.push({ pathname: "/Units", params: { status: 2 } })
                                    }
                                    document.querySelector(".loading").classList.add("hideloader");
                                })
                                .catch(error => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    console.log('error', error)
                                });
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${text}/Unit/Edit`, {
                        method: 'PUT',
                        headers: {
                            "Authorization": `Bearer ${this.getToken()}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            companyId: this.state.user.companyId,
                            id: id,
                            code: code,
                            nameA: name_ar,
                            nameE: name_en,
                            notes: notes,
                            taxCode: texCode
                        }),
                        redirect: 'follow'
                    })
                        .then(response => response.json())
                        .then(result => {
                            if (result.isSuccess == true) {
                                let Units = [...this.state.Units];
                                let unit = Units.find(item => item.id == id);
                                unit.nameA = result.data.nameA;
                                unit.nameE = result.data.nameE;
                                unit.taxCode = result.data.taxCode;
                                unit.notes = result.data.notes;
                                this.setState({
                                    Units: [...Units]
                                });
                                setName_Ar('')
                                setName_EN('')
                                setNotes('')
                                setTexCode('')
                                history.push({ pathname: "/Units", params: { status: 2 } })
                            }
                            document.querySelector(".loading").classList.add("hideloader");
                        })
                        .catch(error => {
                            document.querySelector(".loading").classList.add("hideloader");
                            console.log('error', error)
                        });
                }

            });


    }
    // Customer Groups
    getCustomersGroup = (isGet = 1) => {
        if (isGet == 1) {
            document.querySelector(".loading").classList.remove("hideloader");
            fetch(text)
                .then(r => r.text())
                .then(text => {
                    let isCheck = text.includes("Afaky#");
                    if (isCheck) {
                        let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                        let myHeaders = new Headers();
                        myHeaders.append("accept", "text/plain");
                        fetch(newUrl, {
                            method: 'POST',
                            headers: myHeaders,
                            redirect: 'follow'
                        })
                            .then(response => response.text())
                            .then(resultUrl => {
                                this.getCustomersGroupData(resultUrl)
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        this.getCustomersGroupData(text)
                    }
                });
        }

        // customersGroup: result.data,
    }
    getCustomersGroupData = (api) => {
        let { companyId } = this.getUserData();
        fetch(`${api}/CustomerGroup/GetAll`, {
            method: 'POST',
            headers: headerMain,
            redirect: 'follow',
            body: JSON.stringify({
                companyId: companyId
            }),
        })
            .then(response => response.json())
            .then(result => {
                this.setState({
                    customersGroup: result.data
                });
                document.querySelector(".loading").classList.add("hideloader");
            })
            .catch(error => {
                document.querySelector(".loading").classList.add("hideloader");
                console.log('error', error)
            });
    }
    createCustomersGroup = (notes, name_ar, name_en, checked, setName_Ar, setName_EN, setCategory, setNotes, byAdminstrator, setByAdminstrator, history) => {
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");
                    fetch(newUrl, {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    })
                        .then(response => response.text())
                        .then(resultUrl => {
                            this.saveCustomersGroup(resultUrl, { notes, name_ar, name_en, checked, setName_Ar, setName_EN, setCategory, setNotes, byAdminstrator, setByAdminstrator, history })
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.saveCustomersGroup(text, { notes, name_ar, name_en, checked, setName_Ar, setName_EN, setCategory, setNotes, byAdminstrator, setByAdminstrator, history })
                }
            });
    }
    saveCustomersGroup = (api, data) => {
        let { companyId } = this.getUserData();
        fetch(`${api}/CustomerGroup/Add`, {
            method: 'POST',
            headers: headerMain,
            redirect: 'follow',
            body: JSON.stringify({
                companyId: companyId,
                id: 0,
                code: "",
                nameA: data.name_ar,
                nameE: data.name_en,
                notes: data.notes,
                byAdmin: data.byAdminstrator
            }),
        })
            .then(response => response.json())
            .then(result => {
                this.setState({
                    customersGroup: [...this.state.customersGroup, result.data]
                });
                data.setName_Ar();
                data.setName_EN();
                data.setByAdminstrator(false);
                data.setNotes('')
                data.history.push({ pathname: "/customersGroup", params: { status: 2 } })

                document.querySelector(".loading").classList.add("hideloader");
            })
            .catch(error => {
                document.querySelector(".loading").classList.add("hideloader");
                console.log('error', error)
            });
    }
    DeleteCustomersGroup = row => {
        let { companyId } = this.getUserData();
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");
                    fetch(newUrl, {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    })
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/CustomerGroup/Delete`, this.createHeaderRequest('DELETE', { companyId: companyId, id: row.id }))
                                .then(response => response.json())
                                .then(result => {
                                    if (result.isSuccess == true) {
                                        let customersGroup = [...this.state.customersGroup];
                                        customersGroup = customersGroup.filter(el => el.id != row.id);
                                        this.setState({
                                            customersGroup: customersGroup
                                        });
                                        alert(result.message)
                                    } else {
                                        alert(result.message)
                                    }
                                }).catch(error => console.log('error', error));
                            document.querySelector(".loading").classList.add("hideloader");
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${text}/CustomerGroup/Delete`, this.createHeaderRequest('DELETE', { companyId: companyId, id: row.id }))
                        .then(response => response.json())
                        .then(result => {
                            if (result.isSuccess == true) {
                                let customersGroup = [...this.state.customersGroup];
                                customersGroup = customersGroup.filter(el => el.id != row.id);
                                this.setState({
                                    customersGroup: customersGroup
                                });
                                alert(result.message)
                            } else {
                                alert(result.message)
                            }
                        }).catch(error => console.log('error', error));
                    document.querySelector(".loading").classList.add("hideloader");
                }
            });
    }
    UpdateCustomersGroup = (customerGroup, notes, name_ar, name_en, setName_Ar, setName_EN, setNotes, byAdminstrator, setByAdminstrator, history) => {
        document.querySelector(".loading").classList.remove("hideloader");
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");
                    fetch(newUrl, this.createHeaderRequest('POST'))
                        .then(response => response.text())
                        .then(resultUrl => this.SaveChangeCustomerGroup(resultUrl, { customerGroup, notes, name_ar, name_en, setName_Ar, setName_EN, setNotes, byAdminstrator, setByAdminstrator, history }))
                        .catch(error => console.log('error', error));
                } else {
                    this.SaveChangeCustomerGroup(text, { customerGroup, notes, name_ar, name_en, setName_Ar, setName_EN, setNotes, byAdminstrator, setByAdminstrator, history })
                }
            });
    }
    SaveChangeCustomerGroup = (api, data) => {
        fetch(`${api}/CustomerGroup/Edit`, this.createHeaderRequest('PUT', {
            id: data.customerGroup.id,
            code: data.customerGroup.code,
            nameA: data.name_ar,
            nameE: data.name_en,
            notes: data.notes,
            companyId: data.customerGroup.companyId,
            byAdmin: data.byAdminstrator
        }))
            .then(response => response.json())
            .then(result => {
                if (result.isSuccess == true) {
                    let customersGroup = [...this.state.customersGroup];
                    let customerGroup = customersGroup.find(item => item.id == data.customerGroup.id);
                    customerGroup.nameA = result.data.nameA;
                    customerGroup.nameE = result.data.nameE;
                    customerGroup.notes = result.data.notes;
                    customerGroup.byAdmin = result.data.byAdmin;
                    this.setState({
                        customersGroup: [...customersGroup]
                    });
                    document.querySelector(".loading").classList.add("hideloader");
                    alert(result.message)
                    data.history.push({ pathname: "/customersGroup", params: { status: 2 } })
                } else {
                    alert(result.message)
                }
            }).catch(error => {
                console.log('error', error)
                document.querySelector(".loading").classList.add("hideloader");
            });
    }
    // Supplier Groups
    getSupplierGroup = (isGet = 1) => {
        if (isGet == 1) {
            document.querySelector(".loading").classList.remove("hideloader");
            fetch(text)
                .then(r => r.text())
                .then(text => {
                    let isCheck = text.includes("Afaky#");
                    if (isCheck) {
                        let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                        let myHeaders = new Headers();
                        myHeaders.append("accept", "text/plain");
                        fetch(newUrl, this.createHeaderRequest('POST'))
                            .then(response => response.text())
                            .then(resultUrl => {
                                this.getSuppliersGroupData(resultUrl)
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        this.getSuppliersGroupData(text)
                    }
                });
        }

        // customersGroup: result.data,
    }
    getSuppliersGroupData = (api) => {
        let { companyId } = this.getUserData();
        fetch(`${api}/SupplierGroup/GetAll`, this.createHeaderRequest('POST', { companyId: companyId }))
            .then(response => response.json())
            .then(result => {
                this.setState({
                    suppliersGroup: result.data
                });
                document.querySelector(".loading").classList.add("hideloader");
            })
            .catch(error => {
                document.querySelector(".loading").classList.add("hideloader");
                console.log('error', error)
            });
    }

    createSupplierGroup = (notes, name_ar, name_en, checked, setName_Ar, setName_EN, setCategory, setNotes, byAdminstrator, setByAdminstrator, history) => {
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");
                    fetch(newUrl, this.createHeaderRequest('POST'))
                        .then(response => response.text())
                        .then(resultUrl => {
                            this.saveSuppliersGroup(resultUrl, { notes, name_ar, name_en, checked, setName_Ar, setName_EN, setCategory, setNotes, byAdminstrator, setByAdminstrator, history })
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.saveSuppliersGroup(text, { notes, name_ar, name_en, checked, setName_Ar, setName_EN, setCategory, setNotes, byAdminstrator, setByAdminstrator, history })
                }
            });
    }
    saveSuppliersGroup = (api, data) => {
        let { companyId } = this.getUserData();
        fetch(`${api}/SupplierGroup/Add`, this.createHeaderRequest('POST', { companyId: companyId, id: 0, code: "", nameA: data.name_ar, nameE: data.name_en, notes: data.notes, byAdmin: data.byAdminstrator }))
            .then(response => response.json())
            .then(result => {
                this.setState({
                    suppliersGroup: [...this.state.suppliersGroup, result.data]
                });
                data.setName_Ar();
                data.setName_EN();
                data.setByAdminstrator(false);
                data.setNotes('')
                data.history.push({ pathname: "/suppliersGroups", params: { status: 2 } })
                document.querySelector(".loading").classList.add("hideloader");
            })
            .catch(error => {
                document.querySelector(".loading").classList.add("hideloader");
                console.log('error', error)
            });
    }
    UpdateSupplierGroup = (customerGroup, notes, name_ar, name_en, setName_Ar, setName_EN, setNotes, byAdminstrator, setByAdminstrator, history) => {
        document.querySelector(".loading").classList.remove("hideloader");
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");
                    fetch(newUrl, this.createHeaderRequest('POST'))
                        .then(response => response.text())
                        .then(resultUrl => this.SaveChangeSupplierGroup(resultUrl, { customerGroup, notes, name_ar, name_en, setName_Ar, setName_EN, setNotes, byAdminstrator, setByAdminstrator, history }))
                        .catch(error => console.log('error', error));
                } else {
                    this.SaveChangeSupplierGroup(text, { customerGroup, notes, name_ar, name_en, setName_Ar, setName_EN, setNotes, byAdminstrator, setByAdminstrator, history })
                }
            });
    }
    SaveChangeSupplierGroup = (api, data) => {
        fetch(`${api}/SupplierGroup/Edit`, this.createHeaderRequest('PUT', {
            id: data.customerGroup.id,
            code: data.customerGroup.code,
            nameA: data.name_ar,
            nameE: data.name_en,
            notes: data.notes,
            companyId: data.customerGroup.companyId,
            byAdmin: data.byAdminstrator
        }))
            .then(response => response.json())
            .then(result => {
                if (result.isSuccess == true) {
                    let suppliersGroup = [...this.state.suppliersGroup];
                    let supplierGroup = suppliersGroup.find(item => item.id == data.customerGroup.id);
                    supplierGroup.nameA = result.data.nameA;
                    supplierGroup.nameE = result.data.nameE;
                    supplierGroup.notes = result.data.notes;
                    supplierGroup.byAdmin = result.data.byAdmin;
                    this.setState({
                        suppliersGroup: [...suppliersGroup]
                    });
                    document.querySelector(".loading").classList.add("hideloader");
                    alert(result.message)
                    data.history.push({ pathname: "/suppliersGroups", params: { status: 2 } })
                } else {
                    alert(result.message)
                }
            }).catch(error => {
                console.log('error', error)
                document.querySelector(".loading").classList.add("hideloader");
            });
    }
    DeleteSupplierGroup = row => {
        let { companyId } = this.getUserData();
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");
                    fetch(newUrl, this.createHeaderRequest('POST'))
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/SupplierGroup/Delete`, this.createHeaderRequest('DELETE', { companyId: companyId, id: row.id }))
                                .then(response => response.json())
                                .then(result => {
                                    if (result.isSuccess == true) {
                                        let suppliersGroup = [...this.state.suppliersGroup];
                                        suppliersGroup = suppliersGroup.filter(el => el.id != row.id);
                                        this.setState({
                                            suppliersGroup: suppliersGroup
                                        });
                                        alert(result.message)
                                    } else {
                                        alert(result.message)
                                    }
                                }).catch(error => console.log('error', error));
                            document.querySelector(".loading").classList.add("hideloader");
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${text}/SupplierGroup/Delete`, this.createHeaderRequest('DELETE', { companyId: companyId, id: row.id }))
                        .then(response => response.json())
                        .then(result => {
                            if (result.isSuccess == true) {
                                let suppliersGroup = [...this.state.suppliersGroup];
                                suppliersGroup = suppliersGroup.filter(el => el.id != row.id);
                                this.setState({
                                    suppliersGroup: suppliersGroup
                                });
                                alert(result.message)
                            } else {
                                alert(result.message)
                            }
                        }).catch(error => console.log('error', error));
                    document.querySelector(".loading").classList.add("hideloader");
                }
            });
    }






    getCustomers = () => {

        let headerCoustomers = new Headers();
        headerCoustomers.append("Authorization", `Bearer ${this.state.token}`);

        let requestOptionsCoustomers = {
            method: 'GET',
            headers: headerCoustomers,
            redirect: 'follow'
        };

        fetch(`${this.state.config}/SupCusEmp/GetAllCustomer?companyId=${this.state.user.companyId}`, requestOptionsCoustomers)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    customers: result.data
                })
            })
            .catch(error => console.log('error', error));

    }

    getDefinitionItems = (num = 1) => {
        if (num == 1) {
            document.querySelector(".loading").classList.remove("hideloader");
            fetch(text)
                .then(r => r.text())
                .then(text => {
                    let isCheck = text.includes("Afaky#");



                    let myHeadersData = new Headers();
                    myHeadersData.append("accept", "*/*");
                    myHeadersData.append("Authorization", `Bearer ${this.getToken()}`);
                    myHeadersData.append("Content-Type", "application/json");

                    if (isCheck) {
                        let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                        let myHeaders = new Headers();
                        myHeaders.append("accept", "text/plain");
                        let requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        fetch(newUrl, requestOptions)
                            .then(response => response.text())
                            .then(resultUrl => {
                                fetch(`${resultUrl}/Item/GetAll`, {
                                    method: 'POST',
                                    headers: myHeadersData,
                                    body: JSON.stringify({
                                        "companyId": 1
                                    }),
                                    redirect: 'follow'
                                })
                                    .then(response => response.json())
                                    .then(result => {
                                        console.log("resultresult ", result);
                                        this.setState({
                                            DefinitionItems: result.data[0],
                                            DefinitionItemsF: result.data[0]
                                        }, () => {
                                            document.querySelector(".loading").classList.add("hideloader");
                                        })
                                    })
                                    .catch(error => {
                                        document.querySelector(".loading").classList.add("hideloader");
                                        console.log('error', error)
                                    });
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        fetch(`${text}/Item/GetAll`, {
                            method: 'POST',
                            headers: myHeadersData,
                            body: JSON.stringify({
                                "companyId": 1
                            }),
                            redirect: 'follow'
                        })
                            .then(response => response.json())
                            .then(result => {
                                // document.querySelector(".loading").classList.add("hideloader");
                                console.log("resultresult ", result);

                                this.setState({
                                    DefinitionItems: result.data[0],
                                    DefinitionItemsF: result.data[0]
                                }, () => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                })
                            })
                            .catch(error => {
                                document.querySelector(".loading").classList.add("hideloader");
                                console.log('error', error)
                            });

                    }

                });
        }

    }

    getSuppliersGroup = () => {
        fetch(`${this.state.config}/SupplierGroup/GetAll`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.state.token}`
            },
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                this.setState({
                    suppliersGroup: result.data
                })
            })
            .catch(error => console.log('error', error));


    }

    onChangeLanguage = (e, i18n) => {
        localStorage.setItem('lang', JSON.stringify(e.target.value));
        window.location.reload();
        i18n.changeLanguage(e.target.value);
    }

    getCompanines = () => {
        let config = this.state.config;
        let HeadersCompany = new Headers();
        HeadersCompany.append("Authorization", `Bearer ${this.state.token}`);
        var requestOptions = {
            method: 'GET',
            headers: HeadersCompany,
            redirect: 'follow'
        };

        fetch(`${config}/Company/GetAll`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    Companines: result.data,
                    // CompaninesFilter: result.data
                })
            })
            .catch(error => console.log('error', error));
    }

    // done new version
    DelCompany = (rowData) => {
        let questionC = window.confirm(`هل تريد حذف شركة ${rowData.nameA}`);
        let config = this.state.config;

        if (questionC) {
            let Companines = [...this.state.Companines]
            let myHeadersDelete = new Headers();
            myHeadersDelete.append("Authorization", `Bearer ${this.state.token}`);


            var requestOptions = {
                method: 'DELETE',
                headers: myHeadersDelete,
                redirect: 'follow'
            };
            fetch(`${config}/Company/Delete?companyId=${rowData.companyId}`, requestOptions)
                .then(response => response.json())
                .then(del => {
                    if (del.status == 200) {
                        Companines = Companines.filter(item => item.companyId != rowData.companyId);
                        this.setState({
                            Companines: Companines
                        })
                        alert(del.message)
                    } else {
                        alert(del.message)
                    }
                })
                .catch(error => console.log('error', error));

        }
    }

    // Done New Version
    newCompany = (e, data, setNameAr, setNameEN) => {
        e.preventDefault();
        let config = this.state.config;
        let AddmyHeaders = new Headers();
        AddmyHeaders.append("Authorization", `Bearer ${this.state.token}`);
        AddmyHeaders.append("Content-Type", "application/json");

        var rawAddCompany = JSON.stringify(data);

        var requestOptions = {
            method: 'POST',
            headers: AddmyHeaders,
            body: rawAddCompany,
            redirect: 'follow'
        };

        fetch(`${config}/Company/Add`, requestOptions)
            .then(response => response.json())
            .then(add => {
                if (add.isSuccess) {
                    this.getCompanines()
                    setNameAr("")
                    setNameEN("")
                    alert(add.message)
                } else {
                    this.getCompanines()
                    alert(add.message)
                }
            })
            .catch(error => console.log('error', error));

    }
    // Done New Version
    getBranches = () => {
        let config = this.state.config;
        let HeadersBranches = new Headers();
        HeadersBranches.append("Authorization", `Bearer ${this.state.token}`);
        var requestOptions = {
            method: 'GET',
            headers: HeadersBranches,
            redirect: 'follow'
        };

        fetch(`${config}/Branch/GetAll`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let after = result.data.filter(bra => bra.companyId == this.state.user.branchId)
                this.setState({
                    Branches: result.data,
                    BranchesF: after
                })
            })
            .catch(error => console.log('error', error));

    }

    // done new version
    UpdateCompany = (e, data) => {
        e.preventDefault();;
        let config = this.state.config;

        fetch(`${config}/Company/Edit`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.state.token}`
            },
            body: JSON.stringify({
                "companyId": data.id,
                "companyCode": "",
                "nameA": data.nameAr,
                "nameE": data.nameEn,
            }),
        })
            .then(response => response.json())
            .then(result => {
                this.getCompanines();
                alert(result.message);
            })
            .catch(error => {
                alert("حدث خطا !")
            });
    }

    // Done new version
    DelBranch = (rowData) => {
        let question = window.confirm(`هل تريد حذف فرع ${rowData.nameA}`);
        let config = this.state.config;

        if (question) {
            fetch(`${config}/Branch/Delete?branchId=${rowData.branchId}&companyId=${rowData.companyId}`, {
                method: 'DELETE',
                headers: {
                    "Authorization": `Bearer ${this.state.token}`,
                }
            })
                .then(response => response.json())
                .then(branche => {
                    this.getBranches()
                    alert(branche.message);
                })
                .catch(error => console.log('error branch', error));
        }
    }
    // done new version
    newBranch = (branch) => {
        let config = this.state.config;
        fetch(`${config}/Branch/Add`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${this.state.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "companyId": this.state.user.companyId,
                "branchId": 0,
                "branchCode": "string",
                "nameA": branch.nameAr,
                "nameE": branch.nameEn
            }),
        })
            .then(response => response.json())
            .then(branche => {
                alert(branche.message)
                this.getBranches()
            })
            .catch(error => console.log('error branch', error));
    }

    // done new version
    UpdateBranch = (e, data) => {
        e.preventDefault();
        let config = this.state.config;
        fetch(`${config}/Branch/Edit`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.state.token}`
            },
            body: JSON.stringify({
                "companyId": data.comId,
                "branchId": data.id,
                "branchCode": "string",
                "nameA": data.nameAr,
                "nameE": data.nameEn,
                "address": data.addres
            }),
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(edit => {
                this.getBranches();
                alert(edit.message);
            })
            .catch(error => alert("حدث خطا !"));
    }

    // Done new version
    FinancialYear = () => {
        let config = this.state.config;
        fetch(`${config}/FinancialYear/GetAll`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${this.state.token}`
            },
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                let financialAfter = result.data.filter(fin => fin.companyId == this.state.user.companyId);
                this.setState({
                    FinancialYear: result.data,
                    FinancialYearF: financialAfter
                })
            })
            .catch(error => console.log('error', error));
    }
    // not done
    newFinancialYear = year => {
        let config = this.state.config;
        var requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${this.state.token}`
            },
            body: JSON.stringify({
                "companyId": this.state.user.companyId,
                "financialYearId": "",
                "financialCode": year.codeYears,
                "beginDate": year.startDate,
                "endDate": year.endDate,
                "beginDateHj": "",
                "endDateHj": "",
                "financialType": "",
                "financialStatus": year.status == 1 ? true : false,
                "notes": year.notes,
                "companyNameA": "",
                "companyNameE": "",
                "branchId": this.state.user.branchId,
                "branchCode": "",
                "branchNameA": "",
                "branchNameE": ""
            }),
            redirect: 'follow'
        };

        fetch(`${config}/FinancialYear/Add`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.FinancialYear();
                alert(result.message)
            })
            .catch(error => console.log('error', error));
    }
    // api only done
    DelFinancialYear = (year) => {
        let questionF = window.confirm(`هل تريد حذف السنة المالية ${year.financialCode}`);
        if (questionF) {
            let delHeaderFin = new Headers();
            delHeaderFin.append("Authorization", `Bearer ${this.state.token}`);
            var requestOptions = {
                method: 'DELETE',
                headers: {
                    "Authorization": `Bearer ${this.state.token}`
                },
                redirect: 'follow'
            };

            fetch(`${this.state.config}/FinancialYear/Delete?financialyearId=${year.financialYear}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status == 200) {
                        this.FinancialYear()
                        alert(result.message)
                    } else {
                        alert(result.message)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    UpdateFinancialYear = (data) => {
        let myHeadersBranch = new Headers();
        myHeadersBranch.append("Authorization", `Bearer ${this.state.token}`);
        myHeadersBranch.append("Content-Type", "application/json");

        let rawBranch = JSON.stringify({
            "companyId": data.companyId,
            "financialYearId": data.id,
            "financialCode": data.codeYears,
            "beginDate": data.startDate,
            "endDate": data.endDate,
            "beginDateHj": "string",
            "endDateHj": "string",
            "financialType": "string",
            "financialStatus": data.status,
            "notes": data.notes,
            "companyNameA": "string",
            "companyNameE": "string",
            "branchId": 0,
            "branchCode": "string",
            "branchNameA": "string",
            "branchNameE": "string"
        });

        let requestOptionsBranch = {
            method: 'PUT',
            headers: myHeadersBranch,
            body: rawBranch,
            redirect: 'follow'
        };

        fetch(`${this.state.config}/FinancialYear/Edit`, requestOptionsBranch)
            .then(response => response.json())
            .then(edit => {
                this.FinancialYear();
                alert(edit.message);
            })
            .catch(error => alert("حدث خطا !"));
    }

    addSupplier = (customer) => {
        this.setState({
            customers: [...this.state.customers, customer]
        })
    }

    EditSupplier = (id) => {
        let Supplier = [...this.state.customers];
    }

    LoginForm = (e, email, password, lang) => {
        e.preventDefault();
        if (email == "" || password == "") {
            alert('من فضلك اكمل البيانات')
        } else {
            let myHeadersLogin = new Headers();
            myHeadersLogin.append("Authorization", "");
            myHeadersLogin.append("Content-Type", "application/json");

            var rawLogin = JSON.stringify({ "userName": email, "password": password, "langId": lang, ip: this.state.ip });

            var requestOptions = {
                method: 'POST',
                headers: myHeadersLogin,
                body: rawLogin,
                redirect: 'follow'
            };
            document.querySelector(".loading").classList.remove("hideloader");

            fetch(`${this.state.config}/User/Login`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    document.querySelector(".loading").classList.add("hideloader");
                    if (result.status == 200) {


                        this.setState({
                            user: result.data,
                            token: result.token,
                            active: true
                        }, () => {
                            localStorage.setItem("afakyLogin", true);
                            localStorage.setItem("afakyToken", result.token);
                            localStorage.setItem("afakyUser", JSON.stringify(result.data));
                            localStorage.setItem("ControllAfaky", JSON.stringify(result.data));
                            window.location.reload()

                        });



                    } else {
                        document.querySelector(".loading").classList.add("hideloader");
                        alert(result.message)
                    }
                })
                .catch(error => {
                    document.querySelector(".loading").classList.add("hideloader");

                });
        }
    }

    getCompany = () => {
        fetch(`${this.state.config}/Company/GetAll`)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    Companines: result.data
                })
            })
            .catch(error => console.log('error company', error));
    }

    // sync storage
    syncStorage = () => {
        localStorage.setItem("cart", JSON.stringify(this.state.Cart));
    };

    // handel Sidebar new version
    handelSidebar = () => {
        let side = document.querySelector('.layout-toggle');
        let siteLayout = document.querySelector('.site-layout');
        side.classList.toggle('hidenSide')
        side.classList.toggle('col-2')
        document.querySelector('.side-bar-layout').classList.toggle('side-bar-v')
        siteLayout.classList.toggle('col-10')
        siteLayout.classList.toggle('col-12')
    };

    onChangeCompany = e => {
        console.log(e.target.value);
        let Filtering = [...this.state.Branches];
        this.setState({
            BranchesF: Filtering.filter(item => item.companyId == +e.target.value),
        })
    }
    onChangeBalance = e => {
        let Filtering = [...this.state.FinancialYearFilter];
        this.setState({
            FinancialYearFilter: Filtering.filter(item => item.branchId == e.target.value)
        })
    }

    onChangeFinicalYears = (e) => {

        fetch(`${this.state.config}/UserCbf/Edit`, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${this.state.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "ucbfid": 0,
                "companyId": this.state.user.companyId,
                "branchId": this.state.user.branchId,
                "financialYearId": +e.target.value,
                "userId": this.state.user.userId,
                "checked": true,
                "active": true,
                "companyNameA": "",
                "branchNameA": "",
                "financialYearCode": "",
                "companyNameE": "",
                "branchNameE": ""
            }),
        })
            .then(response => response.json())
            .then(result => {
                alert(result.message)
            })
            .catch(error => console.log('error', error));

    }

    // مجموعة الموردين
    AddSuppliersGroup = (name_ar, name_en, cat, checked, setName_Ar, setName_EN, setChecked) => {
        if (name_ar == "") {
            alert('من فضلك اكمل البيانات بشكل صحيح')
        } else {
            if (!checked) {
                if (cat == "") {
                    alert('اختار القسم')
                } else {
                    this.addSupplier({ id: Math.random() * 10, name_ar, name_en, cat, checked: checked })
                    setName_Ar("")
                    setName_EN("")
                    setChecked(false)
                }
            } else {
                this.addSupplier({ id: Math.random() * 10, name_ar, name_en, cat, checked: checked })
                setName_Ar("")
                setName_EN("")
                setChecked(false)
            }
        }
    }

    DelSuppliersGroup = rowData => {
        let questionF = window.confirm(`هل تريد حذف  ${rowData.nameA}`);
        if (questionF) {
            let delHeaderFin = new Headers();
            delHeaderFin.append("Authorization", `Bearer ${this.state.token}`);
            var requestOptions = {
                method: 'DELETE',
                headers: {
                    "Authorization": `Bearer ${this.state.token}`
                },
                redirect: 'follow'
            };

            fetch(`${this.state.config}/SupplierGroup/Delete?SupplierGroupId=${rowData.sgid}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status == 200) {
                        this.getSuppliersGroup()
                        alert(result.message);

                    } else {
                        alert(result.message)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    // مجموعة العملاء


    // حذف صنف
    DelDefinitionItems = rowData => {
        console.log("rowData  ", rowData);
        let questionF = window.confirm(`هل تريد حذف  ${rowData.nameA}`);

        if (questionF) {
            fetch(text)
                .then(r => r.text())
                .then(text => {
                    let isCheck = text.includes("Afaky#");
                    let myHeadersData = new Headers();
                    myHeadersData.append("accept", "*/*");
                    myHeadersData.append("Authorization", `Bearer ${this.getToken()}`);
                    myHeadersData.append("Content-Type", "application/json");

                    if (isCheck) {
                        let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                        let myHeaders = new Headers();
                        myHeaders.append("accept", "text/plain");
                        let requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        fetch(newUrl, requestOptions)
                            .then(response => response.text())
                            .then(resultUrl => {
                                fetch(`${resultUrl}/Item/Delete`, {
                                    method: 'DELETE',
                                    headers: {
                                        "Authorization": `Bearer ${this.state.token}`
                                    },
                                    body: JSON.stringify({
                                        "companyId": rowData.companyId,
                                        "itemId": rowData.itemCode
                                    }),
                                    redirect: 'follow'
                                })
                                    .then(response => response.json())
                                    .then(result => {
                                        this.setState({
                                            DefinitionItemsF: this.state.DefinitionItemsF.filter(dd => dd.id != rowData.id),
                                            DefinitionItems: this.state.DefinitionItems.filter(dd => dd.id != rowData.id)
                                        }, () => {
                                            document.querySelector(".loading").classList.add("hideloader");
                                        })
                                    })
                                    .catch(error => {
                                        document.querySelector(".loading").classList.add("hideloader");
                                        console.log('error', error)
                                    });
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        fetch(`${text}/Item/Delete`, {
                            method: 'DELETE',
                            headers: {
                                "Authorization": `Bearer ${this.state.token}`
                            },
                            body: JSON.stringify({
                                "companyId": rowData.companyId,
                                "itemId": rowData.itemCode
                            }),
                            redirect: 'follow'
                        })
                            .then(response => response.json())
                            .then(result => {
                                // document.querySelector(".loading").classList.add("hideloader");

                                this.setState({
                                    DefinitionItemsF: this.state.DefinitionItemsF.filter(dd => dd.id != rowData.id),
                                    DefinitionItems: this.state.DefinitionItems.filter(dd => dd.id != rowData.id)
                                }, () => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                })
                            })
                            .catch(error => {
                                document.querySelector(".loading").classList.add("hideloader");
                                console.log('error', error)
                            });

                    }

                });

        }





        // if (questionF) {
        //     var requestOptions = {
        // method: 'DELETE',
        // headers: {
        //     "Authorization": `Bearer ${this.state.token}`
        // },
        // body: JSON.stringify({
        //     "companyId": rowData.companyId,
        //     "itemId": rowData.id
        // }),
        // redirect: 'follow'
        //     };
        //     fetch(`${this.state.config}/Item/Delete`, requestOptions)
        //         // fetch(`${this.state.config}/Item/Delete?ItemId=${rowData.itemId}&CompnyId=${rowData.companyId}`, requestOptions)
        //         .then(response => response.json())
        //         .then(result => {
        //             if (result.status == 200) {
        //                 alert(result.message);
        //                 this.setState({
        // DefinitionItemsF: this.state.DefinitionItemsF.filter(dd => dd.itemId != rowData.itemId),
        // DefinitionItems: this.state.DefinitionItems.filter(dd => dd.itemId != rowData.itemId)
        //                 })
        //             } else {
        //                 alert(result.message)
        //             }
        //         })
        //         .catch(error => console.log('error', error));
        // }
    }



    addItemDefintion = (data, history) => {
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                let myHeadersData = new Headers();
                myHeadersData.append("accept", "*/*");
                myHeadersData.append("Authorization", `Bearer ${this.getToken()}`);
                myHeadersData.append("Content-Type", "application/json");

                let tas3er = new Headers();
                tas3er.append("Authorization", `Bearer ${this.state.token}`);
                tas3er.append("Content-Type", "application/json");

                data.list.forEach((value, index, array) => {
                    if (!value.companyId) {
                        value.companyId = this.state.user.companyId;
                    }
                })
                var rawtas3er = JSON.stringify({
                    "id": 1,
                    "code": +data.code + "",
                    "nameA": data.name_ar,
                    "nameE": data.name_en,
                    "costType": data.costType,
                    "notes": data.notes,
                    "companyId": this.state.user.companyId,
                    "groupId": data.groupId,
                    "itemType": data.itemType,
                    "itemCode": "",
                    "maximum": 0,
                    "reorder": 0,
                    "minimum": 0,
                    "hasColor": false,
                    "hasSort": false,
                    "hasProductionDate": false,
                    "hasExpiryDate": false,
                    "hasSerial": false,
                    "productionPeriod": 0,
                    "photo": "",
                    "taxRate": 0,
                    "exempt": false,
                    "noReturned": false,
                    "wTaxRate": 0,
                    "tableTaxRate": 0,
                    "wRate": 0,
                    "icId": 0,
                    "defaultStoreId": 0,
                    "automaticDiscountP": false,
                    "hasWeight": false,
                    "automaticDiscountS": false,
                    "allowZeroPrice": false,
                    "hideItem": false,
                    "noCubic": false,
                    "noQuantity": false,
                    "hidePrice": false,
                    "hasHeightWidth": false,
                    "expectedDays": 0,
                    "rFileName": "",
                    "generateSerial": false,
                    "hideItemP": false,
                    "density": 0,
                    "atbup": false,
                    "productNo": "",
                    "allowIR": false,
                    "level1": 0,
                    "level2": 0,
                    "level3": 0,
                    "categoryId": 1,
                    "codeType": 0,
                    "itemCodeTax": "",
                    "taxSourceRate": 0,
                    "gtin": "",
                    "itemGroupName": "1",
                    "costTypeName": "1",
                    "itemTypeName": "1",
                    "itemsCompaniesName": "",
                    "defaultStoreName": "",
                    "itemCategoryName": "",
                    "codeTypeName": "",
                    "itemsUnit": data.list,
                    // [
                    //     {
                    //         "companyId": this.state.user.companyId,
                    //         "itemId": "",
                    //         "unitId": "",
                    //         "id": "",
                    //         "mainUnit": data.smallUnit, // اصغر وحدة
                    //         "rate": data.rate,
                    //         "defaultUnit": data.defaultM, // افتراضي المشتريات
                    //         "barCode": data.Barcode,
                    //         "purchasePolicy": data.purchasePolicy,
                    //         "purchaseValue": data.purchasePolicyVal,
                    //         "purchaseDiscountType": data.purchaseDiscount,
                    //         "purchaseDiscountValue": data.purchaseDiscountVal,
                    //         "salesPolicy": data.salePolicy,
                    //         "salesValue": data.valSalePolicy,
                    //         "salesDiscountType": data.salesDiscount, // نوع ا
                    //         "salesDiscountValue": data.salesDiscountVal1,
                    //         "defaultUnitSales": data.salesDiscountVal2,
                    //         "minimumSaleValue": data.minimumSalePrice, // الحد الادني لسعر البيع
                    //         "salesV1": data.salesV1,
                    //         "salesV2": data.salesV2,
                    //         "salesV3": data.salesV3,
                    //         "salesV4": data.salesV4,
                    //         "salesV5": data.salesV5,
                    //         "salesValue2": data.salesDiscountVal2, // سعر بيع اخر
                    //         "taxValue": "", //dis
                    //         "sellingP": data.suggestedSellingPrice // سعر البيع  المقترح dis
                    //     }
                    // ],
                    "itemGroupsLevel": [
                        {
                            "companyId": 0,
                            "itemId": 0,
                            "iGlId": 0,
                            "groupsLevelsId": 0,
                            "groupsLevelName": "string",
                            "groupId": 0
                        }
                    ],
                    "itemTSpecifications": [
                        {
                            "companyId": 0,
                            "itemId": 0,
                            "itsid": 0,
                            "tsid": 0,
                            "tstext": "string"
                        }
                    ],
                    "itemCompilation": [
                        {
                            "companyId": 0,
                            "itemId": 0,
                            "id": 0,
                            "citemId": 0,
                            "unitId": 0,
                            "quantity": 0,
                            "isize": 0
                        }
                    ]
                });



                let requestOptions2 = {
                    method: 'POST',
                    headers: tas3er,
                    body: rawtas3er,
                    redirect: 'follow'
                };


                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };


                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/Item/Add`, requestOptions2)
                                .then(response => response.json())
                                .then(result => {
                                    console.log("result.isSuccessresult.isSuccess", result.isSuccess);
                                    if (result.isSuccess) {
                                        // let Fs = [result.data, ...this.state.DefinitionItems];
                                        // let GeneralVoucherN = { ...this.state.DefinitionItems };
                                        // GeneralVoucherN = Fs;
                                        // this.setState({
                                        //     DefinitionItems: Fs,
                                        //     DefinitionItemsF: Fs
                                        // });
                                        history.push({ pathname: "/DefinitionItems" })
                                    } else {
                                        alert(result.message);
                                    }
                                })
                                .catch(error => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    console.log('error here', error)
                                });
                        })
                        .catch(error => console.log('error', error));
                } else {

                    fetch(`${text}/Item/Add`, requestOptions2)
                        .then(response => response.json())
                        .then(result => {
                            console.log("resss", result);
                            if (result.isSuccess) {
                                // let Fs = [result.data, ...this.state.DefinitionItems];
                                // let GeneralVoucherN = { ...this.state.DefinitionItems };
                                // GeneralVoucherN = Fs;
                                // this.setState({
                                //     DefinitionItems: GeneralVoucherN,
                                //     DefinitionItemsF: GeneralVoucherN
                                // });
                                history.push({ pathname: "/DefinitionItems" })
                            } else {
                                alert(result.message);
                            }
                        })
                        .catch(error => {
                            document.querySelector(".loading").classList.add("hideloader");
                            console.log('error here', error)
                        });

                }

            });
    }

    // تحصيل نقدي
    CashCollection = (
        Cancel, // total cancel
        totalTaxAdPresent, // total precent
        totalTaxAd, // total tax
        InvoicedIdList, // id list return
        typeInvoice, // retuen or voice
        search, // user id 
        list, // list orders 
        total, // صافي
        listPayments,
        payment,
        typeInvoiceValue,
        typePrint,
        posPintAfterSave,
        storesId,
        posInvoiceTypeId,
        POSTables = [],
        setRunPrint,
        end
    ) => {
        if (window.location.pathname == '/POSTables') {

            PayCashVoicePOS(
                {
                    $,
                    list,
                    listPayments,
                    payment,
                    total,
                    typeInvoice,
                    posPintAfterSave,
                    search,
                    typeInvoiceValue,
                    InvoicedIdList,
                    totalTaxAd,
                    typePrint,
                    Cancel,
                    token: this.state.token,
                    config: this.state.config,
                    posReportNo: this.state.posReportNo,
                    user: this.state.user,
                    mostData: this.state.mostData,
                    storesId,
                    posInvoiceTypeId,
                    POSTables,
                    setRunPrint,
                    end
                }
            )
        } else {
            PayCashVoice(
                $,
                list,
                listPayments,
                payment,
                total,
                typeInvoice,
                posPintAfterSave,
                search,
                typeInvoiceValue,
                InvoicedIdList,
                totalTaxAd,
                typePrint,
                Cancel,
                this.state.token,
                this.state.config,
                this.state.posReportNo,
                this.state.user,
                this.state.mostData,
                storesId,
                posInvoiceTypeId
            )
        }


    }

    // تعليق الفاتورة
    HoldInvoice = (InvoicedIdList, typeInvoice, search, list, setList, total, setTotal, setPriceAfterDiscount, setSearch, inputRef) => {
        let totalafter = list.map(item => {
            return (+item.price * +item.quantity) - (+item.discount1 || 0)
        }).reduce((acc, curr) => {
            return acc + curr
        }, 0);


        let totalDiscount = list.map(item => {
            return +item.discount1 || 0
        }).reduce((acc, curr) => {
            return acc + curr
        }, 0);


        let typeingInvoce = document.getElementById('typeOption').value;
        let headerCachCollection = new Headers();
        headerCachCollection.append("Authorization", `Bearer ${this.state.token}`);
        headerCachCollection.append("Content-Type", "application/json");

        let rawInvoice;
        let DataInvoiceSended;
        if (typeInvoice) {
            DataInvoiceSended = {
                "InvoiceType": 17,
                "sceId": search,
                "description": "",
                "text": typeInvoice,
                "currencyId": 1,
                "rate": 1,
                "total": totalafter,
                "itemsDiscount": 0,
                "additionalDiscount": 0,
                "additionalDiscountPercent": 0,
                "totalDiscount": +totalDiscount,
                "netTotal": totalafter,
                "amountPaid": totalafter,
                "remainingAmount": 0,
                "totalPayment": totalafter,
                "type": +typeingInvoce,
                "sourceId": +InvoicedIdList,
                "taxRate": 0,
                "taxValue": 0,
                "taxSalesValue": 0,
                "cashTendered": 0,
                "delegateId": 0,
                "policyId": 0,
                "invoiceDetails": list,
                "invoicePayment": [{
                    "paymentId": 1,
                    "paymentType": 5,
                    "paymentValue": totalafter,
                    "accountId": ""
                }]
            }
            rawInvoice = JSON.stringify(DataInvoiceSended);

        } else {
            DataInvoiceSended = {
                "InvoiceType": 10,
                "sceId": search,
                "text": typeInvoice,
                "description": "",
                "currencyId": 1,
                "rate": 1,
                "total": totalafter,
                "itemsDiscount": 0,
                "additionalDiscount": 0,
                "additionalDiscountPercent": 0,
                "totalDiscount": 0,
                "netTotal": totalafter,
                "amountPaid": totalafter,
                "remainingAmount": 0,
                "totalPayment": totalafter,
                "type": 0,
                "sourceId": 0,
                "taxRate": 0,
                "taxValue": 0,
                "taxSalesValue": 0,
                "cashTendered": 0,
                "delegateId": 0,
                "policyId": 0,
                "invoiceDetails": list,
                "invoicePayment": [{
                    "paymentId": 1,
                    "paymentType": 5,
                    "paymentValue": totalafter,
                    "accountId": ""
                }]
            }
            rawInvoice = JSON.stringify(DataInvoiceSended);
        }

        fetch(`${this.state.config}/PosForm/AddInvoiceHold`, {
            method: 'POST',
            headers: headerCachCollection,
            body: rawInvoice,
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                if (result.isSuccess) {
                    setList([]);
                    setPriceAfterDiscount(0)
                    setSearch('')
                    alert(result.message);
                    this.getMostData()
                } else {
                    alert(result.message)
                }
            })
            .catch(error => console.log('error', error));
    } // تعيق الفاتورة

    // سند قبض سريع معرفة الرصيد
    QuickVoucherGetBalance = (customerId, setBalancePromiss) => {
        let user = this.state.user;
        let headerQuickVoucher = new Headers();
        headerQuickVoucher.append("Authorization", `Bearer ${this.state.token}`);
        headerQuickVoucher.append("Content-Type", "application/json");
        fetch(`${this.state.config}/VoucherForm/GetSupCustBalance`, {
            method: 'POST',
            headers: headerQuickVoucher,
            body: JSON.stringify({
                "companyId": user.companyId,
                "sceid": customerId,
                "employeeActionType": 0,
                "balance": 0
            }),
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                if (result.isSuccess) {
                    setBalancePromiss(result.data.balance)
                } else {
                    alert(result.message)
                }
            })
            .catch(error => console.log('error', error));
    } // سند قبض سريع معرفة الرصيد

    // المتبقي من سند القبض
    BalanceRemaining = (targ, balancePromiss, setViewBalanceRemaining) => {
        let val = targ;
        let newValue = balancePromiss - val;
        setViewBalanceRemaining(newValue);
    } // المتبقي من سند القبض

    // سند قبض سريع
    QuickVoucher = (typePrint, customerId, descriptionVoucher, setNameCustomerPromiss, setIdCustomerPromiss, nameCustomerPromissRef, setViewBalanceRemaining, setBalancePromiss, balancePromiss, viewBalanceRemaining) => {
        let BalanceRemaining = +document.getElementById('BalanceRemaining').value;
        let Data = JSON.parse(localStorage.getItem("ControllAfaky"));
        let companyId = Data.companyId;
        let branchId = Data.branchId;
        let financialYear = Data.financialYear ? Data.financialYear : Data.financialYearId;


        let headerQuickVoucher = new Headers();
        headerQuickVoucher.append("Authorization", `Bearer ${this.state.token}`);
        headerQuickVoucher.append("Content-Type", "application/json");
        fetch(`${this.state.config}/VoucherForm/QuickAdd`, {
            method: 'POST',
            headers: headerQuickVoucher,
            body: JSON.stringify({
                "companyId": companyId,
                "branchId": branchId,
                "fyId": financialYear,
                "description": descriptionVoucher,
                "sceid": customerId,
                "amount": BalanceRemaining
            }),
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                if (result.isSuccess) {
                    if (typePrint) {
                        let parms = { customerId, descriptionVoucher, BalanceRemaining, balancePromiss, viewBalanceRemaining, user: this.state.user };
                        let handle = window.open(window.location.origin + '/print-receipt-voucher', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                        handle.window.parameters = JSON.stringify(parms);
                    }
                    document.getElementById('setDescriptionVoucher').value = "";
                    document.getElementById('BalanceRemaining').value = 0;
                    setNameCustomerPromiss('');
                    setIdCustomerPromiss('');
                    setViewBalanceRemaining(0);
                    setBalancePromiss(0);
                    nameCustomerPromissRef.current.value = "";
                    // this.getGeneralVoucher()
                } else {
                    alert(result.message)
                }
            })
            .catch(error => console.log('error', error));
    } // سند قبض سريع

    // الفواتير المعلقة
    GetHoldInvoice = () => {

    } //الفواتير المعلقة


    addVoucherForm = (data, jvType, history, text, customers) => {
        if (data.boxBankValue.accountId) {
            document.querySelector(".loading").classList.remove("hideloader");
            let voucherHolder = data.typeAccount + "";
            let total;
            if (voucherHolder == 1) {
                total = data.list.map((item) => {
                    return item.amount;
                }).reduce((acc, curr) => {
                    return acc + curr;
                }, 0);
            }

            if (voucherHolder == 2 || voucherHolder == 3) {
                total = data.customersGrid.map((item) => {
                    return item.paymentValue;
                }).reduce((acc, curr) => {
                    return acc + curr;
                }, 0);
            }

            if (voucherHolder == 4) {
                total = data.Loans.map((item) => {
                    return item.paymentValue;
                })
                    .reduce((acc, curr) => {
                        return acc + curr;
                    }, 0);
            }

            let Data = JSON.parse(localStorage.getItem("ControllAfaky"));
            let companyId = Data.companyId;
            let branchId = Data.branchId;
            let financialYear = Data.financialYear ? Data.financialYear : Data.financialYearId;
            data.list.forEach(item => {
                item.amountLocal = item.amount * item.sY;
            })
            let objRow = {
                "companyId": companyId,
                "branchId": branchId,
                "jvtype": jvType,
                "fyid": financialYear,
                "ftid": 0,
                "ftserial": "",
                "ftdate": data.date,
                "ftdateHj": "",
                "status": data.typeing,
                "description": data.description,
                "fileNo": "",
                "totalAmount": total,
                "jvid": 0,
                "accountId": data.boxBankValue.accountId + "",
                "totalAmountSides": 0,
                "voucherHolder": +voucherHolder,
                "currencyId": +data.currencyIdGeneral,
                "rate": +data.RateGlobal,
                "amountPayment": total,
                "sceid": +data.sceid,
                "delegateId": 0,
                "employeeActionType": +data.typesolf,
                "shiftId": 0,
                "transferStatus": 0,
                "treasury": "",
                "branchIdTo": 0,
                "userIdAdded": 0,
                "financialTransactionsDetailsList": data.list,
                "financialTransactionsPaymentList": data.customersGrid,
                "loanList": data.Loans,
                "financialTransactionsSalaryList": [],
                "ftvacationsPaymentList": [],
                "checkList": [],
                "sideList": [],
                "jvSerial": ""
            }
            let nameType = "";
            if (jvType == 7) {
                nameType = "سند صرف"
            } else if (jvType == 140) {
                nameType = "سند قبض"
            }

            let QuickVoucherAdd = new Headers();
            QuickVoucherAdd.append("Authorization", `Bearer ${this.state.token}`);
            QuickVoucherAdd.append("Content-Type", "application/json");
            fetch(`${this.state.config}/VoucherForm/Add`, {
                method: 'POST',
                headers: QuickVoucherAdd,
                body: JSON.stringify(objRow),
                redirect: 'follow'
            })
                .then(response => response.json())
                .then(result => {
                    document.querySelector(".loading").classList.add("hideloader");
                    if (result.isSuccess) {
                        let Fs = [result.data, ...this.state.GeneralVoucher.financialTransactionsList];
                        let GeneralVoucherN = { ...this.state.GeneralVoucher };
                        GeneralVoucherN.financialTransactionsList = Fs;
                        this.setState({
                            GeneralVoucher: GeneralVoucherN
                        });
                        history.push({ pathname: text })
                    } else {
                        alert(result.message);
                    }
                })
                .catch(error => {
                    document.querySelector(".loading").classList.add("hideloader");
                    console.log('error', error)

                });
        } else {
            alert('من فضلك اختار اسم الحساب ')
        }


    }

    EditVoucherForm = (data, jvType, setUnderList, itemId, history, text) => {
        document.querySelector(".loading").classList.remove("hideloader");
        let voucherHolder = data.typeAccount + "";
        let total;
        if (voucherHolder == 1) {
            total = data.UnderList.map((item) => {
                return item.amount;
            })
                .reduce((acc, curr) => {
                    return acc + curr;
                }, 0);
        }
        if (voucherHolder == 2 || voucherHolder == 3) {
            total = data.customersGrid.map((item) => {
                return item.paymentValue;
            })
                .reduce((acc, curr) => {
                    return acc + curr;
                }, 0);
        }
        if (voucherHolder == 4) {
            total = data.Loans.map((item) => {
                return item.paymentValue;
            }).reduce((acc, curr) => {
                return acc + curr;
            }, 0);
        }
        let Data = JSON.parse(localStorage.getItem("ControllAfaky"));
        let companyId = Data.companyId;
        let branchId = Data.branchId;
        let financialYear = Data.financialYear ? Data.financialYear : Data.financialYearId;

        let objRow = {
            "companyId": companyId,
            "branchId": branchId,
            "jvtype": jvType,
            "fyid": financialYear,
            "ftid": +itemId,
            "ftserial": itemId + "",
            "ftdate": data.date,
            "ftdateHj": "",
            "status": data.typeing,
            "description": String(data.description),
            "fileNo": "",
            "totalAmount": total,
            "jvid": +jvType,
            "accountId": data.boxBankValue.accountId + "",
            "totalAmountSides": 0,
            "voucherHolder": +voucherHolder,
            "currencyId": +data.currencyIdGeneral,
            "rate": +data.RateGlobal,
            "amountPayment": total,
            "sceid": +data.sceid,
            "delegateId": 0,
            "employeeActionType": +data.typesolf,
            "shiftId": 0,
            "transferStatus": 0,
            "treasury": "",
            "branchIdTo": 0,
            "userIdAdded": 0,
            "financialTransactionsDetailsList": data.UnderList,
            "financialTransactionsPaymentList": data.customersGrid,
            "loanList": data.Loans,
            "financialTransactionsSalaryList": [],
            "ftvacationsPaymentList": [],
            "checkList": [],
            "sideList": [],
            "jvSerial": ""
        }
        let QuickVoucherAdd = new Headers();
        QuickVoucherAdd.append("Authorization", `Bearer ${this.state.token}`);
        QuickVoucherAdd.append("Content-Type", "application/json");
        fetch(`${this.state.config}/VoucherForm/Edit`, {
            method: 'POST',
            headers: QuickVoucherAdd,
            body: JSON.stringify(objRow),
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                document.querySelector(".loading").classList.add("hideloader");
                if (result.isSuccess) {
                    alert(result.message)
                    history.push(text)
                } else {
                    alert(result.message);
                }
            })
            .catch(error => {
                document.querySelector(".loading").classList.add("hideloader");

                console.log('error', error)
            });

    }

    // كشف حساب العادي
    GetReportAccountStatemen = (ArrayBranchesSend, started, ended, AccountId, acountName, active1, active2, ArrayTyping, cumulative) => {


        if (document.querySelector('.input-account-statement').value == "") {
            alert("من فضلك ادخل اسم الحساب")
        } else {

            if (active1.checked) {
                active1 = true;
            } else if (!active1.checked) {
                active1 = false;
            }
            if (active2.checked) {
                active2 = true;
            } else if (!active2.checked) {
                active2 = false;
            }
            if (ArrayTyping.length == 0) {
                ArrayTyping = this.state.AccountFormReport.journalVoucherTypeList;
            }
            if (ArrayBranchesSend.length == 0) {
                ArrayBranchesSend = this.state.AccountFormReport.branchList;
            }

            fetch(`${this.state.config}/AccountFormReport/GetAll`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                body: JSON.stringify({
                    companyId: this.state.user.companyId,
                    formName: "TsmAccountStatement",
                    beginDate: started,
                    endDate: ended,
                    cumulative: cumulative,
                    balanceCollection: active1,
                    notDsplayingZeroBalance: active2,
                    branchList: ArrayBranchesSend,
                    accountList: [{
                        accountId: AccountId,
                        accountName: acountName,
                        accountOrder: ""
                    }],
                    journalVoucherTypeList: ArrayTyping
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.isSuccess) {
                        if (result.data.length == 0) {
                            alert('لا يوجد بيانات')
                        } else {
                            this.setState({
                                ReportsAccounts: result.data
                            })
                        }

                    }
                }).catch((error) => console.log("error", error));

        }
    }

    filterVoucher = (ftid) => {
        let questionF = window.confirm(`هل تريد الحذف`);
        if (questionF) {
            fetch(`${this.state.config}/VoucherForm/Delete`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                body: JSON.stringify({
                    companyId: this.state.myControll.companyId,
                    branchId: this.state.myControll.branchId,
                    fyId: this.state.myControll.financialYearId ? this.state.myControll.financialYearId : this.state.myControll.financialYear,
                    voucherType: 140,
                    ftId: ftid,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.isSuccess) {
                        alert(result.message);
                        let Fs = [...this.state.GeneralVoucher.financialTransactionsList];
                        Fs = Fs.filter(item => item.ftid != ftid);
                        let GeneralVoucherN = { ...this.state.GeneralVoucher };
                        GeneralVoucherN.financialTransactionsList = Fs;
                        this.setState({
                            GeneralVoucher: GeneralVoucherN
                        });
                    } else {
                        alert(result.message);
                    }
                })
                .catch((error) => console.log("error", error));
        }


    }


    clearCasH() { }


    createHeaderRequest(method, data = null) {
        if (data) {
            return {
                method: method,
                headers: headerMain,
                redirect: 'follow',
                body: JSON.stringify(data)
            }
        } else {
            return {
                method: method,
                headers: headerMain,
                redirect: 'follow',
            }
        }

    }

    render() {
        return (
            <ProductContext.Provider value={{
                ...this.state,
                onChangeLanguage: this.onChangeLanguage,
                getCompanies: this.getCompanies,
                handelSidebar: this.handelSidebar,
                LoginForm: this.LoginForm,
                addSupplier: this.addSupplier,
                EditSupplier: this.EditSupplier,
                DelCompany: this.DelCompany,
                UpdateCompany: this.UpdateCompany,
                DelBranch: this.DelBranch,
                newCompany: this.newCompany,
                UpdateBranch: this.UpdateBranch,
                newBranch: this.newBranch,
                newFinancialYear: this.newFinancialYear,
                DelFinancialYear: this.DelFinancialYear,
                UpdateFinancialYear: this.UpdateFinancialYear,
                onChangeCompany: this.onChangeCompany,
                onChangeBalance: this.onChangeBalance,
                onChangeFinicalYears: this.onChangeFinicalYears,
                SaveUnits: this.SaveUnits,
                getUnits: this.getUnits,
                DeleteUnit: this.DeleteUnit,
                UpdateUnit: this.UpdateUnit,
                setConfig: this.setConfig,
                getSuppliersGroup: this.getSuppliersGroup,
                AddSuppliersGroup: this.AddSuppliersGroup,
                DelSuppliersGroup: this.DelSuppliersGroup,
                createCustomersGroup: this.createCustomersGroup,
                DeleteCustomersGroup: this.DeleteCustomersGroup,
                DelDefinitionItems: this.DelDefinitionItems,
                addItemDefintion: this.addItemDefintion,
                CashCollection: this.CashCollection,
                HoldInvoice: this.HoldInvoice,
                getMostData: this.getMostData,
                BalanceRemaining: this.BalanceRemaining,
                QuickVoucherGetBalance: this.QuickVoucherGetBalance,
                QuickVoucher: this.QuickVoucher,
                addVoucherForm: this.addVoucherForm,
                delVoucherForm: this.delVoucherForm,
                getGeneralVoucher: this.getGeneralVoucher,
                getAccountFormReport: this.getAccountFormReport,
                GetReportAccountStatemen: this.GetReportAccountStatemen,
                EditVoucherForm: this.EditVoucherForm,
                filterVoucher: this.filterVoucher,
                getToken: this.getToken,
                getUserData: this.getUserData,
                getCustomersGroup: this.getCustomersGroup,
                UpdateCustomersGroup: this.UpdateCustomersGroup,
                getSupplierGroup: this.getSupplierGroup,
                createSupplierGroup: this.createSupplierGroup,
                UpdateSupplierGroup: this.UpdateSupplierGroup,
                DeleteSupplierGroup: this.DeleteSupplierGroup,
                getDefinitionItems: this.getDefinitionItems

            }
            } > {this.props.children}
            </ProductContext.Provider>
        );
    }
}




export { ProductProvider, ProductContext };