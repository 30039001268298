import React from "react";
import "./App.css";
import "./styles/bootstrap.min.css";
import { Route, Switch } from "react-router-dom";
import Loading from "./Loading";
// pages
import Default from "./pages/Default";
import Login from "./pages/Login/Login";
import Routes from "./Routes";
import PrintVoice from "./pages/SaleInvoice/PrintVoice";
import Privacy from "./Privacy";
import AccountStatementSmall from "./Printers/AccountStatementSmall";
import PrinteReciptGeneral from "./ReciptVoucherGeneral/PrinteReciptGeneral";
import { ItemsBalanceReportsView } from "./Reports/ItemsBalanceReports/ItemsBalanceReports";
import GeneratePDF from "./components/PDF/GeneratePDF";
import { StockValuationTablePrint } from "./pages/StockValuation/StockValuation";
import Know from "./components/PointOfSales/Know";
import PrintDeveley from "./pages/SaleInvoice/PrintDeveley";
import PrintTable from "./pages/SaleInvoice/PrintTable";
import $ from 'jquery';

function App() {

    let active = localStorage.getItem("afakyLogin")
        ? JSON.parse(localStorage.getItem("afakyLogin"))
        : false;


    if (active) {
        return (
            <React.Fragment>
                <Loading />
                <Switch>
                    {Routes.map((route) => (
                        <Route key={route.id} path={route.path} exact={route.exact} component={route.component} />
                    ))}
                    <Route path="/printers" component={PrintVoice} />
                    <Route path="/PrintDeveley" component={PrintDeveley} />
                    <Route path="/PrintTable" component={PrintTable} />

                    <Route path="/privacy-policy" component={Privacy} />
                    <Route path="/GeneratePDF" component={GeneratePDF} />
                    <Route path="/account-statement-printing-quick" component={AccountStatementSmall} />
                    <Route path="/PrinteReciptGeneral" component={PrinteReciptGeneral} />
                    <Route path="/ItemsBalanceReportsView" component={ItemsBalanceReportsView} />
                    <Route path="/StockValuationTablePrint" component={StockValuationTablePrint} />
                    <Route path="/item-price-query" component={Know} />

                    <Route component={Default} />

                </Switch>
            </React.Fragment>
        );
    } else {
        return (
            <div className="position-relative">
                <Loading />
                <Switch>
                    <Route path="/" component={Login} />
                </Switch>
            </div>
        );
    }
}

export default App;
