import React from 'react'

export default function TableStock({ data }) {
    return (
        <tr>
            <td> {data.itemCode} </td>
            <td> {data.itemName} </td>
            <td> {data.costTypeName} </td>
            <td> {data.unitName || ""} </td>
            <td> {data.mQuantity} </td>
            <td> {data.mCost} </td>
            <td> {+data.mTotalCost.toFixed(2)} </td>
        </tr>
    )
}
