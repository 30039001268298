import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Roadmap from '../../components/Roadmap';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import Stand from '../ComponentsInheritanc/Stand';


function AddCustomGroup() {
    const { createCustomersGroup } = useContext(ProductContext);

    const { t } = useTranslation();

    const [category, setCategory] = useState([]);
    const [code, setCode] = useState('');
    const [name_ar, setName_Ar] = useState('');
    const [name_en, setName_EN] = useState('');
    const [notes, setNotes] = useState('');
    const [checked, setChecked] = useState(false);
    const [each, setEach] = useState(true);
    const [byAdminstrator, setByAdminstrator] = useState(false);
    const [texCode, setTexCode] = useState('');

    const history = useHistory();

    const Categories = (e) => {
        e.preventDefault();
        if (name_ar == "" || name_en == "") {
            alert('من فضلك تاكد من البيانات');
        } else {
            createCustomersGroup(notes, name_ar, name_en, checked, setName_Ar, setName_EN, setCategory, setNotes, byAdminstrator, setByAdminstrator, history)
        }
    }

    return (
        <Layout>
            <div className="company text-right font-ar container companyReciptVoucher py-5" style={{ maxWidth: "1100px" }}>
                <div className="container py-4">
                    <div className="row mx-0">
                        <Roadmap name={t('customerGroup')} child={t('CreateCustomerGroup')} childTo={{ pathname: '/customersGroup', params: { status: 2 } }} />
                        <Stand
                            setCode={setCode}
                            name_ar={name_ar}
                            setName_Ar={setName_Ar}
                            name_en={name_en}
                            setName_EN={setName_EN}
                            texCode={texCode}
                            setTexCode={setTexCode}
                            notes={notes}
                            setNotes={setNotes}
                            onSubmit={Categories}
                            useTaxCode={false}
                            linkReturn={{ pathname: '/customersGroup', params: { status: 2 } }}
                            each={each}
                            setEach={setEach}
                        >
                            <div className="row">
                                <div className="col-12 col-md-6 my-2">
                                    <input type="checkbox" className="mx-2" id="adminstrator" checked={byAdminstrator} onChange={() => setByAdminstrator(!byAdminstrator)} />
                                    <label htmlFor='adminstrator'> {t('byadministrator')} </label>
                                </div>
                            </div>
                        </Stand>
                    </div>
                </div>
            </div>
            {/* <div className="company text-right font-ar container companyReciptVoucher py-5" style={{ maxWidth: "1100px" }}>
                <div className="container py-4">
                    <div className="row mx-0">
                        <div className="col-12 col-md-8 mx-auto px-0">
                            <form dir="rtl">
                                <div className="form-group mx-auto">
                                    <label>{t('code')}</label>
                                    <input type="number" disabled value={code} className="form-control" onChange={(e) => setCode(e.target.value)} />
                                </div>
                                <div className="form-group mx-auto">
                                    <label>{t('namear')}</label>
                                    <input type="text" value={name_ar} className="form-control" onChange={(e) => setName_Ar(e.target.value)} />
                                </div>

                                <div className="form-group mx-auto">
                                    <label>{t('nameen')}</label>
                                    <input type="text" value={name_en} className="form-control" onChange={(e) => setName_EN(e.target.value)} />
                                </div>

                                <div className="d-flex form-check">

                                    <input className="form-check-input mx-2" checked={checked} type="checkbox" onClick={() => setChecked(!checked)} id="checkParent" />
                                    <label className="form-check-label" for="checkParent">
                                        متفرع
                                    </label>
                                </div>


                                <select className="form-control my-3" value={cat} onChange={e => setCat(e.target.value)} >
                                    <option>القسم</option>
                                    {category.map((cat, i) => {
                                        return <option key={i} value={cat}> {cat.name_ar} </option>
                                    })}
                                </select>

                                <div className="submiting d-flex">
                                    <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => Categories(e)}>حفظ</button>
                                    <Link to={{ pathname: '/customersGroup', params: { status: 2 } }} type="button" className="btn btn-primary mx-2 px-4">تراجع</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div > */}
        </Layout >
    )
}

export default AddCustomGroup
