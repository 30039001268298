import React, { useContext, useEffect, useState, useRef } from "react";
import Layout from "../../Layout";
import DatePicker from "react-datepicker";
import Pdf from "react-to-pdf";
import { ProductContext } from "../../context/context";
import TableAccount from "./TableAccount";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



function AccountStatement() {
    const ref = React.createRef();
    const options = {
        orientation: 'landscape',
        unit: 'in',
    };
    const { t, i18n } = useTranslation();
    const inputRef3 = useRef();
    const [AccountId, setAccountId] = useState('');
    const [ArrayTyping, setArrayTyping] = useState([]);
    const [ArrayBranches, setArrayBranches] = useState([]);
    const [ArrayBranchesSend, setArrayBranchesSend] = useState([]);
    const [options3, setOptions3] = useState([]);
    const [AccountFormReportData, setAccountFormReportData] = useState([]);
    const { AccountFormReport = [], GetReportAccountStatemen, ReportsAccounts = [], mostData = [], user, getAccountFormReport } = useContext(ProductContext);

    const [StartDataCustomerStatement, setStartDataCustomerStatement] = useState(new Date());
    const [EndDataCustomerStatement, setEndDataCustomerStatement] = useState(new Date());
    const [typePrint, setTypePrint] = useState(false);
    const [Data, setData] = useState([]);
    const [cumulative, setCumulative] = useState(true);



    useEffect(() => {
        getAccountFormReport();
    }, [])
    useEffect(() => {
        setData(ReportsAccounts)
        // console.log(ReportsAccounts);
    }, [ReportsAccounts])

    useEffect(() => {
        if (AccountFormReport.length != 0) {
            setAccountFormReportData(AccountFormReport);
            setArrayBranches(AccountFormReport.branchList);
            setCumulative(AccountFormReport.cumulative)
        }
    }, [AccountFormReport]);

    useEffect(() => {
        if (mostData.length != 0) {
            setTypePrint(mostData.printFromFrontEnd)
        }
    }, [mostData])

    if (AccountFormReportData.length != 0) {
        AccountFormReportData.journalVoucherTypeList.forEach(item => {
            item.value = item.journalVoucherTypeName;
            item.label = item.journalVoucherTypeName;
            item.id = item.jvType;
        })
    }

    if (ArrayBranches.length != 0) {
        ArrayBranches.forEach(item => {
            item.value = item.branchId;
            item.label = item.branchName;
            item.id = item.branchId;
        })
    }

    const handelValue = e => {
        console.log(e.target.value);
        if (e.target.value == 1) setCumulative(true)
        if (e.target.value == 2) setCumulative(false)

    }

    const handelChange3 = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setOptions3([]);
        } else {
            setOptions3(
                AccountFormReportData.accountList.filter((option) =>
                    option.accountName.toLowerCase().includes(val.toLowerCase())
                )
            );
        }
    };

    const onViewPrint = () => {
        let parms = { nameCustomer: inputRef3.current.value, AccountId, user, data: ReportsAccounts, ArrayBranches: ArrayBranchesSend.length == 0 ? ArrayBranches : ArrayBranchesSend, allBarnches: ArrayBranches, datefrom: StartDataCustomerStatement, dateto: EndDataCustomerStatement };
        let handle = window.open(window.location.origin + '/account-statement-printing', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
        handle.window.parameters = JSON.stringify(parms);
    }

    const getSelector = (e) => {
        setArrayTyping(e);
    }

    const getSelectorBranchs = (e) => {
        setArrayBranchesSend(e);
    }

    return (
        <Layout>
            <div className="company text-right font-ar container companyReciptVoucher py-5 px-0" style={{ maxWidth: "1170px" }}>
                <div className="container-fluid AccountStatement" style={{ minHeight: "70vh" }}>
                    <div className="container py-5">
                        <div className="row flex-row-reverse">
                            <form dir={i18n.language == "en" ? "ltr" : "rtl"} className={i18n.language == "en" ? "text-left col-12" : "text-right col-12"} onSubmit={e => e.preventDefault()}>

                                <div className="col-12 mx-auto">
                                    <div className="row">
                                        <div className="col-12 col-md-6 mx-auto">
                                            <label className="label-account-statement"> {t('beginning')} </label>
                                            <DatePicker style={{ width: "100%" }} className="form-control started" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={StartDataCustomerStatement} onChange={(date) => setStartDataCustomerStatement(date)} />
                                        </div>

                                        <div className="col-12 col-md-6 mx-auto">
                                            <label className="label-account-statement"> {t('endofhistory')} </label>
                                            <DatePicker style={{ width: "100%" }} className="form-control ended" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={EndDataCustomerStatement} onChange={(date) => setEndDataCustomerStatement(date)} />
                                        </div>

                                    </div>

                                    <div className="row my-3 align-items-center flex-row-reverse ">
                                        <div className="col-12 col-md-6 mx-auto account-statement-container">
                                            <label className="label-account-statement"> {t('branches')} </label>
                                            <Select options={ArrayBranches} isMulti placeholder={t('all')} onChange={e => getSelectorBranchs(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container">
                                            <label className="label-account-statement"> {t('type')} </label>
                                            <Select options={AccountFormReportData.journalVoucherTypeList} isMulti placeholder={t('all')} onChange={e => getSelector(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container mt-3">
                                            <label className="label-account-statement"> {t('accounts')} </label>
                                            <div className="filterdeftcustoner">
                                                <input type="search" autoComplete="off" className="form-control input-account-statement" ref={inputRef3} onChange={(e) => handelChange3(e)} />
                                                <ul className="list-group list-group-filter-search  list-group-filter-search-2 text-left">
                                                    {options3.map((option, i) => {
                                                        return (
                                                            <React.Fragment key={i}>
                                                                <button key={i} type="button" className="list-group-item list-group-item-action" onClick={() => {
                                                                    inputRef3.current.value = option.accountName;
                                                                    setAccountId(option.accountId)
                                                                    setOptions3([]);
                                                                }}
                                                                >
                                                                    {option.accountName}
                                                                </button>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 mt-2 mx-auto account-statement-container align-self-center mt-3">
                                            <div className={`form-check form-check-inline mr-0 d-flex justify-content-end flex-row-reverse`}>
                                                <label className="label-account-statement d-inline-block mx-2 mb-0" htmlFor="CheckboxCollection"> {t('Accumulatingtheopening')} </label>
                                                <input className="" type="checkbox" id="CheckboxCollection" defaultChecked={true} value="تجميع الرصيد الافتتاحي في حالة اختيار اكتر من فرع" />
                                            </div>

                                            <div className={`form-check mt-2 form-check-inline mr-0  d-flex justify-content-end flex-row-reverse`}>
                                                <label className="label-account-statement d-inline-block mx-2 mb-0" htmlFor="CheckboxNotCollection" > {t('Notdisplayingzerobalanceaccounts')} </label>
                                                <input className="" type="checkbox" id="CheckboxNotCollection" defaultChecked={true} value="تجميع الرصيد الافتتاحي في حالة اختيار اكتر من فرع" />
                                            </div>

                                            <div className="d-flex mt-2">
                                                <div className={` form-check form-check-inline mr-0 d-flex justify-content-end flex-row-reverse`}>
                                                    <label className="label-account-statement d-inline-block mx-2 mb-0" htmlFor="trakm"> تراكمي </label>
                                                    <input className="" type="radio" id="trakm" name="cumulative" defaultChecked={cumulative} onChange={(e) => handelValue(e)} value="1" />
                                                </div>

                                                <div className={`form-check form-check-inline mr-0  d-flex justify-content-end flex-row-reverse`}>
                                                    <label className="label-account-statement d-inline-block mx-2 mb-0" htmlFor="move" > الحركات خلال الفترة </label>
                                                    <input className="" type="radio" id="move" name="cumulative" onChange={(e) => handelValue(e)} value="2" />
                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                    <div className={` ${i18n.language == "en" ? "text-left" : "text-right"} `}>
                                        <button className="btn btn-danger py-1 my-4 mx-0 px-4" type="button" onClick={() => GetReportAccountStatemen(
                                            ArrayBranchesSend,
                                            StartDataCustomerStatement,
                                            EndDataCustomerStatement,
                                            AccountId,
                                            inputRef3.current.value,
                                            document.getElementById('CheckboxCollection'),
                                            document.getElementById('CheckboxNotCollection'),
                                            ArrayTyping,
                                            cumulative
                                        )} >
                                            {t('view')}
                                        </button>
                                        <button className="btn btn-danger py-1 my-4 mx-1 px-4" type="button" onClick={() => onViewPrint()} >   {t('print')} </button>
                                        {/* <button className="btn btn-danger py-1 my-4 mx-1 px-4" type="button" onClick={() => onViewPrint()} >   Download Pdf </button> */}
                                        <Pdf targetRef={ref} filename="account_statement.pdf" options={options} scale={0.9999999999999}>
                                            {({ toPdf }) => <button className="btn btn-danger py-1 my-4 mx-1 px-4" onClick={toPdf}>Pdf</button>}
                                        </Pdf>

                                        <ExcelFile name="Summary Reports" element={<button className="btn btn-danger py-1 my-4 mx-1 px-4"> Execl</button>}>
                                            <ExcelSheet data={Data} name="Summary Reports">
                                                <ExcelColumn label="الفرع" value="jvId" />
                                                <ExcelColumn label="رقم القيد" value="jvId" />
                                                <ExcelColumn label="التاريخ" value="jvDate" />
                                                <ExcelColumn label="المصدر" value="journalVoucherTypeName" />
                                                <ExcelColumn label="الوصف" value="dtlDescription" />
                                                <ExcelColumn label="مدين" value="debitLocal" />
                                                <ExcelColumn label="دائن" value="creditLocal" />
                                                {/* <ExcelColumn label="الرصيد" value="costOut" /> */}
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </div>

                                </div>
                            </form>
                            <hr />
                        </div>

                        <div ref={ref} className="mx-auto" >
                            <TableAccount ReportsAccounts={ReportsAccounts} ArrayBranches={ArrayBranches} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AccountStatement;
