import React from 'react'

function OfficerTable(props) {
    let {t, Loans, changePaymentValueLoan} = props;
    return (
        <div>
            <table className="table table-striped employeeOnlySolf d-none">
                <thead>
                    <tr className="text-center table-list-costing">
                        <th> {t('type')} </th>
                        <th scope="col"> {t('num')} </th>
                        <th scope="col"> {t('Advanceamount')} </th>
                        <th scope="col"> {t('duedate')} </th>
                        <th scope="col"> {t('amountamount')} </th>
                    </tr>
                </thead>
                <tbody className="lists text-center lonsList" style={{ display: "inherit" }}>
                    {Loans.map(({ ftid, journalVoucherTypeName, loanValue, paymentValue, dueDate, sourceFtid, sourceLoanId }, idx) => {
                        return (
                            <tr key={idx} className={`row-${idx}`}>
                                <td > {journalVoucherTypeName} </td>
                                <td> {ftid} </td>
                                <td> {loanValue} </td>
                                <td> {dueDate} </td>
                                <td style={{ width: '200px' }}> <input type="number" className={`form-control text-center w-100 td-${idx} input-paymentValue-${idx}`} defaultValue={paymentValue} onChange={e => changePaymentValueLoan(e.target.value, sourceFtid, sourceLoanId)} /> </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default OfficerTable
