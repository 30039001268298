const FRANCH = {
    afakyProgram: "SYSTÈME ERP AFAKY",
    afakyDescription: "Un programme de comptabilité arabe simple pour ajuster la comptabilité et l'inventaire, produit par Afaky Company for Information Technology. Il fonctionne avec toutes les devises internationales. N'importe qui peut l'apprendre en moins de deux heures sans expérience préalable en comptabilité financière. Il donne des rapports très divers. Il est le résultat d'une longue expérience et la première version de celui-ci date de 2008 et est constamment mis à jour en s'appuyant sur l'expérience accumulée de l'entreprise et en tenant compte des demandes des clients",
    afakyMainSite: "Site principal",
    license: "Licence système",
    help: "Aide système",
    versionNumber: "Numéro de version",
    customerName: "Nom du client",
    securityCode: "Code de sécurité",
    securityUnLoakCode: "Code de déverrouillage de sécurité",

    licensestatus: "État de la licence",
    Send: "Envoyer",

    hello: "Bonjour",
    nameSystem: "Afaky",
    nameTag: "Afaky Web",
    descTag: "Un des produits d'Afaky Information Technology Company",
    username: `Nom d'utilisateur`,
    pass: 'Mot de passe',
    remember: "Souviens-toi de moi",
    login: "Connectez-vous au système",
    system: "Système",
    company: "la société",
    branches: "branches",
    branche: "branche",
    fiscal_years: "Exercices",
    period: "période",
    curreny: "devise",
    country: "pays",
    cities: "villes",
    regions: "Régions",
    nationality: "nationalité",
    suppliers_group: "Groupe de fournisseurs",
    suppliers: "fournisseuses",
    customers_groups: "Groupes De Clients",
    customers: "les clients",
    sponsors: "sponsors",
    // _______

    employees: "employées",
    employee: "employée",

    change_employee_branch: "changer de branche d'employé",
    send_SMS: "envoyer un SMS",
    fiscal_years: "exercices",
    users: "utilisatrices",
    alarms_setting: "réglage des alarmes",
    alarms_today: "alarmes aujourd'hui",
    Database: "Base de données",
    alarms_setting: "réglage des alarmes",

    // ---
    log_out: "Se déconnecter",
    exit: "Sortie",
    setting: "paramètre",
    general_settings: "réglages généraux",
    PC_setting: "Réglage de l'ordinateur",
    accounts: "Comptes",
    counting: "compte",
    journal_voucher_daily: "bon de journal quotidien",
    voucher_daily: "bon tous les jours",
    Financial_transactions: "Opérations financières",


    cash: "espèces",
    alcash: "Caissière",
    Banks: "statut de changement de prise de papier",
    receipt_voucher: "bon de réception",


    transfer_voucher: "Bon de transfert",
    reports: "rapports",


    account_statement: "relevé de compte",

    paper_catch_and_pay: "Capture papier et paiement",

    paper_catch: "capture de papier",

    paper_pay: "papier payer",
    paper_catch_change_status: "statut de changement de prise de papier",
    bank: "banque",

    paper_status: "état du papier",
    paper_catch_report: "rapport de capture papier",
    paper_pay_report: "rapport de paie papier",
    stores: "magasins",
    definition_stores: "magasins de définition",
    definition_items: "éléments de définition",
    stores_opening_balance: "solde d'ouverture des magasins",
    assemble_item: "assembler l'article",
    packing_item: "article d'emballage",

    receipt_goods: "réception des marchandises",


    exchange_goods: "échanger des marchandises",
    transfer_of_goods: "transfert de marchandises",
    transfer_of_goods_linked_to_receipt: "transfert de marchandises - lié à la réception",
    receipt_of_goods_transfer: "réception du transfert de marchandises",
    execution_of_goods: "exécution des marchandises",
    inventory_minutes: "minutes d'inventaire",
    stocktaking: "inventaire",
    reserve_quantities: "quantités de réserve",
    release_quantities: "quantités libérées",
    item_balance: "solde de l'article",
    follow_cost: "suivre le coût",
    item_serial_query: "demande de numéro de série d'article",
    barcode_print: "impression code-barres",
    modify_items_prices: "modifier les prix des articles",
    adoption: "adoption",
    Purchase: "Acheter",
    Purchase_orders: "Acheter en ligne",
    Purchase_deals: "Offres d'achat",
    Purchase_invoice: "Facture d'achat",
    returned_purchase: "Achat retourné",
    notices_supplier: "avis fournisseur",
    sales: "Ventes",
    Retur: "Retour",
    commissioms_policy: "politique de commissions",
    commissioms_salesman: "vendeur de commissions",
    quotations: "citations",
    sales_orders: "commandes",
    sales_invoice: "facture de vente",
    returned_sales: "ventes retournées",
    customer_notices: "avis clients",
    review_invoices: "revoir les factures",
    item_price_query: "Requête sur le prix de l'article",
    offers: "des offres",
    moreData: "plus de données",
    define_units: "définir les unités",
    define_groups: "définir des groupes",
    define_item: "définir des éléments",
    POS: "PDV",
    POSPoint: "Point de vente ouvert ",
    POSPointR: "Ouvrir un point de vente restaurant",
    sellOrder: "ordre de vente",
    point_of_sale_settings: "paramètres du point de vente",
    Cost_Center_Directory: "Répertoire des centres de coûts",
    definition_stores: "magasins de définition",
    journalVoucherDaily: "bon de journal quotidien",
    accounSstatement: "Relevé de compte",
    reports: "rapports",
    debtRecoveryCustomer: "Recouvrement de créances - Customer",
    debtRecoverySuppliers: "Recouvrement de créances - Fournisseur",
    categoryCard: "Carte de catégorie",
    mainunit: "Unité principale",
    defaultunitpurchase: "Achat d'unité par défaut",
    defaultunitpurchase: "Ventes unitaires par défaut",
    SalesInvoiceSummary: "Résumé de la facture de vente",
    PurchaseInvoiceSummary: "Récapitulatif de la facture d'achat",

    PurchaseInvoice: "Facture d'achat",
    PurchaseReturned: "Achat retourné",
    SalesInvoice: "Facture de vente",
    SalesReturned: "Ventes retournées",

    numJV: 'Numéro MV',

    receipt: "Réception de capture",
    transfer: "Le reçu",

    // point of sales
    name: "Nom",
    code: "Code",
    unit: "Unité",
    number: "Numéro",
    qtn: "Quantité",
    price: "Prix",
    discount: "Remise",
    vat: "T.V.A.",
    total: "Totale",
    setting: "Paramètre",
    nettotal: 'Total Net',
    totalbeforetax: "Total hors taxe",
    discountitems: 'Articles à prix réduits',
    Accountstatement: "Relevé de compte",
    customerPos: "Client",
    customerNamePos: "Nom du client",
    beginning: "Le début de l'histoire",
    endofhistory: "Fin de l'histoire",

    print: "Imprimer",
    save: "Sauver",
    edit: "Éditer",

    view: "Voir",
    history: "Histoire",
    source: "La source",
    Nusource: "Numéro source",
    Debtor: "Débiteur",
    Creditor: "Créancier",
    Balance: "Solde",
    previousbalance: "Solde précédent",
    amountPaid: "Le montant payé",
    amount: "Montant ",

    Residual: "Résiduel",
    description: "La description",
    cashcollection: "Collecte d'argent",
    pay: "Payer",
    Pendingorders: "Les ordres en attente",
    cancel: "Annuler",
    Suspension: "Suspension",
    saleinvoice: "Facture de vente",
    Returns: "Retour",
    Retreat: "Retraite",
    movementtype: "Type de mouvement",
    independentmovement: "mouvement indépendant",
    recovery: "Récupération",
    date: "Date",
    Check: "Vérifier",
    addcustomernew: "Ajouter un nouveau client",
    mobile: "Portable",
    add: "Ajouter",
    status: "Statut",
    box: "Compte",
    boxnum: "Nom du compte",
    registrationNumber: "Numéro d'enregistrement",
    num: "Numéro",
    accountName: "Nom du compte",
    certified: "Prise en charge",
    notsupported: "Non supporté",
    currenyType: "Monnaie",
    sy: "S.Y",
    bondowner: "Propriétaire de l'obligation",
    salesRepresentative: "Commercial",
    type: 'Taper',
    barcode: "code à barre",

    costCenter: "Centre de coûts",
    dateInvoice: "Date de facturation",
    numebrInvoice: "Numéro de facture",
    Advanceamount: "Montant de l'avance",
    duedate: "Date d'échéance",
    Notdisplayingzerobalanceaccounts: "Ne pas afficher les comptes à solde nul",
    Accumulatingtheopening: "Cumul du solde d'ouverture si plus d'une succursale est sélectionnée",
    all: "Tout",
    settingPOSPrint: "Paramètres d'impression du point de vente",


    Prescriptionprintsettings: "Paramètres d'impression de prescription",
    display: "Affichage",
    Thisprinter: "Cette imprimante ",
    Itemgroup: "Groupe d'articles",
    Item: " Article",
    restaurantprintersettings: "Paramètres de l'imprimante du restaurant",
    active: "Actif",
    Showthepriceinthekitchenbill: "Afficher le prix sur la note de cuisine",
    groupMain: "Groupe principal",
    printaftersaving: "Imprimer après enregistrement",
    printfromweb: "Imprimer à partir du site Web",
    Combinedprinter: "Imprimante combinée",

    effectiveyear: "Déterminer l'année effective",
    accountReports: "Rapports de compte",
    usernameCustomerar: "Nom du client ( Arabich )",
    usernameCustomeren: "Nom du client ( English )",


    cachPayment: "Cache",
    visaPayment: "Visa",
    mastercardPayment: "MasterCard",
    aglPayment: "Crédit",
    SummaryReports: "Récapitulatif des factures",
    ItemsBalanceReports: "ArticlesSoldeRapports",
    Reports: "Rapports",
    stockValuation: "Évaluation des stocks",
    Telephone: "Téléphone",
    Fax: "Fax",
    Email: "E-mail",
    address: "Adresse",
    tax_registration_number: "numéro d'identification fiscale",
    VAT_registration_number: "Numéro d'immatriculation à la TVA",
    Notes: "Remarques",
    webSite: "site Internet",
    File_No_Commercial_Registry: "N° de dossier - Registre du commerce",
    amount: "Montant",
    simplified_tax_invoice: "facture fiscale simplifiée",
    companyS: "la société",
    Tax_Number: "Numéro d'identification fiscale",
    Commercial_Register: "Registre du commerce",
    customer: "client",
    vat2: "Taxe sur la valeur ajoutée",
    Totalaftertax: "Total après impôt",


    // ___
    Thanks: "Merci de votre visite.. A bientôt",
    thenumberofpieces: "le nombre de pièces",
    amountamount: "montant",

    CashReceiptVoucher: "Bon de caisse",
    CashPaymentVoucher: "Bon de paiement en espèces",

    BankReceiptVoucher: "Reçu bancaire",
    BankPaymentVoucher: "Bon de paiement bancaire",

    itemCard: "Fiche article",
    itemCardW: "Carte d'article sans frais",

    vatTable: "taxe de table",

    // 

    print: "imprimer",
    ordersDevelery: "Bons de livraison",
    tables: "les tables",
    orderNum: "Numéro de commande",
    printerSettings: "Paramètres de l'imprimante",
    invoicePrinting: "Impression de factures",
    numberOfCopies: "Nombre de copies",
    kitchenPrint: "cuisine Imprimer",
    onlyPrintVariables: "Afficher uniquement les variables",
    // 
    arrangeGroupsAndItems: "Organiser les groupes et les articles dans l'écran d'ouverture du point de vente",
    input: "saisir",
    alphabetic: "alphabétique",
    Code: "Code",
    // 
    m: "m",

    customerGroup: "Groupe de clients",
    SupplierGroup: "Groupe de fournisseurs",
    viewall: "Voir tout",
    salesAndReturns: "Ventes et retours",
    store: "Magasin",
    group: "Grouper",
    mainGroup: "Groupe principal",
    groupOfItems: "Groupe d'articles",
    items: "Articles",
    ItemCompanies: "Entreprises d'articles",
    Itemcode: "Code de l'article",
    Itemname: "Nom de l'article",
    thesmallestunit: "la plus petite unité",
    defaultPurchases: "Achats par défaut",
    defaultSales: "Ventes par défaut",

    AllitemsbalanceZero: "Tous les articles - solde de l'article nul",
    AllitemsbalanceNotZero: "Tous les articles - le solde de l'article n'est pas nul",
    Negativeitemsonly: "Éléments négatifs uniquement",
    Positiveitemsonly: "Éléments positifs uniquement",
    costType: "Type de coût",
    cost: "Coût",
    totalCost: "Coût total",
    movementsduring: "Mouvements au cours de la période",
    ChooseItem: "Choisissez l'article",

    local: "Local",
    delevery: "Livraison",
    safary: "Dehors",

    RestaurantScreenSettings: "Paramètres de l'écran du restaurant",
    activate: "Activer",
    IPAdress: "Adresse IP",

    arabic: "Arabe",
    english: "Anglaise",
    french: "Française",


    definctionUnit: "Unités",
    codeTax: "Code fiscal",
    namear: "Nom Arabe",
    nameen: "Nom anglais",

    transactionDate: "Date de la transaction",
    transactionTypeName: "Date de la transaction",
    byadministrator: "par l'administrateur système",


    followeUser: "Suivi des utilisateurs",

    WriteEnglishArabic: "Écrivez le nom en anglais comme le nom en arabe",

    CreateSupplierGroup: "Ajouter un groupe de fournisseurs",
    CreateCustomerGroup: "Ajouter un groupe de clients",
    createUnit: "Ajouter une définition d'unité",


};

export default FRANCH;