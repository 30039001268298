import MaterialTable from "material-table"
import React, { useContext } from "react"
import { ProductContext } from "../../context/context";
import { FiEdit2 } from "react-icons/fi";
import { RiUserFollowLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';


function DataTableCompany({ RefrenceCompany = {} }) {
    
    const { Companines = [], DelCompany } = useContext(ProductContext);
    let { chkDelete, chkModify, chkTrackingUsers, chkReview = false } = RefrenceCompany;

    
    let columns = [
        { title: 'م', field: 'companyId',cellStyle: { textAlign: "center"}},
        { title: 'الاسم', field: 'nameA',cellStyle: { backgroundColor: '#ddd', color: '#000', textAlign: "center"} },
        { title: 'العنوان', field: 'address'},
        { title: 'السجل التجاري', field: 'email' },
        { title: 'الرقم الضريبي', field: 'taxNumber', type: 'numeric'},
        { title: 'اعدادات', field: 'edit', cellStyle: {  color: '#000', textAlign: "center"}, render: rowData => { 
            return (
                <>
                    {chkModify
                        ? <Link to={`/companies/edit/${rowData.companyId}`} className="btn btn-primary py-1 px-2"> <FiEdit2 /> </Link> 
                        : <button disabled className="btn btn-primary py-1 px-2"> <FiEdit2 /> </button>
                    } 
                    <button className="btn btn-info py-1 mx-2 px-2" disabled={chkTrackingUsers ? false : true} > <RiUserFollowLine /> </button>
                    <button className="btn btn-danger py-1 mx-2 px-2" disabled={chkDelete ? false : true} onClick={() => DelCompany(rowData)} > <MdDelete /> </button>
                </>
            )
        }},
    ];





    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={columns}
                data={chkReview ? Companines : []}
                title={null}
                options={{
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    }
                }}
                options={{
                    actionsColumnIndex: -1,
                    paginationType:"stepped",
                    showFirstLastPageButtons:false,
                    addRowPosition:"first"
                }}
            />
        </div>
    )
}

export default DataTableCompany