import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ProductContext } from '../context/context'
import logo from "../context/image.png";
import logo2 from "../images/assets/afaky.png";
import Group from './MultiGroup/Group';
import { useHistory } from "react-router-dom";
import logoutIcon from "../images/assets/logout.png"
import transformation from "../images/assets/transformation.png"
// trans
import { useTranslation } from "react-i18next";

let Links = [];

function Sidebar() {
    const { handelSidebar, allRefrence = [] } = useContext(ProductContext);
    const { t } = useTranslation();

    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('lang')) {
            let lng = JSON.parse(localStorage.getItem('lang'));
            if (lng === 'en' || lng === 'fr') {
                document.getElementById('lay').classList.add('side-bar-v-left')
            } else {
                document.getElementById('rowing-layout').classList.add('flex-row-reverse');
                document.getElementById('lay').classList.add('side-bar-v-right');
            }
        } else {
            document.getElementById('rowing-layout').classList.add('flex-row-reverse');
            document.getElementById('lay').classList.add('side-bar-v-right');
        }
    }, [])

    const CheckRef = (name = true) => {
        if (name) {
            if (allRefrence.length !== 0) {
                return allRefrence.find(item => item.mName === name) ? allRefrence.find(item => item.mName === name).showForm : false
            }
        } else {
            return true;
        }

    }

    const GenerationLink = (id, text, path, ref, child) => ({ id, text, path, refrence: ref, child });


    if (allRefrence.length !== 0) {
        Links = [
            GenerationLink(7, t('system'), "/", CheckRef('TsmSystem'), [
                {
                    text: t('SupplierGroup'),
                    path: '/suppliersGroups',
                    refrence: CheckRef('TsmSupplierGroup'),
                    group: false
                }, {
                    text: t('customerGroup'),
                    path: '/customersGroup',
                    refrence: CheckRef('TsmCustomerGroup'),
                    group: false
                },
            ]),
            GenerationLink(1, t('Financial_transactions'), "/", CheckRef("TsmFinancialTransactions"), [{
                text: t('receipt_voucher'),
                path: '/receipt-voucher',
                refrence: CheckRef('TsmTransferVoucherReceipt'),
                group: false
            },
            {
                text: t('transfer_voucher'),
                path: '/transfer-voucher',
                refrence: CheckRef('TsmTransferVoucher'),
                group: false
            },

            // 
            {
                text: t('alcash'),
                path: '/',
                refrence: CheckRef('TsmFCash'),
                group: [
                    {
                        text: t('CashReceiptVoucher'),
                        path: '/Cash-Receipt-Voucher',
                        refrence: CheckRef('TsmFCash'),
                        group: false
                    }, {
                        text: t('CashPaymentVoucher'),
                        path: '/Cash-Payment-Voucher',
                        refrence: CheckRef('TsmFBank'),
                        group: false
                    }
                ]
            },
            {
                text: t('Banks'),
                path: '/',
                refrence: CheckRef('TsmFCash'),
                group: [
                    {
                        text: t('BankReceiptVoucher'),
                        path: '/Bank-Receipt-Voucher',
                        refrence: CheckRef('TsmFBank'),
                        group: false
                    },
                    {
                        text: t('BankPaymentVoucher'),
                        path: '/Bank-Payment-Voucher',
                        refrence: CheckRef('TsmFBank'),
                        group: false
                    }
                ]
            },
                // 

            ]),
            GenerationLink(6, t('stores'), "/", CheckRef('TsmInventory'), [
                {
                    text: t('definctionUnit'),
                    path: '/Units',
                    refrence: CheckRef('TsmUnits'),
                    group: false
                }
                ,
                {
                    text: t('define_item'),
                    path: '/DefinitionItems',
                    refrence: CheckRef('TsmItems'),
                    group: false
                }
            ]),
            GenerationLink(2, t('accountReports'), "/",
                CheckRef("TsmReports"), [{
                    text: t('accounSstatement'),
                    path: '/account-statement',
                    refrence: CheckRef('TsmAccountStatement'),
                    group: false
                }]),
            GenerationLink(3, t('POS'), "/", CheckRef("TsmPointsOfSale"), [{
                text: t('POSPoint'),
                path: '/SaleInvoice',
                refrence: CheckRef('TsmOpenPOS'),
                group: false,
                type: 4
            }, {
                text: t('POSPointR'),
                path: '/POSTables',
                refrence: true,
                refrence: CheckRef('TsmOpenPOSTables'),
                type: 4
            }, {
                text: t('item_price_query'),
                path: '/item-price-query',
                refrence: true,
                refrence: CheckRef('TsmItemPriceQuery'),
                group: false
            }, {
                text: t('point_of_sale_settings'),
                path: '/pointSettings',
                refrence: CheckRef('TsmPOSSetting'),
                group: false
            }]),
            GenerationLink(4, t('Reports'), "/", true, [{
                text: t('SummaryReports'),
                path: '/SummaryReports',
                refrence: CheckRef('TsmPOSInvoicesSummary'),
                group: false
            }, {
                text: t('ItemsBalanceReports'),
                path: '/ItemsBalanceReports',
                refrence: true,
                group: false
            }, {
                text: t('stockValuation'),
                path: '/stockValuation',
                refrence: CheckRef('TsmStockAssessment'),
                group: false
            }, {
                text: t('itemCard'),
                path: '/category_card',
                refrence: CheckRef('TsmItemCard'),
                group: false
            }, {
                text: t('itemCardW'),
                path: '/category_card_cost',
                refrence: CheckRef('TsmItemCardNoCost'),
                group: false
            }
            ]),
            GenerationLink(5, t('help'), "/", true, [{
                text: t('license'),
                path: '/license',
                refrence: true,
                group: false
            }
            ]),
            // GenerationLink(5, 'المبيعات', "/", true, [{
            //     text: "فاتورة بيع",
            //     path: '/sale_invoice',
            //     refrence: true,
            //     group: false
            // },
            //     //  {
            //     //     text: "مرتجع بيع",
            //     //     path: '/ItemsBalanceReports',
            //     //     refrence: true,
            //     //     group: false
            //     // }, {
            //     //     text: "امر بيع",
            //     //     path: '/stockValuation',
            //     //     refrence: CheckRef('TsmStockAssessment'),
            //     //     group: false
            //     // }, {
            //     //     text: "عروض اسعار",
            //     //     path: '/category_card',
            //     //     refrence: CheckRef('TsmItemCard'),
            //     //     group: false
            //     // }
            // ]),



        ];
    }

    const LogOut = () => {
        localStorage.removeItem('afakyLogin');
        localStorage.removeItem('afakyToken');
        localStorage.removeItem('afakyUser');
        window.location.replace('/')
    }


    return (
        <div className="side-bar-v side-bar-layout" id="lay">
            <div className="d-flex justify-content-between align-items-center px-4 py-3 hashSmallResponsive">
                <img src={logo || logo2} width="100" height="50" onClick={() => history.push("/")} alt="" />
                {/* <FaArrowCircleLeft className="icon-arrow-sidebar text-muted" onClick={handelSidebar} /> */}
                <img src={transformation} className="iconMax2" onClick={handelSidebar} style={{ cursor: "pointer" }} />
            </div>
            <hr className="m-0" />
            <ul>
                {Links.map((link, i) => {
                    return link.child === false ? (
                        link.refrence ?
                            <div key={i}>
                                <li className="sidebar-item">
                                    <Link to={link.path} className="nav-link font-ar a-link">
                                        <span> {link.text} </span>
                                    </Link>
                                </li>
                            </div>
                            : ''

                    ) : <Group key={i} linkOld={link} i={i} />

                })}
                <li className="sidebar-item mx-0">
                    <button className={`nav-link logout font-ar w-100`} onClick={() => LogOut()} style={{ border: 0, background: "none" }}>
                        {/* <button className={`nav-link font-ar w-100 ${allRefrence.length != 0 ? allRefrence.find(item => item.mName == "TsmLogOff").showForm ? "" :  "d-none" :   "d-none" }`}  onClick={() => LogOut()} style={{border: 0, background:"none"}}> */}
                        <div className="d-flex justify-content-center">
                            <span className='mx-3'> {t('log_out')} </span>
                            <span> <img src={logoutIcon} className="iconMax2" />  </span>
                        </div>
                    </button>
                </li>
            </ul>
        </div>
    )
}


export { Links };
export default Sidebar;