import MaterialTable from 'material-table';
import React, { useContext, useState } from 'react'
import { ProductContext } from '../../context/context';

import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";



function DataTableSupller() {
    const { DelSuppliersGroup } = useContext(ProductContext)

    const [columns, setColumns] = useState([
        { title: 'الاسم', field: 'nameA',cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'المجموعة الرئيسبة', field: 'sgid',cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'اعدادات', field: 'edit', cellStyle: {  color: '#000', textAlign: "center"}, render: rowData => { 
            return (
                <>
                    <button className="btn btn-primary py-1 px-2"> <FiEdit2 /> </button> 
                    <button className="btn btn-danger py-1 mx-2 px-2" onClick={(e,rowData) => DelSuppliersGroup(rowData)}> <MdDelete /> </button> 
                </>
            )
        }},
    ])

    const edit = user => {
        window.location.replace(window.location.origin + `/supplier/edit/${user.id}`)
    }

    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={columns}
                data={[]}
                title={null}
              
                options={{
                    actionsColumnIndex: -1,
                    paginationType:"stepped",
                    showFirstLastPageButtons:false,
                    addRowPosition:"first"
                }}
            />
        </div>
    )
}

export default DataTableSupller
