import React, { useState } from 'react'
import Layout from "../../Layout";


function AddCostCenterDirectory() {

    const [category, setCategory] = useState([]);
    const [code, setCode] = useState('');
    const [name_ar, setName_Ar] = useState('');
    const [name_en, setName_EN] = useState('');
    const [cat, setCat] = useState('');
    const [checked, setChecked] = useState(false);

    const Categories = (e) => {
        e.preventDefault();

        if(name_ar == "") {
            alert('من فضلك اكمل البيانات بشكل صحيح')
        }else {
            if (!checked){
                if(cat == ""){
                    alert('اختار القسم')
                }else {
                    // addSupplier({id: Math.random() * 10, name_ar,name_en,code, cat, checked: checked})
                    let Cat = [...category, {id: Math.random() * 10 ,name_ar,name_en,code, cat, checked: checked}];
                    setName_Ar("")
                    setName_EN("")
                    setCode("")
                    setCategory(Cat)
                    setCat('')
                    setChecked(false)
                }
            } else {
                let Cat = [...category, {id: Math.random() * 10 ,name_ar,name_en,code, cat, checked: checked}];
                // addSupplier({id: Math.random() * 10, name_ar,name_en,code, cat, checked: checked})
                setName_Ar("")
                setName_EN("")
                setCode("")
                setCategory(Cat)
                setChecked(false)
                setCat('')
            }
        }
    }


    return (
        <Layout>
                <div className="company text-right font-ar">
                    <div className="container-fluid py-4">
                        <div className="row mx-0">
                            <div className="col-12 col-md-8 mx-auto px-0">
                                <form dir="rtl">
                                        <div className="form-group mx-auto">
                                            <label>الكود</label>
                                            <input type="number" value={code} className="form-control" onChange={(e) => setCode(e.target.value)} />
                                        </div>
                                        <div className="form-group mx-auto">
                                            <label>الاسم بالعربي</label>
                                            <input type="text" value={name_ar} className="form-control" onChange={(e) => setName_Ar(e.target.value)} />
                                        </div>

                                        <div className="form-group mx-auto">
                                            <label>الاسم بالانجليزي</label>
                                            <input type="text" value={name_en} className="form-control" onChange={(e) => setName_EN(e.target.value)} />
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input" checked={checked} type="checkbox" onClick={() => setChecked(!checked)}  id="checkParent" />
                                            <label className="form-check-label mr-4" for="checkParent">
                                                متفرع
                                            </label>
                                        </div>


                                        <select className="form-control my-3" value={cat} onChange={e => setCat(e.target.value)} >
                                            <option>القسم</option>
                                            {category.map(( cat, i )=> {
                                                return <option key={i} value={cat}> {cat.name_ar} </option>
                                            })}
                                        </select>

                                    <div className="submiting d-flex">
                                        <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => Categories(e)}>حفظ</button>
                                        <button type="button" className="btn btn-primary mx-2 px-4">تراجع</button>
                                    </div>
                                </form>
    
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
    )
}

export default AddCostCenterDirectory

