import React, { useContext, useState } from 'react'
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md"
import { ProductContext } from '../../context/context';
import { useTranslation } from "react-i18next";


function Table({ fractions = 1, returnBtnHold, returnBtn, TotaBill, list, DelItem, AddNumber, MinusNumber, changeCountItem, changePriceItem, modifyQuantity, modifyPrice, modifyDiscount, changeDiscountItem }) {

    const { mostData } = useContext(ProductContext)
    const [qun, setQun] = useState(1);
    const [priceItem, setPriceItem] = useState("");
    const [discountItem, setDiscountItem] = useState("");

    const { t } = useTranslation();


    const toggleQuantity = (e, idx, itemId, check, quantity, unitId) => {
        e.preventDefault();
        let btn1 = document.getElementById(`inputQuantity${idx}`);
        let btn2 = document.getElementById(`btnQuantity${idx}`);
        btn1.classList.toggle('d-none');
        btn2.classList.toggle('d-none');
        if (check) {
            changeCountItem(itemId, qun, unitId)
            setQun(1)
            TotaBill()
        } else {
            setQun(quantity)
        }
    }

    const togglePrice = (e, idx, itemId, check, price, unitId) => {
        e.preventDefault();
        let btn1 = document.getElementById(`inputPrice${idx}`);
        let btn2 = document.getElementById(`btnPrice${idx}`);
        btn1.classList.toggle('d-none');
        btn2.classList.toggle('d-none');
        if (check) {
            changePriceItem(itemId, priceItem, unitId)
            setPriceItem(priceItem)
            TotaBill()
        } else {
            setPriceItem(price)
        }
    }

    const toggleDiscount = (e, idx, itemId, check, discount, unitId) => {
        e.preventDefault();
        let btn1 = document.getElementById(`inputDiscount${idx}`);
        let btn2 = document.getElementById(`btnDiscount${idx}`);
        btn1.classList.toggle('d-none');
        btn2.classList.toggle('d-none');
        if (check) {
            changeDiscountItem(itemId, discountItem, unitId)
            setDiscountItem(discountItem)
            TotaBill()
        } else {
            setDiscountItem(discount)
        }
    }
    if (returnBtn || returnBtnHold) {
        return (
            <table className="table table-striped font-ar tabel-master" dir="rtl">
                <thead>
                    <tr className="text-center bg-header-tr">
                        <th scope="col" className="thCode"> {t('code')} </th>
                        <th scope="col" className="thName"> {t('name')} </th>
                        <th scope="col" className="thUnit"> {t('unit')} </th>
                        <th scope="col" className="thNamber"> {t('qtn')} </th>
                        <th scope="col" className="thPrice"> {t('price')} </th>
                        <th scope="col" className="thDiscount"> {t('discount')} </th>
                        <th scope="col" className="th5m"> {t('vat')} </th>
                        <th scope="col" className="thTotal"> {t('total')} </th>
                        <th scope="col" className="thSetting" style={{ width: "110px !important" }}> {t('setting')} </th>
                    </tr>
                </thead>
                <tbody>



                    {list.map((el, idx) => {
                        let { id, unitId, itemId, netPrice, total, quantity, price, tatolItem, discount1, taxRate } = el;
                        let Lists = mostData.itemsList.filter(item => item.itemId == itemId);
                        let { itemName, unitName, itemCode } = Lists.find(un => un.unitId == unitId);

                        el.discount = (el.quantity * el.price) * el.discountPercent1 / 100;
                        el.discount1 = el.discount;
                        el.totalAfterDiscount1 = (el.quantity * el.price) - el.discount;
                        el.totalAfterDiscount2 = el.totalAfterDiscount1;
                        el.totalAfterDiscount3 = el.totalAfterDiscount1;
                        el.netPrice = el.totalAfterDiscount1;
                        el.taxValue = (el.netPrice * el.taxRate) / 100;
                        el.tableTaxValue = (el.netPrice * el.tableTaxPercent) / 100;
                        el.tatolItem = el.netPrice + el.tableTaxValue + el.taxValue;
                        return (
                            <tr key={id} className="text-center">
                                <th scope="row" className="thCode">{itemCode}</th>
                                <td className="thName">{itemName}</td>
                                <td className="thUnit"> {unitName} </td>
                                <td style={{ width: "100px" }} className="thNamber">

                                    <form id={`inputQuantity${idx}`} className="d-none tdQuantity">
                                        <input type="number" value={qun} onChange={e => setQun(e.target.value)} className="form-control mx-auto text-right" />
                                        <button type="submit" className="btn-save font-ar" onClick={modifyQuantity ? e => toggleQuantity(e, idx, itemId, true, quantity, unitId) : () => console.log("")}> حفظ </button>
                                    </form>
                                    <button className="btn" id={`btnQuantity${idx}`} onClick={modifyQuantity ? e => toggleQuantity(e, idx, itemId, false, quantity, unitId) : () => console.log("")}> {quantity} </button>
                                </td>
                                <td className="thPrice">

                                    <form id={`inputPrice${idx}`} className="d-none tdPrice">
                                        <input type="number" value={priceItem} onChange={e => setPriceItem(e.target.value)} className="form-control mx-auto text-right" />
                                        <button type="submit" className="btn-save font-ar" onClick={e => togglePrice(e, idx, itemId, true, price, unitId)}> حفظ </button>
                                    </form>
                                    <button className="btn" id={`btnPrice${idx}`} onClick={modifyPrice ? e => togglePrice(e, idx, itemId, false, price, unitId) : ''}> {price} </button>


                                </td>
                                <td className="thDiscount">

                                    <form id={`inputDiscount${idx}`} className="d-none tdDiscount">
                                        <input type="number" value={discountItem} onChange={e => setDiscountItem(e.target.value)} className="form-control mx-auto text-right" />
                                        <button type="submit" className="btn-save font-ar" onClick={e => toggleDiscount(e, idx, itemId, true, discount1, unitId)}> حفظ </button>
                                    </form>
                                    <button className="btn" id={`btnDiscount${idx}`}
                                        onClick={modifyDiscount ? e => toggleDiscount(e, idx, itemId, false, discount1, unitId) : ''}
                                    > {discount1} </button>
                                </td>
                                <td className="th5m"> {(((+quantity * +price) - +discount1) * (taxRate / 100)).toFixed(fractions)}  </td>
                                <td className="totalSellesTableItem thTotal">
                                    {+el.tatolItem.toFixed(fractions)}
                                </td>
                                <td className="thSetting" style={{ width: "110px !important" }}>
                                    <div className="icons-setting-voice">
                                        <button className="btn btn-setting btn-primary py-1 px-1 btnPlus" onClick={() => AddNumber(itemId, tatolItem, unitId)}>
                                            <AiFillPlusCircle className="setting-icon-sele" />
                                        </button>
                                        <button className="btn btn-setting btn-primary py-1 px-1 btnMinus" onClick={() => MinusNumber(itemId)}>
                                            <AiFillMinusCircle className="setting-icon-sele" />
                                        </button>
                                        <button className="btn btn-setting btn-danger py-1 px-1" onClick={() => DelItem(itemId)}>
                                            <MdDelete className="setting-icon-sele" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}


                </tbody>
            </table>
        )
    } else {

        return (
            <table className="table table-striped font-ar tabel-master" dir="rtl">
                <thead>
                    <tr className="text-center bg-header-tr">
                        <th scope="col" className="thCode"> {t('code')} </th>
                        <th scope="col" className="thName"> {t('name')} </th>
                        <th scope="col" className="thUnit"> {t('unit')} </th>
                        <th scope="col" className="thNamber"> {t('qtn')}  </th>
                        <th scope="col" className="thPrice"> {t('price')} </th>
                        <th scope="col" className="thDiscount"> {t('discount')} </th>
                        <th scope="col" className="th5m"> {t('vat')} </th>
                        <th scope="col" className="thTotal"> {t('total')} </th>
                        <th scope="col" className="thSetting" style={{ width: "110px !important" }}> {t('setting')} </th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((el, idx) => {
                        let { name, itemCode, discount1, unitName, itemId, quantity, price, tatolItem, unitId, taxRate } = el;
                        el.discount = (el.quantity * el.price) * el.discountPercent1 / 100;
                        el.discount1 = el.discount;
                        el.totalAfterDiscount1 = (el.quantity * el.price) - el.discount;
                        el.totalAfterDiscount2 = el.totalAfterDiscount1;
                        el.totalAfterDiscount3 = el.totalAfterDiscount1;
                        el.netPrice = el.totalAfterDiscount1;
                        el.taxValue = (el.netPrice * el.taxRate) / 100;
                        el.tableTaxValue = (el.netPrice * el.tableTaxPercent) / 100;
                        el.tatolItem = el.netPrice + el.tableTaxValue + el.taxValue;
                        return (
                            <tr key={idx} className="text-center">
                                <th scope="row" className="thCode">{itemCode}</th>
                                <td className="thName">{name}</td>
                                <td className="thUnit"> {unitName} </td>
                                <td style={{ width: "100px" }} className="thNamber">
                                    <form id={`inputQuantity${idx}`} className="d-none tdQuantity">
                                        <input type="number" value={qun} onChange={e => setQun(e.target.value)} className="form-control mx-auto text-right btnQtn" />
                                        <button type="submit" className="btn-save font-ar" onClick={e => toggleQuantity(e, idx, itemId, true, quantity, unitId)}> حفظ </button>
                                    </form>
                                    <button className="btn btnQtn" id={`btnQuantity${idx}`} onClick={modifyQuantity ? e => toggleQuantity(e, idx, itemId, false, quantity, unitId) : ''}> {quantity} </button>
                                </td>
                                <td className="thPrice">

                                    <form id={`inputPrice${idx}`} className="d-none tdPrice">
                                        <input type="number" value={priceItem} onChange={e => setPriceItem(e.target.value)} className="form-control mx-auto text-right btnprice" />
                                        <button type="submit" className="btn-save font-ar" onClick={e => togglePrice(e, idx, itemId, true, price, unitId)}> حفظ </button>
                                    </form>
                                    <button className="btn btnprice" id={`btnPrice${idx}`} onClick={modifyPrice ? e => togglePrice(e, idx, itemId, false, price, unitId) : ''}> {price} </button>

                                </td>
                                <td className="thDiscount">

                                    <form id={`inputDiscount${idx}`} className="d-none tdDiscount">
                                        <input type="number" value={discountItem} onChange={e => setDiscountItem(e.target.value)} className="form-control mx-auto text-right" />
                                        <button type="submit" className="btn-save font-ar" onClick={e => toggleDiscount(e, idx, itemId, true, discount1, unitId)}> حفظ </button>
                                    </form>
                                    <button className="btn" id={`btnDiscount${idx}`}
                                        onClick={modifyDiscount ? e => toggleDiscount(e, idx, itemId, false, discount1, unitId) : ''}
                                    > {+discount1.toFixed(fractions)} </button>

                                </td>
                                <td className="th5m"> {+(((+quantity * +price) - +discount1) * (taxRate / 100)).toFixed(fractions)}  </td>
                                <td className="totalSellesTableItem thTotal"> {+el.tatolItem.toFixed(fractions)}  </td>
                                {/* <td className="totalSellesTableItem thTotal"> { parseFloat(tatolItem.toFixed(fractions))  } </td> */}
                                <td className="thSetting" style={{ width: "110px !important" }}>
                                    <div className="icons-setting-voice">
                                        <button className="btn btn-setting btn-primary py-1 px-1 btnPlus" onClick={() => AddNumber(itemId, tatolItem, unitId)}>
                                            <AiFillPlusCircle className="setting-icon-sele" />
                                        </button>
                                        <button className="btn btn-setting btn-primary py-1 px-1 btnMinus" onClick={() => MinusNumber(itemId, unitId)}>
                                            <AiFillMinusCircle className="setting-icon-sele" />
                                        </button>
                                        <button className="btn btn-setting btn-danger py-1 px-1" onClick={() => DelItem(itemId, unitId, idx)}>
                                            <MdDelete className="setting-icon-sele" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )


    }
}

export default Table
