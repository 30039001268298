import MaterialTable from "material-table"
import React, { useContext } from "react"
import { ProductContext } from "../../context/context";
import { RiUserFollowLine } from "react-icons/ri";

import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';


function DataTableMatruialFin({ financialYearRefrence }) {

    const { FinancialYearF, DelFinancialYear } = useContext(ProductContext);
    let { chkDelete, chkModify, chkTrackingUsers, chkReview } = financialYearRefrence;

    let colums = [
        { title: 'م', field: 'financialYearId',cellStyle: { textAlign: "center"}},
        { title: 'كود السنة', field: 'financialCode',cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'العنوان', field: 'addres'},
        { title: 'تاريخ النهاية', field: 'endDate'},
        { title: 'ملاحظات', field: 'notes'},
        { title: 'الحالة', field: 'financialStatus' , render: rowData => rowData.financialStatus == "0" ? "نشط" : "مغلق" },
        { title: 'اعدادات', field: 'edit', cellStyle: {  color: '#000', textAlign: "center"}, render: rowData => { 
            return (
                <>
                    {chkModify
                        ? <Link to={`/fiscalYear/edit/${rowData.financialYearId}`} className="btn btn-primary py-1 px-2"> <FiEdit2 /> </Link> 
                        : <button disabled className="btn btn-primary py-1 px-2"> <FiEdit2 /> </button> 
                    }
                    <button className="btn btn-info py-1 mx-2 px-2" disabled={chkTrackingUsers ? false : true} > <RiUserFollowLine /> </button>
                    <button className="btn btn-danger py-1 mx-2 px-2" disabled={chkDelete ? false : true} onClick={() => delRow(rowData)} > <MdDelete /> </button> 
                </>
            )
        }},
    ]
    const delRow = id => {
        DelFinancialYear(id)
    }

    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={colums}
                data={chkReview ? FinancialYearF : []}
                title={null}
                options={{
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    }
                }}
                options={{
                    actionsColumnIndex: -1,
                    paginationType:"stepped",
                    showFirstLastPageButtons:false,
                    addRowPosition:"first"
                }}
            />
        </div>
    )
}

export default DataTableMatruialFin
