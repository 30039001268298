import React from 'react'

function MrEmployee(props) {
    let {
        newRow, removeRow,
        list, i18n, t, userRef, handelChangeUsers, users,
        setUsers, GetBalanceUser, selectCurreny, currencyList, changeRateCurrency
    } = props;

    const onChangeAmount = (val, id) => {
        list.find(el => el.idRow == id).amount = +val;
        list.find(el => el.idRow == id).amountLocal = val * list.find(el => el.idRow == id).sY;
    }
    const onChangeDescription = (val, id) => {
        list.find(el => el.idRow == id).description = val;
        list.find(el => el.idRow == id).desc = val;
    }

    return (
        <div>
            <table className="table table-striped Mr" dir={i18n.language == "en" ? "ltr" : "rtl"}>
                <thead>
                    <tr className="text-center table-list-costing">
                        <th> {t('accountName')} </th>
                        <th> {t('currenyType')} </th>
                        <th> {t('sy')} </th>
                        <th> {t('amountamount')} </th>
                        <th> {t('description')} </th>
                        {/* <th> {t('costCenter')} </th> */}
                        <th> {t('Balance')} </th>
                        <th>
                            <button type="button" className={`btn btn-success px-3 py-1`} onClick={() => newRow()}> + </button>
                        </th>
                    </tr>
                </thead>
                <tbody className="lists text-center" style={{ display: "inherit" }}>
                    {list.map((item, idx) => {
                        return (
                            <tr key={item.idRow} className={`row-${item.idRow}`}>
                                <td className="user-account-search-container">
                                    <button type="button" className={`btn btn-primary w-100 btn-name-${item.idRow}`} data-toggle="modal" data-target={`#usersAdded-${item.idRow}`}>
                                        {t('accountName')}
                                    </button>
                                    <div className="modal fade" id={`usersAdded-${item.idRow}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title font-main"> اختر الحساب </h5>
                                                </div>
                                                <div className="modal-body">
                                                    <input
                                                        type="search"
                                                        autoComplete="off"
                                                        className={`form-control td-${item.idRow} td-nameAcount-${item.idRow}`}
                                                        ref={userRef}
                                                        onChange={(e) => handelChangeUsers(e)}
                                                    />
                                                    <ul className={`list-group list-group-filter-search  list-group-filter-search-2 text-left tdul-${idx}`}>
                                                        {users.map((option, i) => {
                                                            return (
                                                                <button key={i}
                                                                    type="button"
                                                                    className="list-group-item list-group-item-action"
                                                                    onClick={() => {
                                                                        document.querySelector(`.td-nameAcount-${item.idRow}`).value = option.accountName;
                                                                        document.querySelector(`.btn-name-${item.idRow}`).innerHTML = option.accountName;
                                                                        list.find(el => el.idRow == item.idRow).accountId = option.accountId;
                                                                        list.find(el => el.idRow == item.idRow).nameAccount = option.accountName;
                                                                        list.find(el => el.idRow == item.idRow).currencyId = +document.querySelector(`.input-balance-currency-${item.idRow}`).value;
                                                                        list.find(el => el.idRow == item.idRow).sY = +document.querySelector(`.input-balance-sY-${item.idRow}`).value;
                                                                        list.find(el => el.idRow == item.idRow).rate = +document.querySelector(`.input-balance-sY-${item.idRow}`).value;
                                                                        setUsers([]);
                                                                        GetBalanceUser(option.accountId, item.idRow);
                                                                    }}
                                                                    data-dismiss="modal" aria-label="Close"
                                                                >
                                                                    {option.accountName}
                                                                </button>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </td>

                                <td className="d-none"> <span className={`td-${idx} input-userIdRef-${idx}`}></span> </td>

                                <td>
                                    <select className={`form-control td-${idx} input-balance-currency-${item.idRow}`} onChange={e => selectCurreny(e.target.value, idx)} >
                                        {currencyList.map(curr => {
                                            return <option key={curr.currencyId} value={curr.currencyId}> {curr.currencyName} </option>
                                        })}
                                    </select>
                                </td>

                                <td>
                                    <input className={`form-control text-center td-${idx} input-balance-sY-${item.idRow}`} onChange={(e) => changeRateCurrency(e.target.value, idx)} type="text" disabled defaultValue={1} />
                                </td>


                                <td>
                                    <input
                                        type="number"
                                        className={`form-control text-center`}
                                        onChange={e => onChangeAmount(e.target.value, item.idRow)}
                                        min={1}
                                    />
                                </td>

                                <td>
                                    <input
                                        type="text"
                                        className={`form-control text-center`}
                                        onChange={e => onChangeDescription(e.target.value, item.idRow)}
                                    />
                                </td>
                                {/* <td className="center-account-search-container">
                                    <input type="text" autoComplete="off" className={`form-control td-${idx}`} ref={centerRef} onChange={(e) => handelChangeCenters(e)} />
                                    <ul className={`list-group list-group-filter-search  list-group-filter-search-2 text-left tdul-${idx}`}>
                                        {centers.map((option, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <button key={i} type="button" className="list-group-item list-group-item-action" onClick={() => {
                                                        centerRef.current.value = option.accountName;
                                                        document.querySelector(`.input-centerId-${idx}`).innerHTML = option.costCenterId;
                                                        setCenterIdRef(option.costCenterId)
                                                        setCenters([]);
                                                    }}
                                                    >
                                                        {option.accountName}
                                                    </button>
                                                </React.Fragment>
                                            );
                                        })}
                                    </ul>
                                </td> */}
                                <td className="d-none"> <span className={`td-${idx} input-centerId-${idx}`}></span> </td>
                                <td className={`balance-td-${item.idRow}`}>0</td>

                                <td className="d-flex justify-content-center px-1">
                                    <button type="button" className={`btn px-x py-1 btn-danger`} onClick={() => removeRow(item.idRow)}> - </button>
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default MrEmployee
