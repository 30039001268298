import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Layout from "../../Layout";
import DataTableMatruial from './DataTableMatruial';
import addIcon from "../../images/assets/14.png"
import searchIcon from "../../images/assets/15.png";

import { ProductContext } from '../../context/context';
import { useTranslation } from 'react-i18next';


import MaterialTable from "material-table"
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';

import text from "../../context/super.txt"
import printerIcon from "../../images/assets/12.png";


function CustomersGroups(props) {
    const { getCustomersGroup, getToken } = useContext(ProductContext);

    const { t, i18n } = useTranslation();
    let dataCloumAr = [
        {
            title: t("Notes"),
            field: "notes",
            cellStyle: { color: "#000", textAlign: "center" },
        },

        {
            title: t("nameen"),
            field: "nameE",
            cellStyle: { color: "#000", textAlign: "center" },
        }, {
            title: t("namear"),
            field: "nameA",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("code"),
            field: "code",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("transactionDate"),
            field: "taxCode",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("transactionTypeName"),
            field: "transactionTypeName",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("username"),
            field: "userName",
            cellStyle: { color: "#000", textAlign: "center" },
        },
    ];

    let dataCloumEn = [
        {
            title: t("username"),
            field: "userName",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("transactionTypeName"),
            field: "transactionTypeName",
            cellStyle: { color: "#000", textAlign: "center" },
        }, {
            title: t("transactionDate"),
            field: "taxCode",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("code"),
            field: "code",
            cellStyle: { color: "#000", textAlign: "center" },
        },

        {
            title: t("nameen"),
            field: "nameE",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("namear"),
            field: "nameA",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("Notes"),
            field: "notes",
            cellStyle: { color: "#000", textAlign: "center" },
        },
    ];
    const [columns, setColumns] = useState(i18n.language == "en" ? dataCloumEn : dataCloumAr);




    const [usersFollowers, setUsersFollowers] = useState([])

    useEffect(() => {
        if (props.location.params) {
            getCustomersGroup(2);
        } else {
            getCustomersGroup(1);
        }
    }, []);

    const getUsersFollower = () => {
        setUsersFollowers([])
        let MostData = new Headers();
        MostData.append("Content-Type", "application/json");
        MostData.append("Authorization", `Bearer ${getToken()}`);
        document.querySelector(".loading").classList.remove("hideloader");
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/CustomerGroup/FollowUsers`, {
                                method: 'POST',
                                headers: MostData,
                                redirect: 'follow',
                                body: JSON.stringify({
                                    "companyId": 1
                                }),
                            })
                                .then(response => response.json())
                                .then(result => {
                                    console.log(result);
                                    setUsersFollowers(result.data)
                                    document.querySelector(".loading").classList.add("hideloader");
                                })
                                .catch(error => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    console.log('error', error)
                                });
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${text}/CustomerGroup/FollowUsers`, {
                        method: 'POST',
                        headers: MostData,
                        redirect: 'follow',
                        body: JSON.stringify({
                            "companyId": 1
                        }),
                    })
                        .then(response => response.json())
                        .then(result => {
                            setUsersFollowers(result.data)
                            console.log(result);
                            document.querySelector(".loading").classList.add("hideloader");
                        })
                        .catch(error => {
                            document.querySelector(".loading").classList.add("hideloader");
                            console.log('error', error)
                        });
                }

            });
    }
    const Print = () => {
        let parms = {};
        let handle = window.open(window.location.origin + `/customersGroup/print`, "_blank", "width=" + window.screen.width + ",height=" + window.screen.height);
        handle.window.parameters = JSON.stringify(parms);
    }


    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="text-right mb-2">
                        <button className="text-light btn btn-danger py-1 font-ar px-4 mx-2" onClick={() => Print()}>
                            {t('print')}
                            <img src={printerIcon} className="iconMax" title="print row" alt="" />
                        </button>
                        <button className="text-light btn btn-danger py-1 font-ar px-4 mx-2" data-toggle="modal" data-target="#customersGroupUsersFollower" onClick={() => getUsersFollower()}>
                            {t('followeUser')}
                            <img src={searchIcon} className="iconMax" title="print row" alt="" />
                        </button>
                        <Link to="/customersGroup/create" className="text-light btn btn-danger py-1 font-ar px-4">
                            {t('add')}
                            <img src={addIcon} className="iconMax" title="edit row" alt="" />
                        </Link>
                    </div>
                    <DataTableMatruial setUsersFollowers={setUsersFollowers} />


                    <div class="modal fade" id="customersGroupUsersFollower" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" style={{ maxWidth: "70%" }}>
                            <div class="modal-content" style={{ background: "#a9becc" }}>
                                <div class="modal-header">
                                    <button type="button" class="close" style={{ background: "none" }} data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">

                                    <div>

                                        <div style={{ maxWidth: "100%" }}>
                                            <MaterialTable
                                                columns={columns}
                                                data={usersFollowers}
                                                title={null}
                                                style={{ backgroundColor: "#a9becc", fontWeight: "bold", color: "red !important" }}
                                                localization={{
                                                    toolbar: {
                                                        exportCSVName: "Export CSV",
                                                        exportPDFName: "Export PDF"
                                                    }
                                                }}

                                                icons={{
                                                    FirstPage: () => <FirstPage />,
                                                    LastPage: () => <LastPage />,
                                                    NextPage: () => <ChevronRight />,
                                                    PreviousPage: () => <ChevronLeft />,
                                                    Clear: () => <Clear />,
                                                    DeleteOutline: () => <DeleteOutline />,
                                                    Export: () => <DeleteOutline />,
                                                }}

                                                options={{
                                                    search: true,
                                                    actionsColumnIndex: 1,
                                                    paginationType: "stepped",
                                                    headerStyle: {
                                                        backgroundColor: "rgb(169 190 204 / 71%)",
                                                        boxShadow: "1px 1px 1px 1px #dddddd85",
                                                        fontWeight: "bold"
                                                    },
                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CustomersGroups
