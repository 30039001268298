import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../../Layout";
import DatePicker from "react-datepicker";
import TableSummary from "./TableSummary";
import { useTranslation } from "react-i18next";
import { ProductContext } from "../../context/context";

function SalesInvoiceSummary() {
    const inputRef = useRef();
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    const [options, setOptions] = useState([]);
    const [options2, setOptions2] = useState([]);
    const [options3, setOptions3] = useState([]);
    const { t } = useTranslation();

    const { Branches = [] } = useContext(ProductContext);

    const [BranchesFilter, setBranchesFilter] = useState([]);

    const [StartDataCustomerStatement, setStartDataCustomerStatement] =
        useState(new Date());
    const [EndDataCustomerStatement, setEndDataCustomerStatement] = useState(
        new Date()
    );

    useEffect(() => {
        setBranchesFilter(Branches);
    }, [Branches]);

    const handelChange = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setOptions([]);
        } else {
            setOptions(
                BranchesFilter.filter((option) =>
                    option.nameA.toLowerCase().includes(val.toLowerCase())
                )
            );
        }
    };
    const handelChange2 = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setOptions2([]);
        } else {
            setOptions2(
                [].filter((option) =>
                    option.nameA.toLowerCase().includes(val.toLowerCase())
                )
            );
        }
    };
    const handelChange3 = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setOptions3([]);
        } else {
            setOptions3(
                [].filter((option) =>
                    option.nameA.toLowerCase().includes(val.toLowerCase())
                )
            );
        }
    };

    return (
        <Layout>
            <div className="container-fluid" style={{ minHeight: "70vh" }}>
                <div className="container py-3">
                    <div className="row">
                        <form dir="rtl" onSubmit={(e) => e.preventDefault()}>
                            <div className="col-11 mx-auto text-right">
                                <div className="row">
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label className="label-account-statement">
                                            بداية التاريخ
                                        </label>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            className="form-control"
                                            showTimeSelect
                                            dateFormat="d/MM/y  HH:MM"
                                            selected={
                                                StartDataCustomerStatement
                                            }
                                            onChange={(date) =>
                                                setStartDataCustomerStatement(
                                                    date
                                                )
                                            }
                                        />
                                    </div>

                                    <div className="col-12 col-md-6 mx-auto">
                                        <label className="label-account-statement">
                                            نهاية التاريخ
                                        </label>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            showTimeSelect
                                            dateFormat="d/MM/y  HH:MM"
                                            selected={EndDataCustomerStatement}
                                            onChange={(date) =>
                                                setEndDataCustomerStatement(
                                                    date
                                                )
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="row my-2 align-items-center">
                                    <div className="col-12 col-md-6 mx-auto account-statement-container">
                                        <label className="label-account-statement">
                                            الفروع
                                        </label>
                                        <div className="filterdeftcustoner">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                ref={inputRef}
                                                onChange={(e) =>
                                                    handelChange(e)
                                                }
                                            />
                                            <ul className="list-group list-group-filter-search  list-group-filter-search-2 text-left">
                                                {options.map((option, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <button
                                                                key={i}
                                                                type="button"
                                                                className="list-group-item list-group-item-action"
                                                                onClick={() => {
                                                                    inputRef.current.value =
                                                                        option.nameA;
                                                                    setOptions(
                                                                        []
                                                                    );
                                                                }}
                                                            >
                                                                {option.nameA}
                                                            </button>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                        <label className="label-account-statement">
                                            مجموعة الموردن
                                        </label>
                                        <div className="filterdeftcustoner">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                ref={inputRef2}
                                                onChange={(e) =>
                                                    handelChange2(e)
                                                }
                                            />
                                            <ul className="list-group list-group-filter-search  list-group-filter-search-2 text-left">
                                                {options2.map((option, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <button
                                                                key={i}
                                                                type="button"
                                                                className="list-group-item list-group-item-action"
                                                                onClick={() => {
                                                                    inputRef2.current.value =
                                                                        option.nameA;
                                                                    setOptions2(
                                                                        []
                                                                    );
                                                                }}
                                                            >
                                                                {option.nameA}
                                                            </button>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                        <label className="label-account-statement">
                                            مجموعة العملاء
                                        </label>
                                        <div className="filterdeftcustoner">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                ref={inputRef3}
                                                onChange={(e) =>
                                                    handelChange3(e)
                                                }
                                            />
                                            <ul className="list-group list-group-filter-search  list-group-filter-search-2 text-left">
                                                {options3.map((option, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <button
                                                                key={i}
                                                                type="button"
                                                                className="list-group-item list-group-item-action"
                                                                onClick={() => {
                                                                    inputRef3.current.value =
                                                                        option.nameA;
                                                                    setOptions3(
                                                                        []
                                                                    );
                                                                }}
                                                            >
                                                                {option.nameA}
                                                            </button>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                        <label className="label-account-statement">
                                            العملاء
                                        </label>
                                        <div className="filterdeftcustoner">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                ref={inputRef3}
                                                onChange={(e) =>
                                                    handelChange3(e)
                                                }
                                            />
                                            <ul className="list-group list-group-filter-search  list-group-filter-search-2 text-left">
                                                {options3.map((option, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <button
                                                                key={i}
                                                                type="button"
                                                                className="list-group-item list-group-item-action"
                                                                onClick={() => {
                                                                    inputRef3.current.value =
                                                                        option.nameA;
                                                                    setOptions3(
                                                                        []
                                                                    );
                                                                }}
                                                            >
                                                                {option.nameA}
                                                            </button>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 account-statement-container my-2">
                                        <label className="label-account-statement">
                                            الحالة
                                        </label>
                                        <div className="filterdeftcustoner">
                                            <select className="form-control">
                                                <option> الكل </option>
                                                <option> معتمد </option>
                                                <option> غير معتمد </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 account-statement-container my-2">
                                        <label className="label-account-statement">
                                            نوع الفاتورة
                                        </label>
                                        <div className="filterdeftcustoner">
                                            <select className="form-control">
                                                <option> مشتريات </option>
                                                <option> مرتجع مشتريات </option>
                                                <option>   مشتريات + مرتجع مشتريات  </option>
                                            </select>
                                        </div>
                                    </div>


                                </div>

                                <button
                                    className="btn btn-outline-primary py-1 my-4 mx-3"
                                    type="button"
                                >
                                    كشف - View
                                </button>
                            </div>
                        </form>
                        <hr />
                    </div>
                    <TableSummary />
                </div>
            </div>
        </Layout>
    );
}

export default SalesInvoiceSummary;
