import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
function TableAccount({ ReportsAccounts = [], ArrayBranches = [] }) {
    let totaling = 0;
    let totalingDebit = 0;
    let totalingCredit = 0;
    const { t, i18n } = useTranslation();

    return (
        <div className="row" style={{ overflow: "auto" }}>
            <div className="col-12 mx-auto px-0">
                <table className="table table-success table-striped text-dark" dir={i18n.language == "en" ? "ltr" : "rtl"}>
                    <thead>
                        <tr className="text-center font-small-responsive  bg-header-tr">
                            <th scope="col" className="fontGlobalHead"> {t('branche')} </th>
                            <th scope="col" className="fontGlobalHead" style={{ width: "50px" }}> {t('registrationNumber')} </th>
                            <th scope="col" className="fontGlobalHead"> {t('date')} </th>
                            <th scope="col" className="fontGlobalHead"> {t('source')} </th>
                            <th scope="col" className="fontGlobalHead"> {t('description')} </th>
                            <th scope="col" className="fontGlobalHead"> {t('Debtor')} </th>
                            <th scope="col" className="fontGlobalHead"> {t('Creditor')} </th>
                            <th scope="col" className="fontGlobalHead"> {t('Balance')} </th>
                        </tr>
                    </thead>
                    <tbody className="font-small-responsive" style={{
                        "maxHeight": "600px",
                        "display": "inline-block",
                        "width": "100%",
                        "overflowY": "auto"
                    }}>
                        {ReportsAccounts.map(({ journalVoucherTypeName, jvDate, jvId, branchId, credit, debit, debitLocal, source, creditLocal, description, dtlDescription }, idx) => {

                            if (source == 0) {
                                if (debitLocal) {
                                    debit = debitLocal
                                }
                                if (creditLocal) {
                                    credit = creditLocal
                                }
                            }
                            if (debit != 0) {
                                totaling += +debit;
                                totalingDebit += debit;
                            }
                            if (credit != 0) {
                                totaling -= credit
                                totalingCredit += credit;
                            }


                            return (
                                <tr className="text-center" key={idx}>
                                    <td className="fontGlobal"> {branchId == 0 ? branchId : ArrayBranches.find(branch => branch.branchId == branchId).branchName} </td>
                                    <td className="fontGlobal" style={{ width: "50px" }}>{jvId}</td>
                                    <td className="fontGlobal">
                                        <div className="d-flex">
                                            <span className="d-inline-block mx-1">{moment(jvDate).format('A')}</span>
                                            <span>{moment(jvDate).format('hh:mm d-mm-yyyy')}</span>
                                        </div>

                                    </td>
                                    <td className="fontGlobal">
                                        {journalVoucherTypeName}

                                    </td>
                                    <td className="fontGlobal">{dtlDescription}</td>
                                    <td className="fontGlobal"> {+debit.toFixed(2)} </td>
                                    <td className="fontGlobal"> {+credit.toFixed(2)} </td>
                                    <td className={totaling >= 0 ? "text-success fontGlobal" : "text-danger fontGlobal"} > {+totaling.toFixed(2)} </td>

                                    <div className="modal fade" id={`modal-${idx}`} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" style={{
                                            maxWidth: "100%",
                                            height: "100vh",
                                            position: "fixed",
                                            width: "100%",
                                            top: "0",
                                            margin: "0"
                                        }}>
                                            <div className="modal-content" style={{ height: "100%" }}>
                                                <div className="section">
                                                    <div className="modal-body">
                                                        <div>
                                                            <h5 style={{ textDecoration: "underline" }}> تقرير كشف حساب </h5>
                                                        </div>
                                                        {idx}
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tr>
                            );
                        })}
                        <tr className="text-center bg-header-tr">
                            <th className="fontGlobal"> {t('total')} </th>
                            <td></td>
                            <td style={{ width: "50px" }}></td>
                            <td></td>
                            <td></td>
                            <td> {totalingDebit} </td>
                            <td> {totalingCredit} </td>
                            {/* <td className={totaling >= 0 ? "text-success" : "text-danger" } > { totaling } </td> */}
                            <td className="text-light" > {+totaling.toFixed(2)} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TableAccount;
