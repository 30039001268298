import React from 'react'

function Tax() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-8 mx-auto my-5">
                    <form>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label className="form-check-label mr-4" for="defaultCheck1">
                                معفي
                            </label>
                        </div>
                        <div className="my-3">
                            <label> نسبة الضريبة </label>
                            <input type="number" className="form-control" />
                        </div>
                        <div className="my-3">
                            <label> نسبة الضريبة الضمان </label>
                            <input type="number" className="form-control" />
                        </div>
                        <div className="my-3">
                            <label> نسبة ضريبةالجدول </label>
                            <input type="number" className="form-control" />
                        </div>


                        <div className="my-3">
                            <label> نسبة الضمان </label>
                            <input type="number" className="form-control" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Tax
