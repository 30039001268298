import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../Layout/index'
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import text from '../../../context/super.txt';
import Select from 'react-select';
import ListSale from './ListSale';
import PaymentDetails from './PaymentDetails';
import uuid from 'react-uuid';

function CreateSales() {
    const getToken = () => localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : '';
    const { t } = useTranslation();
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [Stores, setStores] = useState([]);
    const [Customers, setCustomers] = useState([]);
    const [Suppliers, setSuppliers] = useState([]);
    // 
    const [date, setDate] = useState(new Date());
    const [store, setStore] = useState('');
    const [salePolicy, setSalePolicy] = useState('');
    const [numInvoiceCustomer, setNumInvoiceCustomer] = useState('');
    const [num, setNum] = useState('');
    const [status, setStatus] = useState('');
    const [option, setOption] = useState('');
    const [customer, setCustomer] = useState('');
    const [supllier, setSupllier] = useState('');
    const [barcode, setBarcode] = useState('');
    const [price, setPrice] = useState('');
    const [curreny, setCurrency] = useState('');
    const [numDaily, setNumDaily] = useState('');
    const [source, setSource] = useState('');
    const [numSource, setNumSource] = useState('');
    const [description, setDescription] = useState('');

    const [typePayment, setTypePayment] = useState([]);


    useEffect(() => {
        const token = getToken();
        document.querySelector(".loading").classList.remove("hideloader");
        let MostData = new Headers();
        MostData.append("Content-Type", "application/json");
        MostData.append("Authorization", `Bearer ${token}`);

        fetch(text).then(res => res.text()).then(config => {
            fetch(`${config}/PosForm/GetAll`, {
                method: 'GET',
                headers: MostData,
                redirect: 'follow'
            }).then(response => response.json()).then(result => {
                console.log(result.data[0]);
                setStores(result.data[0].storesList)
                setCustomers(result.data[0].customerList)
                setSuppliers(result.data[0].salesmen)
                setTypePayment(result.data[0].paymentTypeList)
                document.querySelector(".loading").classList.add("hideloader");
            })
                .catch(error => {
                    document.querySelector(".loading").classList.add("hideloader");
                    console.log('error', error)
                });
        });
    }, [])
    if (Stores.length != 0) {
        Stores.forEach(item => {
            item.value = item.storeName;
            item.label = item.storeName;
            item.id = item.storeId;
        })
    }
    if (typePayment.length != 0) {
        typePayment.forEach(item => {
            item.value = item.paymentTypeName;
            item.label = item.paymentTypeName;
            item.id = item.bptId;
        })
    }
    if (Customers.length != 0) {
        Customers.forEach(item => {
            item.value = item.customerName;
            item.label = item.customerName;
            item.id = item.id;
        })
    }
    if (Suppliers.length != 0) {
        Suppliers.forEach(item => {
            item.value = item.employeeName;
            item.label = item.employeeName;
            item.id = item.id;
        })
    }

    const addPayment = () => {
        let id = uuid();
        let obj = { id };
        setPaymentDetails(state => [...state, obj]);
    }

    const DelPayment = id => setPaymentDetails(state => state.filter(item => item.id != id));

    const Submit = () => {
        let obj = { date, store, salePolicy, numInvoiceCustomer, num, status, option, customer, supllier, barcode, price, curreny, numDaily, source, numSource, description, paymentDetails };
        console.log(obj);
    }

    return (
        <Layout>
            <div className="create-sale">
                <div className="container">
                    <form action="" dir='rtl'>
                        <div className="row">
                            <div className="col-12 col-md-4 mx-auto account-statement-container">
                                <label className="label-account-statement"> المخزن </label>
                                <Select options={Stores} placeholder="اختر المخزن" onChange={e => setStore(e)} />
                            </div>

                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement">سياسة البيع</label>
                                <select name="" id="" dir='rtl' onChange={(e) => setSalePolicy(e.target.value)} className='form-control'>
                                    <option value="">1</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement">رقم فاتورةالعميل</label>
                                <input type="number" onChange={e => setNumInvoiceCustomer(e.target.value)} className="form-control" />
                            </div>

                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement" htmlFor="">الرقم</label>
                                <input type="number" onChange={e => setNum(e.target.value)} className="form-control" />
                            </div>

                            <div className="col-12 col-md-4">
                                <label className="label-account-statement" htmlFor=""> {t('date')} </label>
                                <DatePicker style={{ width: "100%" }} className="form-control mt-1 formatDate" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={date} onChange={(date) => setDate(date)} />
                            </div>

                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement" htmlFor="">الحالة</label>
                                <select name="" id="" dir='rtl' onChange={(e) => setStatus(e.target.value)} className='form-control'>
                                    <option value="1">معتمد </option>
                                    <option value="0">غير معتمد</option>
                                </select>
                            </div>

                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement" htmlFor=""></label>
                                <select name="" id="" dir='rtl' onChange={(e) => setOption(e.target.value)} className='form-control'>
                                    <option value="">صرف مع الفاتورة</option>
                                    <option value="">صرف بعد الفاتورة</option>
                                </select>
                            </div>

                            <div className="col-12 col-md-4 mx-auto account-statement-container">
                                <label className="label-account-statement"> العميل </label>
                                <Select options={Customers} placeholder="اختر عميل" onChange={e => setCustomer(e)} />
                            </div>
                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement" htmlFor="">مندوب المبيعات</label>
                                <Select options={Suppliers} placeholder="اختر مندوب مبيعات" onChange={e => setSupllier(e)} />
                            </div>

                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement" htmlFor="">العملة</label>
                                <select name="" id="" dir='rtl' onChange={e => setCurrency(e.target.value)} className='form-control'>
                                    <option value="">1</option>
                                </select>
                            </div>

                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement" htmlFor="">سعر الصرف</label>
                                <input type="number" onChange={e => setPrice(e.target.value)} className="form-control" />
                            </div>

                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement" htmlFor="">باركود</label>
                                <input type="number" onChange={e => setBarcode(e.target.value)} className="form-control" />
                            </div>

                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement" htmlFor="">المصدر</label>
                                <select name="" id="" dir='rtl' onChange={e => setSource(e.target.value)} className='form-control'>
                                    <option value="">حركة مستقلة</option>
                                    <option value="">امر بيع</option>
                                    <option value="">عرض سعر</option>
                                    <option value="">حجز كميات</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement" htmlFor="">رقم المصدر</label>
                                <input type="number" onChange={e => setNumSource(e.target.value)} className="form-control" />
                            </div>
                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement" htmlFor="">رقم القيد</label>
                                <input type="number" onChange={e => setNumDaily(e.target.value)} className="form-control" />
                            </div>



                            <div className="col-12 col-md-4 py-1">
                                <label className="label-account-statement" htmlFor="">الوصف</label>
                                <textarea name="" id="" onChange={e => setDescription(e.target.value)} className='form-control' rows="2"></textarea>
                            </div>


                            <ListSale />

                            <PaymentDetails addPayment={addPayment} DelPayment={DelPayment} paymentDetails={paymentDetails} typePayment={typePayment} />



                            <div>
                                <button className='btn btn-success' type='button' onClick={() => Submit()}> Submit </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default CreateSales