import React from 'react'
import { useTranslation } from 'react-i18next';

function TablePay({ list }) {
    const { t } = useTranslation();

    return (
        <table className="table table-striped font-ar" style={{minHeight: "100vh !important"}} dir="rtl">
            <thead>
                <tr  className="text-center">
                    <th scope="col"> {t('code')} </th>
                    <th scope="col"> {t('name')} </th>
                    <th scope="col"> {t('number')} </th>
                    <th scope="col"> {t('total')} </th>
                </tr>
            </thead>
            <tbody>
            {list.map(({ id, name, itemCode, tatolItem, quantity }, idx) => {
                    return (
                        <tr key={idx} className="text-center">
                            <th scope="row">{ itemCode }</th>
                            <td>{ name }</td>
                            <td>{ quantity }</td>
                            <td>{ tatolItem }</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default TablePay
