import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import * as printerStyleInvoice from "../../styles/printInvoice.module.css";
import { ProductContext } from '../../context/context';
import logo from '../../context/image.png'
import { useBarcode } from 'react-barcodes';
import { useTranslation } from 'react-i18next';
import { AiFillPrinter } from 'react-icons/ai';
import moment from 'moment';


export default function PrintDeveley() {

    const [data, setData] = useState([]);
    const [Lists, setLists] = useState([]);
    const [list, setList] = useState([]);

    const { mostData } = useContext(ProductContext);

    const { t } = useTranslation();
    const { inputRef } = useBarcode({
        value: 125,
        // value: voiceId,
        options: {
            displayValue: false,
            width: 3,
        },
    });
    const { user } = useContext(ProductContext);


    useEffect(() => {
        if (window.parameters) {
            let items = JSON.parse(window.parameters);
            if (items) {

                setData(items.result.data[0]);
                setList(items.result.data[0].postempDetailList)
                setLists(items.result.data)
                console.log("data is", items);
            }
        }
    }, []);
    useEffect(() => {
        console.log(mostData);
    }, [mostData])


    const PintWihtPrinter = () => {
        window.focus();
        window.print();
    };

    let totalQtn = 0;
    let discounts = 0;
    let totals = 0;

    return (
        <React.Fragment>
            {/* {Lists.map(({ printerReturnedType, postempDetailList = [] }) => {
                if (printerReturnedType == 1) {
                    return (
                        <div className={`${printerStyleInvoice.content} d-flex px-0`}>

                            <div className={`${printerStyleInvoice.contentFlex}`}>

                                <div className="text-center my-2">
                                    <img src={logo} width="120" height="auto" alt="" />
                                </div>

                                <div className="details-invoice-printSalseVoive my-4">
                                    <div className={`${printerStyleInvoice.riginth}`}>
                                        <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {user.companyNameA} </p>
                                        <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {user.branchNameA} </p>
                                    </div>

                                    <table>
                                        <thead>
                                            <tr style={{ borderTop: "2px solid #000", borderBottom: "2px solid #000" }}>
                                                <th className={`${printerStyleInvoice.element}`}> {t('total')} </th>
                                                <th className={`${printerStyleInvoice.element}`}> {t('price')} </th>
                                                <th className={`${printerStyleInvoice.element}`}>  {t('amount')} </th>
                                                <th className={`${printerStyleInvoice.element}`}> {t('name')} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {postempDetailList.map(({ name, netPrice, total, quantity, discount1 }, idx) => {
                                                totalQtn += quantity;
                                                discounts += discount1;
                                                return (
                                                    <tr key={idx} style={{ borderBottom: "2px solid #000" }}>
                                                        <td className={`${printerStyleInvoice.element}`}> {+total.toFixed(3)} </td>
                                                        <td className={`${printerStyleInvoice.element}`}> {+netPrice.toFixed(3)} </td>
                                                        <td className={`${printerStyleInvoice.element}`}> {quantity} </td>
                                                        <td className={`${printerStyleInvoice.element}`}> {name} </td>
                                                    </tr>
                                                );
                                            }
                                            )}

                                        </tbody>
                                    </table>

                                    <div className={`w-100 d-block ${printerStyleInvoice.footerBtn}`}>
                                        <button className="btn btn-primary btn-block font-main my-3" style={{ fontSize: "20px" }} onClick={() => PintWihtPrinter()}>
                                            Print <AiFillPrinter />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }



                if (printerReturnedType == 2) {
                    return (
                        <div className={`${printerStyleInvoice.content} d-flex px-0`}>

                            <div className={`${printerStyleInvoice.contentFlex}`}>

                                <div className="text-center my-2">
                                    <img src={logo} width="120" height="auto" alt="" />
                                </div>

                                <div className="details-invoice-printSalseVoive my-4">
                                    <div className={`${printerStyleInvoice.riginth}`}>
                                        <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {user.companyNameA} </p>
                                        <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {user.branchNameA} </p>
                                    </div>

                                    <table>
                                        <thead>
                                            <tr style={{ borderTop: "2px solid #000", borderBottom: "2px solid #000" }}>
                                                <th className={`${printerStyleInvoice.element}`}> {t('total')} </th>
                                                <th className={`${printerStyleInvoice.element}`}> {t('price')} </th>
                                                <th className={`${printerStyleInvoice.element}`}>  {t('amount')} </th>
                                                <th className={`${printerStyleInvoice.element}`}> {t('name')} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {postempDetailList.map(({ name, netPrice, total, quantity, discount1 }, idx) => {
                                                totalQtn += quantity;
                                                discounts += discount1;
                                                return (
                                                    <tr key={idx} style={{ borderBottom: "2px solid #000" }}>
                                                        <td className={`${printerStyleInvoice.element}`}> {+total.toFixed(3)} </td>
                                                        <td className={`${printerStyleInvoice.element}`}> {+netPrice.toFixed(3)} </td>
                                                        <td className={`${printerStyleInvoice.element}`}> {quantity} </td>
                                                        <td className={`${printerStyleInvoice.element}`}> {name} </td>
                                                    </tr>
                                                );
                                            }
                                            )}

                                        </tbody>
                                    </table>

                                    <div className={`w-100 d-block ${printerStyleInvoice.footerBtn}`}>
                                        <button className="btn btn-primary btn-block font-main my-3" style={{ fontSize: "20px" }} onClick={() => PintWihtPrinter()}>
                                            Print <AiFillPrinter />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                return <h6> Not Printer .... </h6>

            })} */}

            <div className={`${printerStyleInvoice.content} d-flex px-0`}>

                <div className={`${printerStyleInvoice.contentFlex}`}>

                    <div className="text-center my-2">
                        <img src={logo} width="120" height="auto" alt="" />
                    </div>

                    <div className="details-invoice-printSalseVoive my-4">
                        <div className={`${printerStyleInvoice.riginth}`}>
                            <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {user.companyNameA} </p>
                            <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {user.branchNameA} </p>
                            <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {data.customerName} </p>
                            <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {data.customerAddress} </p>
                            <div className="d-flex align-items-center justify-content-between">
                                <p className={`${printerStyleInvoice.TdInvoice} mb-0`}> توصيل خارجي </p>
                                <p className={`${printerStyleInvoice.TdInvoice} mb-0`}> فاتورة مبيعات </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice} mx-4 mb-0`}></h6>
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                <h6 className={`${printerStyleInvoice.TdInvoice} mx-4 mb-0`}> 2 </h6>
                                <p className={`${printerStyleInvoice.TdInvoice} mb-0`}> رقم الطلب </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                <h6 className={`${printerStyleInvoice.TdInvoice} mx-4 mb-0`}>  {moment(data.invoiceDate).format("DD-MM-YYYY h:mm")} </h6>
                                <p className={`${printerStyleInvoice.TdInvoice} mb-0`}> التاريخ </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                <h6 className={`${printerStyleInvoice.TdInvoice} mx-4 mb-0`}></h6>
                                <p className={`${printerStyleInvoice.TdInvoice} mb-0`}> متلقي الطلب </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                <h6 className={`${printerStyleInvoice.TdInvoice} mx-4 mb-0`}></h6>
                                <p className={`${printerStyleInvoice.TdInvoice} mb-0`}>الطيار </p>
                            </div>
                            <table class="table table-bordered" dir='rtl'>

                                <tbody>
                                    <tr>
                                        <th scope="row">العميل</th>
                                        <td> {data.customerName} </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">تليفون</th>
                                        <td> {data.customerTel} </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">المنطقة</th>
                                        <td> {data.region} </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">العنوان</th>
                                        <td> {data.customerAddress} </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">الدور</th>
                                        <td> {data.floorNo} </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">علامة مميزة</th>
                                        <td> {data.apartment} </td>
                                    </tr>

                                </tbody>
                            </table>



                        </div>

                        <table>
                            <thead>
                                <tr style={{ borderTop: "2px solid #000", borderBottom: "2px solid #000" }}>
                                    <th className={`${printerStyleInvoice.element}`}> {t('total')} </th>
                                    <th className={`${printerStyleInvoice.element}`}> {t('price')} </th>
                                    <th className={`${printerStyleInvoice.element}`}>  {t('amount')} </th>
                                    <th className={`${printerStyleInvoice.element}`}> {t('name')} </th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map(({ itemName, netPrice, total, quantity, discount1 }, idx) => {
                                    totalQtn += quantity;
                                    discounts += discount1;
                                    totals += netPrice;
                                    return (
                                        <tr key={idx} style={{ borderBottom: "2px solid #000" }}>
                                            <td className={`${printerStyleInvoice.element}`}> {+total.toFixed(3)} </td>
                                            <td className={`${printerStyleInvoice.element}`}> {+netPrice.toFixed(3)} </td>
                                            <td className={`${printerStyleInvoice.element}`}> {quantity} </td>
                                            <td className={`${printerStyleInvoice.element}`}> {itemName} </td>
                                        </tr>
                                    );
                                }
                                )}
                                <hr />
                                <tr className="my-3" style={{ borderTop: "1px solid #000", borderBottom: "5px dashed #000" }}>
                                    <td className={`${printerStyleInvoice.element}`}> {+totals.toFixed(3)} </td>
                                    <td className={`${printerStyleInvoice.element}`}> {t('total')} </td>
                                    <td className={`${printerStyleInvoice.element}`}></td>
                                    <td className={`${printerStyleInvoice.element}`}></td>
                                </tr>
                                <hr />
                            </tbody>
                        </table>



                        <div className="d-flex justify-content-center">
                            <p className={`${printerStyleInvoice.TdInvoice}`}> {+discounts.toFixed(3)} </p>
                            <p className={`${printerStyleInvoice.TdInvoice2}  mb-0 my-0 py-0`}>: {t('discount')} </p>
                        </div>

                        <div className="d-flex justify-content-center">
                            {/* <p className={`${printerStyleInvoice.TdInvoice}`}> {+netTotal.toFixed(2)} </p> */}
                            <p className={`${printerStyleInvoice.TdInvoice2}  mb-0 my-0 py-0`}>: {t('nettotal')} </p>
                        </div>



                        <div></div>

                        <div className="text-center">
                            <img ref={inputRef} className={`${printerStyleInvoice.imgFluidPrint}`} alt="barcode" />
                        </div>
                        <div></div>
                        <div className={`${printerStyleInvoice.contentFooter}`}>
                            {/* <p className={`${printerStyleInvoice.TdInvoice}`}>فاتورة مبيعات - {moment(dateNow).format("DD-MM-YYYY h:mm")}</p> */}
                            <div></div>
                            <p className={`${printerStyleInvoice.TdInvoice}`}> {t('Thanks')}</p>
                            <hr />
                            <div></div>
                            {/* <p className={`${printerStyleInvoice.TdInvoice}`}> {address} </p> */}
                        </div>

                        <div className={`w-100 d-block ${printerStyleInvoice.footerBtn}`}>
                            <button className="btn btn-primary btn-block font-main my-3" style={{ fontSize: "20px" }} onClick={() => PintWihtPrinter()}>
                                Print <AiFillPrinter />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>



    )
}
