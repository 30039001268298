
import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import TableSalesReturned from './TableSalesReturned';

function SalesReturned() {

    const { allRefrence = [] } = useContext(ProductContext);

    let TsmSalesDiscard = {};
    if(allRefrence.length != 0){
        TsmSalesDiscard = allRefrence.find(item => item.mName == "TsmSalesDiscard") ? allRefrence.find(item => item.mName == "TsmSalesDiscard") : false; 
    }


    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-11 mx-auto my-3 text-center">
                        <div className="text-right mb-2"> 
                            
                        </div>
                        <TableSalesReturned Refrencecustomers={TsmSalesDiscard} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SalesReturned
