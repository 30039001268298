import React from 'react'
import BasicData from './BasicData'

function Tabs(props) {
    let {
            taxNumber,
            VATnumber,
            dataCreated,
            commercialNumber,
            addres,
            phone1,
            phone2,
            typeExport,
            cellPhone,
            email,
            fax,
            webSite,
            notes,
            setTaxNumber, 
            setVATnumber,
            setDataCreated,
            setCommercialNumber,
            setAddres,
            setPhone1,
            setPhone2,
            setTypeExport,
            setCellPhone,
            setEmail,
            setFax,
            setWebSite,
            setNotes
    } = props;
    return (
        <React.Fragment>
            <ul className="nav nav-tabs p-0" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <a 
                        className="nav-link active" 
                        id="basic_data-tab" 
                        data-toggle="tab" 
                        href="#basic_data" 
                        role="tab" 
                        aria-controls="home" 
                        aria-selected="true"
                    >بيانات اساسية</a>
                </li>
                {/* <li className="nav-item" role="presentation">
                    <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">اخري</a>
                </li> */}
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="basic_data" role="tabpanel" aria-labelledby="basic_data-tab">
                    <BasicData 
                        taxNumber={taxNumber}
                        VATnumber={VATnumber}
                        dataCreated={dataCreated}
                        commercialNumber={commercialNumber}
                        addres={addres}
                        phone1={phone1}
                        phone2={phone2}
                        typeExport={typeExport}
                        cellPhone={cellPhone}
                        email={email}
                        fax={fax}
                        webSite={webSite}
                        notes={notes}
                        setTaxNumber={setTaxNumber}
                        setVATnumber={setVATnumber}
                        setDataCreated={setDataCreated}
                        setCommercialNumber={setCommercialNumber}
                        setAddres={setAddres}
                        setPhone1={setPhone1}
                        setPhone2={setPhone2}
                        setTypeExport={setTypeExport}
                        setCellPhone={setCellPhone}
                        setEmail={setEmail}
                        setFax={setFax}
                        setWebSite={setWebSite}
                        setNotes={setNotes}
                    />
                </div>
                {/* <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div> */}
            </div>
        </React.Fragment>
    )
}

export default Tabs
