import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ProductContext } from '../../../context/context';
import Layout from "../../../Layout";
import TransferCachTable from './TransferCachTable';

import addIcon from "../../../images/assets/14.png";


function CashExchange(props) {
    const { getGeneralVoucher, GeneralVoucher } = useContext(ProductContext);
    const [details, setDetails] = useState(false);

    useEffect(() => {
        if (props.location.state != undefined) {
            if (props.location.state.detail) {
                setDetails(props.location.state.detail)
            } else {
                if (props.location.params) {
                    getGeneralVoucher(3, 2);
                } else {
                    getGeneralVoucher(3, 1);
                }
            }
        } else {
            if (props.location.params) {
                getGeneralVoucher(3, 2);
            } else {
                getGeneralVoucher(3, 1);
            }
        }
    }, []);

    const { t } = useTranslation();

    if (GeneralVoucher.length != 0) {

        return (
            <Layout>
                <div className="container-fluid">
                    <div className="row mx-0">
                        <div className="col-12 col-md-11 mx-auto my-3 text-center px-0">
                            <div className="text-right mb-2">
                                <Link to="/Cash-Payment-Voucher/create" className="text-light btn btn-danger py-1 font-ar">
                                    {t('add')}
                                    <img src={addIcon} className="iconMax" title="edit row" alt="" />

                                </Link>
                            </div>
                            <TransferCachTable details={details} />
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
    else {
        return 'loading .........'
    }


}

export default CashExchange
