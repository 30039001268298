import { SettingsPhone } from '@material-ui/icons';
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";


function AddBranches() {
    const { newBranch } = useContext(ProductContext);
    const [code, setCode] = useState('');
    const [nameAr, setNameAr] = useState('');
    const [nameEn, setNameEN] = useState('');
    const [dataCreated, setDataCreated] = useState('');
    const [addres, setAddres] = useState('');
    const [phone1, setPhone1] = useState(''); // موبيل 1
    const [phone2, setPhone2] = useState(''); // موبيل 2
    const [cellPhone, setCellPhone] = useState(''); // جول
    const [email, setEmail] = useState(''); // البريد الالكتروني
    const [fax, setFax] = useState(''); // فاكس
    const [webSite, setWebSite] = useState(''); // ويب
    const [notes, setNotes] = useState(''); // ويب

    const SaveBranchData = e => {
        e.preventDefault();
        let branch = {
            code,
            nameAr,
            nameEn,
            dataCreated,
            addres,
            phone1,
            phone2,
            cellPhone,
            email,
            fax,
            webSite,
            notes
        }
        newBranch(branch);
        // console.log(branch);
        emptyForm()
    }
    
    
        const emptyForm = () => {
            setCode('');
            setNameAr('');
            setNameEN('')
            setDataCreated('')
            setAddres('')
            setPhone1('')
            setPhone2('')
            setCellPhone('')
            setEmail('')
            setFax('')
            setWebSite('')
            setNotes('')
        }


        return (
            <Layout>
                <div className="company text-right font-ar">
                    <div className="container-fluid py-4">
                        <div className="row mx-0">
                            <div className="col-12 col-md-10 mx-auto px-0">
                                <form dir="rtl">
                                    <div className="row py-2">
                                        <div className="col-12 col-md-2 mx-auto">
                                            <label htmlFor="codeBranch">كود الفرع</label>
                                            <input type="number" value={code} disabled onChange={e => setCode(e.target.value)} className="form-control" id="codeBranch" />
                                        </div>
                                        <div className="col-12 col-md-5 mx-auto">
                                            <label htmlFor="nameArB">الاسم بالعربي</label>
                                            <input type="text" value={nameAr} onChange={e => setNameAr(e.target.value)} className="form-control" id="nameArB" />
                                        </div>
                                        <div className="col-12 col-md-5 mx-auto">
                                            <label htmlFor="nameEnB">الاسم بالانجليزي</label>
                                            <input type="text" value={nameEn} onChange={e => setNameEN(e.target.value)} className="form-control" id="nameEnB" />
                                        </div>
                                    </div>

                                    <hr className="my-4" />
                                    {/* Start */}
                                    <div className="py-4">
                                        <div className="row py-2">
                                            <div className="col-12 col-md-6 mx-auto">
                                                <label style={{fontSize:"14px"}}>تاريخ الانشاء</label>
                                                <input type="date" value={dataCreated} onChange={e => setDataCreated(e.target.value)} className="form-control" />
                                            </div>
                                            <div className="col-12 col-md-6 mx-auto">
                                                <label style={{fontSize:"14px"}}> العنوان </label>
                                                <input type="text" value={addres} onChange={e => setAddres(e.target.value)} className="form-control" />
                                            </div>
                                        </div>


                                        <div className="row py-2">
                                            <div className="col-12 col-md-6 mx-auto">
                                                <label style={{fontSize:"14px"}}>تلفون</label>
                                                <input type="number" value={phone1} onChange={e => setPhone1(e.target.value)} className="form-control" />
                                            </div>
                                            <div className="col-12 col-md-6 mx-auto">
                                                <label style={{fontSize:"14px"}}>تلفون</label>
                                                <input type="number" value={phone2} onChange={e => setPhone2(e.target.value)} className="form-control" />
                                            </div>
                                        </div>

                                        
                                        <div className="row py-2">
                                            <div className="col-12 col-md-6 mx-auto">
                                                <label style={{fontSize:"14px"}}>جوال</label>
                                                <input type="number" value={cellPhone} onChange={e => setCellPhone(e.target.value)} className="form-control" />
                                            </div>
                                            <div className="col-12 col-md-6 mx-auto">
                                                <label style={{fontSize:"14px"}}>فاكس</label>
                                                <input type="number" value={fax} onChange={e => setFax(e.target.value)} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row py-2">
                                            <div className="col-12 col-md-6 mx-auto">
                                                <label style={{fontSize:"14px"}}>البريد الالكتروني</label>
                                                <input type="email" value={email} onChange={e => setEmail(e.target.value)} className="form-control" />
                                            </div>
                                            <div className="col-12 col-md-6 mx-auto">
                                                <label style={{fontSize:"14px"}}>موقع الانترنت</label>
                                                <input type="url" value={webSite} onChange={e => setWebSite(e.target.value)} className="form-control" />
                                            </div>
                                        </div>
        

                                        <div className="form-group mx-auto">
                                            <label style={{fontSize:"14px"}}>ملاحظات</label>
                                            <textarea type="url" value={notes} onChange={e => setNotes(e.target.value)} className="form-control"></textarea>
                                        </div>

                                    </div>
                                    {/* End of Branches */}



                                    <div className="submiting d-flex">
                                        <button type="submit" className="btn btn-primary mx-2 px-4" onClick={e => SaveBranchData(e)}>حفظ</button>
                                        <Link type="button" to="/branches" className="btn btn-primary mx-2 px-4">تراجع</Link>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
    )
}

export default AddBranches
