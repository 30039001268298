import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa'
import { RiMoneyDollarCircleLine } from "react-icons/ri"

function PayTabs({ onSaveCredit, list = [], Agl, onChangeCredit, masterCard, onChangeCach, onChangeMasterCard, onChangeVisa, totalMatserCard }) {

    const { t } = useTranslation();
  
    return (
        <div>
            <nav>
                <div className="nav nav-tabs nav-tabs-pay-table flex-row-reverse" id="nav-tab" role="tablist">
                    {/* Cach */}
                    <a className="nav-link active" id="pay-nav-home-tab" data-toggle="tab" href="#pay-nav-home" role="tab" aria-controls="pay-nav-home" aria-selected="true">
                        {t('cachPayment')} <RiMoneyDollarCircleLine /> <sub> <span class="badge badge-pill badge-warning" id="ChachId"> 0 </span> </sub> 
                    </a>{/* End of Cach */}

                    {/* visa */}
                    <a className="nav-link" id="pay-nav-profile-tab" data-toggle="tab" href="#pay-nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
                    {t('visaPayment')} <FaCcVisa /> 
                        <sub> <span class="badge badge-pill badge-info" id="visaId"> 0 </span> </sub> 
                    </a>{/* end of visa */}

                    {/* mastercard */}
                    <a className="nav-link" id="pay-nav-contact-tab" data-toggle="tab" href="#pay-nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">
                    {t('mastercardPayment')} <FaCcMastercard /> <sub> <span class="badge badge-pill badge-primary" id="masterCardIdId"> 0 </span> </sub> 
                    </a>{/* end of mastercard */}

                    {/* credit */}
                    <a className="nav-link" id="pay-nav-credit-tab" data-toggle="tab" href="#pay-nav-credit" role="tab" aria-controls="nav-contact" aria-selected="false">
                    {t('aglPayment')}  <FaCcMastercard />   
                        <sub> <span class="badge badge-pill badge-success" id="creditId" > 0 </span> </sub> 
                        
                    </a>{/* end of credit */}
                </div>
            </nav>

            <div className="tab-content" id="nav-tabContent">
                {/* Cach */}
                <div className="tab-pane fade show active" id="pay-nav-home" role="tabpanel" aria-labelledby="pay-nav-home-tab"> 
                    <input type="number" className="form-control my-3" disabled={list.length === 0 ? true : false} onChange={ (e) => onChangeCach(e)} id="cachFromPay" />

                </div>{/* end of cach */}

                {/* visa */}
                <div className="tab-pane fade" id="pay-nav-profile" role="tabpanel" aria-labelledby="pay-nav-profile-tab"> 
                    <input type="number" className="form-control my-3" disabled={list.length === 0 ? true : false}  onChange={e => onChangeVisa(e)} id="visaFromPay" />
                </div>{/* end of visa */}

                {/* mastercard */}
                <div className="tab-pane fade" id="pay-nav-contact" role="tabpanel" aria-labelledby="pay-nav-contact-tab">
                    <input type="number" className="form-control my-3" disabled={list.length === 0 ? true : false}  onChange={e => onChangeMasterCard(e)} id="masterCardFromPay" />
                   
                </div>{/* end of mastercard */}
                
                {/* credit */}
                <div className="tab-pane fade" id="pay-nav-credit" role="tabpanel" aria-labelledby="pay-nav-credit-tab">
                    <input type="number" className="form-control my-3" disabled={list.length === 0 ? true : false}  onChange={e => onChangeCredit(e)} id="creditFromPay" />                    
                </div>{/* end of credit */}
            </div>

            <div className="detailsPayes">
                <div className="">
                    <h6 className="d-flex justify-content-between px-3"> 
                        <span className="pay-mastercard-value font-weight-bold font-main text-success">{ masterCard }</span> 
                        <span> : { t('amountPaid') } </span> 
                    </h6>
                    <h6 className="d-flex justify-content-between px-3"> 
                        <span className="residual-mastercard-value font-main text-danger">{ totalMatserCard } </span> 
                        <span> : { t('Residual') } </span> 
                     </h6>
                </div>
                <hr />
            </div>
            <button className="font-ar btn btn-success d-block my-3 w-100 mt-5" id="SavePayTable" 
                disabled={masterCard === 0 ? true : false} 
                // disabled={list.length === 0 ? true : false}
                onClick={() => onSaveCredit()}>
                { t('save') }
            </button>

            <div className="text-right">
                
                <button className="font-ar btn btn-agl text-center my-3 w-100 mt-2" data-dismiss="modal" onClick={() => Agl()}>
                    { t('aglPayment') }
                </button>
            </div>


        </div>
    )
}

export default PayTabs
