import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ProductProvider } from "./context/context";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import ENGLISH from "./i18n/En";
import ARABIC from "./i18n/Ar";
import FRANCH from "./i18n/Fr";
import "react-datepicker/dist/react-datepicker.css";
import "rc-pagination/assets/index.css";



const translationEn = ENGLISH;
const translationAr = ARABIC;
const translationFr = FRANCH;

let defaultLang;
if (localStorage.getItem("lang")) {
    defaultLang = JSON.parse(localStorage.getItem("lang"));
} else {
    defaultLang = "ar";
}

i18next.use(initReactI18next).init({
    resources: {
        en: {
            translation: translationEn,
        },
        ar: {
            translation: translationAr,
        },
        fr: {
            translation: translationFr,
        },
    },
    lng: defaultLang,
    fallbackLng: defaultLang,
    interpolation: {
        escapeValue: false,
    },
});



ReactDOM.render(
    <ProductProvider>
        <Router>
            <App />
        </Router>
    </ProductProvider>
    ,
    document.getElementById("root")
);
