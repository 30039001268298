import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import Layout from "../../Layout";
import DataTableBranches from './DataTableBranches';
import { ProductContext } from '../../context/context';

function Branches() {
    
    const { allRefrence = [] } = useContext(ProductContext);

    let branchRefrence = {};
    if(allRefrence.length != 0){
        branchRefrence = allRefrence.find(item => item.mName == "TsmBranch") ? allRefrence.find(item => item.mName == "TsmBranch") : false; 
    }

    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-11 mx-auto my-3 text-center">
                        <div className="text-right mb-2"> 
                            {branchRefrence.chkAdd 
                                ? <Link to="/branches/add-branche" className="text-light btn btn-primary py-0 font-ar"> اضافة </Link> 
                                : <button disabled className="text-light btn btn-primary py-0 font-ar"> اضافة </button>
                            }
                        </div>
                        <DataTableBranches branchRefrence={branchRefrence} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Branches
