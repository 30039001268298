import React, { useContext } from 'react'
import { ProductContext } from '../../context/context';

function ItemsMainGroups({ ItemsGroupsFilter = [], AddList, ChildGroupsFilter, setItemsGroupsFilter, ChildGroupsFiltering }) {
    const { DefinitionItemsT: Items = [] } = useContext(ProductContext);
    const Filtering = ({ id }) => {
        let itemsFiltered = Items.filter(item => item.groupId === id);
        // console.log(ItemsGroupsFilter);
        // console.log(itemsFiltered);
        setItemsGroupsFilter(itemsFiltered)
    }
    return (
        <React.Fragment>
            <div className="items-child-groups">

                {ChildGroupsFiltering.map((row, idx) => {
                    return (
                        <button key={idx} className="item-list" onClick={() => Filtering({ id: row.groupId })}>
                            <h6 className="title-item"> {row.groupName} </h6>
                        </button>
                    )
                })}
            </div>
            <hr />
            <div className="items-groups">

                {ItemsGroupsFilter.map((row, idx) => {
                    return (
                        <button key={idx} className="item-list" onClick={() => AddList(row.automaticDiscountS, row.itemId, row.itemCode, row.salesValue, row.unitId, row.salesDiscountValue, row.salesDiscountType, row.taxRate, row.tableTaxRate)
                        }>
                            <h6 className="title-item"> {row.itemName} </h6>
                        </button>
                    )
                })}
            </div>
        </React.Fragment>

    )
}

export default ItemsMainGroups