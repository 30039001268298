import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Layout from "../Layout";
import DatePicker from "react-datepicker";
import { ProductContext } from "../context/context";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import LoadingVoucher from './LoadingVoucher';
import MrEmployee from "./MrEmployee";
import uuid from 'react-uuid'

import "../assets/css/reciptVoucher.css"
import Roadmap from "../components/Roadmap";

function ReciptVoucherGeneralEdit(props) {
    const { GeneralVoucher = [], EditVoucherForm, user, myControll, token, config } = useContext(ProductContext);
    const [Item, setItem] = useState({});
    const [itemId, setItemId] = useState(props.match.params.id);
    const [UnderList, setUnderList] = useState([]);
    const [active, setActive] = useState(false);
    const [AccountName, setAccountName] = useState({});

    const [customersGrid, setCustomersGrid] = useState([]); // فواتير العملاء


    let history = useHistory();

    useEffect(() => {
        if (GeneralVoucher.length != 0) {
            setVoucherHolderList(GeneralVoucher.voucherHolderList);
            setAccountsListFilter(GeneralVoucher.accountsList);
            setCostCenterList(GeneralVoucher.costCenterList);
            setCurrencyList(GeneralVoucher.currencyList);
            setSalesmanList(GeneralVoucher.salesmanList);
            setEmployeeActionTypeList(GeneralVoucher.employeeActionTypeList);
            setBoxBankList(GeneralVoucher.boxBankList);
            setCustomersArr(GeneralVoucher.supplierCustomerList.filter((items) => items.type == 51));
            setEmployees(GeneralVoucher.supplierCustomerList.filter((items) => items.type == 52));
            // مناديب البيع
            setSallesMan(GeneralVoucher.salesmanList);
            setItem(GeneralVoucher.financialTransactionsList.find((item) => item.ftid == itemId));

        }
    }, [GeneralVoucher]);


    useEffect(() => {
        document.querySelector(".loading").classList.remove("hideloader");
        fetch(`${config}/VoucherForm/GetAllDetails`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                companyId: myControll.companyId,
                branchId: myControll.branchId,
                fyId: myControll.financialYearId
                    ? myControll.financialYearId
                    : myControll.financialYear,
                voucherType: 140,
                ftId: itemId,
            }),
            redirect: "follow",
        })
            .then((response) => response.json())
            .then((result) => {
                document.querySelector(".loading").classList.add("hideloader");
                if (result.isSuccess) {
                    result.data[0].financialTransactionsDetailsList.forEach((item) => {
                        item.idRow = uuid();
                    });
                    setUnderList(result.data[0].financialTransactionsDetailsList);
                    setCustomersGrid(result.data[0].financialTransactionsPaymentList)
                }
            })
            .catch((error) => {
                document.querySelector(".loading").classList.add("hideloader");
                console.log("error", error);
            });
    }, []);

    const userRef = useRef();
    const centerRef = useRef();
    const { t, i18n } = useTranslation();
    // voucherHolderList
    const [list, setList] = useState([]);
    const [currencyId, setCurrencyId] = useState("");
    const [typesolf, setTypesolf] = useState("");
    const [centerIdRef, setCenterIdRef] = useState("");
    const [userIdRef, setUserIdRef] = useState("");
    const [typeAccount, setTypeAccount] = useState("1");
    const [description, setDescription] = useState("");
    const [date, setDate] = useState(new Date());
    const [typeing, setTyping] = useState("1");
    const [currencyIdGeneral, setCurrencyIdGeneral] = useState("1");
    const [boxBankList, setBoxBankList] = useState([]);
    const [boxBankValue, setBoxBankValue] = useState("");
    const [VoucherHolderList, setVoucherHolderList] = useState([]);
    const [accountsListFilter, setAccountsListFilter] = useState([]);
    const [costCenterList, setCostCenterList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [salesmanList, setSalesmanList] = useState([]);
    const [employeeActionTypeList, setEmployeeActionTypeList] = useState([]);
    const [users, setUsers] = useState([]);
    const [centers, setCenters] = useState([]);
    const [customersArr, setCustomersArr] = useState([]); // customer man
    const [sallesMan, setSallesMan] = useState([]); // sales man
    const [Employees, setEmployees] = useState([]); // sales man
    const [sceid, setSceid] = useState(0); // فواتير العملاء
    const [Loans, setLoans] = useState([]); // السلف
    const [Paper, setPaper] = useState([]); // الشيكات
    const [totalPayment, setTotalPayment] = useState(0);

    if (boxBankList.length != 0) {
        boxBankList.forEach((item) => {
            item.value = item.accountId;
            item.label = item.accountName;
            item.id = item.accountId;
        });
    }

    useEffect(() => {
        if (Item) {
            if (Item.accountId) {
                setAccountName(boxBankList.find((norm) => norm.accountId == Item.accountId));
                setBoxBankValue(boxBankList.find((norm) => norm.accountId == Item.accountId));
                setTypeAccount(Item.voucherHolder);
                setSceid(Item.sceid)
            }
        }
    }, [Item]);

    const newRow = () => {
        let newObj = { idRow: uuid(), nameAccount: "", currency: "", sY: 0, amount: "", desc: "", description: " ", costCenter: "", companyId: 0, branchId: 0, jvtype: 140, fyid: 0, ftid: 0, serial: 0, accountId: "", currencyId: 0, rate: 0, amount: 0, amountLocal: 0, description: "", costCenterId: "", issides: false, invoiceType1: 0, invoiceId1: 0, id1: 0 }
        let newList = [...list, newObj];
        setList(newList);
    }

    const removeRow = async (id) => {
        let find = list.find(item => item.idRow == id);
        if (find) {
            setList(list.filter(item => item.idRow != id));
        } else {
            setUnderList(UnderList.filter(item => item.idRow != id));
        }

    }


    const handelChangeUsers = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setUsers([]);
        } else {
            setUsers(
                accountsListFilter.filter(
                    (option) =>
                        option.accountName
                            .toLowerCase()
                            .includes(val.toLowerCase()) ||
                        option.accountId
                            .toLowerCase()
                            .includes(val.toLowerCase())
                )
            );
        }
    };




    const handelChangeCenters = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setCenters([]);
        } else {
            setCenters(
                costCenterList.filter((option) =>
                    option.accountName.toLowerCase().includes(val.toLowerCase())
                )
            );
        }
    };

    const addCatchReceipt = (e) => {
        e.preventDefault();
        let descGlobal = document.querySelector('.textareItem').value;
        let RateGlobal = document.querySelector(`.rate-currency`).value;
        const mergeArray = UnderList.concat(list);
        let obj = { typeAccount, date, totalPayment, typeing, boxBankValue, currencyIdGeneral, description: descGlobal + "", Loans, UnderList: mergeArray, RateGlobal, customersGrid, sceid, typesolf };
        EditVoucherForm(obj, 140, setUnderList, itemId, history, "/receipt-voucher");
    };

    const selectCurreny = (val, idx) => {
        let curreny = currencyList.find((item) => item.currencyId == +val);
        setCurrencyId(+val);
        if (curreny.isLocal) {
            document.querySelector(`.input-balance-sY-${idx}`).value =
                curreny.rate;
            document.querySelector(`.input-balance-sY-${idx}`).disabled = true;
        } else {
            document.querySelector(`.input-balance-sY-${idx}`).value =
                curreny.rate;
            document.querySelector(`.input-balance-sY-${idx}`).defaultValue =
                curreny.rate;
            document
                .querySelector(`.input-balance-sY-${idx}`)
                .removeAttribute("disabled");
        }
        let ListItems = [...list];
        let item = ListItems.find((row) => row.idx == idx);
        item.currencyId = curreny.currencyId;
    };

    const typeSolf = (val) => {
        setTypesolf(val);
        if (val == 2) {
            document.querySelectorAll(".employeeOnlySolf").forEach((item) => {
                item.classList.add("d-none");
            });
        }
        if (val == 1) {
            document.querySelectorAll(".employeeOnlySolf").forEach((item) => {
                item.classList.remove("d-none");
            });
        }
    };

    const ChangeAccount = (val) => {
        setCustomersGrid([]);
        setTypesolf(0);
        if (val == 1) {
            // استاذ
            setTypeAccount(1);
            setSceid(0);

            setCustomersGrid([]);
            document.querySelectorAll(".customerOnly").forEach((item) => {
                item.classList.add("d-none");
            });
            document.querySelectorAll(".Mr").forEach((item) => {
                item.classList.remove("d-none");
            });
            document.querySelectorAll(".employeeOnly").forEach((item) => {
                item.classList.add("d-none");
            });
            document.querySelectorAll(".employeeOnlySolf").forEach((item) => {
                item.classList.add("d-none");
            });
        }
        if (val == 2 || val == 3) {
            // عميل

            if (val == 3) {
                setCustomersArr(GeneralVoucher.supplierCustomerList.filter((items) => items.type == 50));
            }
            if (val == 2) {
                setCustomersArr(GeneralVoucher.supplierCustomerList.filter((items) => items.type == 51));
            }
            setTypeAccount(val);
            setUnderList([
                { idx: 0, nameAccount: "", currency: "", sY: 0, amount: "", desc: "", description: "", costCenter: "", companyId: 0, branchId: 0, jvtype: 2, fyid: 0, ftid: 0, serial: 1, accountId: "", currencyId: 0, rate: 0, amount: 0, amountLocal: 0, description: "", costCenterId: "", issides: false, invoiceType1: 0, invoiceId1: 0, id1: 0 },
            ]);
            document.querySelectorAll(".customerOnly").forEach((item) => {
                item.classList.remove("d-none");
            });
            document.querySelectorAll(".Mr").forEach((item) => {
                item.classList.add("d-none");
            });
            document.querySelectorAll(".employeeOnly").forEach((item) => {
                item.classList.add("d-none");
            });
            document.querySelectorAll(".employeeOnlySolf").forEach((item) => {
                item.classList.add("d-none");
            });
        }
        if (val == 4) {
            // موظف
            setTypeAccount(4);
            document.querySelectorAll(".customerOnly").forEach((item) => {
                item.classList.add("d-none");
            });
            document.querySelectorAll(".Mr").forEach((item) => {
                item.classList.add("d-none");
            });
            document.querySelectorAll(".employeeOnly").forEach((item) => {
                item.classList.remove("d-none");
            });
        }
    };

    const GetBalanceUser = (id, idRow) => {
        fetch(`${config}/VoucherForm/GetBalance`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                companyId: user.companyId,
                accountId: id,
                balance: 0,
            }),
            redirect: "follow",
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.isSuccess) {
                    document.querySelector(`.balance-td-${idRow}`).innerHTML = result.data.balance;
                }
            })
            .catch((error) => console.log("error", error));
    };

    const selectCurrenyGeneral = (val) => {
        let curreny = currencyList.find((item) => item.currencyId == +val);
        setCurrencyIdGeneral(val);

        if (curreny.isLocal) {
            document.querySelector(`.rate-currency`).value = curreny.rate;
            document.querySelector(`.rate-currency`).disabled = true;
        } else {
            document.querySelector(`.rate-currency`).value = curreny.rate;
            document.querySelector(`.rate-currency`).defaultValue = curreny.rate;
            document.querySelector(`.rate-currency`).removeAttribute("disabled");
        }
    };

    const CalTotal = () => {
        let total = UnderList.map((item) => {
            return item.amount;
        }).reduce((acc, curr) => {
            return acc + curr;
        }, 0);
        setTotalPayment(total);
    };

    const gerGridCustomer = (val) => {
        setSceid(val);
        if (val != "00") {
            let requestOptionsCoustomers = {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    companyId: user.companyId,
                    branchId: user.branchId,
                    fyid: user.financialYear,
                    ftid: 0,
                    ftdate: date,
                    jvtype: 140,
                    voucherHolder: 2,
                    sceId: val,
                    employeeActionType: 0,
                }),
                redirect: "follow",
            };

            fetch(`${config}/VoucherForm/ReturnGrid`, requestOptionsCoustomers)
                .then((response) => response.json())
                .then((res) => {
                    setCustomersGrid(res.data[0].financialTransactionsPaymentList);
                })
                .catch((error) => console.log("error", error));
        }
    };

    const changePaymentValue = (val, invoiceId) => {
        let customers = [...customersGrid];
        let customer = customers.find((item) => item.invoiceId == invoiceId);
        customer.paymentValue = +val;
    };

    const changePaymentValueLoan = (val, sourceFtid, sourceLoanId) => {
        let LoansData = [...Loans];
        let LoansDataFilter = LoansData.filter(
            (item) => item.sourceFtid == sourceFtid
        );
        let Loan = LoansDataFilter.find(
            (item) => item.sourceLoanId == sourceLoanId
        );
        Loan.paymentValue = +val;
    };

    const changeEmployee = (val) => {
        setSceid(val);
        if (val != "00") {
            let requestOptionsCoustomers = {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    companyId: user.companyId,
                    branchId: user.branchId,
                    fyid: user.financialYear,
                    ftid: 0,
                    ftdate: date,
                    jvtype: 140,
                    voucherHolder: 4,
                    sceId: val,
                    employeeActionType: 1,
                }),
                redirect: "follow",
            };

            fetch(`${config}/VoucherForm/ReturnGrid`, requestOptionsCoustomers)
                .then((response) => response.json())
                .then((res) => {
                    setLoans(res.data[0].loanList);
                })
                .catch((error) => console.log("error", error));
        }
    };

    const changeBalanceamount = (val, idx) => {
        let ListItems = [...UnderList];
        let item = ListItems.find((row) => row.idx == idx);
        item.amount = +val;
    };

    const changeAccountNameAndId = (AccountId, AccountName, idx) => {
        let ListItems = [...UnderList];
        let item = ListItems.find((row) => row.idx == idx);
        item.accountId = document.querySelector(`.input-userIdRef-${idx}`).innerHTML;
        item.nameAccount = document.querySelector(`.input-userNameRef-${idx}`).innerHTML;
    };

    const changeDesc = (val, idx) => {
        let ListItems = [...UnderList];
        let item = ListItems.find((row) => row.idx == idx);
        item.description = val + "";
        console.log(item);
    };

    const changeRateCurrency = (val, idx) => {
        let ListItems = [...UnderList];
        let item = ListItems.find((row) => row.idx == idx);
        item.rate = +val;
    };
    // 

    if (Object.keys(AccountName).length != 0) {
        return (
            <Layout>
                <div className="company text-right font-ar container companyReciptVoucher py-5" style={{ maxWidth: "1100px" }}>
                    <div className="container py-4">
                        <div className="row mx-0">
                            <Roadmap name={t('receipt_voucher')} child={t('edit')} childTo={{ pathname: '/receipt-voucher', params: { status: 2 } }} />

                            <div className="col-12 col-md-10 mx-auto px-0">
                                <form onSubmit="return false;" dir={i18n.language == "en" ? "ltr" : "rtl"} className={`${i18n.language == "en" ? "text-left" : "text-right"}`} >
                                    <div className="row py-2 flex-row-reverse">
                                        <div className="col-12 col-md-6 mx-auto">
                                            <label htmlFor=""> {t("num")} </label>
                                            <input type="number" className="form-control mt-1" value={Item.ftserial} disabled />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto">
                                            <label htmlFor=""> {t("date")} </label>
                                            <DatePicker style={{ width: "100%" }} className="form-control mt-1 formatDate" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={date} onChange={(date) => setDate(date)} />
                                        </div>
                                    </div>

                                    {/* معتمد غير معتمد */}
                                    <div className="row py-2  flex-row-reverse">
                                        <div className="col-12 col-md-6">
                                            <label> {t("accountName")} </label>
                                            <Select options={boxBankList} onChange={(e) => setBoxBankValue(e)} defaultValue={AccountName} />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label> {t("status")} </label>
                                            <select className="form-control my-1" dir={i18n.language == "en" ? "ltr" : "rtl"} onChange={(e) => setTyping(e.target.value)} >
                                                <option value="1" selected={Item.status == 1 ? true : false}>
                                                    {t("certified")}
                                                </option>
                                                <option value="0" selected={Item.status == 0 ? true : false} >
                                                    {t("notsupported")}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* العملة */}
                                    <div className="row py-2  flex-row-reverse">
                                        <div className="col-12 col-md-6">
                                            <label> {t("currenyType")} </label>
                                            <select className={`form-control`} dir={i18n.language == "en" ? "ltr" : "rtl"} onChange={(e) => selectCurrenyGeneral(e.target.value)} >
                                                {currencyList.map((curr) => (
                                                    <option key={curr.currencyId} value={curr.currencyId} selected={curr.currencyId == Item.currencyId ? true : false}>
                                                        {curr.currencyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto">
                                            <label htmlFor=""> {t("sy")} </label>
                                            <input type="number" className="text-right form-control mt-1 rate-currency" disabled defaultValue={Item.rate} />
                                        </div>
                                    </div>

                                    {/*  الوصف واسم الحساب */}
                                    <div className="row py-2 my-3 flex-row-reverse">
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="">
                                                {t("description")}
                                            </label>
                                            <input className="form-control mt-1 text-right textareItem" onChange={(e) => setDescription(e.target.value)} defaultValue={Item.description} />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label> {t("bondowner")} </label>
                                            <select className="form-control my-1" dir={i18n.language == "en" ? "ltr" : "rtl"} onChange={(e) => ChangeAccount(e.target.value)}>
                                                {VoucherHolderList.map((sel) => (
                                                    <option key={sel.id} value={sel.id} selected={Item.voucherHolder == sel.id ? true : false} name={sel.vhName}>
                                                        {sel.vhName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {/*  */}
                                    </div>

                                    {/* للعملاء */}
                                    <div className={`row py-2 my-3 customerOnly ${Item.voucherHolder == 1 ? "d-none" : ''}`}>
                                        <div className="col-12 col-md-6">
                                            <label> {t("customers")} </label>
                                            <select className="form-control my-1" onChange={(e) => gerGridCustomer(e.target.value)} >
                                                <option value="00"> --- </option>
                                                {customersArr.map((sel) => (
                                                    <option key={sel.id} selected={Item.sceid == sel.id ? true : false} value={sel.id} >
                                                        {sel.customerName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label>
                                                {t("salesRepresentative")}
                                            </label>
                                            <select className="form-control my-1">
                                                {sallesMan.map((sel) => (
                                                    <option key={sel.id} value={sel.id}>
                                                        {sel.employeeName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* الموظف */}
                                    <div className={`row py-2 my-3 employeeOnly ${Item.voucherHolder != 4 ? "d-none" : ''}`}>
                                        <div className="col-12 col-md-6">
                                            <label> {t("type")} </label>
                                            <select className="form-control my-1" onChange={(e) => typeSolf(e.target.value)}>
                                                <option value="00"> --- </option>
                                                {employeeActionTypeList.map(
                                                    (sel) => <option key={sel.id} value={sel.id}>
                                                        {sel.employeeActionTypeName}
                                                    </option>

                                                )}
                                            </select>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <label> {t("employee")} </label>
                                            <select className="form-control my-1" onChange={(e) => changeEmployee(e.target.value)}>
                                                <option value="00"> --- </option>
                                                {Employees.map((sel) => {
                                                    return (
                                                        <option key={sel.id} value={sel.id}>
                                                            {sel.customerName}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>

                                    </div>

                                    {/* Mr employee */} {/* V1 */}
                                    <MrEmployee
                                        newRow={newRow}
                                        removeRow={removeRow}
                                        Item={Item}
                                        setUnderList={setUnderList}
                                        UnderList={UnderList}
                                        list={list}
                                        setUserIdRef={setUserIdRef}
                                        t={t}
                                        i18n={i18n}
                                        userRef={userRef}
                                        handelChangeUsers={handelChangeUsers}
                                        users={users}
                                        setUsers={setUsers}
                                        GetBalanceUser={GetBalanceUser}
                                        selectCurreny={selectCurreny}
                                        currencyList={currencyList}
                                        changeRateCurrency={changeRateCurrency}
                                        changeBalanceamount={changeBalanceamount}
                                        changeDesc={changeDesc}
                                        centerRef={centerRef}
                                        handelChangeCenters={handelChangeCenters}
                                        centers={centers}
                                        setCenterIdRef={setCenterIdRef}
                                        setCenters={setCenters}
                                        accountsListFilter={accountsListFilter}
                                        changeAccountNameAndId={changeAccountNameAndId}
                                    />
                                    {/* end of Mr employee */} {/* V1 */}

                                    {/* Customer employee */}
                                    <table className={`table table-striped customerOnly  ${Item.voucherHolder != 2 ? "d-none" : ''} `}>
                                        <thead>
                                            <tr className="text-center table-list-costing">
                                                <th> {t("type")} </th>
                                                <th> {t("numebrInvoice")} </th>
                                                <th> {t("dateInvoice")} </th>
                                                <th> {t("description")} </th>
                                                <th> {t("currenyType")} </th>
                                                <th> {t("sy")} </th>
                                                <th> {t("amount")} </th>
                                                <th> {t("amountPaid")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="lists text-center" style={{ display: "inherit" }}>
                                            {customersGrid.map(({ jvtypeName, invoiceId, invoiceDate, description, currencyId, rate, amount, paymentValue }, idx) => {
                                                return (
                                                    <tr key={idx} className={`row-${idx}`}>
                                                        <td> {jvtypeName} </td>
                                                        <td> {invoiceId} </td>
                                                        <td> {invoiceDate} </td>
                                                        <td> {description} </td>
                                                        <td>
                                                            <select className={`form-control td-${idx} input-customer-currency-${idx}`} disabled>
                                                                {currencyList.map(
                                                                    (curr) => {
                                                                        return (
                                                                            <option key={curr.currencyId} value={curr.currencyId} selected={curr.currencyId == currencyId ? true : false}>
                                                                                {curr.currencyName}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </select>
                                                        </td>
                                                        <td> {rate} </td>
                                                        <td> {amount} </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className={`form-control text-center w-100 td-${idx} input-paymentValue-${idx}`}
                                                                defaultValue={paymentValue}
                                                                onChange={(e) => changePaymentValue(e.target.value, invoiceId)}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                    {/* end of Customer employee */}

                                    {/* table employee */}
                                    <table className={`table table-striped employeeOnlySolf ${Item.voucherHolder != 4 ? "d-none" : ''} `}>
                                        <thead>
                                            <tr className="text-center table-list-costing">
                                                <th> {t("type")} </th>
                                                <th scope="col"> {t("num")} </th>
                                                <th scope="col"> {t("Advanceamount")} </th>
                                                <th scope="col">{t("duedate")}</th>
                                                <th scope="col"> {t("amount")} </th>
                                            </tr>
                                        </thead>
                                        <tbody className="lists text-center lonsList" style={{ display: "inherit" }}>
                                            {Loans.map(
                                                ({ ftid, journalVoucherTypeName, loanValue, paymentValue, dueDate, sourceFtid, sourceLoanId }, idx) => {
                                                    return (
                                                        <tr key={idx} className={`row-${idx}`}>
                                                            <td> {journalVoucherTypeName} </td>
                                                            <td> {ftid} </td>
                                                            <td> {loanValue} </td>
                                                            <td> {dueDate} </td>
                                                            <td style={{ width: "200px" }}>
                                                                <input type="number" className={`form-control text-center w-100 td-${idx} input-paymentValue-${idx}`} defaultValue={paymentValue} onChange={(e) => changePaymentValueLoan(e.target.value, sourceFtid, sourceLoanId)} />
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                    {/* end of table employee */}
                                    <hr />
                                    <div className={`submiting d-flex mt-5 ${i18n.language == "en" ? "justify-content-end" : ""} `}>
                                        <Link to={{ pathname: '/receipt-voucher', params: { status: 2 } }} className="btn btn-primary mx-2 px-4" >
                                            {t("Retreat")}
                                        </Link>
                                        <button type="button" className="btn btn-primary mx-2 px-4" onClick={(e) => addCatchReceipt(e)}>
                                            {t("save")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    } else {
        return <LoadingVoucher />
    }
}

export default ReciptVoucherGeneralEdit
