import React, { useState } from 'react'
import Layout from "../../Layout";


function AddStore() {

    const [category, setCategory] = useState([]);
    const [code, setCode] = useState('');
    const [name_ar, setName_Ar] = useState('');
    const [name_en, setName_EN] = useState('');
    const [cat, setCat] = useState('');


    const Categories = (e) => {
        e.preventDefault();
        let Cat = [...category, {name_ar,name_en,code, cat}];
        setCategory(Cat)
        console.log(Cat);
    }


    return (
        <Layout>
                <div className="company text-right font-ar">
                    <div className="container-fluid py-4">
                        <div className="row mx-0">
                            <div className="col-12 col-md-8 mx-auto px-0">
                                <form dir="rtl">
                                        <div className="form-group mx-auto">
                                            <label>الكود</label>
                                            <input type="number" className="form-control" onChange={(e) => setCode(e.target.value)} />
                                        </div>
                                        <div className="form-group mx-auto">
                                            <label>الاسم بالعربي</label>
                                            <input type="text" className="form-control" onChange={(e) => setName_Ar(e.target.value)} />
                                        </div>

                                        <div className="form-group mx-auto">
                                            <label>الاسم بالانجليزي</label>
                                            <input type="text" className="form-control" onChange={(e) => setName_EN(e.target.value)} />
                                        </div>


                                        <select className="form-control my-3" onChange={e => setCat(e.target.value)} >
                                            <option>القسم</option>
                                            {category.map(( cat, i )=> {
                                                return <option key={i} value={cat}> {cat.name_ar} </option>
                                            })}
                                        </select>

                                    <div className="submiting d-flex">
                                        <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => Categories(e)}>حفظ</button>
                                        <button type="button" className="btn btn-primary mx-2 px-4">تراجع</button>
                                    </div>
                                </form>
    
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
    )
}

export default AddStore
