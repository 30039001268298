import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Edit(props) {
    let id = props.match.params.id;
    const { UpdateCustomersGroup, customersGroup = [] } = useContext(ProductContext);
    const [code, setCode] = useState('');
    const [name_ar, setName_Ar] = useState('');
    const [name_en, setName_EN] = useState('');
    const [notes, setNotes] = useState('');
    const [each, setEach] = useState(false);
    const [customerGroup, setCustomerGroup] = useState({});
    const [byAdminstrator, setByAdminstrator] = useState(false);

    const { t } = useTranslation();


    useEffect(() => {
        let customerGroup = customersGroup.find(item => item.id == id);
        if (customerGroup) {
            setCustomerGroup(customerGroup)
            setCode(customerGroup.code)
            setName_Ar(customerGroup.nameA)
            setName_EN(customerGroup.nameE)
            setNotes(customerGroup.notes)
            setByAdminstrator(customerGroup.byAdmin)
        }

    }, [customersGroup])
    const history = useHistory();

    const UpdateUnitSave = async (e) => {
        e.preventDefault();
        try {
            await UpdateCustomersGroup(customerGroup, notes, name_ar, name_en, setName_Ar, setName_EN, setNotes, byAdminstrator,setByAdminstrator, history);
        } catch (e) {
            console.log(e);
        }
    }
    const checkChange = () => {
        setEach(!each)
    }

    useEffect(() => {
        if (each) {
            setName_EN(name_ar)
        }
        if (!each) {
            setName_EN(customerGroup.nameE)
        }
    }, [each, customerGroup])


    return (
        <Layout>
            <div className="company text-right font-ar container companyReciptVoucher py-5" style={{ maxWidth: "1100px" }}>
                <div className="container py-4">
                    <div className="container-fluid py-4">
                        <div className='row'>
                            <div className="col-12 col-md-10 mx-auto">
                                <form dir="rtl">
                                    <div className="row mx-0">
                                        <div className="col-6 mx-auto my-2">
                                            <label style={{ fontSize: "14px" }}>{t('code')}</label>
                                            <input type="number" className="form-control" value={code} disabled />
                                        </div>
                                        <div className="col-6 mx-auto my-2">
                                            <label style={{ fontSize: "14px" }}>{t('namear')}</label>
                                            <input type="text" className="form-control" value={name_ar} onChange={(e) => setName_Ar(e.target.value)} />
                                        </div>
                                        <div className="col-6 mx-auto my-2">
                                            <label style={{ fontSize: "14px" }} label>{t('nameen')}</label>
                                            <input type="text" className="form-control" value={name_en} onChange={(e) => setName_EN(e.target.value)} />
                                        </div>
                                        <div className="col-6 mx-auto align-self-center my-2">
                                            <input type="checkbox" id='htmlEach' className="mx-3" checked={each} onChange={() => checkChange()} />
                                            <label htmlFor='htmlEach'> {t('WriteEnglishArabic')} </label>
                                        </div>
                                        <div className="col-6 mx-auto my-2">
                                            <label style={{ fontSize: "14px" }}> {t('Notes')} </label>
                                            <textarea type="url" className="form-control" value={notes} onChange={e => setNotes(e.target.value)} ></textarea>
                                        </div>
                                        <div className="col-6 mx-auto my-2">
                                            <input type="checkbox" className="mx-2" id="adminstrator" checked={byAdminstrator} onChange={() => setByAdminstrator(!byAdminstrator)} />
                                            <label htmlFor='adminstrator'> {t('byadministrator')} </label>
                                        </div>
                                        <hr />
                                        <div className="submiting d-flex">
                                            <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => UpdateUnitSave(e)}> تعديل </button>
                                            <Link to={{ pathname: '/customersGroup', params: { status: 2 } }} className="btn btn-primary mx-2 px-4">تراجع</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default Edit
