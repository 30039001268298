export const PrivacyData = [
    {
        id: 1,
        title: "سياسة خصوصية",
        child: [
            {
                text: "تعني هذه السياسة بخصوصية زوار موقع افاقي. وهي تحدد نوع البيانات والمعلومات التي نقوم في موقع افاقي بجمعها من الزوار، وكيف نستخدمها ونعرضها، والخطوات التي نتخذها لحماية خصوصيتك .",
            },
        ],
    },
    {
        id: 2,
        title: "جمع المعلومات",
        child: [
            {text: "تقوم موقع افاقي بجمع المعلومات الشخصية عندما تصبح مستخدما مسجلا في موقع افاقي ، وعندما تستخدم منتجات وخدمات موقع افاقي ، وعندما تزور موقع افاقي، وعندما تشترك في الدعاية أو المسابقات."},
            {text: "وقد نقوم كذلك بجمع معلومات غير شخصية عن زيارتك للموقع مثل عنوان واسم الموقع الذي دخلت منه إلى موقعنا، وكم الوقت الذي استغرقته في كل صفحة، وعنوان الـ IP الخاص بجهازك. تُستخدم هذه المعلومات لتحسين أداء الموقع والعمليات التي تجري عليه."},
            {text: "عندما تصبح مستخدما مسجلا لدينا، نسألك عن معلومات مثل: اسمك، وتاريخ ميلادك، وجنسك (ذكر/أنثى)، والبلد ومدينتك، أرقام تليفوناتك، وبريدك الإلكتروني."},
            {text: "يتلقى موقع افاقي المعلومات من متصفحك (البرنامج الذي تستخدمه لتصفح الإنترنت)، ويقوم بتسجيلها أوتوماتيكيا على الخادم (السيرفر)، بما في ذلك عنوان الـ IP الخاص بك، والمعلومات التي تتضمنها ملفات الكوكي (cookie) الخاصة بـ موقع افاقي ، وأية صفحة تدخل عليها في الموقع."},
            {text: "يستخدم موقع افاقي هذه المعلومات في الأغراض العامة التالية: لمواءمة الإعلانات والمحتوى الذي تراه، أو استكمال طلباتك من منتجاتنا وخدماتنا، أو تطوير خدماتنا، أو الاتصال بك، أو توفير تقارير إحصائية لعملاء داخليين أو خارجيين."},
        ],
    },
    {
        id: 3,
        title: "معلومات حسابك",
        child: [
            {text: "تستطيع تعديل بيانات حسابك على موقع افاقي في أي وقت. إن قيامك بالتسجيل في موقع افاقي، يعني موافقتك على تلقي معلومات معينة من موقع افاقي وشركائها."},
        ],
    },
    {
        id: 4,
        title: "ملفات الكوكي Cookies والتقنيات ذات الصلة",
        child: [
            {text: "قد نستخدم ملفات الكوكيز Cookies لتحسين المحتوى المقدم لك خلال تصفحك لموقعنا. إن هذه الملفات لا تتضمن أية معلومات شخصية ولا تقوم بجمع مثل هذه المعلومات، لكنها قد تقوم بجمع معلومات عن جهازك.. إن رفضك لنزول ملفات الكوكيز Cookies من موقع افاقي على جهازك لن يمنعك من تصفح موقعنا، ولكنه قد يحد من قدرتك على الاستفادة من بعض المميزات المتقدمة التي توفرها."},
            {text: "تستخدم بعض صفحات الموقع أكواد تسمى one-pixel gifs أو clear gifs أو web beacons تُدرج في الصفحات لمراقبة النشاط القائم على موقعنا. وهذه التكنولوجيا لا تتضمن أيا من معلوماتك الشخصية، ولا تقوم بجمع مثل هذه المعلومات."},
        ],
    },
    {
        id: 5,
        title: "الإعلانات والروابط الخارجية",
        child: [
            {text: "يتضمن موقع افاقي إعلانات خارجية أو روابط لمواقع خارجية.  موقع افاقي غير مسئولة عن محتوى أو ممارسات الخصوصية التي تتضمنها المواقع الأخرى. قد يستخدم شركاؤنا أو المعلنون معنا مواد خارجية لخدمة أو عرض الإعلانات التي تظهر على موقعنا. وقد تقوم هذه المواد الخارجية بتنزيل ملفات الكوكيز cookies على جهازك عند زيارة موقعنا. إن أية ملفات كوكيز cookies خارجية تخضع لسياسة الخصوصية الخاصة بموقع الشركة التي تقوم بتنزيلها."},
        ],
    },
    {
        id: 6,
        title: "عرض المعلومات لجهات خارجية",
        child: [
            {text: "قد تستخدم موقع افاقي المعلومات المجموعة عن زوار موقعنا أو تعرضها على شركائنا أو جهات خارجية ذات صلة؛ لتحسين موقعنا وجذب المزيد من المعلنين والإدراجات الإعلانية. فمثلا، قد نكشف لغيرنا عن عدد زوار موقعنا ومعدل مكوثهم في كل صفحة. إن مثل هذا الاستخدام أو المشاركة في المعلومات المجموعة لا يكشف عن أي من معلوماتك الشخصية لجهات خارجية."},
            // {text: "لا تقوم موقع افاقي بتأجير معلوماتك الشخصية، أو بيعها، أو مشاركتها مع أفراد آخرين أو شركات غير معنية إلا لتقديم منتجات، أو خدمات، أو معلومات طلبتها، بعد استئذانك، أو وفقا للظروف التالية"},
        ],
    },
    {
        id: 7,
        title: "الأمن",
        child: [
            {text: "إن موقع افاقي تنظر إلى أمنك بجدية، حيث تتخذ خطوات مسئولة لحماية معلوماتك. لا يوجد نقل معلومات على الإنترنت يمكن ضمان كونه آمنا 100%. إننا لا نستطيع ضمان أمن أية معلومات تنقلها إلينا ومن ثم فإنك تقوم بذلك على مسئوليتك. ولكن بمجرد تلقينا للمعلومات، فإننا نبذل الجهود المفترضة لأمن هذه المعلومات على أنظمتنا. ومع ذلك، فضلا ضع في اعتبارك أن هذا لا يضمن أن هذه المعلومات لن يتم الدخول عليها، أو الكشف عنها، أو تغييرها، أو تدميرها من خلال اختراق أي من برامج الأمان والحماية من الاختراق التي قد نستخدمها. إن موقع افاقي تواصل تقييم وتنفيذ تطويرات في تكنولوجيا وممارسات الأمن، غير أنه من الممكن لنا فقط اتخاذ خطوات للتقليل من مخاطر الوصول غير المشروع للمعلومات."},
            {text: "إن استخدامك لهذا الموقع، أو إمدادنا بمعلومات شخصية عنك، يعني موافقتك على احتمال اتصالنا بك إليكترونيا فيما يخص الأمن، والخصوصية، والشئون الإدارية المتعلقة باستخدامك لهذا الموقع."},
        ],
    },
    {
        id: 8,
        title: "بروتوكول SSL",
        child: [
            {text: "تستخدم موقع افاقي بروتوكول SSL لتشفير نوعية معينة من المعلومات لدى نقلها، مثل معلومات الدفع.. فمتى طلب منك موقع افاقي معلومات عن بطاقة ائتمانك لإتمام عملية دفع أو لأغراض تحققية، فإن المعلومات سترسل مشفرة بواسطة بروتوكول SSL. ولن يتم تخزين معلومات بطاقتك الائتمانية على أي خادم (سيرفر) لدينا وإنما تخزن على خادم (سيرفر) مزود خدمة الدفع الذي يتميز بأمنه والتزامه بمعايير المطابقة القياسية PCI. وهذا كله لتحقيق الأمن والأمان. عندما تُدخل رقم بطاقة ائتمانك على موقع افاقي، فإنه سوف يستخدم مرة واحدة وحسب، وستحتاج لإدخال بيانات بطاقة ائتمانك مرة أخرى خلال عمليات الدفع المستقبلية."},
        ],
    },
    {
        id: 9,
        title: "تخزين آمن للبيانات",
        child: [
            {text: "تقوم موقع افاقي باتخاذ الإجراءات الوقائية المفترضة لحماية معلوماتك الشخصية."},
        ],
    },
    {
        id: 10,
        title: "تحديثات سياسة خصوصية وأمن البيانات",
        child: [
            {text: "إن هذه السياسة تحل محل كل سياسات خصوصية وأمن البيانات التي سبق نشرها في الموقع. وتحتفظ  موقع افاقي بحقها في تحديث هذه السياسة بإجراء أية تعديلات عليها في أي وقت."},
        ],
    },
];
