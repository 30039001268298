import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../../../Layout";
import DatePicker from "react-datepicker";
import { ProductContext } from "../../../context/context";
import TableCustomer from "./TableCustomer";

function Customer() {
    const inputRef = useRef();
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    const [search, setSearch] = useState("");
    const [options, setOptions] = useState([]);
    const [options2, setOptions2] = useState([]);
    const [options3, setOptions3] = useState([]);

    const { Branches = [] } = useContext(ProductContext);

    const [BranchesFilter, setBranchesFilter] = useState([]);

    useState(new Date());
    const [EndDataCustomerStatement, setEndDataCustomerStatement] = useState(
        new Date()
    );

    useEffect(() => {
        setBranchesFilter(Branches);
    }, [Branches]);

    const handelChange = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setOptions([]);
        } else {
            setOptions(
                BranchesFilter.filter((option) =>
                    option.nameA.toLowerCase().includes(val.toLowerCase())
                )
            );
        }
    };
    const handelChange2 = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setOptions2([]);
        } else {
            setOptions2(
                [].filter((option) =>
                    option.nameA.toLowerCase().includes(val.toLowerCase())
                )
            );
        }
    };
    const handelChange3 = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setOptions3([]);
        } else {
            setOptions3(
                [].filter((option) =>
                    option.nameA.toLowerCase().includes(val.toLowerCase())
                )
            );
        }
    };

    return (
        <Layout>
            <div className="container-fluid" style={{ minHeight: "70vh" }}>
                <div className="container py-3">
                    <div className="row">
                        <form
                            dir="rtl"
                            onSubmit={(e) => e.preventDefault()}
                            className="col-12 px-0"
                        >
                            <div className="col-11 mx-auto text-right">
                                <div className="row pt-3">
                                    <div className="col-12 col-md-3 mx-auto">
                                        <label className="label-account-statement">
                                            الي تاريخ
                                        </label>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            showTimeSelect
                                            dateFormat="d/MM/y  HH:MM"
                                            selected={EndDataCustomerStatement}
                                            onChange={(date) =>
                                                setEndDataCustomerStatement(
                                                    date
                                                )
                                            }
                                        />
                                    </div>

                                    <div className="col-12 col-md-3 mx-auto account-statement-container">
                                        <label className="label-account-statement">
                                            الفروع
                                        </label>
                                        <div className="filterdeftcustoner">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                ref={inputRef}
                                                onChange={(e) =>
                                                    handelChange(e)
                                                }
                                            />
                                            <ul className="list-group list-group-filter-search  list-group-filter-search-2 text-left">
                                                {options.map((option, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <button
                                                                key={i}
                                                                type="button"
                                                                className="list-group-item list-group-item-action"
                                                                onClick={() => {
                                                                    inputRef.current.value =
                                                                        option.nameA;
                                                                    setOptions(
                                                                        []
                                                                    );
                                                                }}
                                                            >
                                                                {option.nameA}
                                                            </button>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-3 mx-auto account-statement-container">
                                        <label className="label-account-statement">
                                            مجموعة العملاء
                                        </label>
                                        <div className="filterdeftcustoner">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                ref={inputRef2}
                                                onChange={(e) =>
                                                    handelChange2(e)
                                                }
                                            />
                                            <ul className="list-group list-group-filter-search  list-group-filter-search-2 text-left">
                                                {options2.map((option, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <button
                                                                key={i}
                                                                type="button"
                                                                className="list-group-item list-group-item-action"
                                                                onClick={() => {
                                                                    inputRef2.current.value =
                                                                        option.nameA;
                                                                    setOptions2(
                                                                        []
                                                                    );
                                                                }}
                                                            >
                                                                {option.nameA}
                                                            </button>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-3 mx-auto account-statement-container">
                                        <label className="label-account-statement">
                                            العملاء
                                        </label>
                                        <div className="filterdeftcustoner">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                ref={inputRef3}
                                                onChange={(e) =>
                                                    handelChange3(e)
                                                }
                                            />
                                            <ul className="list-group list-group-filter-search  list-group-filter-search-2 text-left">
                                                {options3.map((option, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <button
                                                                key={i}
                                                                type="button"
                                                                className="list-group-item list-group-item-action"
                                                                onClick={() => {
                                                                    inputRef3.current.value =
                                                                        option.nameA;
                                                                    setOptions3(
                                                                        []
                                                                    );
                                                                }}
                                                            >
                                                                {option.nameA}
                                                            </button>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        {/* <select
                                            name="branches"
                                            className="barnches-search-drop-list-account-statement"
                                        >
                                            <option value="0"> --- </option>
                                            {BranchesFilter.map(
                                                (option, idx) => (
                                                    <option
                                                        key={idx}
                                                        value={option.branchId}
                                                    >
                                                        {option.companyNameA}
                                                    </option>
                                                )
                                            )}
                                        </select> */}
                                    </div>
                                </div>
                                <button
                                    className="btn btn-outline-primary py-1 my-4 mx-3"
                                    type="button"
                                >
                                    كشف - View
                                </button>
                            </div>

                            <hr />
                        </form>
                    </div>
                    <TableCustomer />
                </div>
            </div>
        </Layout>
    );
}

export default Customer;
