import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";



function UpdateFiscal(props) {
    let id = props.match.params.id;
    const { FinancialYear, UpdateFinancialYear } = useContext(ProductContext);
    const [codeYears, setCodeYears] = useState('');
    const [status, setStatus] = useState(1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [notes, setNotes] = useState('');
    const [comId, setComId] = useState('');

    useEffect(() => {
        let financial = FinancialYear.find(item => item.financialYearId == id);
        console.log(financial);
        setCodeYears(financial.financialCode);
        setStartDate(financial.beginDate);
        setStatus(financial.financialStatus)
        setEndDate(financial.endDate);
        setNotes(financial.notes);
        setComId(financial.companyId)
    }, [])

    const onSaveDate = (e) => {
        e.preventDefault();
        let fiscalYear = {
            codeYears,
            status,
            startDate,
            endDate,
            notes,
            id,
            companyId: comId
        }
        UpdateFinancialYear(fiscalYear)
        // newFinancialYear(fiscalYear)
        emptyForm();
    }

    const emptyForm = () => {
        setCodeYears('');
        setStartDate('');
        setEndDate('');
        setNotes('');
    }

    return (
        <Layout>
            <div className="company text-right font-ar">
                <div className="container-fluid py-4">
                    <div className="row mx-0">
                        <div className="col-12 col-md-10 mx-auto px-0">
                            <form dir="rtl">
                                <div className="row py-2">
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label>كود السنة</label>
                                        <input type="number" value={codeYears} onChange={e => setCodeYears(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto">
                                        <div className="form-group">
                                            <label>الحالة</label>
                                            <select className="font-ar form-control" value={status} onChange={e => setStatus(e.target.value)}>
                                                {status == 1 && "1" ? 
                                                    <React.Fragment>
                                                        <option value="1"> نشط </option>
                                                        <option value="0"> مغلق </option>
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <option value="0"> مغلق </option>
                                                        <option value="1"> نشط </option>
                                                    </React.Fragment>
                                                }
                                                
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <hr className="my-4" />
                                {/* Start */}
                                <div className="py-4">
                                    <div className="row py-2">
                                        <div className="col-12 col-md-6 mx-auto">
                                            <label style={{fontSize:"14px"}}>تاريخ البدية</label>
                                            <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto">
                                            <label style={{fontSize:"14px"}}> تاريخ النهاية </label>
                                            <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} className="form-control" />
                                        </div>
                                    </div>


                                    <div className="form-group mx-auto">
                                        <label style={{fontSize:"14px"}}>ملاحظات</label>
                                        <textarea value={notes} onChange={e => setNotes(e.target.value)} className="form-control"></textarea>
                                    </div>

                                </div>
                                {/* End of Branches */}



                                <div className="submiting d-flex">
                                    <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => onSaveDate(e)}>حفظ</button>
                                    <Link to="/fiscal_years" type="button" className="btn btn-primary mx-2 px-4">تراجع</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default UpdateFiscal
