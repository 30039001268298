import React, { useContext, useState } from "react";
import { ProductContext } from "../../context/context";
import MaterialTable from "material-table"
import { useHistory } from 'react-router-dom';

import searchIcon from "../../images/assets/15.png";
import EditIcon from "../../images/assets/11.png";
import delIcon from "../../images/assets/13.png";

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import { useTranslation } from "react-i18next";

function DataTableMatruial() {
    const { suppliersGroup = [], DeleteSupplierGroup } = useContext(ProductContext);
    const { t, i18n } = useTranslation();
    const history = useHistory();




    let dataCloumAr = [
        {
            title: t("setting"),
            field: "edit",
            cellStyle: { color: "#000", textAlign: "center" },
            render: (rowData) => {
                return (
                    <React.Fragment>
                        <button className="btn py-1 mx-1 px-0" onClick={() => DeleteSupplierGroup(rowData)}>
                            <img src={delIcon} className="iconMax" title="del row" alt="" />
                        </button>
                        <button className="btn  py-1 mx-1 px-0" onClick={() => history.push(`/suppliersGroups/edit/${rowData.id}`)}>
                            <img src={EditIcon} className="iconMax" title="edit row" alt="" />
                        </button>
                    </React.Fragment>
                );
            },
        },
        {
            title: t("Notes"),
            field: "notes",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("nameen"),
            field: "nameE",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("namear"),
            field: "nameA",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("code"),
            field: "code",
            cellStyle: { color: "#000", textAlign: "center" },
        },
    ];

    let dataCloumEn = [
        {
            title: t("code"),
            field: "code",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("namear"),
            field: "nameA",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("nameen"),
            field: "nameE",
            cellStyle: { color: "#000", textAlign: "center" },
        },

        {
            title: t("Notes"),
            field: "notes",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("setting"),
            field: "edit",
            cellStyle: { color: "#000", textAlign: "center" },
            render: (rowData) => {
                return (
                    <React.Fragment>
                        <button className="btn py-1 mx-1 px-0" onClick={() => DeleteSupplierGroup(rowData)}>
                            <img src={delIcon} className="iconMax" title="del row" alt="" />
                        </button>
                        <button className="btn  py-1 mx-1 px-0" onClick={() => history.push(`/suppliersGroups/edit/${rowData.id}`)}>
                            <img src={EditIcon} className="iconMax" title="edit row" alt="" />
                        </button>
                    </React.Fragment>
                );
            },
        },
    ];

    const [columns, setColumns] = useState(i18n.language == "en" ? dataCloumEn : dataCloumAr);


    return (
        <div style={{ maxWidth: "100%" }}>
            <MaterialTable
                columns={columns}
                data={suppliersGroup}
                title={null}
                style={{ backgroundColor: "#a9becc", fontWeight: "bold", color: "red !important" }}
                localization={{
                    toolbar: {
                        exportCSVName: "Export CSV",
                        exportPDFName: "Export PDF"
                    }
                }}

                icons={{
                    FirstPage: () => <FirstPage />,
                    LastPage: () => <LastPage />,
                    NextPage: () => <ChevronRight />,
                    PreviousPage: () => <ChevronLeft />,
                    Search: () => <img src={searchIcon} className="iconMax" alt="" />,
                    Clear: () => <Clear />,
                    DeleteOutline: () => <DeleteOutline />,
                    Export: () => <DeleteOutline />,
                }}

                options={{
                    search: true,
                    actionsColumnIndex: 1,
                    paginationType: "stepped",
                    headerStyle: {
                        backgroundColor: "rgb(169 190 204 / 71%)",
                        boxShadow: "1px 1px 1px 1px #dddddd85",
                        fontWeight: "bold"
                    },
                }}

            />
        </div>
    )
}

export default DataTableMatruial
