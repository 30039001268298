import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ProductContext } from '../../context/context';
import text from '../../context/super.txt';
import logo from '../../context/image.png';
import * as SummaryReports from "../../SummaryReports/SummaryReports.module.css";

const getToken = () => localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : '';


let MostData = new Headers();
MostData.append("Content-Type", "application/json");
MostData.append("Authorization", `Bearer ${getToken()}`);

function Print() {
    const { getUserData } = useContext(ProductContext);
    const [data, setData] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        let items = JSON.parse(window.parameters);
        if (items) {
            fetch(text)
                .then(r => r.text())
                .then(text => {
                    let isCheck = text.includes("Afaky#");
                    if (isCheck) {
                        let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                        let myHeaders = new Headers();
                        myHeaders.append("accept", "text/plain");
                        let requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        fetch(newUrl, requestOptions)
                            .then(response => response.text())
                            .then(resultUrl => {
                                request(resultUrl)
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        request(text)
                    }
                });
        }
    }, [window.parameters])

    const request = (api) => {
        let { companyId } = getUserData();
        fetch(`${api}/Unit/Print`, {
            method: 'POST',
            headers: MostData,
            redirect: 'follow',
            body: JSON.stringify({
                companyId: companyId
            }),
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setData(result.data)
                document.querySelector(".loading").classList.add("hideloader");
            })
            .catch(error => {
                document.querySelector(".loading").classList.add("hideloader");
                console.log('error', error)
            });
    }
    const printData = () => {
        window.focus();
        window.print();
    };

    return (


        <div className={`${SummaryReports.containerHeigth} p-0 mx-0`}>

            <div className="row mx-auto p-0">
                <div className="col-12 mx-auto head my-3 px-0">
                    <div className="text-center">
                        <img src={logo} width="120" height="auto" alt="logo company" />
                    </div>
                    <table className="table my-3" dir='rtl'>
                        <thead>
                            <tr className={`${SummaryReports.element}`}>
                                <th className={`${SummaryReports.element}`}>{t("code")}</th>
                                <th className={`${SummaryReports.element}`}>{t("namear")}</th>
                                <th className={`${SummaryReports.element}`}>{t("nameen")}</th>
                                <th className={`${SummaryReports.element}`}>{t("Notes")}</th>
                                <th className={`${SummaryReports.element}`}>{t("codeTax")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(el => {
                                return (
                                    <tr key={data.length} className={`${SummaryReports.element}`}>
                                        <td className={`${SummaryReports.element}`}>{el.code}</td>
                                        <td className={`${SummaryReports.element}`}>{el.nameA}</td>
                                        <td className={`${SummaryReports.element}`}>{el.nameE}</td>
                                        <td className={`${SummaryReports.element}`}>{el.noted}</td>
                                        <td className={`${SummaryReports.element}`}>{el.taxCode}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>



                <div className='col-12 mx-auto'>
                    <button className='btn btn-primary btn-block w-100' onClick={() => printData()}> Print </button>
                </div>
            </div>

        </div>
    )
}

export default Print