import React, { useContext } from 'react'
import { ProductContext } from '../../context/context';

function ListMainGroups({ ListMainGroups = [], setItemsGroupsFilter, setChildGroupsFilter, ChildGroupsFilter, setChildGroupsFiltering }) {
    const { DefinitionItemsT: Items = [] } = useContext(ProductContext);
    const FilterGroups = ({ id }) => {
        let itemsFiltered = Items.filter(item => item.groupId === id);
        let ChildsFiltered = ChildGroupsFilter.filter(item => item.groupIdParent === id);
        setItemsGroupsFilter(itemsFiltered)
        setChildGroupsFiltering(ChildsFiltered)
    }

    return (
        <div className="list-groups">
            {ListMainGroups.map((row, idx) => {
                return (
                    <button key={idx} className="child-list" onClick={() => FilterGroups({ id: row.groupId })}>
                        <h6 className="title-list">{row.groupName.substring(0, 12)}</h6>
                    </button>
                )
            })}
        </div>
    )
}

export default ListMainGroups