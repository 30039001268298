import React, { useContext, useState } from 'react'
import Layout from "../../Layout";
import { Link } from "react-router-dom"
import { ProductContext } from '../../context/context';

function AddGroupSuppliers() {
    const { customers, AddSuppliersGroup } = useContext(ProductContext);
    // const [category, setCategory] = useState([]);
    const [code, setCode] = useState('');
    const [name_ar, setName_Ar] = useState('');
    const [name_en, setName_EN] = useState('');
    const [cat, setCat] = useState('');
    const [checked, setChecked] = useState(false);

    

    const Categories = (e) => {
        e.preventDefault();
        AddSuppliersGroup(name_ar, name_en, cat, checked, setName_Ar, setName_EN, setChecked)
    }


    return (
        <Layout>
            <div className="company text-right font-ar">
                <div className="container-fluid py-4">
                    <div className="row mx-0">
                        <div className="col-12 col-md-8 mx-auto px-0">
                            <form dir="rtl">
                                <div className="form-group mx-auto">
                                    <label>الكود</label>
                                    <input type="number" value={code} className="form-control" disabled onChange={(e) => setCode(e.target.value)} />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <label>الاسم بالعربي</label>
                                        <input type="text" value={name_ar} className="form-control" onChange={(e) => setName_Ar(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label>الاسم بالانجليزي</label>
                                        <input type="text" value={name_en} className="form-control" onChange={(e) => setName_EN(e.target.value)} />
                                    </div>
                                </div>

                                <div className="form-check mt-3">
                                    <input className="form-check-input" checked={checked} type="checkbox" onClick={() => setChecked(!checked)}  id="checkParent" />
                                    <label className="form-check-label mr-4" for="checkParent">
                                        متفرع
                                    </label>
                                </div>


                                {/*  */}
                                <select className="form-control my-3" value={cat} onChange={e => setCat(e.target.value) }>
                                    <option>القسم</option>
                                    {customers.filter(item => item.checked == true ).map(( cat, i )=> {
                                        return <option key={i} value={cat.name_ar}> {cat.name_ar} </option>
                                    })}
                                </select>

                                <div className="submiting d-flex">
                                    <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => Categories(e)}>حفظ</button>
                                    <Link to="/suppliers" className="btn btn-primary mx-2 px-4">تراجع</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AddGroupSuppliers
