import MaterialTable from "material-table"
import React, { useState, useContext } from "react"
import { ProductContext } from "../../context/context";
import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { RiUserFollowLine } from "react-icons/ri";
import searchIcon from "../../images/assets/15.png";
import EditIcon from "../../images/assets/11.png";
import delIcon from "../../images/assets/13.png";

import { Link, useHistory } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Clear, DeleteOutline, FirstPage, LastPage } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

function DataTableMatruial({ itemsRefrence = {} }) {

    const { DefinitionItemsF, DelDefinitionItems } = useContext(ProductContext);
    let { chkDelete, chkModify, chkTrackingUsers, chkReview } = itemsRefrence;


    const history = useHistory();



    const { t, i18n } = useTranslation();
    let dataCloumAr = [{
        title: t("setting"),
        field: "edit",
        cellStyle: { color: "#000", textAlign: "center" },
        render: (rowData) => {
            return (
                <React.Fragment>
                    <button className="btn py-1 mx-1 px-0" onClick={() => DelDefinitionItems(rowData)}>
                        <img src={delIcon} className="iconMax" title="del row" alt="" />
                    </button>
                    {/* <button className="btn  py-1 mx-1 px-0" onClick={() => history.push(`/DefinitionItems/item/${rowData.id}`)}>
                         <img src={EditIcon} className="iconMax" title="edit row" alt="" />
                     </button>
                     <button className="btn py-1 mx-1 px-0" onClick={() => Print(rowData)}>
                        <img src={printerIcon} className="iconMax" title="print row" alt="" />
                    </button> */}

                </React.Fragment>
            );
        },
    },
    // {
    //     title: "سعر البيع",
    //     field: "salesValue",
    //     cellStyle: { color: "#000", textAlign: "center" },
    // },
    {
        title: "النوع",
        field: "itemTypeName",
        cellStyle: { color: "#000", textAlign: "center" },
    },
    {
        title: "المجموعة",
        field: "itemGroupName",
        cellStyle: { color: "#000", textAlign: "center" },
    },

    {
        title: "اسم الصنف",
        field: "nameA",
        cellStyle: { color: "#000", textAlign: "center" },
    },
    {
        title: "كود الصنف",
        field: "code",
        cellStyle: { color: "#000", textAlign: "center" },
    },
    ];

    let dataCloumEn = [
        {
            title: "Code Item",
            field: "code",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: "Name Item",
            field: "nameE",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: "Group",
            field: "itemGroupName",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: "Type",
            field: "itemTypeName",
            cellStyle: { color: "#000", textAlign: "center" },
        },

        // {
        //     title: "Sales Price",
        //     field: "salesValue",
        //     cellStyle: { color: "#000", textAlign: "center" },
        // },

        {
            title: t("setting"),
            field: "edit",
            cellStyle: { color: "#000", textAlign: "center" },
            render: (rowData) => {
                return (
                    <React.Fragment>
                        <button className="btn btn-danger py-1 mx-1 px-0" onClick={(e) => DelDefinitionItems(rowData.ftid)}>
                            <img src={delIcon} className="iconMax" title="edit row" alt="" />
                        </button>
                        {/*<button className="btn btn-info py-1 mx-1 px-0" onClick={() => history.push(`/Units`)}>
                            <img src={EditIcon} className="iconMax" title="edit row" alt="" />
                        </button>
                         <button className="btn btn-primary py-1 mx-1 px-0" onClick={() => Print(rowData)}>
                            <img src={printerIcon} className="iconMax" title="edit row" alt="" />
                        </button> */}
                        {/* <button className="btn py-1 mx-1 px-0" data-toggle="modal" data-target="#UsersFollower" onClick={() => getUsersFollower(rowData)}>
                            <img src={searchIcon} className="iconMax" title="print row" alt="" />
                        </button> */}
                    </React.Fragment>
                );
            },
        },
    ];
    const [columns, setColumns] = useState(i18n.language == "en" ? dataCloumEn : dataCloumAr);



    return (

        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={columns}
                data={chkReview ? DefinitionItemsF.itemsForm : []}
                style={{ backgroundColor: "#a9becc", fontWeight: "bold", color: "red !important" }}
                localization={{
                    toolbar: {
                        exportCSVName: "Export CSV",
                        exportPDFName: "Export PDF"
                    }
                }}
                icons={{
                    FirstPage: () => <FirstPage />,
                    LastPage: () => <LastPage />,
                    NextPage: () => <ChevronRight />,
                    PreviousPage: () => <ChevronLeft />,
                    Search: () => <img src={searchIcon} className="iconMax" alt="" />,
                    Clear: () => <Clear />,
                    DeleteOutline: () => <DeleteOutline />,
                    Export: () => <DeleteOutline />,
                }}

                options={{
                    search: true,
                    actionsColumnIndex: 1,
                    paginationType: "stepped",
                    headerStyle: {
                        backgroundColor: "rgb(169 190 204 / 71%)",
                        boxShadow: "1px 1px 1px 1px #dddddd85",
                        fontWeight: "bold"
                    },
                }}
            />
        </div>
    )
}

export default DataTableMatruial
