import React from "react";
import Layout from "../../Layout";
import { Link } from "react-router-dom";
import DataTableMatruial from "./DataTableMatruial";

function Stores() {
    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-11 mx-auto my-3 text-center">
                        <div className="text-right mb-2"> 
                            <Link to="/stores/add-store" className="text-light btn btn-primary py-0"> New </Link>
                        </div>
                        <DataTableMatruial />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Stores
