import MaterialTable from "material-table"
import React, { useState, useEffect } from "react"




function DataTable() {

    useEffect(() => {
        const getData = () => {
            fetch("https://jsonplaceholder.typicode.com/users")
                .then(response => response.json())
                .then(json => {
                    setUsers(json)
                });
        };

        getData();
    }, []);

    const [users, setUsers] = useState([]);

    const [columns, setColumns] = useState([
        { title: 'م', field: 'id',cellStyle: { textAlign: "center"}},
        { title: 'الاسم', field: 'name', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'المجموعة الرئيسية', field: 'username', cellStyle: {  color: '#000', textAlign: "center"} },
        // { title: 'حفظ', field: 'username',render: rowData => <button className="btn btn-success py-0">Save </button> },
        
    ])


    const delRow = id => {
        setUsers(users.filter(item => item.id != id))
    }

    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={columns}
                data={users}
                title={null}
                options={{
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    }
                }}
                actions={
                    [
                        {
                            icon: 'edit',
                            tooltip: 'تعديل',
                            onClick: (event, rowData) => delRow(rowData.id)
                        },
                        {
                            icon: 'delete',
                            tooltip: 'حذف',
                            onClick: (event, rowData) => delRow(rowData.id)
                        },
                        {
                            icon: 'save',
                            tooltip: 'تتبع المستخدمين',
                            onClick: (event, rowData) => delRow(rowData.id)
                        },
                    ]}
                    options={{
                        actionsColumnIndex: -1
                    }}
                // options={{
                //     paging:true,
                //     pageSize: 5,    // make initial page size
                //     emptyRowsWhenPaging: true,   //to make page size fix in case of less data rows
                //     pageSizeOptions:[6,12,20,50],    // rows selection options
                // }}
            />
        </div>
    )
}

export default DataTable
