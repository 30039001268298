const ARABIC = {
    afakyProgram: "برنامج افاقي المحاسبي",
    afakyDescription: "برنامج محاسبة عربي سهل لضبط المحاسبة و المخزون من إنتاج شركة افاقى لتقنية المعلومات يعمل مع جميع العملات العالمية بإمكان أي شخص التعلم عليه اقل من ساعتين دون خبرة سابقة في المحاسبة المالية وهو يعطي تقارير متنوعة جدا هو حصيلة خبرة طويلة و أول إصدار منه عام 2008 ويتم تحديثة باستمرار بالاعتماد على خبرة الشركة المتراكمة ومراعاة لطلبات الزبائن",
    afakyMainSite: "الموقع الرسمي",
    license: "ترخيص النظام",
    help: "المساعدة",
    versionNumber: "رقم الاصدار",
    customerName: "اسم العميل",
    securityCode :"كود الحماية",
    securityUnLoakCode :"كود فك الحماية",
    licensestatus: "حالة الترخيص",
    Send: "موافق",
    m: "م",


    hello: "مرحبا",
    nameSystem: "افاقي",
    nameTag: "افاقي ويب",
    username: ' المستخدم',
    pass: 'كلمة المرور',
    remember: "تذكرني",
    login: "دخول الي النظام",
    descTag: " إحدى منتجات شركة افاقى لتقنية المعلومات",
    system: "النظام",
    company: "الشركات",
    companyS: "الشركة",
    branches: "الفروع",
    branche: "الفرع",
    fiscal_years: "السنوات المالية",
    period: "الفترات المحاسبية",
    curreny: "عملات النظام",
    country: "الدول",
    cities: "المدن",
    regions: "المناطق",
    nationality: "الجنسيات",
    suppliers_group: "مجموعات الموردين",
    suppliers: "الموردين",
    customers_groups: "مجموعات العملاء",
    customers: "العملاء",
    sponsors: "الكفلاء",
    employees: "الموظفين",
    employee: "الموظف",
    change_employee_branch: "تغيير فرع الموظف",
    send_SMS: "رسائل الجوال",
    change_financail_year: "تحديد السنة الفعلية",
    users: "المستخدمين",
    alarms_setting: "اعدادات التنبيهات",
    alarms_today: "تنبيهات اليوم",
    Database: "قاعدة البيانات",
    // alarms_setting: "alarms setting",
    log_out: "تسجيل الخروج",
    exit: "انهاء",
    setting: "الاعدادات",
    general_settings: "الاعدادات العامة",
    PC_setting: "اعدادات الجهاز",
    accounts: "الحسابات",
    counting: "الدفاتر",
    journal_voucher_daily: "journal voucher daily",
    voucher_daily: "voucher daily",
    Financial_transactions: "المعاملات المالية",
    cash: "نقدي",
    Banks: "البنوك",
    receipt_voucher: "سند قبض عام",
    numJV: 'رقم الحركة',
    receipt: "سند قبض",
    transfer: "سند صرف",
    alcash: "النقدية",

    CashReceiptVoucher: "سند قبض نقدي",
    CashPaymentVoucher: "سند صرف نقدي",


    BankReceiptVoucher: "سند قبض بنكي",
    BankPaymentVoucher: "سند صرف بنكي",



    transfer_voucher: "سند صرف عام",
    reports: "reports",
    account_statement: "account statement",
    paper_catch_and_pay: "Paper catch and pay",
    paper_catch: "paper catch",
    paper_pay: "paper pay",
    paper_catch_change_status: "paper catch change status",
    bank: "bank",
    paper_status: "paper status",
    paper_catch_report: "paper catch report",
    paper_pay_report: "paper pay report",
    stores: "المخازن",
    definition_stores: "definition stores",
    definition_items: "definition items",
    stores_opening_balance: "stores opening balance",
    assemble_item: "assemble item",
    packing_item: "packing item",
    receipt_goods: "receipt goods",
    exchange_goods: "exchange goods",
    transfer_of_goods: "transfer of goods",
    transfer_of_goods_linked_to_receipt: "transfer of goods - linked to receipt",
    receipt_of_goods_transfer: "receipt of goods transfer",
    execution_of_goods: "execution of goods",
    inventory_minutes: "inventory minutes",
    stocktaking: "stocktaking",
    reserve_quantities: "reserve quantities",
    release_quantities: "release quantities",
    item_balance: "item balance",
    follow_cost: "follow cost",
    item_serial_query: "item serial query",
    barcode_print: "barcode print",
    modify_items_prices: "modify items prices",
    adoption: "adoption",
    Purchase: "Purchase",
    Purchase_orders: "Purchase orders",
    Purchase_deals: "Purchase deals",
    Purchase_invoice: "Purchase invoice",
    returned_purchase: "returned Purchase",
    notices_supplier: "notices supplier",
    sales: "مبيعات",
    commissioms_policy: "commissioms policy",
    commissioms_salesman: "commissioms salesman",
    quotations: "quotations",
    sales_orders: "sales orders",
    sales_invoice: "sales invoice",
    returned_sales: "returned sales",
    customer_notices: "customer notices",
    review_invoices: "review invoices",
    item_price_query: "استعلام اسعار الاصناف",
    offers: "offers",
    moreData: "المزيد",
    define_units: "تعريف الوحدات",
    define_groups: "تعريف المجموعات",
    define_item: "تعريف الاصناف",
    POS: "نقاط البيع",
    POSPoint: "فتح نقطة بيع ",
    sellOrder: "امر بيع",
    point_of_sale_settings: "اعدادات نقاط البيع",
    Cost_Center_Directory: "دليل مراكز التكلفة",
    definition_stores: "تعريف المخازن",
    journalVoucherDaily: "يوميات القيود اليومية",

    accounSstatement: "كشف حساب",
    reports: 'التقارير',
    debtRecoveryCustomer: "اعمار الديون - عميل",
    debtRecoverySuppliers: "اعمار الديون - مورد",
    categoryCard: "كارت الصنف",
    mainunit: "اصغر وحدة",
    defaultunitpurchase: "افتراضى مشتريات",
    defaultunitsales: "افتراضى مبيعات",

    SalesInvoiceSummary: "ملخص فواتير المبيعات",
    PurchaseInvoiceSummary: "ملخص فواتير المشتريات",
    PurchaseInvoice: " فاتورة المشتريات ",
    PurchaseReturned: "مرتجع مشتريات",
    SalesInvoice: " فاتورة المبيعات ",
    SalesReturned: " مرتجع مبيعات ",


    // point of sales
    name: "الاسم",
    namear: "الاسم عربي",
    nameen: "الاسم انجليزي",
    code: "كود",
    unit: "الوحدة",
    number: "العدد",
    qtn: "الكمية",

    price: "السعر",
    discount: "خصم",
    vat: "ض.ق.م",
    vatTable: "ض.ج",
    vat2: "ضريبة القيمة المضافة",
    total: "الاجمالي",
    Totalaftertax: "الاجمالي بعد الضريبة",
    setting: "الاعدادات",
    nettotal: 'الصافي',
    totalbeforetax: "الاجمالي قبل الضريبة",
    discountitems: 'خصم الاصناف',
    Accountstatement: "كشف حساب",
    beginning: "بداية التاريخ",
    endofhistory: "نهاية التاريخ",
    customerPos: "عميل",
    print: "طباعة",
    save: "حفظ",
    edit: "تعديل",
    view: "عرض",
    barcode: "باركود",
    history: "التاريخ",
    source: "المصدر",
    Nusource: "رقم المصدر",
    Debtor: "مدين",
    Creditor: "دائن",
    Balance: "الرصيد",
    customerNamePos: "اسم العميل",
    previousbalance: "الرصيد السابق",
    amountPaid: "المبلغ المدفوع",
    amount: "المبلغ ",
    Residual: "المتبقي",
    description: "الوصف",
    cashcollection: "تحصيل نقدي",
    pay: "دفع",
    Pendingorders: "الطلبات المعلقة",
    cancel: "الغاء",
    Suspension: "تعليق",
    saleinvoice: "فاتورة بيع",
    Returns: "المرتجعات",
    Retur: "مرتجعات",
    independentmovement: "حركة مستقلة",
    Retreat: "تراجع",
    movementtype: "نوع الحركة",
    recovery: "استرجاع",
    date: "التاريخ",
    Check: "اختر",
    addcustomernew: "اضافة عميل جديد",
    mobile: "موبيل",
    add: "اضافة",
    status: "الحالة",
    box: "اسم الحساب",
    boxnum: "رقم الحساب",
    registrationNumber: "رقم القيد",
    num: "الرقم",
    accountName: "اسم الحساب",
    certified: "معتمد",
    notsupported: "غير معتمد",
    currenyType: "العملة",
    sy: "س.ص",
    bondowner: "صاحب السند",
    salesRepresentative: "مندوب البيع",
    type: 'النوع',
    costCenter: "مركز التكلفة",
    dateInvoice: "تاريخ الفاتورة",
    numebrInvoice: "رقم الفاتورة",
    Advanceamount: "مبلغ السلفة",
    duedate: "تاريخ الاستحقاق",
    Notdisplayingzerobalanceaccounts: "عدم عرض الحسابات التي رصيدها صفر",
    Accumulatingtheopening: "تجميع الرصيد الافتتاحي في حالة اختيار اكتر من فرع",
    all: "الكل",
    settingPOSPrint: "اعدادات طباعة نقطة البيع",
    Prescriptionprintsettings: "اعدادات طباعة الروشتة",
    display: "شاشة العرض",
    Thisprinter: "هذة الطابعة ",
    Itemgroup: "مجموعة الصنف",
    Item: " الصنف",
    restaurantprintersettings: "اعدادات طابعة المطعم",
    active: "تنشيط",
    Showthepriceinthekitchenbill: "عرض السعر في فاتورة المطبخ",
    groupMain: "المجموعة الرئيسية",
    printaftersaving: "الطباعة بعد الحفظ",
    printfromweb: "الطباعة من الموقع ",
    Combinedprinter: "الطابعة المجمعة",
    effectiveyear: "تحديد السنة الفعالة",
    accountReports: "تقارير الحسابات",
    usernameCustomerar: "اسم العميل (عربي)",
    usernameCustomeren: "اسم العميل (English)",
    POSPointR: "فتح نقطة بيع مطاعم",


    cachPayment: "كاش",
    visaPayment: "فيزا",
    mastercardPayment: "مستر كارد",
    aglPayment: "اجل",
    SummaryReports: "الفواتير – ملخص",
    Reports: "تقارير",
    ItemsBalanceReports: "ارصدة الاصناف - مجموعات الاصناف",
    stockValuation: "تقييم المخزون",
    // ---
    Telephone: "تليفون",
    Fax: "فاكس",
    Email: "البريد الالكتروني",
    address: "العنوان",
    tax_registration_number: " رقم التسجيل الضريبي ",
    VAT_registration_number: "رقم التسجيل بضريبة القيمة المضافة",
    Notes: "ملاحظات",
    webSite: "موقع الانترنت",
    File_No_Commercial_Registry: "رقم الملف - السجل التجاري",
    amount: "الكمية",
    simplified_tax_invoice: "فاتورة ضريبية مبسطة",
    Tax_Number: "الرقم الضريبي",
    Commercial_Register: " السجل التجاري",
    customer: "عميل",
    Thanks: "شكرا لزيارتك .. نراكم قريبا ",
    thenumberofpieces: "عدد القطع",
    amountamount: "المبلغ ",
    category_card: "كارت الصنف",

    itemCard: "كارت الصنف",
    itemCardW: "كارت الصنف بدون تكلفة",

    // 
    print: "طباعة",
    ordersDevelery: "طلبات التوصيل",
    tables: "الطاولات",
    orderNum: "رقم الطلب",
    printerSettings: "اعدادات الطابعة",
    invoicePrinting: "طباعة الفاتورة",
    numberOfCopies: "عدد النسخ",
    kitchenPrint: "طباعة المطبخ",
    onlyPrintVariables: "طباعة المتغيرات فقط",
    // 
    arrangeGroupsAndItems: "ترتيب المجموعات والاصناف في شاشة فتح نقطة بيع",
    input: "الادخال",
    alphabetic: "ابجدي",
    Code: "الكود",
    // 
    customerGroup: "مجموعة العملاء",
    SupplierGroup: "مجموعة الموردين",
    CreateSupplierGroup: "اضافة مجموعة موردين",
    CreateCustomerGroup: "اضافة مجموعة عملاء",
    createUnit: "اضافة تعريف وحدات",
    // 
    viewall: "عرض الكل",
    salesAndReturns: "مبيعات ومرتجعات",

    store: "المخزن",
    group: "المجموعة",
    mainGroup: "المجموعة الرئيسية",
    groupOfItems: "مجموعة الاصناف",
    items: "الاصناف",
    ItemCompanies: "شركات الاصناف",
    Itemcode: "كود الصنف",
    Itemname: "اسم الصنف",

    thesmallestunit: "اصغر وحدة",
    defaultPurchases: "افتراضي مشتريات",
    defaultSales: "افتراضي مبيعات",

    AllitemsbalanceZero: "كل الاصناف - رصيد الصنف بصفر",
    AllitemsbalanceNotZero: "كل الاصناف - رصيد الصنف ليس بصفر",
    Negativeitemsonly: "الاصناف السالبة فقط",
    Positiveitemsonly: "الاصناف الموجبة فقط",

    costType: "نوع التكلفة",
    cost: "التكلفة",
    totalCost: "اجمالي التكلفة",
    movementsduring: "الحركات خلال الفترة",
    ChooseItem: "اختر الصنف",
    local: "محلي",
    delevery: "توصيل خارجي",
    safary: "سفري",


    RestaurantScreenSettings: "اعدادات شاشة المطعم",
    activate: "تنشيط",
    IPAdress: "رقم Ip",

    arabic: "اللغة العربية",
    english:"اللغة الانجليزية",
    french:"اللغة الفرنسية",

    // Units

    definctionUnit : "تعريف الوحدات",
    codeTax: "كود الربط مع الضرائب",

    transactionDate: "تاريخ الحركة",
    transactionTypeName: "نوع المعاملة",

    byadministrator: "بواسطة مدير النظام",
    followeUser: "تتبع المستخدمين",

    WriteEnglishArabic: "كتابة الاسم انجليزي مثل الاسم عربي",
}

export default ARABIC;