import React, { useRef, useState, useContext, useMemo, useEffect } from "react";
import Table from "./Table";
import { FcSearch } from "react-icons/fc";
import TablePay from "./TablePay";
import PayTabs from "./PayTabs";
import { ProductContext } from "../../context/context";
import Doms from "../../images/dom_Trim.mp4";
import { BsFillPersonPlusFill } from "react-icons/bs";
import PrintNumber from "./PrintNumber";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import $ from 'jquery';


function SaleInvoice() {
    const { t, i18n } = useTranslation();
    const { DefinitionItemsT, CashCollection, mostData = [], user, token, config, HoldInvoice, QuickVoucher, QuickVoucherGetBalance, BalanceRemaining, lang, getMostData, allRefrence = [] } = useContext(ProductContext);
    const inputRef = useRef();
    const userRef = useRef();
    const userBarCode = useRef();
    const nameCustomerRef = useRef(); // كشف حساب
    const nameCustomerPromissRef = useRef(); // كشف حساب


    useEffect(() => {
        document.getElementById("mostClick").addEventListener("click", (e) => {
            if (e.target.id == "openUser") {
                if (document.querySelector(".list-group-filter-search-1")) {
                    document.querySelector(".list-group-filter-search-1").classList.remove("d-none");
                    document.querySelector(".list-group-filter-search-2").classList.remove("d-none");
                }
            } else {
                if (document.querySelector(".list-group-filter-search-1")) {
                    document.querySelector(".list-group-filter-search-1").classList.add("d-none");
                    document.querySelector(".list-group-filter-search-2").classList.add("d-none");
                }
            }
        });
    }, []);


    // terms
    const [typePrint, setTypePrint] = useState(false);
    const [posPintAfterSave, setPosPintAfterSave] = useState(false);
    const [Dates, setDates] = useState({});
    const [modify, setModify] = useState(false);
    const [openPOS, setOpenPOS] = useState(false);
    const [modifyPrice, setModifyPrice] = useState(false);
    const [modifyDiscount, setModifyDiscount] = useState(false);
    const [modifyQuantity, setModifyQuantity] = useState(false);
    const [returned, setReturned] = useState(false);
    const [changeAccount, setChangeAccount] = useState(false);
    const [deleteAddedRow, setDeleteAddedRow] = useState(false);
    const [invoiceHold, setInvoiceHold] = useState(false);
    const [cancelCheque, setCancelCheque] = useState(false);
    const [stores, setStores] = useState([]);
    const [storesId, setStoresId] = useState(1);
    const [close, setClose] = useState(false);
    const [settingCompany, setSettingCompany] = useState({});

    const [receiptV, setReceiptV] = useState(false);
    const [accountV, setAccountV] = useState(false);


    // Holds invoice 
    const [AllHolds, setAllHolds] = useState([]);

    const [search, setSearch] = useState("");
    const [searchId, setSearchId] = useState("");
    const [salesManId, setSalesManId] = useState("");
    const [taxSourceRate, setTaxSourceRate] = useState(0); // ضريبة المنبع

    window.onload = function () {
        getMostData();
        window.document.body.classList.add('overwrite')
    }
    const [fractions, setFractions] = useState(2);

    useEffect(() => {
        if (mostData.length !== 0) {
            document.querySelector(".loading").classList.add("hideloader");
            let userD = mostData.customerList.find(customer => customer.posDefaultCusCash == true);
            if (userD) {
                setSearch(userD.customerName)
                setSearchId(userD.id)
            }
            setFractions(mostData.companyList[0].fractions)
            // console.log(mostData.companyList[0].fractions);
            setModify(mostData.modifyItem)
            setOpenPOS(mostData.openPOS)
            setModifyPrice(mostData.modifyPrice)
            setModifyQuantity(mostData.modifyQuantity)
            setReturned(mostData.returned)
            setChangeAccount(mostData.changeAccount)
            setDeleteAddedRow(mostData.deleteAddedRow)
            setInvoiceHold(mostData.invoiceHold)
            setCancelCheque(mostData.cancelCheque)
            setAllHolds(mostData.invoiceHodeList)
            setModifyDiscount(mostData.modifyDiscount)
            setStores(mostData.storesList)
            setStoresId(mostData.storesList.find(st => st.defaultStore) ? mostData.storesList.find(st => st.defaultStore).storeId : 1);
            setClose(false)
            setSettingCompany(mostData.companyList[0])
            setTaxSourceRate(mostData.companyList[0].taxSourceRate)
            setTypePrint(mostData.printFromFrontEnd)
            setPosPintAfterSave(mostData.posPintAfterSave)
            setDates(mostData)
        } else {
            setClose(true)
        }
    }, [mostData])

    useEffect(() => {
        if (allRefrence.length !== 0) {
            setReceiptV(allRefrence.find(item => item.mName === "TsmFinancialTransactions").showForm ? allRefrence.find(item => item.mName === "TsmFinancialTransactions").showForm : false);
            setAccountV(allRefrence.find(item => item.mName === "TsmAccountStatement").showForm ? allRefrence.find(item => item.mName === "TsmAccountStatement").showForm : false)
        }
    }, [allRefrence])


    // terms
    // مرتجعات
    const [returnList, setReturnList] = useState([]);
    const [returnListS, setReturnListS] = useState([]);
    const [InvoicedIdList, setInvoicedIdList] = useState(0);
    // مرتجعات
    const [taxTableRate, setTaxTableRate] = useState(0);
    const [falseed, setFalseed] = useState(false);

    const [returnBtn, setReturnBtn] = useState(false);
    const [returnBtnHold, setReturnBtnHold] = useState(false);
    const [typeInvoice, setTypeInvoice] = useState(10);
    const [PriceAfterDiscount, setPriceAfterDiscount] = useState(0);
    const [total, setTotal] = useState(0);
    const [totalCheck, setTotalCheck] = useState(0);
    const [list, setList] = useState([]);
    const [options, setOptions] = useState([]);
    // vise
    const [cach, setCach] = useState("");
    const [visa, setVisa] = useState("");
    const [masterCard, setMasterCard] = useState(0);
    const [credit, setCredit] = useState("");
    const [totalCredit, setTotalCredit] = useState("");
    const [totalMatserCard, setTotalMatserCard] = useState(0);
    const [listPayments, setListPayments] = useState([]);

    // add customer state
    const [userCustomer, setUserCustomer] = useState("");
    const [mobilsUser, setMobilsUser] = useState("");

    // كشف حساب
    const [CustomersStatementList, setCustomersStatementList] = useState([]);
    const [CustomersStatement, setCustomersStatement] = useState([]);
    const [nameCustomerStatement, setNameCustomerStatement] = useState("");
    const [IdCustomerStatement, setIdCustomerStatement] = useState("");
    const [StartDataCustomerStatement, setStartDataCustomerStatement] = useState(new Date());
    const [EndDataCustomerStatement, setEndDataCustomerStatement] = useState(new Date());

    const [totalDiscount, setTotalDiscount] = useState(0); //  اجمالي الخصم 
    const [totalBeforeTax, setTotalBeforeTax] = useState(0); // اجمالي السعر قبل الضريبة
    const [totalTaxAd, setTotalTaxAd] = useState(0); // اجمالي ضريبة القيمة المضافة
    const [totalTaxAdPresent, setTotalTaxAdPresent] = useState(0); // اجمالي ضريبة القيمة المضافة

    // سند القبض
    const [CustomersPromiss, setCustomersPromiss] = useState([]);
    const [nameCustomerPromiss, setNameCustomerPromiss] = useState("");
    const [IdCustomerPromiss, setIdCustomerPromiss] = useState("");
    const [balancePromiss, setBalancePromiss] = useState(0);
    const [viewBalanceRemaining, setViewBalanceRemaining] = useState(0);
    const [descriptionVoucher, setDescriptionVoucher] = useState("");
    // المندوب

    const countries = useMemo(() => {
        if (!search) return [];
        return mostData.customerList.filter((country) => {
            return country.customerName.toLowerCase().includes(search.toLowerCase());
        });
    }, [search, mostData]);

    const CUstomerNames = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setCustomersStatement([]);
        } else {
            setCustomersStatement(
                mostData.customerList.filter((option) =>
                    option.customerName.toLowerCase().includes(val.toLowerCase())
                )
            );
        }
    };

    const PromissNames = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setCustomersPromiss([]);
        } else {
            setCustomersPromiss(mostData.customerList.filter((option) => option.customerName.toLowerCase().includes(val.toLowerCase())));
        }
    };

    const handelChange = (e) => {
        let val = e.target.value;
        if (e.target.value == "") {
            setOptions([]);
        } else {
            setOptions(DefinitionItemsT.filter((option) => option.itemName.toLowerCase().includes(val.toLowerCase())));
        }
    };

    // search for code invoice in returned
    const handelChangeInvoiceCode = (e) => {
        let val = e.target.value;
        // console.log(val);

        if (e.target.value == "") {
            setReturnList([]);
        } else {
            setReturnList(returnListS.filter((option) => option.invoiceCode.toLowerCase().includes(val.toLowerCase())));
        }
    }; // search for code invoice in returned

    // add item to list
    const AddList = async (automaticDiscount, itemId, itemCode, tatolPrice = 0, unitId, discount = 0, salesDiscountType, taxRate, tableTaxRate, qtn = 1) => {
        let Check = list.find((item) => item.itemId === itemId);
        let Check31 = list.filter((item) => item.itemId === itemId);
        let Check33 = Check31.find((item) => item.unitId === unitId);
        let CheckLast1 = list.filter((item) => item.itemId === itemId);
        let CheckLast = CheckLast1.find((item) => item.unitId === unitId);
        
        console.log("qtn ", qtn);


        
        if (automaticDiscount === false) {
            discount = 0;
        } else {
            if (salesDiscountType === 2) {
                discount = (tatolPrice * discount) / 100
            }
        }


        if (Check) {
            Check.quantity = qtn;
            if (Check.unitId == unitId) {
                Check33.quantity += 1;
                let vallll = 0; // ضريبة القيمة المضافة
                let vallll2 = 0; // الجدول
                if (settingCompany.useTaxSales == true) {
                    vallll = (((+Check33.quantity * +Check33.price) - +Check33.discount1) * (Check33.taxRate / 100));
                    // let vallllNew = Math.floor(+vallll).toFixed(4);
                    Check33.taxValue += vallll;
                    let total78 = ((+Check33.quantity * +Check33.price) - +Check33.discount1); // اجمالي الصنف بعد ض ج 
                    Check33.tatolItem = total78 + vallll; // ض ق الجديدة 
                }
                if (settingCompany.useTableTaxSales == true) {
                    vallll2 = (((+Check33.quantity * +Check33.price) - +Check33.discount1) * (Check33.tableTaxPercent / 100));
                    Check33.tableTaxValue += vallll2;
                }
                if (settingCompany.nTaxTFees) { // done new version
                    Check33.tatolItem = ((+Check33.quantity * +Check33.price) - +Check33.discount1) + vallll + vallll2;
                }
                if (settingCompany.nTaxTFees == false) {
                    let ss2 = ((+Check33.quantity * +Check33.price) - +Check33.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
                    let newsss = ss2 * (taxRate / 100); // ض ق الجديدة 
                    Check33.tatolItem = ss2 + newsss; // ض ق الجديدة 
                }

                Check33.total = (Check33.price * Check33.quantity);
                Check33.netPrice = (Check33.price * Check33.quantity) - +Check33.discount1;
                // Check33.netPrice = (Check33.price * Check33.quantity);
                Check33.totalAfterDiscount1 = Check33.netPrice;
                Check33.totalAfterDiscount2 = Check33.netPrice;
                Check33.totalAfterDiscount3 = Check33.netPrice;
                Check33.discountPercent1 = (Check33.discount * 100) / Check33.price;
                Check33.discountPercent2 = (Check33.discount * 100) / Check33.price;
                Check33.discountPercent3 = (Check33.discount * 100) / Check33.price;
                TotaBeforeTaxAdFun();
                setTotalTaxAdPresent(state => state + Check33.taxRate)
                TotaBill()
            } else {
                // console.log("1-2");
                if (!CheckLast) {
                    let Items = DefinitionItemsT.filter((item) => item.itemId == itemId);
                    let Item = Items.find((item) => item.unitId == unitId);
                    CheckLast.quantity = qtn;
                    let ItemCreate = {
                        itemId: Item.itemId,
                        itemCode,
                        unitId,
                        name: Item.itemName,
                        quantity: qtn,
                        price: +tatolPrice,
                        discount: +discount,
                        discount1: +discount,
                        tatolItem: 0,
                        unitName: Item.unitName,
                        storeId: storesId,
                        taxValue: 0,
                        invoiceType: 10,
                        id: 0,
                        total: tatolPrice,
                        discountPercent1: (discount * 100) / tatolPrice,
                        totalAfterDiscount1: tatolPrice,
                        discount2: 0,
                        discountPercent2: 0,
                        totalAfterDiscount2: tatolPrice,
                        discount3: 0,
                        discountPercent3: 0,
                        totalAfterDiscount3: tatolPrice,
                        netPrice: tatolPrice,
                        description: "",
                        totalQuantity: 0,
                        taxRate: taxRate,
                        taxValue: 0,
                        tableTaxPercent: tableTaxRate,
                        tableTaxValue: 0,
                        additionalCost: 0,
                        freeQuantity: 0,
                        sectionId: 0,
                        colorId: 0,
                        sortId: 0,
                        cost: 0,
                        productionDate: "2021-10-11T14:46:13.985Z",
                        expiryDate: "2021-10-11T14:46:13.985Z",
                        serial: "",
                        bodyNo: "",
                        model: "",
                        boardNo: "",
                        motorNo: "",
                        jobNo: "",
                        guaranteeDate: "2021-10-11T14:46:13.985Z",
                        psalesPrice: 0,
                        pmsalesPrice: 0,
                        salesManId: 0,
                        publicPrice: 0,
                        taxValueBefore: 0,
                        cubic: 0,
                        hidePrice: false,
                        height: 0,
                        width: 0,
                        heaving: 0,
                        icount: 0,
                        dayUseId: 0,
                        todayDosesId: 0,
                        id1: 0,
                        idDTL: 0
                    };

                    // 
                    let vallll = 0; // ضريبة القيمة المضافة
                    let vallll2 = 0; // الجدول

                    if (settingCompany.useTaxSales == true) {
                        vallll = (((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) * (taxRate / 100));
                        let vallllNew = Math.floor(+vallll).toFixed(4);
                        ItemCreate.taxValue = vallllNew;
                        console.log("ضريبة قيمة مضافة");
                    }
                    if (settingCompany.useTableTaxSales == true) {
                        vallll2 = (((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) * (tableTaxRate / 100));
                        ItemCreate.tableTaxValue = vallll2;
                        console.log("ضريبة [جدول]");
                    }

                    if (settingCompany.nTaxTFees) { // done new version
                        ItemCreate.tatolItem = ((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) + vallll + vallll2;
                    } // done new version


                    if (settingCompany.nTaxTFees == false) {
                        let ss2 = ((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
                        let newsss = ss2 * (taxRate / 100); // ض ق الجديدة 
                        ItemCreate.tatolItem = ss2 + newsss; // ض ق الجديدة 
                    }
                    // 
                    ItemCreate.total = (+ItemCreate.quantity * +ItemCreate.price);
                    // ItemCreate.netPrice = (+ItemCreate.quantity * +ItemCreate.price);
                    ItemCreate.netPrice = (+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1;
                    // ItemCreate.netPrice = ItemCreate.tatolItem; 
                    ItemCreate.totalQuantity = ItemCreate.quantity;
                    ItemCreate.totalAfterDiscount1 = (+ItemCreate.quantity * +ItemCreate.price) - ItemCreate.discount1;
                    ItemCreate.totalAfterDiscount2 = (+ItemCreate.quantity * +ItemCreate.price) - ItemCreate.discount1;
                    ItemCreate.totalAfterDiscount3 = (+ItemCreate.quantity * +ItemCreate.price) - ItemCreate.discount1;
                    setList((state) => [...state, ItemCreate]);
                    setTotalCheck((state) => state + +ItemCreate.tatolItem)
                    setTaxTableRate(satte => satte + +vallll2)
                    setPriceAfterDiscount((satte) => satte + ItemCreate.price);
                    setTotalDiscount(state => state + +ItemCreate.discount1)
                    setTotalBeforeTax(state => state + ((+ItemCreate.quantity * +ItemCreate.price) - ItemCreate.discount1))
                    setTotalTaxAd(state => state + vallll)
                    setTotalTaxAdPresent(state => state + taxRate)
                    if (list.length == 0) {
                        console.log("list 0");
                        if (settingCompany.useTaxSourceSales == false) {
                            setTotal((ItemCreate.total - ItemCreate.discount1) + vallll + vallll2);
                            // console.log(ItemCreate.total);
                        } else {
                            setTotal((ItemCreate.total - ItemCreate.discount1) - (ItemCreate.total * taxSourceRate / 100) + vallll + vallll2);
                            // console.log(total);
                        }

                    } else {

                        setTotal(state => state + (ItemCreate.total - ItemCreate.discount1));
                    }
                    TotaBill();


                }
                else {
                    console.log("here 5");
                    CheckLast.quantity += 1;
                    // // 
                    let vallll = 0; // ضريبة القيمة المضافة
                    let vallll2 = 0; // الجدول
                    if (settingCompany.useTaxSales == true) {
                        vallll = (((+CheckLast.quantity * +CheckLast.price) - +CheckLast.discount1) * (taxRate / 100));
                        let vallllNew = Math.floor(+vallll).toFixed(4);
                        CheckLast.taxValue = vallllNew;
                        console.log("ضريبة قيمة مضافة");
                    }
                    if (settingCompany.useTableTaxSales == true) {
                        vallll2 = (((+CheckLast.quantity * +CheckLast.price) - +CheckLast.discount1) * (tableTaxRate / 100));
                        CheckLast.tableTaxValue = vallll2;
                        console.log("ضريبة [جدول]");
                    }
                    if (settingCompany.nTaxTFees) { // done new version
                        CheckLast.tatolItem = ((+CheckLast.quantity * +CheckLast.price) - +CheckLast.discount1) + vallll + vallll2;
                    } // done new version
                    if (settingCompany.nTaxTFees == false) {
                        let ss2 = ((+CheckLast.quantity * +CheckLast.price) - +CheckLast.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
                        let newsss = ss2 * (taxRate / 100); // ض ق الجديدة 
                        CheckLast.tatolItem = ss2 + newsss; // ض ق الجديدة 
                    }
                    // 

                    CheckLast.tatolItem = ((+CheckLast.quantity * +tatolPrice) - (CheckLast.discount * CheckLast.quantity)) + vallll + vallll2;
                    CheckLast.total = (+CheckLast.quantity * +tatolPrice);
                    CheckLast.netPrice = (+CheckLast.quantity * +tatolPrice) - +CheckLast.discount1;
                    // CheckLast.netPrice = (+CheckLast.quantity * +tatolPrice);
                    CheckLast.totalAfterDiscount1 = ((+CheckLast.quantity * +tatolPrice) - (CheckLast.discount * CheckLast.quantity)) + vallll + vallll2;
                    CheckLast.totalAfterDiscount1 = CheckLast.netPrice;
                    CheckLast.totalAfterDiscount2 = CheckLast.netPrice;
                    CheckLast.totalAfterDiscount3 = CheckLast.netPrice;
                    setList((state) => [...state]);
                    TotaBill()
                    setTotalCheck(total + +CheckLast.netPrice)
                    // setPriceAfterDiscount((satte) => satte + CheckLast.price);
                }
            }
        }
        else {
            // console.log("here 1");
            let Items = DefinitionItemsT.filter((item) => item.itemId === itemId);
            let Item = Items.find((item) => item.unitId === unitId);

            let ItemCreate = {
                itemId: Item.itemId,
                itemCode,
                unitId,
                name: Item.itemName,
                quantity: qtn,
                price: +tatolPrice,
                discount: +discount,
                discount1: +discount,
                tatolItem: 0,
                unitName: Item.unitName,
                storeId: storesId,
                tableTaxValue: 0,
                invoiceType: 10,
                id: 0,
                total: 0,
                discountPercent1: tatolPrice != 0 ? (discount * 100) / tatolPrice : 0,
                totalAfterDiscount1: tatolPrice,
                discount2: 0,
                discountPercent2: 0,
                totalAfterDiscount2: tatolPrice,
                discount3: 0,
                discountPercent3: 0,
                totalAfterDiscount3: tatolPrice,
                netPrice: tatolPrice,
                description: "",
                totalQuantity: 1,
                taxRate: taxRate,
                taxValue: 0,
                tableTaxPercent: tableTaxRate,
                additionalCost: 0,
                freeQuantity: 0,
                sectionId: 0,
                colorId: 0,
                sortId: 0,
                cost: 0,
                productionDate: "2021-10-11T14:46:13.985Z",
                expiryDate: "2021-10-11T14:46:13.985Z",
                serial: "",
                bodyNo: "",
                model: "",
                boardNo: "",
                motorNo: "",
                jobNo: "",
                guaranteeDate: "2021-10-11T14:46:13.985Z",
                psalesPrice: 0,
                pmsalesPrice: 0,
                salesManId: 0,
                publicPrice: 0,
                taxValueBefore: 0,
                cubic: 0,
                hidePrice: false,
                height: 0,
                width: 0,
                heaving: 0,
                icount: 0,
                dayUseId: 0,
                todayDosesId: 0,
                id1: 0,
                idDTL: 0
            };
            let vallll = 0; // ضريبة القيمة المضافة
            let vallll2 = 0; // الجدول

            let totalQtnPrice = (+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1;
            if (settingCompany.useTaxSales === true) {
                vallll = +totalQtnPrice * (taxRate / 100);
                ItemCreate.taxValue = vallll;
            }
            if (settingCompany.useTableTaxSales === true) {
                vallll2 = +totalQtnPrice * (tableTaxRate / 100);
                ItemCreate.tableTaxValue = vallll2;
            }
            if (settingCompany.nTaxTFees) { // done new version
                ItemCreate.tatolItem = +totalQtnPrice + vallll + vallll2;
            } // done new version

            if (settingCompany.nTaxTFees === false) {
                let ss2 = +totalQtnPrice + vallll2; // اجمالي الصنف بعد ض ج 
                let newsss = ss2 * (taxRate / 100); // ض ق الجديدة 
                ItemCreate.tatolItem = ss2 + newsss; // ض ق الجديدة 
            }

            ItemCreate.total = (+ItemCreate.quantity * +ItemCreate.price);
            ItemCreate.netPrice = +totalQtnPrice;
            ItemCreate.totalQuantity = ItemCreate.quantity;
            ItemCreate.totalAfterDiscount1 = +totalQtnPrice;
            ItemCreate.totalAfterDiscount2 = +totalQtnPrice;
            ItemCreate.totalAfterDiscount3 = +totalQtnPrice;

            setList((state) => [...state, ItemCreate]);
            setTotalCheck((state) => state + +ItemCreate.tatolItem)
            setTaxTableRate(satte => satte + +vallll2)
            setPriceAfterDiscount((satte) => satte + ItemCreate.price);
            setTotalDiscount(state => state + +ItemCreate.discount1)
            setTotalBeforeTax(state => state + ((+ItemCreate.quantity * +ItemCreate.price)))
            setTotalTaxAd(state => state + vallll)
            setTotalTaxAdPresent(state => state + taxRate)
            if (list.length === 0) {
                if (settingCompany.useTaxSourceSales === false) {
                    setTotal((ItemCreate.total - ItemCreate.discount1) + vallll + vallll2);
                } else {
                    setTotal((ItemCreate.total - ItemCreate.discount1) - (ItemCreate.total * taxSourceRate / 100) + vallll + vallll2);
                }
            } else {
                setTotal(state => state + (ItemCreate.total - ItemCreate.discount1) + vallll + vallll2);
            }
            // TotaBill()
        }

    }; // add item to list

    // delete item from list
    const DelItem = (code, unitId, idx) => {
        list.splice(idx, 1);
        setList(list);
        setTotalCheck(total);
        TotaTaxTableRate()
        TotaDiscountBill()
        TotaBeforeTaxAdFun()
        TotaBill()
    }; // delete item from list

    // increment qunatity item 
    const AddNumber = (code, tatolItem, unitId) => {
        let lists = [...list];
        let Items = lists.filter((item) => item.itemId == code);
        let listItems = Items.find((item) => item.unitId == unitId);
        listItems.quantity += 1;
        let vallll = 0; // ضريبة القيمة المضافة
        let vallll2 = 0; // الجدول

        listItems.discount = (listItems.quantity * listItems.price) * listItems.discountPercent1 / 100;
        listItems.discount1 = listItems.discount;
        listItems.netPrice = (listItems.quantity * listItems.price) - listItems.discount;
        // listItems.tatolItem = listItems.netPrice;

        if (settingCompany.useTaxSales == true) {
            vallll = (listItems.netPrice * listItems.taxRate) / 100;
        }
        if (settingCompany.useTableTaxSales == true) {
            vallll2 = (listItems.netPrice * listItems.tableTaxPercent) / 100;
        }
        if (settingCompany.nTaxTFees) { // done new version
            listItems.tatolItem = listItems.netPrice + listItems.tableTaxValue + listItems.taxValue;
        } // done new version
        if (settingCompany.nTaxTFees == false) {
            let ss2 = listItems.netPrice + vallll2; // اجمالي الصنف بعد ض ج 
            let newsss = (ss2 * listItems.taxRate) / 100; // ض ق الجديدة 
            listItems.tatolItem = ss2 + newsss; // ض ق الجديدة 
        }
        console.log(settingCompany);

        listItems.taxValue = vallll;
        listItems.total = (listItems.price * listItems.quantity);
        // listItems.netPrice = (listItems.price * listItems.quantity) - (listItems.discount1 * listItems.quantity);
        // listItems.netPrice = (listItems.price * listItems.quantity) - listItems.discount1;

        // listItems.discountPercent1 = ((listItems.discount1 * 100) / listItems.price);
        listItems.totalAfterDiscount1 = listItems.netPrice;
        listItems.totalAfterDiscount2 = listItems.netPrice;
        listItems.totalAfterDiscount3 = listItems.netPrice;
        setList(lists);
        TotaTaxTableRate()
        TotaBeforeTaxFun()
        TotaDiscountBill()
        TotaBeforeTaxAdFun();
        TotaBeforeTaxAdFun()
        TotaBill()
    };// increment qunatity item 

    // decrement qunatity item 
    const MinusNumber = (code, unitId) => {
        let lists = [...list];
        let Items = lists.filter((item) => item.itemId == code);
        let listItems = Items.find((item) => item.unitId == unitId);

        listItems.quantity -= 1;
        let vallll = 0; // ضريبة القيمة المضافة
        let vallll2 = 0; // الجدول

        listItems.discount = (listItems.quantity * listItems.price) * listItems.discountPercent1 / 100;
        listItems.discount1 = listItems.discount;
        listItems.netPrice = (listItems.quantity * listItems.price) - listItems.discount;
        // listItems.tatolItem = listItems.netPrice;

        if (settingCompany.useTaxSales == true) {
            vallll = (listItems.netPrice * listItems.taxRate) / 100;
        }
        if (settingCompany.useTableTaxSales == true) {
            vallll2 = (listItems.netPrice * listItems.tableTaxPercent) / 100;
        }
        if (settingCompany.nTaxTFees) { // done new version
            listItems.tatolItem = listItems.netPrice + listItems.tableTaxValue + listItems.taxValue;
        } // done new version
        if (settingCompany.nTaxTFees == false) {
            let ss2 = listItems.netPrice + vallll2; // اجمالي الصنف بعد ض ج 
            let newsss = (ss2 * listItems.taxRate) / 100; // ض ق الجديدة 
            listItems.tatolItem = ss2 + newsss; // ض ق الجديدة 
        }
        console.log(settingCompany);

        listItems.taxValue = vallll;
        listItems.total = (listItems.price * listItems.quantity);
        // listItems.netPrice = (listItems.price * listItems.quantity) - (listItems.discount1 * listItems.quantity);
        // listItems.netPrice = (listItems.price * listItems.quantity) - listItems.discount1;

        // listItems.discountPercent1 = ((listItems.discount1 * 100) / listItems.price);
        listItems.totalAfterDiscount1 = listItems.netPrice;
        listItems.totalAfterDiscount2 = listItems.netPrice;
        listItems.totalAfterDiscount3 = listItems.netPrice;
        setList(lists);
        TotaTaxTableRate()
        TotaBeforeTaxFun()
        TotaDiscountBill()
        TotaBeforeTaxAdFun();
        TotaBeforeTaxAdFun()
        TotaBill()
    }; // decrement qunatity item 

    // تعليق
    const Hold = (InvoicedIdList, returnBtn, searchId, list, setList, total, PriceAfterDiscount, setTotal, setPriceAfterDiscount, setSearch, inputRef) => {
        HoldInvoice(InvoicedIdList, returnBtn, searchId, list, setList, total, PriceAfterDiscount, setTotal, setPriceAfterDiscount, setSearch, inputRef);
    };

    // الغاء
    const Cancel = () => {
        document.querySelector('.listsReturned').classList.add('d-none');
        document.querySelector('.SalePostionSearch').classList.remove('backgroundReturned');
        document.getElementById('InvoiceCodeing').value = "";
        let returnedV = document.querySelectorAll('.returnedV');
        returnedV.forEach(item => item.classList.remove('d-none'));
        setReturnList([])
        inputRef.current.value = "";
        userRef.current.value = "";
        userBarCode.current.value = "";
        setOptions([]);
        setList([]);
        setTotal(0);
        setTotalCheck(0);
        setCach(0);
        setVisa(0);
        setMasterCard(0);
        setReturnBtn(false)
        setTaxTableRate(0)
        setTotalTaxAdPresent(0)
        setTotalBeforeTax(0)
        setTotalTaxAd(0)
        setTotalDiscount(0)
        setCredit('')
        setTotalMatserCard(0)
        $('#masterCardFromPay').val(0)
        $('#visaFromPay').val(0)
        $('#creditFromPay').val(0)
        $('#cachFromPay').val(0)
    };

    // value cach
    const onChangeCach = (e) => {

        let num = +e.target.value;
        document.getElementById('ChachId').innerHTML = +num;
        let obj = {
            "paymentId": 5,
            "paymentType": 5,
            "paymentValue": +num,
            "accountId": ""
        }
        if (masterCard == 0) {
            if (e.target.value == 0) {
                setListPayments(listPayments.filter(payment => payment.paymentId != 5))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 5);
                if (tests) {
                    tests.paymentValue = +e.target.value;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        } else {
            if (e.target.value == 0) {
                setMasterCard(0);
                setListPayments(listPayments.filter(payment => payment.paymentId != 5))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 5);
                if (tests) {
                    tests.paymentValue = +num;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        }
        CalculationValues()

    }; // value cach

    // value visa
    const onChangeVisa = (e) => {
        let num = +e.target.value;
        document.getElementById('visaId').innerHTML = +num;
        let obj = {
            "paymentId": 3,
            "paymentType": 3,
            "paymentValue": +num,
            "accountId": ""
        }
        if (masterCard == 0) {
            if (e.target.value == 0) {
                setListPayments(listPayments.filter(payment => payment.paymentId != 3))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 3);
                if (tests) {
                    tests.paymentValue = +e.target.value;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        } else {
            if (e.target.value == 0) {
                setMasterCard(0);
                setListPayments(listPayments.filter(payment => payment.paymentId != 3))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 3);
                if (tests) {
                    tests.paymentValue = +num;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        }
        // ****
        CalculationValues()
    };

    const onChangeMasterCard = (e) => {
        let num = +e.target.value;
        document.getElementById('masterCardIdId').innerHTML = +num;

        let obj = {
            "paymentId": 2,
            "paymentType": 2,
            "paymentValue": +num,
            "accountId": ""
        }
        if (masterCard == 0) {
            if (e.target.value == 0) {
                setListPayments(listPayments.filter(payment => payment.paymentId != 2))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 2);
                if (tests) {
                    tests.paymentValue = +e.target.value;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        } else {
            if (e.target.value == 0) {
                setListPayments(listPayments.filter(payment => payment.paymentId != 2))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 2);
                if (tests) {
                    tests.paymentValue = +e.target.value;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        }
        // ****
        CalculationValues()
    };// value visa

    // credit
    const onChangeCredit = (e) => {
        let num = e.target.value;
        document.getElementById('creditId').innerHTML = +num;

        let obj = {
            "paymentId": 6,
            "paymentType": 6,
            "paymentValue": +num,
            "accountId": ""
        }

        if (e.target.value == "") {
            setTotalCredit(totalCheck)
            setCredit("");
            setListPayments(listPayments.filter(payment => payment.paymentId != 6))
        } else {
            setCredit(e.target.value);
            let newTotal = total - +e.target.value;
            setTotalCredit(newTotal)
            let testKda = [...listPayments];
            let tests = testKda.find(payment => payment.paymentId == 6);
            if (tests) {
                tests.paymentValue = +e.target.value;
                setListPayments(testKda)
            } else {
                testKda.push(obj);
                setListPayments(testKda)
            }
        }
        CalculationValues()
    };// value credit

    const CalculationValues = () => {
        let cash = document.getElementById('ChachId').innerHTML;
        let visa = document.getElementById('visaId').innerHTML;
        let masterCard = document.getElementById('masterCardIdId').innerHTML;
        let credit = document.getElementById('creditId').innerHTML;
        let total = +cash + +visa + +masterCard + +credit;
        setMasterCard(total);
    }

    const onSaveCredit = () => {
        let cash = $('#cachFromPay').val();
        let visa = $('#visaFromPay').val();
        let master = $('#masterCardFromPay').val();
        let credit = $('#creditFromPay').val();

        let totalPaid = $('.isCheckTotal').html();

        if (cash != "" && visa != "" && master != "" && credit != "") {
            let collection = +cash + +visa + +master + +credit;
            if (collection == totalPaid) {
                CashCollection(
                    Cancel,
                    totalTaxAdPresent,
                    totalTaxAd,
                    InvoicedIdList,
                    returnBtn ? true : false,
                    searchId,
                    list,
                    total,
                    listPayments,
                    2,
                    typeInvoice,
                    typePrint,
                    posPintAfterSave,
                    storesId
                )
            } else {
                alert('من فضلك تاكد من ان صافي الدفعات يساوي اجمالي الدفعات');
            }
        } else {
            CashCollection(
                Cancel,
                totalTaxAdPresent,
                totalTaxAd,
                InvoicedIdList,
                returnBtn ? true : false,
                searchId,
                list,
                total,
                listPayments,
                2,
                typeInvoice,
                typePrint,
                posPintAfterSave,
                storesId
            )
        }

    }

    // form quantity
    const SubForm = (e) => {
        e.preventDefault();
        let snd = new Audio(Doms); // buffers automatically when created
        let sepretor = '';


        

        if (DefinitionItemsT.length != 0) {
            if (DefinitionItemsT[0].barcodeSeparator) {
                sepretor = DefinitionItemsT[0].barcodeSeparator[0];
                // console.log("barcodeSeparator:", DefinitionItemsT[0].barcodeSeparator[0]);
            }
        }

        let val = document.getElementById("barCodeSearch").value;
        console.log("val" , val);
        console.log("settingCompany is ", settingCompany);


        if (val.search(sepretor) != -1) {
            val = val.slice(0, val.search("@"));
        }
        let val2With = 1;
        let text = val;
        if(val.startsWith(settingCompany.itemCodeStartWith)) {
            let myLength = settingCompany.itemCodeStartWith.length + settingCompany.itemCodeLength;
            val2With = val;
            text = val2With.slice(0 , myLength)
            console.log("text 1 ", text);
            val2With = val2With.slice(myLength);
            console.log("here 1 ", val2With);
            val2With = val2With.slice(0, val2With.length - 1)
            console.log("here 2 ", val2With);
            val2With = val2With  / settingCompany.weightFactorDivision;
            console.log("here 3 ", val2With);
        }
        console.log("val2" , val);
        console.log("val2With" , val2With);
        console.log("text" , text);
        val = text;
        let Item = DefinitionItemsT.find((item) => item.itemCode == val); // search by code
        let Item2 = DefinitionItemsT.find((item) => item.itemId == val); // search by id
        let Item3 = DefinitionItemsT.find((item) => item.barCode == String(val)); // search by barcode

        if (Item) {
            Item.quantity = val2With;
            AddList(
                Item.automaticDiscountS,
                Item.itemId,
                Item.itemCode,
                Item.salesValue,
                Item.unitId,
                Item.salesDiscountValue,
                Item.salesDiscountType,
                Item.taxRate,
                Item.tableTaxRate,
                val2With,
            );
            document.getElementById("barCodeSearch").value = "";
        }
        else if (Item2) {
            Item2.quantity = val2With;
            AddList(
                Item2.automaticDiscountS,
                Item2.itemId,
                Item2.itemCode,
                Item2.salesValue,
                Item2.unitId,
                Item2.salesDiscountValue,
                Item2.salesDiscountType,
                Item2.taxRate,
                Item2.tableTaxRate,
                val2With,

            );
            document.getElementById("barCodeSearch").value = "";
        }
        else if (Item3) {
            Item3.quantity = val2With;
            AddList(
                Item3.automaticDiscountS,
                Item3.itemId,
                Item3.itemCode,
                Item3.salesValue,
                Item3.unitId,
                Item3.salesDiscountValue,
                Item3.salesDiscountType,
                Item3.taxRate,
                Item3.tableTaxRate,
                val2With,
            );
            document.getElementById("barCodeSearch").value = "";
        }
        else {
            snd.play();
            document.getElementById("barCodeSearch").value = "";
            setTimeout(() => {
                alert("لا يوجد هذا الصنف");
            }, 400);
        }
    }; // form quantity

    // model new customer
    const NewUserCustomer = () => {
        setSearch(userCustomer);
        let namear = document.querySelector('.customerNewNamear').value;
        let nameen = document.querySelector('.customerNewNameen').value;
        let txtFile1 = document.querySelector('.txtFile1').value;
        let txtFile2 = document.querySelector('.txtFile2').value;
        let mobileFile = document.querySelector('.mobileFile').value;
        let fax = document.querySelector('.faxFile').value;
        let emailFile = document.querySelector('.emailFile').value;
        let siteFile = document.querySelector('.siteFile').value;
        let addressFile = document.querySelector('.addressFile').value;
        let NumTax = document.querySelector('.NumTax').value;
        // let fileNum = document.querySelector('.fileNum').files[0];
        let fileNum = document.querySelector('.fileNum').value;
        let NumTaxPlus = document.querySelector('.NumTaxPlus').value;
        let notesFile = document.querySelector('.notesFile').value;

        let dataUser = {
            companyId: user.companyId,
            id: 0,
            formType: 51,
            code: "",
            groupId: 0,
            nameA: namear,
            nameE: nameen,
            tel1: txtFile1,
            tel2: txtFile2,
            mobile: mobileFile,
            fax: fax,
            email: emailFile,
            site: siteFile,
            address: addressFile,
            accountId: "",
            notes: notesFile,
            countryId: 0,
            cityId: 0,
            regionId: 0,
            workDate: "2022-01-05T14:47:17.820Z",
            workDateHj: "",
            iscustSupp: false,
            isDelegate: false,
            allowCreditLimit: true,
            creditLimit: 0,
            status: false,
            currencyId: 0,
            delegateId: 0,
            responsiblePerson: "",
            accountIdLoan: "",
            accountIdPettyCash: "",
            openingBalance: 0,
            debitCredit: true,
            dueDate: "2022-01-05T14:47:17.820Z",
            dueDateHj: "string",
            cpid: 0,
            taxRegistrationNo: NumTax,
            fileNo: fileNum,
            taxesNature: 0,
            posdefaultCusCash: false,
            discountPercent: 0,
            cpidSales: 0,
            dueDays: 0,
            mainCustomerId: 0,
            salesPolicyId: 0,
            region: "",
            floorNo: "",
            apartment: "",
            storeId: 0,
            itemsMaximumId: 0,
            complyMaximumItems: false,
            paperCatch: "",
            paperPay: "",
            workingHours: 0,
            numberId: "",
            sourceId: "",
            jobId: 0,
            issueDateId: "2022-01-05T14:47:17.820Z",
            issueDateHjid: "",
            expiryDateId: "2022-01-05T14:47:17.820Z",
            expiryDateHjid: "",
            numberP: "",
            sourceP: "",
            jobP: 0,
            "issueDateP": "2022-01-05T14:47:17.820Z",
            "issueDateHjp": "",
            "expiryDateP": "2022-01-05T14:47:17.820Z",
            expiryDateHjp: "",
            numberWc: "",
            sourceWc: "",
            jobWc: 0,
            "issueDateWc": "2022-01-05T14:47:17.820Z",
            "issueDateHjwc": "",
            "expiryDateWc": "2022-01-05T14:47:17.820Z",
            expiryDateHjwc: "",
            numberSi: "",
            sourceSi: "",
            jobSi: 0,
            "issueDateSi": "2022-01-05T14:47:17.820Z",
            "issueDateHjsi": "",
            "expiryDateSi": "2022-01-05T14:47:17.820Z",
            expiryDateHjsi: "",
            numberDl: "",
            sourceDl: "",
            licenseIdDl: 0,
            "issueDateDl": "2022-01-05T14:47:17.820Z",
            "issueDateHjdl": "",
            "expiryDateDl": "2022-01-05T14:47:17.820Z",
            expiryDateHjdl: "",
            allowancesValue: 0,
            departmentId: 0,
            locationtId: 0,
            employeeJobId: 0,
            religionId: 0,
            statusId: 0,
            gender: 0,
            maritalStatus: 0,
            nationalityId: 0,
            "birthDate": "2022-01-05T14:47:17.820Z",
            birthDateHj: "",
            branchId: 0,
            educationalLevelId: 0,
            bloodTypeId: 0,
            bankId: 0,
            subscribeTypeId: 0,
            workNatureId: 0,
            sponsorsId: 0,
            directManagerId: 0,
            "appointmentDate": "2022-01-05T14:47:17.820Z",
            "appointmentDateHj": "",
            "decadeEndDate": "2022-01-05T14:47:17.820Z",
            decadeEndDateHj: "",
            certification: "",
            patchy: false,
            patchyNotes: "",
            annualLeave: 0,
            emergencyLeave: 0,
            airlinesId: 0,
            linesTravelId: 0,
            degreesTicketsId: 0,
            haveTicket: false,
            ticketCount: 0,
            employeeMachineNo: 0,
            identityPersonalityId: 0,
            personalityId: "",
            pcityId: 0,
            "pissueDate": "2022-01-05T14:47:17.820Z",
            pissueDateHj: "",
            "pexpiryDate": "2022-01-05T14:47:17.820Z",
            pexpiryDateHj: "",
            drivingLicensesId: 0,
            licenseId: "",
            lcityId: 0,
            "lissueDate": "2022-01-05T14:47:17.820Z",
            lissueDateHj: "",
            "lexpiryDate": "2022-01-05T14:47:17.820Z",
            lexpiryDateHj: "",
            guarantorName: "",
            guarantorComputerId: "",
            guarantorTel: "",
            sponsorsId1: 0,
            sponsorsId2: 0,
            driverValue: 0,
            csourceId: 0,
            csnotes: "",
            cpidCoupon: 0,
            cpidSalesCoupon: 0,
            delegateIdC: 0,
            automaticDiscountP: false,
            automaticDiscountS: false,
            paperBounce: "",
            vatno: NumTaxPlus,
            eftspercent: 0,
            efts: false,
            dcontributionTax: false,
            street: "",
            buildingNumber: "",
            postalCode: "",
            room: "",
            bfloor: "",
            landmark: "",
            additionalInformation: "",
            typeTax: ""
        };
        fetch(`${config}/SupCusEmp/SupplierCustomerQuickAdd`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(dataUser),
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                if (result.isSuccess) {
                    setUserCustomer("");
                    setMobilsUser("");
                    mostData.customerList = [...mostData.customerList, result.data];
                    setSearch()
                    setSearch(result.data.customerName);
                    setSearchId(result.data.id);

                    document.querySelector('.customerNewNamear').value = "";
                    document.querySelector('.customerNewNameen').value = "";
                    document.querySelector('.txtFile1').value = "";
                    document.querySelector('.txtFile2').value = "";
                    document.querySelector('.mobileFile').value = "";
                    document.querySelector('.faxFile').value = "";
                    document.querySelector('.emailFile').value = "";
                    document.querySelector('.siteFile').value = "";
                    document.querySelector('.addressFile').value = "";
                    document.querySelector('.NumTax').value = "";
                    document.querySelector('.fileNum').value = "";
                    document.querySelector('.NumTaxPlus').value = "";
                    document.querySelector('.notesFile').value = "";
                    alert(result.message);
                    // getMostData()
                } else {
                    alert(result.message)
                }
            })
            .catch(error => console.log('error', error));




    }; // model new customer

    // on change qunatity from tabel list
    const changeCountItem = (id, qun, unitId) => {
        let lists = [...list];
        let listItemsLists = lists.filter((item) => item.itemId == id);
        let listItems = listItemsLists.find((item) => item.unitId == unitId);
        listItems.quantity = +qun;
        let vallll = 0; // ضريبة القيمة المضافة
        let vallll2 = 0; // الجدول

        listItems.discount = (listItems.quantity * listItems.price) * listItems.discountPercent1 / 100;
        listItems.discount1 = listItems.discount;
        listItems.netPrice = (listItems.quantity * listItems.price) - listItems.discount;
        // listItems.tatolItem = listItems.netPrice;

        if (settingCompany.useTaxSales == true) {
            vallll = (listItems.netPrice * listItems.taxRate) / 100;
        }
        if (settingCompany.useTableTaxSales == true) {
            vallll2 = (listItems.netPrice * listItems.tableTaxPercent) / 100;
        }
        if (settingCompany.nTaxTFees) { // done new version
            listItems.tatolItem = listItems.netPrice + listItems.tableTaxValue + listItems.taxValue;
        } // done new version
        if (settingCompany.nTaxTFees == false) {
            let ss2 = listItems.netPrice + vallll2; // اجمالي الصنف بعد ض ج 
            let newsss = (ss2 * listItems.taxRate) / 100; // ض ق الجديدة 
            listItems.tatolItem = ss2 + newsss; // ض ق الجديدة 
        }
        console.log(settingCompany);

        listItems.taxValue = vallll;
        listItems.total = (listItems.price * listItems.quantity);
        // listItems.netPrice = (listItems.price * listItems.quantity) - (listItems.discount1 * listItems.quantity);
        // listItems.netPrice = (listItems.price * listItems.quantity) - listItems.discount1;

        // listItems.discountPercent1 = ((listItems.discount1 * 100) / listItems.price);
        listItems.totalAfterDiscount1 = listItems.netPrice;
        listItems.totalAfterDiscount2 = listItems.netPrice;
        listItems.totalAfterDiscount3 = listItems.netPrice;
        setList(lists);
        TotaTaxTableRate()
        TotaBeforeTaxFun()
        TotaDiscountBill()
        TotaBeforeTaxAdFun();
        TotaBeforeTaxAdFun()
        TotaBill()
    }; // on change qunatity from tabel list

    // on change price from tabel list
    const changePriceItem = (id, qun, unitId) => {
        let lists = [...list];
        let listItemsLists = lists.filter((item) => item.itemId == id);
        let listItems = listItemsLists.find((item) => item.unitId == unitId);
        listItems.price = +qun;
        let vallll = 0; // ضريبة القيمة المضافة
        let vallll2 = 0; // الجدول

        listItems.discount = (listItems.quantity * listItems.price) * listItems.discountPercent1 / 100;
        listItems.discount1 = listItems.discount;
        listItems.netPrice = (listItems.quantity * listItems.price) - listItems.discount;
        // listItems.tatolItem = listItems.netPrice;

        if (settingCompany.useTaxSales == true) {
            vallll = (listItems.netPrice * listItems.taxRate) / 100;
        }
        if (settingCompany.useTableTaxSales == true) {
            vallll2 = (listItems.netPrice * listItems.tableTaxPercent) / 100;
        }
        if (settingCompany.nTaxTFees) { // done new version
            listItems.tatolItem = listItems.netPrice + listItems.tableTaxValue + listItems.taxValue;
        } // done new version
        if (settingCompany.nTaxTFees == false) {
            let ss2 = listItems.netPrice + vallll2; // اجمالي الصنف بعد ض ج 
            let newsss = (ss2 * listItems.taxRate) / 100; // ض ق الجديدة 
            listItems.tatolItem = ss2 + newsss; // ض ق الجديدة 
        }

        listItems.taxValue = vallll;
        listItems.total = (listItems.price * listItems.quantity);
        // listItems.netPrice = (listItems.price * listItems.quantity) - (listItems.discount1 * listItems.quantity);
        // listItems.netPrice = (listItems.price * listItems.quantity) - listItems.discount1;

        // listItems.discountPercent1 = ((listItems.discount1 * 100) / listItems.price);
        listItems.totalAfterDiscount1 = listItems.netPrice;
        listItems.totalAfterDiscount2 = listItems.netPrice;
        listItems.totalAfterDiscount3 = listItems.netPrice;
        setList(lists);
        TotaTaxTableRate()
        TotaBeforeTaxFun()
        TotaDiscountBill()
        TotaBeforeTaxAdFun();
        TotaBeforeTaxAdFun()
        TotaBill()

    }; // on change price from tabel list

    // on change discount from tabel list
    const changeDiscountItem = (id, qun, unitId) => {
        let lists = [...list];
        let listItemsLists = lists.filter((item) => item.itemId == id);
        let listItems = listItemsLists.find((item) => item.unitId == unitId);
        listItems.discount1 = +qun;
        listItems.discount = +qun;
        let vallll = 0; // ضريبة القيمة المضافة
        let vallll2 = 0; // الجدول
        if (settingCompany.useTaxSales == true) {
            vallll = (((+listItems.quantity * +listItems.price) - +listItems.discount1) * (listItems.taxRate / 100));
            let total1 = ((+listItems.quantity * +listItems.price) - +listItems.discount1); // اجمالي الصنف بعد ض ج 
            listItems.tatolItem = total1 + vallll; // ض ق الجديدة 

        } else if (settingCompany.useTableTaxSales == true) {
            vallll2 = (((+listItems.quantity * +listItems.price) - +listItems.discount1) * (listItems.tableTaxRate / 100));
        } else if (settingCompany.nTaxTFees) { // done new version

            listItems.tatolItem = ((+listItems.quantity * +listItems.price) - +listItems.discount1) + vallll + vallll2;
        } else if (settingCompany.nTaxTFees == false) {

            let ss2 = ((+listItems.quantity * +listItems.price) - +listItems.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
            let newsss = ss2 * (listItems.taxRate / 100); // ض ق الجديدة 
            listItems.tatolItem = ss2 + newsss; // ض ق الجديدة 
        }

        listItems.total = (listItems.price * listItems.quantity);
        listItems.taxValue = vallll;
        listItems.netPrice = (listItems.price * listItems.quantity) - listItems.discount1;


        listItems.discountPercent1 = ((listItems.discount1 * 100) / listItems.price);
        listItems.totalAfterDiscount1 = listItems.netPrice;
        listItems.totalAfterDiscount2 = listItems.netPrice;
        listItems.totalAfterDiscount3 = listItems.netPrice;


        setList(lists);
        TotaDiscountBill()
        TotaTaxTableRate()
        TotaBeforeTaxAdFun();
        TotaBill()
    }; // on change price from tabel list

    // view customer
    const onViewCustomer = () => {
        if (StartDataCustomerStatement == "" || EndDataCustomerStatement == "" || nameCustomerStatement == "") {
            alert('من فضلك تاكد من ادخال البيانات')
        } else {
            fetch(`${config}/PosForm/RptAccountStatementSUPCUT`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "companyId": user.companyId,
                    "sceId": IdCustomerStatement,
                    "dateFrom": StartDataCustomerStatement,
                    "dateTo": EndDataCustomerStatement,
                    "langauge": lang == "en" ? 1 : 0
                }),
                redirect: 'follow'
            })
                .then(response => response.json())
                .then(result => {
                    if (result.isSuccess) {
                        setCustomersStatementList(result.data);
                    }
                })
                .catch(error => console.log('error', error));
        }
    }; // view customer

    // ***********************************************
    const onViewPrint = () => {
        if (typePrint) {
            let parms = { user, data: CustomersStatementList, ArrayBranches: mostData, customer: nameCustomerStatement, dateto: EndDataCustomerStatement, datefrom: StartDataCustomerStatement };
            let handle = window.open(window.location.origin + '/account-statement-printing-quick', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
            handle.window.parameters = JSON.stringify(parms);
        }
    }

    const [pointer, setPointer] = useState("barCodeSearch");

    // change pointer keyboard
    const changePrinter = (num) => {
        let pointerSelect = document.getElementById(pointer);
        if (num != 10) {
            if (pointerSelect) pointerSelect.value += num;
        } else pointerSelect.value = pointerSelect.value.slice(0, -1);
    }; // change pointer keyboard

    const SubFormHidden = (e) => {
        e.preventDefault();
    };

    // مرتجعات returned
    const Returned = () => {
        if (list.length != 0) {
            Hold(InvoicedIdList, returnBtn ? true : false, searchId, list, setList, total, PriceAfterDiscount, setTotal, setPriceAfterDiscount, setSearch, inputRef)
        }
        Cancel()
        let SalePostionSearch = document.querySelector('.SalePostionSearch');
        SalePostionSearch.classList.add('backgroundReturned');
        setReturnBtn(!returnBtn);
        let returnedV = document.querySelectorAll('.returnedV');
        returnedV.forEach(item => {
            item.classList.toggle('d-none')
        });
        document.querySelector('.listsReturned').classList.toggle('d-none')

        fetch(`${config}/PosForm/BtnReturned`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ "companyId": user.companyId, "branchId": user.branchId, "fyId": user.financialYear, "sceId": searchId, "type": 10, "invoiceId": 0 }),
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(list => {
                setReturnListS(list.data)
            })
            .catch(error => console.log('error', error));

    } // مرتجعات returned

    // cancel returned
    const ReturnedCancel = () => {
        Cancel()
    } // cancel returned

    // cancel details
    const returnedDetails = invoiceId => {
        document.querySelector('#InvoiceCodeing').value = "";
        setInvoicedIdList(invoiceId)
        fetch(`${config}/PosForm/BtnReturnedDetails`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ "companyId": user.companyId, "branchId": user.branchId, "fyId": user.financialYear, "sceId": searchId, "type": 10, "invoiceId": invoiceId }),
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(data => {
                let tt = data.data.map((item) => {
                    return (item.quantity * item.price) - item.discount1;;
                }).reduce((acc, curr) => {
                    return acc + curr;
                }, 0);
                let totalBeforeDiscountAndTax = data.data.map((item) => {
                    return item.price * item.quantity;
                })
                    .reduce((acc, curr) => {
                        return acc + curr;
                    }, 0);
                let totalD = data.data.map((item) => {
                    return item.discount1;
                })
                    .reduce((acc, curr) => {
                        return acc + curr;
                    }, 0);
                setTotalDiscount(totalD);
                setList(data.data)
                setReturnList([])
                setTotal(+tt)
                setTotalBeforeTax(totalBeforeDiscountAndTax);
            })
            .catch(error => console.log('error', error));
    } // cancel details

    // ReturnedHoldInvoice
    const ReturnedHoldInvoice = invoiceholdId => {
        setReturnBtnHold(true);
        if (list.length != 0) {
            Hold()
        }
        fetch(`${config}/PosForm/ReturnInvoiceHold`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "invoiceId": invoiceholdId,
                "invoiceCode": ""
            }),
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                if (result.isSuccess) {
                    setList(result.data)
                    getMostData();
                } else {
                    alert(result.message)
                }
                return result.data
            }).then((data) => {
                let totalDataItems = data.map((item) => {
                    return item.netPrice;
                })
                    .reduce((acc, curr) => {
                        return acc + curr;
                    }, 0);
                setTotal(totalDataItems);
            })
            .catch(error => console.log('error', error));
    }

    // calculation total from list
    const TotaBill = () => {

        list.forEach((el) => {
            el.discount = (el.quantity * el.price) * el.discountPercent1 / 100;
            el.discount1 = el.discount;
            el.totalAfterDiscount1 = (el.quantity * el.price) - el.discount;
            el.totalAfterDiscount2 = el.totalAfterDiscount1;
            el.totalAfterDiscount3 = el.totalAfterDiscount1;
            el.netPrice = el.totalAfterDiscount1;
            el.taxValue = (el.netPrice * el.taxRate) / 100;
            el.tableTaxValue = (el.netPrice * el.tableTaxPercent) / 100;
            el.tatolItem = el.netPrice + el.tableTaxValue + el.taxValue;
        });
        let netTotalList = list.map((item) => {
            return item.netPrice;
        }).reduce((acc, curr) => {
            return acc + curr;
        }, 0);


        // اجمالي ضريبة القيمة المضافة
        let taxSalesList = list.map((item) => {
            return item.taxValue;
        }).reduce((acc, curr) => {
            return acc + curr;
        }, 0);
        // اجمالي ضريبة الجدول
        let taxValueTable = list.map((item) => {
            return item.tableTaxValue;
        }).reduce((acc, curr) => {
            return acc + curr;
        }, 0);

        // الاجمالي قبل الضريبة
        let totalBeforeDiscountAndTax = list.map((item) => {
            return item.netPrice;
        }).reduce((acc, curr) => {
            return acc + curr;
        }, 0);

        let totalItemsDiscount = list.map((item) => {
            return item.discount1 * item.quantity;
        }).reduce((acc, curr) => {
            return acc + curr;
        }, 0);
        setTotalDiscount(totalItemsDiscount)
        setTaxTableRate(+taxValueTable);
        if (settingCompany.useTaxSourceSales === false) {
            let sd = netTotalList + taxSalesList + taxValueTable;
            setTotal(sd);
        } else {
            let ggg = netTotalList - (netTotalList * taxSourceRate / 100) + taxSalesList + taxValueTable;
            setTotal(ggg);
        }
        setTotalBeforeTax(totalBeforeDiscountAndTax);
    }; // calculation total from list

    // calculation total from list
    const TotaDiscountBill = () => {
        let total = list.map((item) => {
            return item.discount1;
        })
            .reduce((acc, curr) => {
                return acc + curr;
            }, 0);
        let totalNew = Math.floor(+total).toFixed(4);
        setTotalDiscount(totalNew);
    }; // 

    // calculation total from list
    const TotaBeforeTaxFun = () => {
        let total = list.map((item) => {
            return (item.quantity * item.price) - item.discount1;
        })
            .reduce((acc, curr) => {
                return acc + curr;
            }, 0);
        let totalNew = Math.floor(+total).toFixed(4);

        setTotalBeforeTax(totalNew);
    }; // 

    // calculation total from TotaBeforeTaxAdFun
    const TotaBeforeTaxAdFun = () => {
        let total = list.map((item) => {
            return (((+item.quantity * +item.price) - +item.discount1) * (item.taxRate / 100));
        })
            .reduce((acc, curr) => {
                return acc + curr;
            }, 0);

        // let totalNew = Math.floor(+total).toFixed(4);

        setTotalTaxAd(total);
    }; // 

    // calculation total from list
    const TotaTaxTableRate = () => {
        let total = list.map((item) => {
            return item.tableTaxValue;
        })
            .reduce((acc, curr) => {
                return acc + curr;
            }, 0);
        // let totalNew = Math.floor(+total).toFixed(4);
        setTaxTableRate(+total);
    }; // calculation total from list


    let totaling = 0;
    let totalingDebit = 0;
    let totalingCredit = 0;

    useEffect(() => {
        userBarCode.current.focus();
        window.addEventListener("keydown", (event) => {
            if (event.keyCode == 119) {
                saveF8();
            }
            if (event.keyCode == 27) {
                Cancel();
            }
        });
    }, [window]);

    if (falseed) {
        CashCollection(
            Cancel,
            totalTaxAdPresent,
            totalTaxAd,
            InvoicedIdList,
            returnBtn ? true : false,
            searchId,
            list,
            total,
            listPayments,
            1,
            typeInvoice,
            typePrint,
            posPintAfterSave,
            storesId
        )
        setFalseed(false);
    }

    const saveF8 = () => {
        setFalseed(true)
    }

    const ClearFormAccountStatemnet = () => {
        setCustomersStatementList([]);
        nameCustomerRef.current.value = "";
    }

    const Agl = () => {
        let listPaymentsUpdate = [{
            paymentId: 6,
            paymentType: 6,
            paymentValue: +total,
            accountId: ""
        }]
        CashCollection(Cancel, totalTaxAdPresent, totalTaxAd, InvoicedIdList, returnBtn ? true : false, searchId, list, total, listPaymentsUpdate, 2, typeInvoice, typePrint, posPintAfterSave, storesId)
    }

    const keyCutomer = e => {
        document.querySelector('.customerNewNameen').value = e.target.value;
    }

    return (
        <div className="container-fluid px-0 voiceSale" id="mostClick" style={{ overflow: "hidden" }} >
            <div className={`row mt-2 ${i18n.language == "en" ? "flex-row-reverse" : ""} ${i18n.language == "fr" ? "flex-row-reverse" : ""}`}>
                <div className="col-12 col-md-8 mx-auto SalePostionSearch tabelScroll" style={{ minHeight: "100vh", maxHeight: "100vh", overflowY: "auto" }}>
                    <div className="d-block d-md-none">
                        <div className="userVoice d-flex align-items-center">
                            <input type="search" autoComplete="off" style={{ width: "90%" }} className="form-control search-input-voice" disabled={changeAccount ? false : true} ref={userRef} value={search} onChange={(e) => setSearch(e.target.value)} onClick={() => setPointer("openUser")} id="openUser" />
                            <button className="btn btn-primary mx-2 py-2" data-toggle="modal" data-target="#modalAddUser">
                                <BsFillPersonPlusFill className="add-user" />
                            </button>
                            <ul className="list-group list-group-filter-search  list-group-filter-search-2 text-left d-none ">
                                {countries.map((country, idx) => (
                                    <button key={idx} className="list-group-item list-group-item-action list-group-item-primary" onClick={(e) => { setSearch(country.customerName); setSearchId(country.id); }}>
                                        <div className="col pt-2">
                                            {country.customerName}
                                        </div>
                                    </button>
                                ))}
                            </ul>
                        </div>

                        <div className="setting-pos d-flex justify-content-between">
                            <button disabled={close} className="btn btn-primary mx-2" data-toggle="modal" data-target="#AccountStatement" >
                                {t('Accountstatement')}
                            </button>
                            <button disabled={close} className="btn btn-primary mx-2" data-toggle="modal" data-target="#AddPromissoryNote">
                                {t('receipt_voucher')}
                            </button>
                        </div>
                    </div>

                    <form dir="rtl" className="mbSm" autoComplete="off">
                        <div className="d-flex w-100 allItemsSearch">
                            <div className="parentForms">
                                <form className="form-barcode" autoComplete="off">
                                    <input type="search" className="form-control search-input-voice search-input-voice px-2" ref={userBarCode} onClick={() => setPointer("barCodeSearch")} id="barCodeSearch" placeholder="... BarCode" disabled={close} />
                                    <button type="submit" className="d-none" style={{ visibility: "hidden" }} onClick={(e) => SubForm(e)}></button>
                                </form>

                                <div className="search-div-voice form-search-items">
                                    <input type="search" className="form-control search-input-voice SearchPoint" disabled={close} ref={inputRef} onClick={() => setPointer("SearchByCode")} placeholder="... Search " id="SearchByCode" onChange={(e) => handelChange(e)} />
                                    <FcSearch className="search-icon-voice" />
                                </div>
                            </div>
                            <ul className="list-group px-0 items " style={{ overflowY: "auto", maxHeight: "300px" }}>
                                {options.map((option, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <button key={i} type="button" className="list-group-item list-group-item-action" onClick={() => {
                                                inputRef.current.value = "";
                                                AddList(option.automaticDiscountS, option.itemId, option.itemCode, option.salesValue, option.unitId, option.salesDiscountValue, option.salesDiscountType, option.taxRate, option.tableTaxRate);
                                                setOptions([]);
                                            }}
                                            > {option.itemName}  {option.unitName} {option.salesValue}
                                            </button>
                                        </React.Fragment>
                                    );
                                })}
                            </ul>
                        </div>
                        <button type="submit" style={{ visibility: "hidden" }} onClick={(e) => SubFormHidden(e)}></button>
                    </form>
                    {/* List */}
                    <Table key={Math.random() * 110} fractions={fractions} returnBtnHold={returnBtnHold} returnBtn={returnBtn} changeDiscountItem={changeDiscountItem} modifyDiscount={modifyDiscount} changePriceItem={changePriceItem} TotaBill={TotaBill} modifyPrice={modifyPrice} modifyQuantity={modifyQuantity} list={list} DelItem={DelItem} AddNumber={AddNumber} MinusNumber={MinusNumber} changeCountItem={changeCountItem} />
                    <div className="totalSale">
                        <div className="d-flex justify-content-between px-4 align-items-center">
                            {settingCompany.useTableTaxSales
                                ?
                                <h6 className="text-small-w">
                                    {+taxTableRate.toFixed(fractions)} : {t('vatTable')}
                                </h6> : ''}

                            {settingCompany.useTaxSales ?
                                <h6 className="text-small-w">
                                    {+totalTaxAd.toFixed(fractions)}  : {t('vat')}
                                </h6> : ''}
                            {settingCompany.useTaxSourceSales ?
                                <h6 className="d-flex align-items-center text-small-w">
                                    <span> <input type="number" className="form-control text-center" value={taxSourceRate} onChange={e => setTaxSourceRate(e.target.value)} style={{ maxWidth: '70px' }} /> </span> : ض.م
                                </h6> : ''}


                            <h6 className="text-small-w">
                                {i18n.language === "en" ?
                                    <>
                                        <span>{t('discountitems')}  : </span>
                                        <span className="text-light font-weight-bold"> {+totalDiscount.toFixed(fractions)}  </span>
                                    </>
                                    :
                                    <>
                                        <span>{t('discountitems')} </span>
                                        <span className="text-light font-weight-bold"> : {+totalDiscount.toFixed(fractions)}  </span>
                                    </>
                                }
                            </h6>
                            <h6 className={`text-small-w ${i18n.language === "en" ? "d-flex flex-row-reverse" : ""} ${i18n.language == "fr" ? "flex-row-reverse" : ""}`}>
                                {i18n.language === "en" ?
                                    <>
                                        <span className={`text-light font-weight-bold ${i18n.language === "ar" ? "d-flex flex-row-reverse" : ""}`}> : {+totalBeforeTax.toFixed(fractions)} </span>
                                        <span>   {t('totalbeforetax')}</span>
                                    </>
                                    :
                                    <>
                                        <span className={`text-light font-weight-bold`}> {+totalBeforeTax.toFixed(fractions)} :</span> <span>   {t('totalbeforetax')}</span>
                                    </>
                                }

                            </h6>
                            <h6 id="totalAllSeles" className="mb-0">
                                {i18n.language === "en" ?
                                    <>
                                        <span className="text-small-w">  {t('nettotal')} : </span>
                                        <span className="text-light font-weight-bold isCheckTotal"> {+total.toFixed(fractions)} </span>
                                    </>
                                    :
                                    <>
                                        <span className="text-small-w">  {t('nettotal')} : </span>
                                        <span className="text-light font-weight-bold isCheckTotal"> {+total.toFixed(fractions)} </span>
                                    </>
                                }
                            </h6>
                        </div>
                        <div className="d-flex justify-content-end d-md-none btn-actions">
                            <button disabled={close} className="btn btn-resposive-in btn-success px-5 classCachMoblie btnSharing" onClick={() => CashCollection(Cancel, totalTaxAdPresent, totalTaxAd, InvoicedIdList, returnBtn ? true : false, searchId, list, total, listPayments, 1, typeInvoice, typePrint, posPintAfterSave, storesId)} style={{ backgroundColor: "#1f9d57", height: "100px" }}>
                                {t('cashcollection')}
                            </button>
                            <button disabled={close} className="btn btn-resposive-in px-5 text-light btnSharing" data-toggle="modal" data-target="#modal-pay" style={{ backgroundColor: "#4839eb", height: "100px" }}  > {t('pay')} </button>
                            <button disabled={close} className="btn btn-resposive-in px-5 text-light btnSharing returnedV btn-added" data-toggle="modal" data-target="#modal-holdes" style={{ backgroundColor: "#9aa9b7" }} > {t('Pendingorders')}  </button>
                            <button disabled={close} className="btn btn-resposive-in px-5 text-light btnSharing btn-danger btn-added" onClick={() => Cancel()} > {t('cancel')} </button>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4 mx-auto setting-salles">
                    <div className="text-center">
                        <div className="d-none d-md-block">
                            <div className="userVoice">
                                {/* المخازن */}
                                <div>
                                    <select className="form-control mb-3 store-input-voice" id="sourceId" onChange={e => setStoresId(+e.target.value)}>
                                        {stores.map((store, ids) => {
                                            return <option key={ids} value={store.storeId} selected={store.storeId === storesId ? true : false} > {store.storeName} </option>
                                        })}
                                    </select>
                                </div>
                                <div className="d-flex align-items-center">
                                    <input
                                        type="search"
                                        autoComplete="off"
                                        style={{ width: "90%" }}
                                        className="form-control search-input-voice1"
                                        disabled={changeAccount ? false : true}
                                        ref={userRef}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        onClick={() => setPointer("openUser")}
                                        id="openUser"
                                    />
                                    <button className="btn btn-primary mx-2 py-2" disabled={close} data-toggle="modal" data-target="#modalAddUser">
                                        <BsFillPersonPlusFill className="add-user" />
                                    </button>
                                    <ul className="list-group list-group-filter-search list-group-filter-search-1 text-left d-none">
                                        {countries.map((country, idx) => (
                                            <button key={idx} className="list-group-item list-group-item-action list-group-item-primary" onClick={() => {
                                                setSearch(country.customerName);
                                                setSearchId(country.id);
                                            }}
                                            >
                                                <div className="col pt-2">
                                                    {country.customerName}
                                                </div>
                                            </button>
                                        ))}
                                    </ul>
                                </div>



                                {/* المناديب */}

                                {/* الوصف */}
                                <div className="my-2">
                                    <input type="text" className="form-control" id="descInvoice" />
                                </div>


                                {/* مرتجعات */}
                                <div className="listsReturned d-none">
                                    <div className="inputSearchIvoiceReturned">
                                        <input className="form-control my-3" placeholder={t('code')} id="InvoiceCodeing" onChange={e => handelChangeInvoiceCode(e)} />
                                    </div>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col"> {t('code')} </th>
                                                <th scope="col"> {t('date')} </th>
                                                <th scope="col"> {t('description')} </th>
                                                <th scope="col"> {t('Check')} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {returnList.map((item, i) => {
                                                return (
                                                    <tr className="item-list-return" key={i}>
                                                        <td> {item.invoiceCode} </td>
                                                        <td> {item.invoiceDate} </td>
                                                        <td> {item.description} </td>
                                                        <td> <button className="btn btn-primary" onClick={() => returnedDetails(item.invoiceId)}> {t('Check')} </button> </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div> {/* مترجعات */}




                                {/* end */}

                            </div>

                            <div className="returnedV">
                                <div className="setting-pos d-flex justify-content-between my-3">
                                    {/* **** */}
                                    <div class="form-check my-2">
                                        <label class="form-check-label" for="posPintAfterSave">
                                            {t('printaftersaving')}
                                        </label>
                                        <input type="checkbox" class="form-check-input ml-2" onChange={() => setPosPintAfterSave(!posPintAfterSave)} id="posPintAfterSave"
                                            defaultChecked={Dates.posPintAfterSave}
                                            checked={posPintAfterSave}
                                        />
                                    </div>

                                    <button disabled={accountV ? false : true} className="btn btn-primary mx-2 font-small-responsive" data-toggle="modal" data-target="#AccountStatement">
                                        {t('Accountstatement')}
                                    </button>
                                    <button disabled={receiptV ? false : true} className="btn text-capitalize btn-primary mx-2 font-small-responsive" data-toggle="modal" data-target="#AddPromissoryNote">
                                        {t('receipt_voucher')}
                                    </button>

                                </div>
                            </div>
                        </div>


                        <div className="setting-salles-btns setting-salles-btns-one">
                            <div className="returnedV">
                                <PrintNumber changePrinter={changePrinter} />
                            </div>

                            <div className="d-none d-md-block">
                                <div className="d-felx justify-content-between font-ar my-3 setting-salles-btns-actione">
                                    <button disabled={close} className="btn btn-resposive btn-success px-1 mx-1 py-2 classCachWeb" onClick={() => CashCollection(Cancel, totalTaxAdPresent, totalTaxAd, InvoicedIdList, returnBtn ? true : false, searchId, list, total, listPayments, 1, typeInvoice, typePrint, posPintAfterSave, storesId)} style={{ backgroundColor: "#1f9d57" }}>
                                        {t('cashcollection')}
                                    </button>
                                    <button disabled={close} className="btn btn-resposive px-1 py-2  mx-1 text-light" data-toggle="modal" data-target="#modal-pay" style={{ backgroundColor: "#4839eb" }}>
                                        {t('pay')}
                                    </button>
                                    <button className="btn btn-resposive px-1 py-2 text-light mx-1 returnedV" disabled={invoiceHold ? false : true} onClick={() => Hold(InvoicedIdList, returnBtn ? true : false, searchId, list, setList, total, PriceAfterDiscount, setTotal, setPriceAfterDiscount, setSearch, inputRef)} style={{ backgroundColor: "#9aa9b7" }} >
                                        {t('Suspension')}
                                    </button>
                                </div>
                                <div className="d-felx justify-content-between my-3">
                                    <button className="btn btn-resposive btn-danger mx-1 px-1 py-2 " disabled={cancelCheque ? false : true} onClick={() => Cancel()} >  {t('cancel')} </button>
                                    {returnBtn
                                        ?
                                        <button className="btn btn-resposive btn-danger mx-1 px-1 py-2" onClick={() => ReturnedCancel()}>
                                            {t('saleinvoice')}
                                        </button>
                                        :
                                        <button disabled={returned ? false : true} className="btn btn-resposive btn-danger mx-1 px-1 py-2" data-toggle="modal" data-target="#modalAsk" >
                                            {t('Returns')}
                                        </button>
                                    }


                                    <button className="btn btn-resposive text-light mx-1 px-0 py-2 returnedV" disabled={invoiceHold ? false : true} data-toggle="modal" data-target="#modal-holdes" style={{ backgroundColor: "#9aa9b7" }} >
                                        {t('Pendingorders')}
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            {/* Models */}

            {/* modal add customer */}
            <div className="modal fade" id="modalAddUser">
                <div className="modal-dialog" style={{ maxWidth: "800px" }}>
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <div className=" text-center">
                                <h5 className="modal-title text-center" id="addCustomerModal"> {t('addcustomernew')} </h5>
                            </div>
                        </div>
                        <div className="modal-body my-4">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <div className="form-group text-right">
                                            <label className="px-2"> {t('usernameCustomeren')} </label>
                                            <input type="text" className="form-control text-right customerNewNameen" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2">{t('usernameCustomerar')}</label>
                                        <input type="text" className="form-control customerNewNamear text-right" onKeyUp={e => keyCutomer(e)} />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('Telephone')} </label>
                                        <input type="number" dir="rtl" className="form-control text-right txtFile1" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('Telephone')} </label>
                                        <input type="number" dir="rtl" className="form-control text-right txtFile2" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('mobile')} </label>
                                        <input type="number" dir="rtl" className="form-control text-right mobileFile" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('Fax')} </label>
                                        <input type="text" dir="rtl" className="form-control text-right faxFile" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('Email')} </label>
                                        <input type="email" className="form-control text-right emailFile" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('webSite')} </label>
                                        <input type="text" className="form-control text-right siteFile" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('address')} </label>
                                        <input type="text" className="form-control text-right addressFile" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2">{t('tax_registration_number')}</label>
                                        <input type="text" className="form-control text-right NumTax" />
                                    </div>

                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('File_No_Commercial_Registry')} </label>
                                        <input type="text" className="form-control text-right fileNum" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('VAT_registration_number')} </label>
                                        <input type="text" className="form-control text-right NumTaxPlus" />
                                    </div>

                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('Notes')} </label>
                                        <textarea className="form-control text-right notesFile"></textarea>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-secondary font-ar" data-dismiss="modal"> {t('cancel')} </button>
                            <button type="button" className="btn btn-primary font-ar" data-dismiss="modal" onClick={(e) => NewUserCustomer()}> {t('save')} </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* كشف حساب */}
            <div className="modal fade" id="AccountStatement">
                <div className="modal-dialog modal-dialog-show-account">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title text-center font-small-responsive-head" id="AddAccountStatement">
                                {t('Accountstatement')}
                            </h5>
                            <button type="button" className="close text-danger" onClick={() => ClearFormAccountStatemnet()} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body mt-1 mb-2" id="list-group-customers">
                            <form className="userVoice">
                                <div className={`row mb-3 flex-row-reverce ${i18n.language == "en" ? "text-left" : "text-right"} ${i18n.language == "fr" ? "text-left" : "text-right"}`}>
                                    <div className="col-12 col-md-12 mx-auto">
                                        <label className=" font-small-responsive-head"> {t('customerPos')} </label>
                                        <input type="search" className="form-control font-small-responsive-head" dir={i18n.language == "en" ? "ltr" : "rtl"} ref={nameCustomerRef} onChange={(e) => CUstomerNames(e)} />
                                    </div>
                                    <div className="col-12 col-md-12 mx-auto mt-3">
                                        <label className=" font-small-responsive-head">  {t('beginning')} </label>
                                        <DatePicker style={{ width: "100%" }} className="form-control font-small-responsive-head" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={StartDataCustomerStatement} onChange={(date) => setStartDataCustomerStatement(date)} />
                                    </div>
                                    <div className="col-12 col-md-12 mx-auto mt-3">
                                        <label className=" font-small-responsive-head"> {t('endofhistory')} </label>
                                        <DatePicker style={{ width: "100%" }} showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={EndDataCustomerStatement} onChange={(date) => setEndDataCustomerStatement(date)} />
                                    </div>


                                </div>
                                <ul className="list-group list-group-filter-search text-left w-100" id="list-group-customer" style={{ top: "69px", overflowY: "auto", maxHeight: "160px" }}>
                                    {CustomersStatement.map(
                                        (customer, idx) => (
                                            <button
                                                key={idx}
                                                className="list-group-item list-group-item-action list-group-item-primary"
                                                onClick={(e) => {
                                                    nameCustomerRef.current.value = customer.customerName;
                                                    setNameCustomerStatement(customer.customerName);
                                                    setIdCustomerStatement(customer.id);
                                                    setCustomersStatement([]);
                                                }}
                                            >
                                                <div className="col pt-2">
                                                    {customer.customerName}
                                                </div>
                                            </button>
                                        )
                                    )}
                                </ul>
                                <div className={`mb-4 mt-3 ${i18n.language == "en" ? "text-left" : "text-right"} ${i18n.language == "fr" ? "text-left" : "text-right"}`}>
                                    <button type="button" className="btn btn-primary px-4 py-1 mx-2 font-small-responsive" onClick={() => onViewPrint()}>
                                        {t('print')}
                                    </button>
                                    <button type="button" className="btn btn-success px-4 py-1 font-small-responsive" onClick={() => onViewCustomer()}>
                                        {t('view')}
                                    </button>
                                </div>
                            </form>
                            {/* start table */}
                            <div style={{ overflow: "auto" }}>
                                <table className="table table-striped" dir={`${i18n.language == "en" ? "ltr" : "rtl"}`}>
                                    <thead>
                                        <tr className="font-small-responsive">
                                            <th scope="col"> {t('history')} </th>
                                            <th scope="col"> {t('source')} </th>
                                            <th scope="col"> {t('Nusource')} </th>
                                            <th scope="col"> {t('Debtor')} </th>
                                            <th scope="col"> {t('Creditor')} </th>
                                            <th scope="col"> {t('Balance')} </th>
                                        </tr>
                                    </thead>
                                    <tbody className="font-small-responsive">
                                        {CustomersStatementList.map(({ journalVoucherTypeName, jvDate, source, sourceNo, credit, debit, debitLocal, creditLocal }, idx) => {
                                            if (source == 0) {
                                                if (debitLocal) {
                                                    debit = debitLocal
                                                }
                                                if (creditLocal) {
                                                    credit = creditLocal
                                                }
                                            }

                                            if (debit != 0) {
                                                totaling += +debit;
                                                totalingDebit += debit;
                                            }
                                            if (credit != 0) {
                                                totaling -= credit
                                                totalingCredit += credit;
                                            }

                                            return (
                                                <tr key={idx}>
                                                    <th scope="row"> {jvDate} </th>
                                                    <td> {journalVoucherTypeName == "" ? "رصيد افتتاحي" : journalVoucherTypeName} </td>
                                                    <td> {sourceNo} </td>
                                                    <td> {debit} </td>
                                                    <td> {credit} </td>
                                                    <td> {Number(totaling).toFixed(3)} </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <th scope="row"> {t('total')} </th>
                                            <td></td>
                                            <td></td>
                                            <td> {Number(totalingDebit).toFixed(3)} </td>
                                            <td> {Number(totalingCredit).toFixed(3)} </td>
                                            <td className={totaling >= 0 ? "text-success" : "text-danger"} > {Number(totaling).toFixed(3)} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>{/* end table */}

                        </div>
                    </div>
                </div>
            </div>

            {/* سند القيض */}
            <div className="modal fade" id="AddPromissoryNote">
                <div className="modal-dialog PromissoryNoteResponsive">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title text-center text-capitalize" id="PromissoryNote" >
                                {t('receipt_voucher')}
                            </h5>
                            <button type="button" className="close text-danger" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body mt-1 mb-2" id="list-group-customers">
                            <div className="userVoice">
                                <div className={`form-group ${i18n.language == "en" ? "text-left" : "text-right"} ${i18n.language == "fr" ? "text-left" : "text-right"}`}>
                                    <label> {t('customerPos')} </label>
                                    <input type="search" className="form-control" dir={i18n.language == "en" ? "ltr" : "rtl"} ref={nameCustomerPromissRef} onChange={(e) => PromissNames(e)} />
                                </div>
                                <ul className="list-group list-group-filter-search text-left w-100" id="list-group-customer" style={{ top: "69px", overflowY: "auto", maxHeight: "160px" }}>
                                    {CustomersPromiss.map(
                                        (customer, idx) => (
                                            <button key={idx} className="list-group-item list-group-item-action list-group-item-primary" onClick={(e) => {
                                                nameCustomerPromissRef.current.value = customer.customerName;
                                                setNameCustomerPromiss(customer.customerName);
                                                setIdCustomerPromiss(customer.id);
                                                setCustomersPromiss([]);
                                                QuickVoucherGetBalance(customer.id, setBalancePromiss)
                                            }}
                                            >
                                                <div className="col pt-2">
                                                    {customer.customerName}
                                                </div>
                                            </button>
                                        )
                                    )}
                                </ul>
                            </div>
                            <div className={`form-group ${i18n.language == "en" ? "text-left" : "text-right"} ${i18n.language == "fr" ? "text-left" : "text-right"}`}>
                                <label> {t('previousbalance')} </label>
                                <input type="number" className="form-control" value={balancePromiss} dir={i18n.language == "en" ? "ltr" : "rtl"} disabled />
                            </div>
                            <div className={`form-group ${i18n.language == "en" ? "text-left" : "text-right"} ${i18n.language == "fr" ? "text-left" : "text-right"}`}>
                                <label> {t('amountPaid')} </label>
                                <input type="number" className="form-control" id="BalanceRemaining" onKeyUp={(e) => BalanceRemaining(e.target.value, balancePromiss, setViewBalanceRemaining)} dir={i18n.language == "en" ? "ltr" : "rtl"} />
                            </div>
                            <div className={`form-group ${i18n.language == "en" ? "text-left" : "text-right"} ${i18n.language == "fr" ? "text-left" : "text-right"}`}>
                                <label> {t('Residual')} </label>
                                <input type="number" className="form-control" value={viewBalanceRemaining} dir={i18n.language == "en" ? "ltr" : "rtl"} disabled />
                            </div>
                            <div className={`form-group ${i18n.language == "en" ? "text-left" : "text-right"} ${i18n.language == "fr" ? "text-left" : "text-right"}`}>
                                <label> {t('description')} </label>
                                <textarea className="form-control" id="setDescriptionVoucher" onKeyUp={(e) => setDescriptionVoucher(e.target.value)}> </textarea>
                            </div>
                            <div className={`form-group mb-3 ${i18n.language == "en" ? "text-left" : "text-right"} ${i18n.language == "fr" ? "text-left" : "text-right"}`}>
                                <button className="btn btn-success px-4 py-1 mt-3" onClick={() => QuickVoucher(typePrint, IdCustomerPromiss, descriptionVoucher, setNameCustomerPromiss, setIdCustomerPromiss, nameCustomerPromissRef, setViewBalanceRemaining, setBalancePromiss, balancePromiss, viewBalanceRemaining)}>
                                    {t('save')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            {/* Modal */}
            <div className="modal fade" id="modal-pay">
                <div className="modal-dialog modal-pay">
                    <div className="modal-content" style={{ minHeight: "80vh" }}>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-5">
                                    <TablePay list={list} />
                                </div>
                                <div className="col-12 col-md-7">
                                    <PayTabs
                                        totalCredit={totalCredit}
                                        total={total}
                                        list={list}
                                        onSaveCredit={onSaveCredit}
                                        cach={cach}
                                        masterCard={masterCard}
                                        visa={visa}
                                        onChangeCach={onChangeCach}
                                        onChangeVisa={onChangeVisa}
                                        onChangeMasterCard={onChangeMasterCard}
                                        credit={credit}
                                        onChangeCredit={onChangeCredit}
                                        totalMatserCard={totalMatserCard}
                                        Agl={Agl}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            <h5 id="totalAllSeles" className="text-muted">
                                {total}
                            </h5>
                            <button type="button" className="btn bg-addition font-ar text-light px-3" data-dismiss="modal">
                                {t('Retreat')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* end modal */}

            {/* Modal */}
            <div className="modal fade" id="modal-holdes">
                <div className="modal-dialog modal-holdes">
                    <div className="modal-content" style={{ minHeight: "80vh" }}>
                        <div className="modal-body">

                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col"> {t('code')} </th>
                                        <th scope="col"> {t('recovery')} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {AllHolds.map((invoiceHold, indexItem) => {
                                        return (
                                            <tr key={indexItem}>
                                                <th scope="row">{invoiceHold.invoiceCode}</th>
                                                <td> <button className="btn btn-success" onClick={() => ReturnedHoldInvoice(invoiceHold.invoiceId)} data-dismiss="modal"> {t('recovery')} </button> </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>


                        </div>
                        <div className="modal-footer d-flex justify-content-between" data-dismiss="modal">
                            <button className="btn btn-danger"> {t('cancel')} </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* end modal */}


            <div className="modal fade" id="modalAsk">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> {t('movementtype')} </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group text-right">
                                <label htmlFor="typeOption"> {t('movementtype')} </label>
                                <select className="form-control" id="typeOption" onChange={e => { setTypeInvoice(e.target.value) }}>
                                    <option value={10}> {t('saleinvoice')} </option>
                                    <option value={17}>{t('independentmovement')}</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal"> {t('cancel')} </button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => Returned()}> {t('save')} </button>
                        </div>
                    </div>
                </div>
            </div>



            {/* <PrintVoice /> */}
        </div>
    );
}
export default SaleInvoice;
