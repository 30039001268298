import MaterialTable from "material-table"
import React, { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom";
import { ProductContext } from "../../context/context";

import { FiEdit2 } from "react-icons/fi";
import { RiUserFollowLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";


function DataTable({ Refrencecustomers = {} }) {

    const { customers = [] } = useContext(ProductContext);
    let { chkDelete, chkModify, chkTrackingUsers, chkReview } = Refrencecustomers;

    let Data = customers;


    const [columns, setColumns] = useState([
        { title: 'الاسم', field: 'nameA', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'المجموعة الرئيسية', field: 'groupId', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'اعدادات', field: 'edit', cellStyle: {  color: '#000', textAlign: "center"}, render: rowData => { 
            return (
                <>
                    {chkModify
                        ? <Link to={`/companies/edit/${rowData.companyId}`} className="btn btn-primary py-1 px-2"> <FiEdit2 /> </Link> 
                        : <button disabled className="btn btn-primary py-1 px-2"> <FiEdit2 /> </button>
                    } 
                    <button className="btn btn-info py-1 mx-2 px-2" disabled={chkTrackingUsers ? false : true} > <RiUserFollowLine /> </button>
                    <button className="btn btn-danger py-1 mx-2 px-2" disabled={chkDelete ? false : true} > <MdDelete /> </button>
                </>
            )
        }},
        
    ])

    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={columns}
                data={chkReview ? Data : []}
                title={null}
                options={{
                    actionsColumnIndex: -1,
                    paginationType:"stepped",
                    showFirstLastPageButtons:false,
                    addRowPosition:"first",
                }}
            />
        </div>
    )
}

export default DataTable
