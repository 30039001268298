import MaterialTable from "material-table"
import React, { useContext } from "react"
import { ProductContext } from "../../context/context";
import { RiUserFollowLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";


function DataTableBranches({ branchRefrence }) {
    const { Branches, DelBranch } = useContext(ProductContext)
    let { chkDelete, chkModify, chkTrackingUsers, chkReview } = branchRefrence;


    let columns = [
        { title: 'م', field: 'branchId',cellStyle: { textAlign: "center"}},
        { title: 'اسم الفروع', field: 'nameA',cellStyle: { color: '#000', textAlign: "center"} },
        { title: 'العنوان', field: 'address'},
        { title: 'اعدادات', field: 'edit', cellStyle: {  color: '#000', textAlign: "center"}, render: rowData => { 
            return (
                <>
                    {chkModify
                        ? <Link to={`/branch/edit/${rowData.branchId}`} className="btn btn-primary py-1 px-2"> <FiEdit2 /> </Link> 
                        : <button disabled className="btn btn-primary py-1 px-2"> <FiEdit2 /> </button> 
                    } 
                    <button className="btn btn-info py-1 mx-2 px-2" disabled={chkTrackingUsers ? false : true} > <RiUserFollowLine /> </button>
                    <button className="btn btn-danger py-1 mx-2 px-2" disabled={chkDelete ? false : true} onClick={() => DelBranch(rowData)} > <MdDelete /> </button> 
                </>
            )
        }},
    ];


    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={columns}
                data={chkReview ? Branches :  []}
                title={null}
                options={{
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    }
                }}
                // actions={actions}
                options={{
                    actionsColumnIndex: -1
                }}
            />
        </div>
    )
}

export default DataTableBranches


// [
//     {
//         icon: 'edit',
//         tooltip: 'تعديل',
//         onClick: (event, rowData) => delRow(rowData.id)
//     },
//     {
//         icon: 'delete',
//         tooltip: 'حذف',
//         onClick: (event, rowData) => delRow(rowData.id)
//     },
//     {
//         icon: 'save',
//         tooltip: 'تتبع المستخدمين',
//         onClick: (event, rowData) => delRow(rowData.id)
//     },
// ]