import React from 'react'
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

function Index({ children }) {

    return (
        <div className="conatiner-fluid mx-0 px-0 layout">
            <div className="row mx-0" id="rowing-layout">
                <div className="col-12 col-md-2 mx-auto sidebar-layout layout-toggle px-0" >
                    <Sidebar />
                </div>
                <div className="col-10 mx-auto px-1 bg-light site-layout bgImageUpdate" style={{ minHeight: "100vh", zIndex: "1" }}>
                    <Navbar />
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Index;
