
import React, { useEffect, useState } from 'react'
import logo from '../context/image.png'
import moment from "moment";
import * as PrinteReciptGeneralStyle from "./PrinteReciptGeneral.module.css";

export default function PrinteReciptGeneral() {
    const [list, setList] = useState([])
    const [listCustomer, setListCustomer] = useState([])
    const [ftdate, setFtdate] = useState("");
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [date, setDate] = useState(new Date());
    const [username, setUsername] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [branchName, setBranchName] = useState("");
    const [boxBankValue, setBoxBankValue] = useState({});
    const [totalAmount, setTotalAmount] = useState("");
    const [jvId, setJvId] = useState("");
    const [voucherHolder, setVoucherHolder] = useState("");
    const [customer, setCustomer] = useState([]);
    const [data, setData] = useState({});
    const [nameType, setNameType] = useState("");


    useEffect(() => {
        if (window.parameters) {
            let items = JSON.parse(window.parameters);
            if (items) {
                setNameType(items.name)
                if (items.data.financialTransactionsDetailsList) {
                    setList(items.data.financialTransactionsDetailsList);
                }
                if (!items.data.financialTransactionsDetailsList) {
                    setList(items.result.data[0].financialTransactionsDetailsList);
                }


                if (items.data.financialTransactionsPaymentList) {
                    setListCustomer(items.data.financialTransactionsPaymentList);
                }
                if (!items.data.financialTransactionsPaymentList) {
                    setListCustomer(items.result.data[0].financialTransactionsPaymentList);
                }
                setFtdate(items.data.ftdate);
                setStatus(items.data.status);
                setDescription(items.data.description);
                setUsername(items.user.userName);
                setCompanyName(items.user.companyNameA);
                setBranchName(items.user.branchNameA);
                setBoxBankValue(items.boxBankValue)
                setTotalAmount(items.data.totalAmount);
                setVoucherHolder(items.data.voucherHolder)

                if (items.customers.length != 0) {
                    setCustomer(items.customers.find(item => item.id == items.data.sceid));
                }
                setData(items.data)
                if (items.result) {
                    if (items.result.data.jvId) {
                        setJvId(items.result.data.jvId);
                    }
                    if (!items.result.data.jvId) {
                        setJvId(items.data.jvid);
                    }
                }

                window.focus();
                window.print();
            }
        }
    }, []);

    return (
        <div className={`container mx-auto ${PrinteReciptGeneralStyle.printerReciptGeneral}`}>
            <div className="row mx-0 px-0">
                <div className="col-12 px-0 text-right">
                    <div className="d-flex mb-3 mt-2">
                        <div className="text-right w-50">
                            <img src={logo} className="card-img-top mt-3" style={{ maxHeight: "120px", maxWidth: "120px" }} />
                        </div>
                        <div className='w-50'>
                            <h6>{companyName}</h6>
                            <h6>{branchName}</h6>
                        </div>
                    </div>
                </div>
                <div className="col-12 px-0">

                    {voucherHolder === 1 ? <HeadTeacher
                        branchName={branchName}
                        ftdate={ftdate}
                        status={status}
                        jvId={jvId}
                        description={description}
                        boxBankValue={boxBankValue}
                        nameType={nameType}
                    /> : <HeadCustomer
                        branchName={branchName}
                        customer={customer}
                        data={data}
                        ftdate={ftdate}
                        status={status}
                        jvId={jvId}
                        description={description}
                        boxBankValue={boxBankValue}
                        nameType={nameType}
                    />
                    }

                    {voucherHolder === 1 ? <TableTeacher list={list} totalAmount={totalAmount} /> : <TableCustomer list={listCustomer} />}

                    <div className={`${PrinteReciptGeneralStyle.Mb} w-100 my-4 d-flex justify-content-between align-items-center flex-row-reverse`}>
                        <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                            <span style={{ textDecoration: "underline" }}> المستلم </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                            <span style={{ textDecoration: "underline" }}> المحاسب </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                            <span style={{ textDecoration: "underline" }}> المدير المالي </span>
                        </div>
                    </div>
                </div>
                <div className={`${PrinteReciptGeneralStyle.footerGeneralRecipt} text-center`}>
                    <hr style={{ background: "#5e5e5e" }} className='mt-0 mb-2' />
                    <div className="d-flex justify-content-between">
                        <h6> {moment(date).format('YYYY-MM-DD H:M A')} </h6>
                        <h6> {username} </h6>
                    </div>
                </div>
            </div>
        </div>
    )
}


const HeadCustomer = ({ branchName, data, customer, ftdate, status, jvId, description, boxBankValue, nameType }) => {
    return (
        <div className="card text-center my-4">
            <div className="card-body" style={{ border: " 1px solid #999", "borderRadius": "2px" }}>
                <h5 className="card-title font-main" style={{ textDecoration: "underline" }}> {nameType} </h5>
                <div className="w-100 my-3 d-flex justify-content-between align-items-center flex-row-reverse">
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> فرع الحركة </h6>
                        <span style={{ textDecoration: "underline" }}> {branchName} </span>
                    </div>
                    <div className="d-flex mt-3 justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> التاريخ </h6>
                        <span style={{ textDecoration: "underline" }}> {moment(ftdate).format('YYYY-MM-DD H:M A')} </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> الحالة </h6>
                        <span style={{ textDecoration: "underline" }}> {status == "1" ? "معتمد" : "غير معتمد"} </span>
                    </div>
                </div>

                <div className="w-100 my-3 d-flex justify-content-between align-items-center flex-row-reverse">
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> الرقم </h6>
                        <span style={{ textDecoration: "underline" }}> {jvId} </span>
                    </div>
                </div>

                <div className="w-100 my-3 d-flex justify-content-between align-items-center flex-row-reverse">
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> الوصف </h6>
                        <span style={{ textDecoration: "underline" }}> {description} </span>
                    </div>
                </div>
                <div className="w-100 my-3 d-flex justify-content-between align-items-center flex-row-reverse">
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> الحساب </h6>
                        <span style={{ textDecoration: "underline" }}> {boxBankValue.accountId} </span>
                        <span className='mx-3' style={{ textDecoration: "underline" }}> {boxBankValue.label} </span>
                    </div>
                </div>
                <div className="w-100 my-3 d-flex justify-content-between align-items-center flex-row-reverse">
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> صاحب السند  </h6>
                        <span style={{ textDecoration: "underline" }}>  <span className='mx-2'>  عميل </span> {customer.customerName} </span>
                    </div>
                </div>


                <div className="w-100 my-3 d-flex justify-content-between align-items-center flex-row-reverse">
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> العملة </h6>
                        <span style={{ textDecoration: "underline" }}> {data.currencyId == 1 ? "جنية مصري" : data.currencyId} </span>
                    </div>
                    <div className="d-flex mt-3 justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> سعر الصرف </h6>
                        <span style={{ textDecoration: "underline" }}> {data.rate} </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> المبلغ المدفوع </h6>
                        <span style={{ textDecoration: "underline" }}> {data.totalAmount} </span>
                    </div>
                </div>



            </div>
        </div>
    )
}

const HeadTeacher = ({ branchName, ftdate, status, jvId, description, boxBankValue, nameType }) => {
    return (
        <div className="card text-center my-4">
            <div className="card-body" style={{ border: " 1px solid #999", "borderRadius": "2px" }}>
                <h5 className="card-title font-main" style={{ textDecoration: "underline" }}> {nameType} </h5>
                <div className="w-100 my-3 d-flex justify-content-between align-items-center flex-row-reverse">
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> فرع الحركة </h6>
                        <span style={{ textDecoration: "underline" }}> {branchName} </span>
                    </div>
                    <div className="d-flex mt-3 justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> التاريخ </h6>
                        <span style={{ textDecoration: "underline" }}> {moment(ftdate).format('YYYY-MM-DD H:M A')} </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> الحالة </h6>
                        <span style={{ textDecoration: "underline" }}> {status == "1" ? "معتمد" : "غير معتمد"} </span>
                    </div>
                </div>
                <div className="w-100 my-3 d-flex justify-content-between align-items-center flex-row-reverse">
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> الرقم </h6>
                        <span style={{ textDecoration: "underline" }}> {jvId} </span>
                    </div>
                </div>

                <div className="w-100 my-3 d-flex justify-content-between align-items-center flex-row-reverse">
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> الوصف </h6>
                        <span style={{ textDecoration: "underline" }}> {description} </span>
                    </div>
                </div>
                <div className="w-100 my-3 d-flex justify-content-between align-items-center flex-row-reverse">
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <h6 className='mb-0 pb-0 ml-3'> الحساب </h6>
                        <span style={{ textDecoration: "underline" }}> {boxBankValue.accountId} </span>
                        <span className='mx-3' style={{ textDecoration: "underline" }}> {boxBankValue.label} </span>
                    </div>
                </div>


            </div>
        </div>
    )
}

const TableCustomer = ({ list }) => {
    return (
        <table className="table text-center" dir='rtl'>
            <thead className='text-center'>
                <tr className='text-center'>
                    <th> النوع </th>
                    <th> رقم الفاتورة </th>
                    <th> تاريخ الفاتورة </th>
                    <th> الوصف </th>
                    <th> العملة </th>
                    <th> س.ص </th>
                    <th> المبلغ </th>
                    <th> المبلغ المحلي </th>
                    <th> المبلغ المدفوع </th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {list.map(({ invoiceId, invoiceDate, description, amount, amountLocal, currencyId, rate, paymentValue }, idx) => {
                    let currencyName = currencyId == 1 ? "جنية مصري" : currencyId;
                    return (
                        <tr key={idx} className='text-center'>
                            <td> العملاء </td>
                            <td> {invoiceId} </td>
                            <td> {invoiceDate} </td>
                            <td> {description} </td>
                            <td> {currencyName} </td>
                            <td> {rate} </td>
                            <td> {amount} </td>
                            <td> {amountLocal} </td>
                            <td> {paymentValue} </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

const TableTeacher = ({ list, totalAmount }) => {
    return (
        <table className="table text-center" dir='rtl'>
            <thead className='text-center'>
                <tr className='text-center'>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className='text-left px-1' style={{ borderLeft: "none !important" }}>اطراف</th>
                    <th className='text-right px-0' style={{ borderRight: "none !important" }}> دائنة </th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                <tr className='text-center'>
                    <th> رقم الحساب </th>
                    <th> اسم الحساب </th>
                    <th> العملة </th>
                    <th> س.ص </th>
                    <th> المبلغ </th>
                    <th> المبلغ المحلي </th>
                    <th> الوصف </th>
                    <th> مركز التكلفة </th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {list.map(({ accountId, nameAccount, amount, amountLocal, accountName, desc, description, currencyId, rate, costCenterId }, idx) => {
                    let currencyName = currencyId == 1 ? "جنية مصري" : currencyId;
                    return (
                        <tr key={idx}>
                            <td> {accountId} </td>
                            <td> {nameAccount ? nameAccount : accountName} </td>
                            <td> {currencyName} </td>
                            <td> {rate} </td>
                            <td> {amount} </td>
                            <td> {amountLocal} </td>
                            <td> {desc ? desc : description} </td>
                            <td> {costCenterId} </td>
                        </tr>
                    )
                })}
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td> الاجمالي </td>
                    <td> {totalAmount} </td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    )
}