import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import DataTableCompany from './DataTableCompany';

function Compaines() {

    const { allRefrence = [] } = useContext(ProductContext);

    let companyRefrence = {};
    if(allRefrence.length != 0){
        companyRefrence = allRefrence.find(item => item.mName == "TsmCompany") ? allRefrence.find(item => item.mName == "TsmCompany") : false; 
    }

    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-12 col-md-11 mx-auto my-3 text-center px-0">
                        <div className="text-right mb-2"> 
                            {companyRefrence.chkAdd 
                                ? <Link to="/companies/add-company" className="text-light btn btn-primary py-0 font-ar"> اضافة </Link> 
                                : <button disabled className="text-light btn btn-primary py-0 font-ar"> اضافة </button>
                            }
                        </div>
                        <DataTableCompany RefrenceCompany={companyRefrence} />
                    </div>
                </div>
            </div>
        </Layout>
    )

    
}

export default Compaines
