import React from 'react'
import { Link } from 'react-router-dom'

function Roadmap({ name, child, childTo }) {
    return (
        <div className="row mx-0">
            <div className="col-12 col-md-10 mx-auto px-0">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb flex-row-reverse">
                        <li className="breadcrumb-item">
                            <Link to={childTo}>{name}</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {child}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    )
}

export default Roadmap