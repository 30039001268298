import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ProductContext } from '../context/context';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Layout from '../Layout'
import TableSummaryReports from './TableSummaryReports';
import text from "../context/super.txt"
import * as StyleSummary from './SummaryReports.module.css';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// import GeneratePDF from '../components/PDF/GeneratePDF';

export default function SummaryReports() {
    const { t, i18n } = useTranslation();

    const [branchList, setbranchList] = useState([]);
    const [Branches, setBranches] = useState([]);

    const [userList, setuserList] = useState([]);
    const [Users, setUsers] = useState([]);

    const [customersList, setcustomersList] = useState([]);
    const [customers, setcustomers] = useState([]);

    const [customersGroupList, setcustomersGroupList] = useState([]);
    const [customersGroup, setcustomersGroup] = useState([]);

    const [supplierGroupList, setsupplierGroupList] = useState([]);
    const [supplierGroup, setsupplierGroup] = useState([]);

    const [type, setType] = useState(0);
    const [invoiceType, setInvoiceType] = useState(0);

    const [Data, setData] = useState([]);

    const { mostData = [] } = useContext(ProductContext);

    const [StartDataCustomerStatement, setStartDataCustomerStatement] = useState(new Date());
    const [EndDataCustomerStatement, setEndDataCustomerStatement] = useState(new Date());
    const [typePrint, setTypePrint] = useState(false);



    const ref = createRef();
    const options = {
        orientation: 'landscape',
        unit: 'in',
        // format: [4,2]
    };

    useEffect(() => {
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/StoreFormReport/GetDefault`, {
                                method: "POST",
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                                    "Content-Type": "application/json",
                                },
                                redirect: "follow",
                                body: JSON.stringify({
                                    companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : 1,
                                    formName: "TsmPOSInvoicesSummary"
                                }),
                            }).then((response) => response.json())
                                .then((result) => {
                                    if (result.status === 200) {
                                        setbranchList(result.data.branchList)
                                        setuserList(result.data.userList)
                                        setcustomersList(result.data.customerList)
                                        setcustomersGroupList(result.data.customerGroupList)
                                        setsupplierGroupList(result.data.supplierGroupList)
                                        setType(result.data.certificationType)
                                        setInvoiceType(result.data.invoiceType)
                                    } else {
                                        alert(result.message)
                                    }
                                }).catch((error) => {
                                    console.log("data", error)
                                });
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${text}/StoreFormReport/GetDefault`, {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                            "Content-Type": "application/json",
                        },
                        redirect: "follow",
                        body: JSON.stringify({
                            companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : 1,
                            formName: "TsmPOSInvoicesSummary"
                        }),
                    }).then((response) => response.json())
                        .then((result) => {
                            if (result.status === 200) {
                                setbranchList(result.data.branchList)
                                setuserList(result.data.userList)
                                setcustomersList(result.data.customerList)
                                setcustomersGroupList(result.data.customerGroupList)
                                setsupplierGroupList(result.data.supplierGroupList)
                                setType(result.data.certificationType)
                                setInvoiceType(result.data.invoiceType)
                            } else {
                                alert(result.message)
                            }
                        }).catch((error) => {
                            console.log("data", error)
                        });
                }



            });
    }, [])




    useEffect(() => {
        if (mostData.length != 0) {
            setTypePrint(mostData.printFromFrontEnd)
        }
    }, [mostData])

    if (customersGroupList.length != 0) {
        customersGroupList.forEach(item => {
            item.value = item.customerGroupName;
            item.label = item.customerGroupName;
            item.id = item.cgId;
        })
    }
    if (supplierGroupList.length != 0) {
        supplierGroupList.forEach(item => {
            item.value = item.supplierGroupName;
            item.label = item.supplierGroupName;
            item.id = item.sgId;
        })
    }
    if (branchList.length != 0) {
        branchList.forEach(item => {
            item.value = item.branchName;
            item.label = item.branchName;
            item.id = item.branchId;
        })
    }
    if (userList.length != 0) {
        userList.forEach(item => {
            item.value = item.userName;
            item.label = item.userName;
            item.id = item.userId;
        })
    }
    if (customersList.length != 0) {
        customersList.forEach(item => {
            item.value = item.customerName;
            item.label = item.customerName;
            item.id = item.id;
        })
    }


    const onChangeBranches = (e) => {
        setBranches(e);
    }

    const onChangeGroupCustomer = (e) => {
        setcustomersGroup(e);
    }

    const onChangeGroupMid = (e) => {
        setsupplierGroup(e);
    }

    const onChangeCustomer = (e) => {
        setcustomers(e);
    }

    const onChangeUsers = (e) => {
        setUsers(e);
    }

    const onSubmitView = () => {
        document.querySelector(".loading").classList.remove("hideloader");
        let data = {
            companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : "",
            formName: "TsmPOSInvoicesSummary",
            beginDate: StartDataCustomerStatement,
            endDate: EndDataCustomerStatement,
            branchList: Branches.length == 0 ? branchList : Branches,
            supplierGroupList: supplierGroup.length == 0 ? supplierGroupList : supplierGroup,
            customerGroupList: customersGroup.length == 0 ? customersGroupList : customersGroup,
            customerList: customers.length == 0 ? customersList : customers,
            userList: Users.length == 0 ? userList : Users,
            certificationType: type,
            invoiceType: invoiceType
        }

        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/StoreFormReport/GetAll`, {
                                method: 'POST',
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(data),
                                redirect: 'follow'
                            })
                                .then(response => response.json())
                                .then(result => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    if (result.status === 200) {
                                        setData(result.data);
                                        CalculationTotal(result.data);
                                        if (result.data.length === 0) {
                                            alert('لا يوجد بيانات')
                                        }
                                    } else {
                                        alert(result.message)
                                    }

                                })
                                .catch(error => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    console.log('error', error)
                                });
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${text}/StoreFormReport/GetAll`, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                        redirect: 'follow'
                    })
                        .then(response => response.json())
                        .then(result => {
                            document.querySelector(".loading").classList.add("hideloader");
                            if (result.status === 200) {
                                setData(result.data);
                                CalculationTotal(result.data);
                                if (result.data.length === 0) {
                                    alert('لا يوجد بيانات')
                                }
                            } else {
                                alert(result.message)
                            }

                        })
                        .catch(error => {
                            document.querySelector(".loading").classList.add("hideloader");
                            console.log('error', error)
                        });
                }



            });

    };

    const onViewPrint = () => {
        let parms = { Items: Data, start: StartDataCustomerStatement, end: EndDataCustomerStatement };
        let handle = window.open(window.location.origin + '/PrintSummaryReports', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
        handle.window.parameters = JSON.stringify(parms);
    }

    const CalculationTotal = (data = []) => {
        let totalItems = data.reduce((total, item) => {
            total += item.netPrice;
            return total;

        }, 0);
        let DataNew = [...data, { netPrice: totalItems, itemName: "الاجمالي", footer: true, }]
        setData(DataNew);
    }

    // let ShowBtns = '';

    // if()

    return (
        <Layout>
            <div className="company text-right font-ar container companyReciptVoucher py-5 px-0" style={{ maxWidth: "1170px" }}>
                <div className="container-fluid AccountStatement" style={{ minHeight: "70vh" }}>
                    <div className="container py-5">
                        <div className="row flex-row-reverse">
                            <form dir={i18n.language === "en" ? "ltr" : "rtl"} className={i18n.language === "en" ? "text-left col-12" : "text-right col-12"} onSubmit={e => e.preventDefault()}>

                                <div className="col-11 mx-auto">
                                    <div className="row">
                                        <div className="col-12 col-md-6 mx-auto">
                                            <label className="label-account-statement"> {t('beginning')} </label>
                                            <DatePicker style={{ width: "100%" }} className="form-control started" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={StartDataCustomerStatement} onChange={(date) => setStartDataCustomerStatement(date)} />
                                        </div>

                                        <div className="col-12 col-md-6 mx-auto">
                                            <label className="label-account-statement"> {t('endofhistory')} </label>
                                            <DatePicker style={{ width: "100%" }} className="form-control ended" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={EndDataCustomerStatement} onChange={(date) => setEndDataCustomerStatement(date)} />
                                        </div>


                                    </div>

                                    <div className="row my-3 align-items-center">
                                        <div className="col-12 col-md-6 mx-auto account-statement-container">
                                            <label className="label-account-statement"> {t('branches')} </label>
                                            <Select options={branchList} isMulti placeholder={t('all')} onChange={e => onChangeBranches(e)} />
                                        </div>


                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement"> {t('customerGroup')} </label>
                                            <Select options={customersGroupList} isMulti placeholder={t('all')} onChange={e => onChangeGroupCustomer(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement"> {t('SupplierGroup')} </label>
                                            <Select options={supplierGroupList} isMulti placeholder={t('all')} onChange={e => onChangeGroupMid(e)} />
                                        </div>


                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement">  {t('customers')} </label>
                                            <Select options={customersList} isMulti placeholder={t('all')} onChange={e => onChangeCustomer(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement">  {t('users')} </label>
                                            <Select options={userList} isMulti placeholder={t('all')} onChange={e => onChangeUsers(e)} />
                                        </div>


                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <select className='form-control' onChange={(e) => setType(e.target.value)}>
                                                <option value="0" selected={type == 0 ? true : false}> {t('viewall')} </option>
                                                <option value="1" selected={type == 1 ? true : false}> {t('certified')} </option>
                                                <option value="2" selected={type == 2 ? true : false}> {t('notsupported')} </option>
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-6 account-statement-container my-2">
                                            <select className='form-control' onChange={(e) => setInvoiceType(e.target.value)}  >
                                                <option value="0" selected={invoiceType == 0 ? true : false} > {t('salesAndReturns')} </option>
                                                <option value="1" selected={invoiceType == 1 ? true : false}> {t('sales')} </option>
                                                <option value="2" selected={invoiceType == 2 ? true : false}> {t('Retur')} </option>
                                            </select>
                                        </div>



                                    </div>

                                    <div className={` ${i18n.language === "en" ? "text-left" : "text-right"} `}>
                                        <button className="btn btn-primary py-1 my-4 mx-0 px-4 " type="button" onClick={() => onSubmitView()} > {t('view')} </button>

                                        <button className="btn btn-primary py-1 my-4 mx-1 px-4" type="button" onClick={() => onViewPrint()} >   {t('print')} </button>
                                        <ExcelFile name="Summary Reports" element={<button className='btn btn-primary mx-1 px-4'> Execl</button>}>
                                            <ExcelSheet data={Data} name="Summary Reports">
                                                <ExcelColumn label="الكود" value="invoiceId" />
                                                <ExcelColumn label="التاريخ" value="invoiceDate" />
                                                <ExcelColumn label="الصنف" value="itemName" />
                                                <ExcelColumn label="السعر" value="netPrice" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                        {/* <Pdf targetRef={ref} filename="SummaryReports.pdf" options={options} scale={1}>
                                        {({ toPdf }) => <button className='btn btn-primary mx-1 px-3' onClick={toPdf}> PDF </button>}
                                    </Pdf> */}


                                    </div>

                                </div>
                            </form>
                            <hr />
                        </div>
                        <div class={`${StyleSummary.table_wrapper_scroll_y} ${StyleSummary.my_custom_scrollbar}`}>
                            <table ref={ref} className="mx-auto table table-bordered table-hover table-striped mb-0 text-center" style={{ position: "relative" }} dir='rtl'>
                                <thead className={`${StyleSummary.theadSummary}`}>
                                    {/* <thead style={{ position: "fixed", maxWidth: "70%" }}> */}
                                    <tr className='head-bg text-light'>
                                        <th> {t('Code')} </th>
                                        <th> {t('history')} </th>
                                        <th> {t('Item')} </th>
                                        <th> {t('price')} </th>
                                    </tr>
                                </thead>
                                <tbody className={`${StyleSummary.tbodySummary}`}>
                                    {Data.map((item, index) => <TableSummaryReports key={index} data={item} />)}
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
}
