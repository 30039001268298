import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import DataTableMatruial from './DataTableMatruial';
import addIcon from "../../images/assets/14.png";


function DefinitionItems(props) {


    const { allRefrence = [], getDefinitionItems } = useContext(ProductContext);
    const { t, i18n } = useTranslation();
    const [details, setDetails] = useState(false);

    let itemsRefrence = {};
    if (allRefrence.length != 0) {
        itemsRefrence = allRefrence.find(item => item.mName == "TsmItems") ? allRefrence.find(item => item.mName == "TsmItems") : false;
    }

    useEffect(() => {
        console.log(props);
        // if (props.location.state != undefined) {
        //     if (props.location.params) {
        //         getDefinitionItems(1);
        //     } else {
        //         getDefinitionItems(1);
        //     }

        // } else {
        //     if (props.location.params) {
        //         getDefinitionItems(2);
        //     } else {
        //         getDefinitionItems(2);
        //     }
        // }
        if (props.location.state != undefined) {
            if (props.location.state.detail) {
                setDetails(props.location.state.detail)
            } else {
                if (props.location.params) {
                    getDefinitionItems(2);
                } else {
                    getDefinitionItems(1);
                }
            }
        } else {
            if (props.location.params) {
                getDefinitionItems(2);
            } else {
                getDefinitionItems(1);
            }
        }
    }, []);
    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-11 mx-auto my-3 text-center">

                        <div className="text-right mb-2">
                            <Link to="/DefinitionItems/add-item" className="text-light btn btn-danger py-1 font-ar px-4">
                                {t('add')}
                                <img src={addIcon} className="iconMax" title="edit row" alt="" />
                            </Link>
                        </div>

                        <DataTableMatruial itemsRefrence={itemsRefrence} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default DefinitionItems
