
export const Links = 

[
    {
        id: 1,
        text: 'system',
        path: '/',
        child: [{
                text: 'company',
                path: '/',
                group: false
            },
            {
                text: 'branches',
                path: '/',
                group: false
            },
            {
                text: 'fiscal years',
                path: '/',
                group: false
            },
            {
                text: 'period',
                path: '/',
                group: false
            },
            {
                text: 'curreny',
                path: '/',
                group: false
            },
            {
                text: 'country',
                path: '/',
                group: false
            },
            {
                text: 'regions',
                path: '/',
                group: false
            },
            {
                text: 'nationality',
                path: '/',
                group: false
            },
            {
                text: 'suppliers group',
                path: '/',
                group: false
            },
            {
                text: 'suppliers',
                path: '/',
                group: false
            },
            {
                text: 'customers groups',
                path: '/',
                group: false
            },
            {
                text: 'customers',
                path: '/',
                group: false
            },
            {
                text: 'sponsors',
                path: '/',
                group: false
            },
            {
                text: 'employees',
                path: '/',
                group: false
            },
            {
                text: 'change employee branch',
                path: '/',
                group: false
            },

            {
                text: 'send SMS',
                path: '/',
                group: false
            },
            {
                text: 'fiscal years',
                path: '/',
                group: false
            },
            {
                text: 'users',
                path: '/',
                group: false
            },
            {
                text: 'alarms setting',
                path: '/',
                group: false
            },
            {
                text: 'alarms today',
                path: '/',
                group: false
            },
            {
                text: 'Database',
                path: '/',
                group: [{
                    text: 'alarms setting',
                    path: '/',
                    group: false
                }, ]
            },
            {
                text: 'اللغة العربية',
                path: '/',
                group: false
            },
            {
                text: 'Log out',
                path: '/',
                group: false
            },
            {
                text: 'Exit',
                path: '/',
                group: false
            },
        ]
    },
    {
        id: 2,
        text: 'setting',
        path: '/',
        child: [{
                text: 'general settings',
                path: '/',
                group: false
            },
            {
                text: 'PC setting',
                path: '/',
                group: false
            },
        ]
    },
    {
        id: 3,
        text: 'accounts', // حسابات
        path: '/',
        child: [{
                text: 'counting',
                path: '/',
                group: [{
                        text: 'counting', // دفاتر
                        path: '/',
                        group: false
                    },
                    {
                        text: 'journal voucher daily', // يوميات القيود اليومية 
                        path: '/',
                        group: false
                    },
                ]
            }, {
                text: 'voucher daily', // قيد يوم
                path: '/',
                group: false
            },
            {
                text: 'Financial transactions', // المعاملات المالية
                path: '/',
                group: [{
                        text: 'cash',
                        path: '/',
                        group: false
                    },
                    {
                        text: 'Banks',
                        path: '/',
                        group: false
                    },
                    {
                        text: 'receipt voucher',
                        path: '/',
                        group: false
                    },
                    {
                        text: 'transfer voucher',
                        path: '/',
                        group: false
                    },
                ]
            }, {
                text: 'reports',
                path: '/',
                group: [{
                    text: 'account statement', // كشف حساب  
                    path: '/',
                    group: false
                }, ]
            },

        ]
    },
    {
        id: 4,
        text: 'paper catch and pay',
        path: '/',
        child: [{
                text: 'paper catch',
                path: '/',
                group: false
            },
            {
                text: 'paper pay',
                path: '/',
                group: false
            },
            {
                text: 'paper catch change status',
                path: '/',
                group: false
            },
            // {
            //     text: 'paper catch change status',
            //     path: '/',
            //     group: false
            // },
            {
                text: 'bank',
                path: '/',
                group: false
            },
            {
                text: 'paper status',
                path: '/',
                group: [{
                        text: 'paper catch report',
                        path: '/',
                    },
                    {
                        text: 'paper pay report',
                        path: '/',
                    }
                ]
            },
        ]
    },
    {
        id: 5,
        text: 'stores',
        path: '/',
        child: [{
                text: 'definition stores',
                path: '/',
                group: false
            },
            {
                text: 'definition items',
                path: '/',
                group: false
            },
            {
                text: 'stores opening balance',
                path: '/',
                group: false
            },
            {
                text: 'assemble item',
                path: '/',
                group: false
            },
            {
                text: 'packing item',
                path: '/',
                group: false
            },
            {
                text: 'receipt goods',
                path: '/',
                group: false
            },
            {
                text: 'exchange goods',
                path: '/',
                group: false
            },
            {
                text: 'transfer of goods',
                path: '/',
                group: false
            },
            {
                text: 'transfer of goods - linked to receipt',
                path: '/',
                group: false
            },
            {
                text: 'receipt of goods transfer',
                path: '/',
                group: false
            },
            {
                text: 'execution of goods',
                path: '/',
                group: false
            },
            {
                text: 'inventory minutes',
                path: '/',
                group: false
            },
            {
                text: 'stocktaking',
                path: '/',
                group: false
            },
            {
                text: 'reserve quantities',
                path: '/',
                group: false
            },
            {
                text: 'release quantities',
                path: '/',
                group: false
            },
            {
                text: 'item balance',
                path: '/',
                group: false
            },
            {
                text: 'follow cost',
                path: '/',
                group: false
            },
            {
                text: 'item serial query',
                path: '/',
                group: false
            },
            {
                text: 'barcode print',
                path: '/',
                group: false
            },
            {
                text: 'modify items prices',
                path: '/',
                group: false
            },
            {
                text: 'adoption',
                path: '/',
                group: false
            },
        ]
    },
    {
        id: 6,
        text: 'Purchase',
        path: '/',
        child: [
            {
                text: 'Purchase orders', 
                path: '/',
                group: false   
            },
            {
                text: 'Purchase deals', 
                path: '/',
                group: false   
            },
            {
                text: 'Purchase invoice', 
                path: '/',
                group: false   
            },
            {
                text: 'returned Purchase', 
                path: '/',
                group: false   
            },
            {
                text: 'notices supplier', 
                path: '/',
                group: false   
            }
        ]
    },
    {
        id: 6,
        text: 'sales',
        path: '/',
        child: [
            {
                text: 'commissioms policy', 
                path: '/',
                group: false   
            },
            {
                text: 'commissioms salesman', 
                path: '/',
                group: false   
            },
            {
                text: 'quotations', 
                path: '/',
                group: false   
            },
            {
                text: 'sales orders', 
                path: '/',
                group: false   
            },
            {
                text: 'sales invoice', 
                path: '/',
                group: false   
            },
            {
                text: 'returned sales', 
                path: '/',
                group: false   
            },
            {
                text: 'customer notices', 
                path: '/',
                group: false   
            },
            {
                text: 'review invoices', 
                path: '/',
                group: false   
            },
            {
                text: 'item price query', 
                path: '/',
                group: false   
            },
            {
                text: 'offers', 
                path: '/',
                group: false   
            }
        ]
    },
    // {
    //     id:2,
    //     text:'about',
    //     path:'/about'
    // },
    // {
    //     id:3,
    //     text:'Fruits',
    //     path:'/Fruits'
    // },
    // {
    //     id:4,
    //     text:'cart',
    //     path:'/Cart'
    // },
    // {
    //     id:5,
    //     text:'contact',
    //     path:'/contact'
    // }
];

