import React from 'react'

function MrEmployee(props) {
 
    let {
        newRow, removeRow, Item, list,setUnderList,
        UnderList, i18n, t, userRef, handelChangeUsers, users,
        setUsers, GetBalanceUser, selectCurreny, currencyList, changeRateCurrency, accountsListFilter
    } = props;

    const onChangeAmount = (val, id) => {
        let find = list.find(item => item.idRow == id);
        if (find) {
            let rate = list.find(el => el.idRow == id).rate ? list.find(el => el.idRow == id).rate : 1;
            list.find(el => el.idRow == id).amount = +val;
            list.find(el => el.idRow == id).amountLocal = +val * +rate;
        } else {
            setUnderList((room) =>
                room.map((list) =>
                    list.idRow === id ? { ...list, amount: +val , amountLocal: +val * list.rate} : list
                )
            );
        }

    }
    const onChangeDescription = (val, id) => {
        let find = list.find(item => item.idRow == id);
        if (find) {
            list.find(el => el.idRow == id).description = val;
            list.find(el => el.idRow == id).desc = val;
        } else {
            setUnderList((room) =>
                room.map((list) =>
                    list.idRow === id ? { ...list, description: +val , desc: +val} : list
                )
            );
        }
    }



    return (
        <div>
            <table className={`table table-striped Mr ${Item.voucherHolder != 1 ? "d-none" : ''} `} dir={i18n.language == "en" ? "ltr" : "rtl"}>
                <thead>
                    <tr className="text-center table-list-costing">
                        <th> {t("accountName")} </th>
                        <th> {t("currenyType")} </th>
                        <th> {t("sy")} </th>
                        <th> {t("amount")} </th>
                        <th> {t("description")} </th>
                        {/* <th> {t("costCenter")} </th> */}
                        <th> {t("Balance")} </th>
                        <th>
                            <button type="button" className="btn btn-success px-3 py-1" onClick={() => newRow()}> + </button>
                        </th>
                    </tr>
                </thead>
                <tbody className="lists text-center" style={{ display: "inherit" }}>
                    {UnderList.map((underItem, idx) => {

                        return (
                            <tr key={idx} className={`row-${underItem.idRow}`}>
                                <td className="user-account-search-container">

                                    <button type="button" className={`btn btn-primary w-100 btn-name-${underItem.idRow}`} data-toggle="modal" data-target={`#users-${underItem.idRow}`}>
                                        {accountsListFilter.find(itemL => itemL.accountId == underItem.accountId) ? accountsListFilter.find(itemL => itemL.accountId == underItem.accountId).accountName : 'اختر العميل'}
                                    </button>

                                    <div className="modal fade" id={`users-${underItem.idRow}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title font-main"> اختر الحساب </h5>
                                                </div>
                                                <div className="modal-body">
                                                    <input
                                                        type="search"
                                                        autoComplete="off"
                                                        className={`form-control td-nameAcount-${underItem.idRow}`}
                                                        defaultValue={accountsListFilter.find(itemL => itemL.accountId == underItem.accountId) ? accountsListFilter.find(itemL => itemL.accountId == underItem.accountId).accountName : ''}
                                                        ref={userRef}
                                                        onChange={(e) => handelChangeUsers(e)}
                                                    />
                                                    <ul className={`list-group list-group-filter-search list-group-filter-search-2 text-left tdul-${idx}`}>
                                                        {users.map(
                                                            (option, i) => {
                                                                return (
                                                                    <React.Fragment key={i}>
                                                                        <button key={i}
                                                                            type="button"
                                                                            className="list-group-item list-group-item-action"
                                                                            onClick={() => {
                                                                                document.querySelector(`.td-nameAcount-${underItem.idRow}`).value = option.accountName;
                                                                                document.querySelector(`.btn-name-${underItem.idRow}`).innerHTML = option.accountName;
                                                                                UnderList.find(el => el.idRow == underItem.idRow).accountId = option.accountId;
                                                                                UnderList.find(el => el.idRow == underItem.idRow).nameAccount = option.accountName;
                                                                                UnderList.find(el => el.idRow == underItem.idRow).currencyId = +document.querySelector(`.input-balance-currency-${underItem.idRow}`).value;
                                                                                UnderList.find(el => el.idRow == underItem.idRow).sY = +document.querySelector(`.input-balance-sY-${underItem.idRow}`).value;
                                                                                UnderList.find(el => el.idRow == underItem.idRow).rate = +document.querySelector(`.input-balance-sY-${underItem.idRow}`).value;
                                                                                setUsers([]);
                                                                                GetBalanceUser(option.accountId, underItem.idRow);
                                                                            }}
                                                                            data-dismiss="modal" aria-label="Close"
                                                                        >
                                                                            {option.accountName}
                                                                        </button>
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="d-none">
                                    <span className={`input-userIdRef-${idx}`}>
                                        {accountsListFilter.find(itemL => itemL.accountId == underItem.accountId) ? accountsListFilter.find(itemL => itemL.accountId == underItem.accountId).accountId : ''}
                                    </span>
                                </td>
                                <td className="d-none">
                                    <span className={`input-userNameRef-${idx}`}>
                                        {accountsListFilter.find(itemL => itemL.accountId == underItem.accountId) ? accountsListFilter.find(itemL => itemL.accountId == underItem.accountId).accountName : ''}
                                    </span>
                                </td>

                                <td>
                                    <select className={`form-control input-balance-currency-${underItem.idRow}`} onChange={(e) => selectCurreny(e.target.value, idx)} >
                                        {currencyList.map(
                                            (curr) => {
                                                return (
                                                    <option key={curr.currencyId} value={curr.currencyId} selected={underItem.currencyId == curr.currencyId ? true : false}>
                                                        {curr.currencyName}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </td>

                                <td>
                                    <input className={`form-control text-center input-balance-sY-${underItem.idRow}`} onChange={(e) => changeRateCurrency(e.target.value, idx)} type="text" disabled defaultValue={1} />
                                </td>

                                <td>
                                    <input
                                        type="number"
                                        className={`form-control text-center input-amount-${underItem.idRow}`}
                                        value={underItem.amount}
                                        onChange={(e) =>  onChangeAmount(e.target.value, underItem.idRow) }
                                        min={1}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className={`form-control text-center input-desc-${underItem.idRow}`}
                                        value={underItem.description}
                                        onChange={(e) => onChangeDescription(e.target.value, underItem.idRow)}

                                    />
                                </td>
                                <td className="d-none">
                                    <span className={`input-centerId-${underItem.idRow}`}></span>
                                </td>
                                <td className={`balance-td-${underItem.idRow}`}>
                                    0
                                </td>
                                <td className="d-flex justify-content-center px-1">
                                    <button type="button" className="btn btn-danger px-3 py-1" onClick={() => removeRow(underItem.idRow)}> - </button>
                                </td>
                            </tr>
                        );
                    })}

                    {list.map((item, idx) => {
                        return (
                            <tr key={item.idRow} className={`row-${item.idRow}`}>
                                <td className="user-account-search-container">
                                    <button type="button" className={`btn btn-primary w-100 btn-name-${item.idRow}`} data-toggle="modal" data-target={`#usersAdded-${item.idRow}`}>
                                        {t('accountName')}
                                    </button>
                                    <div className="modal fade" id={`usersAdded-${item.idRow}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title font-main"> اختر الحساب </h5>
                                                </div>
                                                <div className="modal-body">
                                                    <input
                                                        type="search"
                                                        autoComplete="off"
                                                        className={`form-control td-${item.idRow} td-nameAcount-${item.idRow}`}
                                                        ref={userRef}
                                                        onChange={(e) => handelChangeUsers(e)}
                                                    />
                                                    <ul className={`list-group list-group-filter-search  list-group-filter-search-2 text-left tdul-${idx}`}>
                                                        {users.map((option, i) => {
                                                            return (
                                                                <button key={i}
                                                                    type="button"
                                                                    className="list-group-item list-group-item-action"
                                                                    onClick={() => {
                                                                        document.querySelector(`.td-nameAcount-${item.idRow}`).value = option.accountName;
                                                                        document.querySelector(`.btn-name-${item.idRow}`).innerHTML = option.accountName;
                                                                        list.find(el => el.idRow == item.idRow).accountId = option.accountId;
                                                                        list.find(el => el.idRow == item.idRow).nameAccount = option.accountName;
                                                                        list.find(el => el.idRow == item.idRow).currencyId = +document.querySelector(`.input-balance-currency-${item.idRow}`).value;
                                                                        list.find(el => el.idRow == item.idRow).sY = +document.querySelector(`.input-balance-sY-${item.idRow}`).value;
                                                                        list.find(el => el.idRow == item.idRow).rate = +document.querySelector(`.input-balance-sY-${item.idRow}`).value;
                                                                        setUsers([]);
                                                                        GetBalanceUser(option.accountId, item.idRow);
                                                                    }}
                                                                    data-dismiss="modal" aria-label="Close"
                                                                >
                                                                    {option.accountName}
                                                                </button>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </td>

                                <td className="d-none"> <span className={`td-${idx} input-userIdRef-${idx}`}></span> </td>

                                <td>
                                    <select className={`form-control td-${idx} input-balance-currency-${item.idRow}`} onChange={e => selectCurreny(e.target.value, idx)} >
                                        {currencyList.map(curr => {
                                            return <option key={curr.currencyId} value={curr.currencyId}> {curr.currencyName} </option>
                                        })}
                                    </select>
                                </td>

                                <td>
                                    <input className={`form-control text-center td-${idx} input-balance-sY-${item.idRow}`} onChange={(e) => changeRateCurrency(e.target.value, idx)} type="text" disabled defaultValue={1} />
                                </td>


                                <td>
                                    <input
                                        type="number"
                                        className={`form-control text-center`}
                                        onChange={e => onChangeAmount(e.target.value, item.idRow)}
                                        min={1}
                                    />
                                </td>

                                <td>
                                    <input
                                        type="text"
                                        className={`form-control text-center`}
                                        onChange={e => onChangeDescription(e.target.value, item.idRow)}
                                    />
                                </td>
                                {/* <td className="center-account-search-container">
                                <input type="text" autoComplete="off" className={`form-control td-${idx}`} ref={centerRef} onChange={(e) => handelChangeCenters(e)} />
                                <ul className={`list-group list-group-filter-search  list-group-filter-search-2 text-left tdul-${idx}`}>
                                    {centers.map((option, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <button key={i} type="button" className="list-group-item list-group-item-action" onClick={() => {
                                                    centerRef.current.value = option.accountName;
                                                    document.querySelector(`.input-centerId-${idx}`).innerHTML = option.costCenterId;
                                                    setCenterIdRef(option.costCenterId)
                                                    setCenters([]);
                                                }}
                                                >
                                                    {option.accountName}
                                                </button>
                                            </React.Fragment>
                                        );
                                    })}
                                </ul>
                            </td> */}
                                <td className="d-none"> <span className={`td-${idx} input-centerId-${item.idRow}`}></span> </td>
                                <td className={`balance-td-${item.idRow}`}>0</td>

                                <td className="d-flex justify-content-center px-1">
                                    <button type="button" className={`btn px-x py-1 btn-danger`} onClick={() => removeRow(item.idRow)}> - </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default MrEmployee
