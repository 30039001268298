import React from "react";

function TableSummary({ List = [] }) {
    return (
        <div className="row">
            <div className="col-11 mx-auto">
                <table className="table text-center table-bordered table-new" dir="rtl">
                    <thead>
                        <tr className="text-center">
                            <th scope="col">م</th>
                            <th scope="col"> رقم الفاتورة </th>
                            <th scope="col">  تاريخ الفاتورة </th>
                            <th scope="col"> الرقم الضريبي </th>
                            <th> اسم المورد </th>
                            <th> الاجمالي قبل ض </th>
                            <th> ض </th>
                            <th>اجمالي بعد ض</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                            <th> -- </th>
                            <td> -- </td>
                            <td> -- </td>
                            <td> -- </td>
                            <td> -- </td>
                            <td> -- </td>
                            <td> -- </td>
                            <td> -- </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TableSummary;
