import React from 'react'

function ListSale() {
    return (
        <div className="list-sale my-3">
            {/* <table class="table table-striped" dir="rtl">
                <thead>
                    <tr>
                        <th className="th_invoice_sale"> الصنف </th>
                        <th className="th_invoice_sale"> الوحدة </th>
                        <th className="th_invoice_sale"> الكمية </th>
                        <th className="th_invoice_sale"> الحالة </th>
                    </tr>
                </thead>
                <tbody>


                </tbody>
            </table> */}
        </div>
    )
}

export default ListSale