

import MaterialTable from "material-table";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { ProductContext } from "../../../context/context";
import { useHistory } from 'react-router-dom';
import { AiFillPrinter } from "react-icons/ai";
import text from "../../../context/super.txt";


import searchIcon from "../../../images/assets/15.png";
import EditIcon from "../../../images/assets/11.png";
import printerIcon from "../../../images/assets/12.png";



import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';


function TransferVoucherTable({ details = null }) {
    const { GeneralVoucher = [], filterVoucher } = useContext(ProductContext);
    const [ReceiptVoucher, setReceiptVoucher] = useState([]);
    const history = useHistory();
    const [data, setData] = useState(null);

    useEffect(() => {
        if (GeneralVoucher.length != 0) {
            if (details) {
                setData(details)
            } else {
                setReceiptVoucher(GeneralVoucher.financialTransactionsList);
            }
        }
    }, [GeneralVoucher, details]);

    useEffect(() => {
        if (data) {
            setReceiptVoucher([data, ...GeneralVoucher.financialTransactionsList]);
        }
    }, [data]);

    const { t, i18n } = useTranslation();
    let dataCloumAr = [{
        title: t("setting"),
        field: "edit",
        cellStyle: { color: "#000", textAlign: "center" },
        render: (rowData) => {
            return (
                <React.Fragment>
                    <button className="btn btn-danger py-1 mx-1 px-1" onClick={(e) => filterVoucher(rowData.ftid)}>
                        <MdDelete />
                    </button>
                    <button className="btn btn-info py-1 mx-1 px-1" onClick={() => history.push(`/receipt-voucher/edit/${rowData.ftid}`)}>
                        <img src={EditIcon} className="iconMax" title="edit row" alt="" />
                        <FiEdit />
                    </button>
                    <button className="btn btn-primary py-1 mx-1 px-1" onClick={() => Print(rowData)}>
                        <img src={printerIcon} className="iconMax" title="print row" alt="" />
                    </button>
                </React.Fragment>
            );
        },
    },
    {
        title: t("total"),
        field: "totalAmount",
        cellStyle: { color: "#000", textAlign: "center" },
    },
    {
        title: t("registrationNumber"),
        field: "jvid",
        cellStyle: { color: "#000", textAlign: "center" },
    },
    {
        title: t("box"),
        field: "accountName",
        cellStyle: { color: "#000", textAlign: "center" },
    },
    {
        title: t("boxnum"),
        field: "accountId",
        cellStyle: { color: "#000", textAlign: "center" },
    },
    {
        title: t("description"),
        field: "description",
        cellStyle: { color: "#000", textAlign: "center" },
    },
    {
        title: t("status"),
        field: "status",
        cellStyle: { color: "#000", textAlign: "center" },
        render: (rowData) => (rowData.status == 1 ? "معتمد" : "غير معتمد"),
    },
    {
        title: t("date"),
        field: "ftdate",
        cellStyle: { color: "#000", textAlign: "center" },
    },
    {
        title: t("numJV"),
        field: "ftserial",
        cellStyle: { color: "#000", textAlign: "center" },
    },
    ];


    let dataCloumEn = [
        {
            title: t("numJV"),
            field: "ftserial",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("date"),
            field: "ftdate",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("status"),
            field: "status",
            cellStyle: { color: "#000", textAlign: "center" },
            render: (rowData) => (rowData.status == 1 ? "معتمد" : "غير معتمد"),
        },
        {
            title: t("description"),
            field: "description",
            cellStyle: { color: "#000", textAlign: "center" },
        },

        {
            title: t("boxnum"),
            field: "accountId",
            cellStyle: { color: "#000", textAlign: "center" },
        },
        {
            title: t("box"),
            field: "accountName",
            cellStyle: { color: "#000", textAlign: "center" },
        },

        {
            title: t("registrationNumber"),
            field: "jvid",
            cellStyle: { color: "#000", textAlign: "center" },
        },


        {
            title: t("total"),
            field: "totalAmount",
            cellStyle: { color: "#000", textAlign: "center" },
        },




        {
            title: t("setting"),
            field: "edit",
            cellStyle: { color: "#000", textAlign: "center" },
            render: (rowData) => {
                return (
                    <React.Fragment>
                        <button className="btn btn-danger py-1 mx-1 px-1" onClick={(e) => filterVoucher(rowData.ftid)}>
                            <MdDelete />
                        </button>
                        <button className="btn btn-info py-1 mx-1 px-1" onClick={() => history.push(`/receipt-voucher/edit/${rowData.ftid}`)}>
                            <img src={EditIcon} className="iconMax" title="edit row" alt="" />
                            <FiEdit />
                        </button>
                        <button className="btn btn-primary py-1 mx-1 px-1" onClick={() => Print(rowData)}>
                            <img src={printerIcon} className="iconMax" title="print row" alt="" />
                        </button>
                    </React.Fragment>
                );
            },
        },
    ];
    const [columns, setColumns] = useState(i18n.language == "en" ? dataCloumEn : dataCloumAr);



    const Print = row => {
        fetch(text)
            .then(r => r.text())
            .then(text => {
                document.querySelector(".loading").classList.remove("hideloader");
                fetch(`${text}/VoucherForm/GetAllDetails`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : "",
                        branchId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).branchId : "",
                        fyId: JSON.parse(localStorage.getItem("ControllAfaky")).financialYearId
                            ? JSON.parse(localStorage.getItem("ControllAfaky")).financialYearId
                            : JSON.parse(localStorage.getItem("ControllAfaky")).financialYear,
                        voucherType: 5,
                        ftId: row.ftid,
                    }),
                    redirect: "follow",
                }).then((response) => response.json())
                    .then((result) => {

                        document.querySelector(".loading").classList.add("hideloader");
                        if (result.isSuccess) {
                            let boxBankValue = {
                                accountName: row.accountName,
                                accountId: row.accountId,
                                label: row.accountName
                            }
                            let parms = {
                                name: "سند صرف بنكي",
                                data: row,
                                user: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")) : {},
                                boxBankValue,
                                customers: [],
                                result: result,
                            };
                            let handle = window.open(window.location.origin + "/PrinteReciptGeneral", "_blank", "width=" + window.screen.width + ",height=" + window.screen.height);
                            handle.window.parameters = JSON.stringify(parms);
                        }


                    })
                    .catch((error) => {
                        document.querySelector(".loading").classList.add("hideloader");
                        console.log("error users", error);
                    });

            });



    }


    return (
        <div style={{ maxWidth: "100%" }}>
            <MaterialTable
                columns={columns}
                data={ReceiptVoucher}
                title={null}
                style={{ backgroundColor: "rgb(169 190 204 / 71%)", fontWeight: "bold", color: "red !important" }}
                localization={{
                    toolbar: {
                        exportCSVName: "Export CSV",
                        exportPDFName: "Export PDF"
                    }
                }}

                icons={{
                    FirstPage: () => <FirstPage />,
                    LastPage: () => <LastPage />,
                    NextPage: () => <ChevronRight />,
                    PreviousPage: () => <ChevronLeft />,
                    Search: () => <img src={searchIcon} className="iconMax" alt="" />,
                    Clear: () => <Clear />,
                    DeleteOutline: () => <DeleteOutline />,
                    Export: () => <DeleteOutline />,
                }}

                options={{
                    search: true,
                    actionsColumnIndex: 1,
                    paginationType: "stepped",
                    headerStyle: {
                        backgroundColor: "rgb(169 190 204 / 71%)",
                        boxShadow: "1px 1px 1px 1px #dddddd85",
                        fontWeight: "bold"
                    },
                }}
            />
        </div>
    );
}

export default TransferVoucherTable;
