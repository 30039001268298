
import React, { useContext } from 'react'
import { ProductContext } from '../../context/context';

function TableItems(props) {

    const { Units = [] } = useContext(ProductContext);
    let { List = [], DefinitionItems,setRate, setSalePolicy, addItem, setBarCode, setDefaultM, setSalesV1, setSalesValue, setSalesV2, setSalesV3, setSalesV4,
        setSalesV5, setSmallUnit, setUnit, delItem, setSalesDiscount, setPurchasePolicy, setPurchaseDiscount, setValSalePolicy, setMinimumSalePrice,
        setSalesDiscountVal1, setSalesDiscountVal2, setPurchasePolicyVal, setPurchaseDiscountVal, setSuggestedSellingPrice
    } = props;
    // console.log("DefinitionItems is ", DefinitionItems.unit);

    return (
        <table className="table mt-4">
            <thead>
                <tr className="text-center">
                    <th scope="col" style={{ fontSize: "12px" }}> الوحدة </th>
                    <th scope="col" style={{ fontSize: "12px" }}> اصغر وحدة </th>
                    <th scope="col" style={{ fontSize: "12px" }}> معدل </th>
                    <th scope="col" className="px-1" style={{ fontSize: "12px" }}> افتراضي مشتريات </th>
                    <th scope="col" className="px-1" style={{ fontSize: "12px" }}> افتراضي مبيعات </th>
                    <th scope="col" className="px-1" style={{ fontSize: "12px" }}> باركود </th>
                    <th scope="col" className="px-0" style={{ fontSize: "12px" }}>اضافة القيمة  </th>
                </tr>
            </thead>
            <tbody>

                {List.map((itemList, i) => {
                    return (
                        <tr key={i} className="">
                            <td scope="row">
                                <select className="form-control" disabled={itemList.dis} onChange={e => setUnit(e.target.value)}>
                                    <option> ... </option>
                                    {DefinitionItems ? DefinitionItems.unit.map(unit => <option value={unit.unitId}> {unit.unitName} </option>) : ''}
                                </select>
                            </td>
                            {/* اصغر وحدة */}
                            <td>
                                <input className="form-control" type="number" disabled={itemList.dis} id={`smallUnit${i}`} onChange={e => setSmallUnit(e.target.value)} />
                            </td>
                            {/* معدل */}
                            <td>
                                <input className="form-control" disabled={itemList.dis} id={`rate${i}`} onChange={e => setRate(e.target.value)} />
                            </td>
                            {/* افتراضي مشتريات */}
                            <td className="text-center">
                                <input class="form-check-input" type="radio" name="DefaultM" onClick={e => setDefaultM(e.target.value)} value={true} />
                            </td>
                            {/* افتراضي مبيعات */}
                            <td className="text-center">
                                <input class="form-check-input" type="radio" name="SalesValue" onClick={e => setSalesValue(e.target.value)} value={true} />

                            </td>
                            {/* باركود	 */}
                            <td>
                                <input className="form-control" disabled={itemList.dis} id={`barCode${i}`} onChange={e => setBarCode(e.target.value)} />
                            </td>



                            <td className="px-0 d-flex align-items-center justify-content-between">
                                <button type="button" style={{ fontSize: "12px" }} disabled={itemList.dis} className="btn btn-primary py-1 mx-1 font-ar" data-toggle="modal" data-target={`#modalItem${i}`}> التسعير </button>
                                {itemList.dis ?
                                    // <button type="button" className="btn btn-danger py-0 mx-1 font-ar" onClick={() => delItem(itemList.id)}> Del </button>
                                    <button type="button" className="btn btn-danger py-0 mx-1 font-ar" onClick={() => alert('Soon')}> Del </button>
                                    : <button type="button" disabled={itemList.dis} className="btn btn-info py-0 mx-1 font-ar" onClick={() => addItem(itemList.id)}> + </button>}


                                <div className="modal fade" id={`modalItem${i}`} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" style={{ maxWidth: '70%' }}>
                                        <div className="modal-content">
                                            <div class="modal-header">
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">

                                                    {/* سياسة البيع	 */}
                                                    <div className="col-12 col-md-4">
                                                        <label style={{ fontSize: "14px" }}> سياسة البيع </label>
                                                        <select className="form-control" onChange={e => setSalePolicy(e.target.value)}>
                                                            <option value=".."> ... </option>
                                                            <option value="1"> سعر محدد </option>
                                                            <option value="2"> اخر فاتورة مبيعات </option>
                                                            <option value="3">اخر فاتورة مبيعات للعميل </option>
                                                            <option value="4"> سعر التكلفة مضاف اليه قيمة  </option>
                                                            <option value="5"> سعر التكلفة مضاف اليه نسبة  </option>
                                                            <option value="6"> اخر فاتورة مشتريات مضاف اليه قيمة  </option>
                                                            <option value="7"> اخر فاتورة مشتريات مضاف اليه نسبة  </option>
                                                            <option value="8"> تكلفة اخر فاتورة مشتريات مضاف اليه قيمة </option>
                                                            <option value="9"> تكلفة اخر فاتورة مشتريات مضاف اليه نسبة </option>
                                                            <option value="10"> وفقا للتسلسل الناتج عن تجميع الصنف </option>
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <label style={{ fontSize: "14px" }}> القيمة </label>
                                                        <input type="number" className="form-control" onChange={e => setValSalePolicy(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <label style={{ fontSize: "14px" }}> الحد الادني لسعر البيع </label>
                                                        <input type="number" className="form-control" onChange={e => setMinimumSalePrice(e.target.value)} />
                                                    </div>

                                                    <div className="col-12 col-md-4">
                                                        <label style={{ fontSize: "14px" }}>  قيمة 1 </label>
                                                        <input type="number" className="form-control" onChange={e => setSalesV1(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-2">
                                                        <label style={{ fontSize: "14px" }}>  قيمة 2 </label>
                                                        <input type="number" className="form-control" onChange={e => setSalesV2(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-2">
                                                        <label style={{ fontSize: "14px" }}>  قيمة 3 </label>
                                                        <input type="number" className="form-control" onChange={e => setSalesV3(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-2">
                                                        <label style={{ fontSize: "14px" }}>  قيمة 4 </label>
                                                        <input type="number" className="form-control" onChange={e => setSalesV4(e.target.value)} />
                                                    </div>

                                                    <div className="col-12 col-md-2">
                                                        <label style={{ fontSize: "14px" }}>  قيمة 5 </label>
                                                        <input type="number" className="form-control" onChange={e => setSalesV5(e.target.value)} />
                                                    </div>


                                                    {/* <div className="col-12 col-md-6">
                                                        <label>  قيمة 6 </label>
                                                        <input type="number" className="form-control" onChange={e => setSalesV5(e.target.value) }/>
                                                    </div> */}
                                                    {/* end	 */}






                                                    {/* خصم مبيعات */}
                                                    <div className="col-12 col-md-6 mt-2">
                                                        <label style={{ fontSize: "14px" }}>خصم مبيعات </label>
                                                        <select className="form-control" onChange={e => setSalesDiscount(e.target.value)}>
                                                            <option value="1"> قيمة  </option>
                                                            <option value="2"> نسبة </option>
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-md-3 mt-2">
                                                        <label style={{ fontSize: "14px" }}>  قيمة 1 </label>
                                                        <input type="number" className="form-control" onChange={e => setSalesDiscountVal1(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-3 mt-2">
                                                        <label style={{ fontSize: "14px" }}>  سعر بيع اخر </label>
                                                        <input type="number" className="form-control" onChange={e => setSalesDiscountVal2(e.target.value)} />
                                                    </div>
                                                    {/* end	 */}


                                                    {/* سياسة الشراء */}
                                                    <div className="col-12 col-md-6 mt-2">
                                                        <label style={{ fontSize: "14px" }}>سياسة الشراء  </label>
                                                        <select className="form-control" onChange={e => setPurchasePolicy(e.target.value)}>
                                                            <option value=".."> ... </option>
                                                            <option value="1"> سعر محدد </option>
                                                            <option value="2"> اخر فاتورة مشتريات   </option>
                                                            <option value="3"> اخر فاتورة مشتريات للمورد </option>
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-md-6 mt-2">
                                                        <label style={{ fontSize: "14px" }}> القيمة </label>
                                                        <input type="number" className="form-control" onChange={e => setPurchasePolicyVal(e.target.value)} />
                                                    </div>
                                                    {/* end	 */}


                                                    {/* خصم مشتريات */}
                                                    <div className="col-12 col-md-6 mt-2">
                                                        <label style={{ fontSize: "14px" }}>خصم مشتريات</label>
                                                        <select className="form-control" onChange={e => setPurchaseDiscount(e.target.value)}>
                                                            <option value=".."> ... </option>
                                                            <option value="1"> قيمة  </option>
                                                            <option value="2"> نسبة </option>
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-md-3 mt-2">
                                                        <label style={{ fontSize: "14px" }}> القيمة </label>
                                                        <input type="number" className="form-control" onChange={e => setPurchaseDiscountVal(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-3 mt-2">
                                                        <label style={{ fontSize: "14px" }}> سعر البيع المقترح </label>
                                                        <input type="number" className="form-control" onChange={e => setSuggestedSellingPrice(e.target.value)} />
                                                    </div>
                                                    {/* end	 */}


                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary font-ar" data-dismiss="modal">حفظ</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                })}





            </tbody>
        </table>
    )
}

export default TableItems