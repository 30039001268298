import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Stand(props) {
    const { t } = useTranslation();

    let { setCode, name_ar, setName_Ar, name_en, setName_EN, texCode, setTexCode, notes, setNotes, onSubmit, useTaxCode, linkReturn, each, setEach } = props;
    const checkChange = () => {
        setEach(!each)
    }

    useEffect(() => {
        if (each) {
            setName_EN(name_ar)
        }
        if (!each) {
            setName_EN('')
        }
    }, [each])


    return (
        <div className="col-12 col-md-10 mx-auto px-0">
            <form dir="rtl">
                <div className="row">
                    <div className="col-6 mx-auto my-2">
                        <label style={{ fontSize: "14px" }}>{t('code')}</label>
                        <input type="number" className="form-control" onChange={(e) => setCode(e.target.value)} disabled />
                    </div>
                    <div className="col-6 mx-auto my-2">
                        <label style={{ fontSize: "14px" }}>{t('namear')}</label>
                        <input type="text" className="form-control" value={name_ar} onChange={(e) => {
                            setName_Ar(e.target.value)
                            if (each) {
                                setName_EN(e.target.value)
                            }
                        }} />
                    </div>
                    <div className="col-6 mx-auto my-2">
                        <label style={{ fontSize: "14px" }} >{t('nameen')}</label>
                        <input type="text" className="form-control" value={name_en} onChange={(e) => setName_EN(e.target.value)} />
                    </div>
                    <div className="col-6 mx-auto align-self-center my-2">
                        <input type="checkbox" id='htmlEach' className="mx-3" checked={each} onChange={() => checkChange()} />
                        <label htmlFor='htmlEach'> {t('WriteEnglishArabic')} </label>
                    </div>
                    {useTaxCode ? <div className="col-6 mx-auto my-2">
                        <label style={{ fontSize: "14px" }}>{t('codeTax')}</label>
                        <input type="number" className="form-control" value={texCode} onChange={e => setTexCode(e.target.value)} />
                    </div> : ''}
                    <div className="col-6 my-2">
                        <label style={{ fontSize: "14px" }}> {t('Notes')} </label>
                        <textarea type="url" className="form-control" value={notes} onChange={e => setNotes(e.target.value)} ></textarea>
                    </div>
                </div>

                {props.children}

                <hr />
                <div className="submiting d-flex">
                    <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => onSubmit(e)}>
                        {t('save')}
                    </button>
                    <Link to={linkReturn} className="btn btn-primary mx-2 px-4">
                        {t('Retreat')}
                    </Link>
                </div>
            </form>
        </div>
    )
}

export default Stand