import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Hero from "../components/Hero";


import Layout from "../Layout/index";

export default function Home() {
    const { t , i18n} = useTranslation();

    return (
        <Layout>
            <div className="company text-right font-ar container companyHero py-5" style={{ maxWidth: "1170px", overflow: "hidden" }}>
                <div className="container-fluid py-4">
                    <div className="row mx-0">
                        <div className="col-12 col-md-11 mx-auto px-0 text-center">
                            <div className="d-flex justify-content-center align-items-center flex-column" style={{ minHeight: "50vh" }}>
                                <h2 className="text-center mb-2"> {t('afakyProgram')} </h2>
                                <div className="line-afaky my-2"></div>
                                <div className="line-second-afaky mb-5"></div>
                                <p className="font-main mb-3" style={{ fontWeight: "bold" }}> {t('afakyDescription')} </p>
                                <a href="https://programsshop.com" className="link-hero" target="blank">
                                    <button className="btn btn-danger">
                                        {t('afakyMainSite')}
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
