import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { ProductConsumer, ProductContext } from '../../context/context';
import Layout from "../../Layout/index";


function EditSup(props) {
    let id = props.match.params.id;
    console.log(props);
    console.log(id);
    const { addSupplier, customers } = useContext(ProductContext);
    const [name_ar, setName_Ar] = useState('');
    const [name_en, setName_EN] = useState('');

    const [user, setUser] = useState(() => {
        return customers.find(item => item.id == id)
    })


    return (
        <Layout>
            <div className="company text-right font-ar">
                <div className="container-fluid py-4">
                    <div className="row mx-0">
                        <div className="col-12 col-md-8 mx-auto px-0">
                            <form dir="rtl">
                                    <div className="form-group mx-auto">
                                        <label>الاسم بالعربي</label>
                                        <input type="text" value={name_ar} className="form-control" onChange={(e) => setName_Ar(e.target.value)} />
                                    </div>

                                    <div className="form-group mx-auto">
                                        <label>الاسم بالانجليزي</label>
                                        <input type="text" value={name_en} className="form-control" onChange={(e) => setName_EN(e.target.value)} />
                                    </div>


                                <div className="submiting d-flex">
                                    <button type="submit" className="btn btn-primary mx-2 px-4" >حفظ</button>
                                    <Link to="/suppliers" className="btn btn-primary mx-2 px-4">تراجع</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default EditSup
