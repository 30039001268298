import React from 'react'
import { GrPersonalComputer } from "react-icons/gr";
import { MdArrowDropDown } from "react-icons/md"

function EditUser() {
    return (
        <div className="container">
            <div className="row mx-0">
                <div className="col-12 col-md-4 mx-auto">
                    <div className="icon-system bg-light my-3 p-4 rounded d-flex justify-content-between align-items-center">
                        <div className="accordion w-100" id="accordionOne">
                                <div className="card" style={{background:"none",border:"0"}}>
                                    <div className="card-header d-flex justify-content-between align-items-center p-0" id="headingOne" style={{background:"none"}}>
                                        <GrPersonalComputer className="align-self-center text-primary" style={{fontSize:"25px"}} />
                                        <div>
                                            <div className="form-check form-check-inline mr-0">
                                                <label className="form-check-label" htmlFor="system-check-box mx-3 font-ar"> النظام </label>
                                                <input className="form-check-input mx-3" type="checkbox" id="system-check-box" value="system" />
                                            </div>
                                            <button className="btn btn-link text outline-none -left btn-link-icon" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <MdArrowDropDown />
                                            </button>
                                        </div>
                                    </div>

                                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionOne">
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="companys-check-box"> الشركات </label>
                                            <input className="form-check-input" type="checkbox" id="companys-check-box" value="companys" />
                                        </div>
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="branches-check-box"> الفروع </label>
                                            <input className="form-check-input" type="checkbox" id="branches-check-box" value="branches" />
                                        </div>
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="fincal-check-box"> السنوات المالية </label>
                                            <input className="form-check-input" type="checkbox" id="fincal-check-box" value="fincal years" />
                                        </div>
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="time-check-box"> الفترات المحاسبية </label>
                                            <input className="form-check-input" type="checkbox" id="time-check-box" value="times" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

                <div className="col-12 col-md-4 mx-auto">
                    <div className="icon-system bg-light my-3 p-4 rounded d-flex justify-content-between align-items-center">
                        <div className="accordion w-100" id="accordiotwo">
                                <div className="card" style={{background:"none",border:"0"}}>
                                    <div className="card-header d-flex justify-content-between align-items-center p-0" id="headingTwo" style={{background:"none"}}>
                                        <GrPersonalComputer className="align-self-center text-primary" style={{fontSize:"25px"}} />
                                        <div>
                                            <div className="form-check form-check-inline mr-0">
                                                <label className="form-check-label" htmlFor="setting-check-box mx-3 font-ar"> الاعدادات </label>
                                                <input className="form-check-input mx-3" type="checkbox" id="setting-check-box" value="system" />
                                            </div>
                                            <button className="btn btn-link text outline-none -left btn-link-icon" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                <MdArrowDropDown />
                                            </button>
                                        </div>
                                    </div>

                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordiotwo">
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="general-setteng-check-box"> اعدادات عامة </label>
                                            <input className="form-check-input" type="checkbox" id="general-general-setteng-check-box" value="general-setteng" />
                                        </div>
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="pc-setteng-check-box"> اعدادات الجهاز </label>
                                            <input className="form-check-input" type="checkbox" id="pc-setteng-check-box" value="pc-setteng" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

                <div className="col-12 col-md-4 mx-auto">
                    <div className="icon-system bg-light my-3 p-4 rounded d-flex justify-content-between align-items-center">
                        <div className="accordion w-100" id="accordionTh">
                                <div className="card" style={{background:"none",border:"0"}}>
                                    <div className="card-header d-flex justify-content-between align-items-center p-0" id="headingThr" style={{background:"none"}}>
                                        <GrPersonalComputer className="align-self-center text-primary" style={{fontSize:"25px"}} />
                                        <div>
                                            <div className="form-check form-check-inline mr-0">
                                                <label className="form-check-label" htmlFor="system-check-box mx-3 font-ar"> الحسابات </label>
                                                <input className="form-check-input mx-3" type="checkbox" id="system-check-box" value="system" />
                                            </div>
                                            <button className="btn btn-link text outline-none -left btn-link-icon" type="button" data-toggle="collapse" data-target="#collapseThr" aria-expanded="true" aria-controls="collapseThr">
                                                <MdArrowDropDown />
                                            </button>
                                        </div>
                                    </div>

                                    <div id="collapseThr" className="collapse" aria-labelledby="headingThr" data-parent="#accordionTh">
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="companys-check-box"> الشركات </label>
                                            <input className="form-check-input" type="checkbox" id="companys-check-box" value="companys" />
                                        </div>
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="branches-check-box"> الفروع </label>
                                            <input className="form-check-input" type="checkbox" id="branches-check-box" value="branches" />
                                        </div>
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="fincal-check-box"> السنوات المالية </label>
                                            <input className="form-check-input" type="checkbox" id="fincal-check-box" value="fincal years" />
                                        </div>
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="time-check-box"> الفترات المحاسبية </label>
                                            <input className="form-check-input" type="checkbox" id="time-check-box" value="times" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>


                <div className="col-12 col-md-4 mx-auto">
                    <div className="icon-system bg-light my-3 p-4 rounded d-flex justify-content-between align-items-center">
                        <div className="accordion w-100" id="accordionFour">
                                <div className="card" style={{background:"none",border:"0"}}>
                                    <div className="card-header d-flex justify-content-between align-items-center p-0" id="headingFour" style={{background:"none"}}>
                                        <GrPersonalComputer className="align-self-center text-primary" style={{fontSize:"25px"}} />
                                        <div>
                                            <div className="form-check form-check-inline mr-0">
                                                <label className="form-check-label" htmlFor="system-check-box mx-3 font-ar"> النظام </label>
                                                <input className="form-check-input mx-3" type="checkbox" id="system-check-box" value="system" />
                                            </div>
                                            <button className="btn btn-link text outline-none -left btn-link-icon" type="button" data-toggle="collapse" data-target="#collapseFor" aria-expanded="true" aria-controls="collapseFor">
                                                <MdArrowDropDown />
                                            </button>
                                        </div>
                                    </div>

                                    <div id="collapseFor" className="collapse" aria-labelledby="headingFour" data-parent="#accordionFour">
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="companys-check-box"> الشركات </label>
                                            <input className="form-check-input" type="checkbox" id="companys-check-box" value="companys" />
                                        </div>
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="branches-check-box"> الفروع </label>
                                            <input className="form-check-input" type="checkbox" id="branches-check-box" value="branches" />
                                        </div>
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="fincal-check-box"> السنوات المالية </label>
                                            <input className="form-check-input" type="checkbox" id="fincal-check-box" value="fincal years" />
                                        </div>
                                        <div className="form-check text-right p-0 mx-3 my-3">
                                            <label className="form-check-label mr-4" htmlFor="time-check-box"> الفترات المحاسبية </label>
                                            <input className="form-check-input" type="checkbox" id="time-check-box" value="times" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div> 

            </div>
        </div>
    )
}

export default EditUser
