import React from 'react'
import { Link } from 'react-router-dom';
import Layout from "../../Layout";
import VoucherDailyTable from './VoucherDailyTable';

function VoucherDaily() {

    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-11 mx-auto my-3 text-center">
                        <div className="text-right mb-2"> 
                            <Link to="/voucherDaily/add" className="text-light btn btn-primary py-0 font-ar"> اضافة </Link>
                        </div>
                        <VoucherDailyTable />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default VoucherDaily;