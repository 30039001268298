import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Roadmap from '../../components/Roadmap';


function Edit(props) {
    let id = props.match.params.id;
    const { UpdateUnit, Units } = useContext(ProductContext);
    const [code, setCode] = useState('');
    const [name_ar, setName_Ar] = useState('');
    const [name_en, setName_EN] = useState('');
    const [notes, setNotes] = useState('');
    const [texCode, setTexCode] = useState('');
    const [each, setEach] = useState(false);
    const [unit, setUnit] = useState({});

    const { t } = useTranslation();


    useEffect(() => {
        let unit = Units.find(item => item.id == id);
        if (unit) {
            setUnit(unit)
            setCode(unit.code)
            setName_Ar(unit.nameA)
            setName_EN(unit.nameE)
            setNotes(unit.notes)
            setTexCode(unit.taxCode)
        }

    }, [Units])
    const history = useHistory();

    const UpdateUnitSave = async (e) => {
        e.preventDefault();
        try {
            await UpdateUnit(id, code, name_ar, name_en, notes, texCode, setName_EN, setName_Ar, setNotes, setTexCode, history);
            // await history.push(`/Units`)
        } catch (e) {
            console.log(e);
        }
    }
    const checkChange = () => {
        setEach(!each)
    }

    useEffect(() => {
        if (each) {
            setName_EN(name_ar)
        }
        console.log(unit);
        if (!each) {
            setName_EN(unit.nameE)
        }
    }, [each, unit])


    return (
        <Layout>
            <div className="company text-right font-ar container companyReciptVoucher py-5" style={{ maxWidth: "1100px" }}>
                <div className="container py-4">
                    <div className="row mx-0">
                        <Roadmap name={t('define_units')} child={t('edit')} childTo={{ pathname: '/Units', params: { status: 2 } }} />
                        <div className="col-12 col-md-10 mx-auto px-0">
                            <form dir="rtl">
                                <div className='row'>
                                    <div className='col-6 mx-auto'>
                                        <div className="form-group mx-auto my-2">
                                            <label style={{ fontSize: "14px" }}>{t('code')}</label>
                                            <input type="number" className="form-control" value={code} disabled />
                                        </div>
                                    </div>
                                    <div className='col-6 mx-auto my-2'>
                                        <div className="form-group mx-auto">
                                            <label style={{ fontSize: "14px" }}>{t('namear')}</label>
                                            <input type="text" className="form-control" value={name_ar} onChange={(e) => setName_Ar(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='col-6 mx-auto my-2'>
                                        <div className="form-group mx-auto">
                                            <label style={{ fontSize: "14px" }} label>{t('nameen')}</label>
                                            <input type="text" className="form-control" value={name_en} onChange={(e) => setName_EN(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='col-6 mx-auto my-2'>
                                        <input type="checkbox" id='htmlEach' className="mx-3" checked={each} onChange={() => checkChange()} />
                                        <label htmlFor='htmlEach'> {t('WriteEnglishArabic')} </label>
                                    </div>
                                    {/* <div className='col-6 mx-auto'></div>
                                        <div className='col-6 mx-auto'></div>
                                        <div className='col-6 mx-auto'></div> */}

                                </div>

                                {/* <div className="form-group mx-auto">
                                        <label style={{ fontSize: "14px" }}>{t('namear')}</label>
                                        <input type="text" className="form-control" value={name_ar} onChange={(e) => setName_Ar(e.target.value)} />
                                    </div> */}

                                {/* <div className="form-group mx-auto">
                                        <label style={{ fontSize: "14px" }} label>{t('nameen')}</label>
                                        <input type="text" className="form-control" value={name_en} onChange={(e) => setName_EN(e.target.value)} />
                                    </div> */}
                                {/* <div className="row">
                                        <div className="col-12 col-md-6 my-2">
                                            <input type="checkbox" className="" checked={each} onChange={() => checkChange()} />
                                        </div>
                                    </div> */}

                                <div className="row py-2">
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label style={{ fontSize: "14px" }}>{t('codeTax')}</label>
                                        <input type="number" className="form-control" value={texCode} onChange={e => setTexCode(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label style={{ fontSize: "14px" }}> {t('Notes')} </label>
                                        <textarea type="url" className="form-control" value={notes} onChange={e => setNotes(e.target.value)} ></textarea>
                                    </div>
                                </div>
                                <hr />
                                <div className="submiting d-flex">
                                    <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => UpdateUnitSave(e)}> تعديل </button>
                                    <Link to={{ pathname: '/Units', params: { status: 2 } }} className="btn btn-primary mx-2 px-4">تراجع</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Edit
