import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import DataTable from './DataTable';

function Customers() {

    const { allRefrence = [] } = useContext(ProductContext);

    let customersRefrence = {};
    if(allRefrence.length != 0){
        customersRefrence = allRefrence.find(item => item.mName == "TsmCustomers") ? allRefrence.find(item => item.mName == "TsmCustomers") : false; 
    }


    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-11 mx-auto my-3 text-center">
                        <div className="text-right mb-2"> 
                            <button disabled={customersRefrence.chkPrint ? false : true } className="text-light btn btn-primary py-0 font-ar mx-2"> طباعة </button>
                            {customersRefrence.chkAdd 
                                ? <Link to="/customer/add" className="text-light btn btn-primary py-0 font-ar"> اضافة </Link> 
                                : <button disabled className="text-light btn btn-primary py-0 font-ar"> اضافة </button>
                            }
                        </div>
                        <DataTable Refrencecustomers={customersRefrence} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Customers
