import React, { useEffect, useRef, useState } from 'react'
import Layout from "../../Layout/index.js";
import text from "../../context/super.txt";
import { useTranslation } from "react-i18next";


function License() {

    const [token, setToken] = useState(false);
    const [mainUrl, setMainUrl] = useState('');
    const [codeSecurity, setCodeSecurity] = useState("");
    const [codeUnlockSecurity, setCodeUnlockSecurity] = useState('');
    const [versionErp, setVersionErp] = useState('');
    const [customerName, setCustomerName] = useState('');

    const [statusErp, setStatusErp] = useState('');
    const [notes, setNotes] = useState('');

    const { t, i18n } = useTranslation();



    let active = localStorage.getItem("afakyLogin")
        ? JSON.parse(localStorage.getItem("afakyLogin"))
        : false;

    useEffect(() => {
        const getToken = () => localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : false;

        setToken(getToken())
        if (token) {
            getMostData()
        }
    }, [token])


    const getMostData = () => {
        document.querySelector(".loading").classList.remove("hideloader");
        let MostData = new Headers();
        MostData.append("Content-Type", "application/json");
        MostData.append("Authorization", `Bearer ${token}`);

        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {

                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(newUrl => {
                            setMainUrl(newUrl)
                            requestsData(newUrl);
                            requestsData2(newUrl);
                            requestsData3(newUrl);
                        })
                        .catch(error => console.log('error', error));
                } else {
                    setMainUrl(text)
                    requestsData(text);
                    requestsData2(text);
                    requestsData3(text);
                }


            });
    }



    const requestsData = async apiUrl => {
        document.querySelector(".loading").classList.remove("hideloader");

        let headerLicense = new Headers();
        headerLicense.append("Content-Type", "application/json");
        headerLicense.append("Authorization", `Bearer ${token}`);
        let requestOpasdasdtions = {
            method: 'GET',
            headers: headerLicense,
            redirect: 'follow'
        };
        fetch(`${apiUrl}/AfakyLicense/VersionERP`, requestOpasdasdtions)
            .then(response => response.json())
            .then(result => {
                document.querySelector(".loading").classList.add("hideloader");
                setVersionErp(result)
            })
            .catch(error => {
                console.log('error', error)
            });
    }

    const requestsData2 = async apiUrl => {
        document.querySelector(".loading").classList.remove("hideloader");
        let headerLicense = new Headers();
        headerLicense.append("Content-Type", "application/json");
        headerLicense.append("Authorization", `Bearer ${token}`);
        let requestOpasdasdtions = {
            method: 'GET',
            headers: headerLicense,
            redirect: 'follow'
        };
        fetch(`${apiUrl}/AfakyLicense/GetSerialERP`, requestOpasdasdtions)
            .then(response => response.text())
            .then(result => {
                document.querySelector(".loading").classList.add("hideloader");
                setCodeSecurity(result)
                console.log(result);
            })
            .catch(error => {
                console.log('error', error)
            });
    }

    const requestsData3 = async apiUrl => {
        document.querySelector(".loading").classList.remove("hideloader");
        let headerLicense = new Headers();
        headerLicense.append("Content-Type", "application/json");
        headerLicense.append("Authorization", `Bearer ${token}`);
        let requestOpasdasdtions = {
            method: 'GET',
            headers: headerLicense,
            redirect: 'follow'
        };
        fetch(`${apiUrl}/AfakyLicense/CheckLicense`, requestOpasdasdtions)
            .then(response => response.json())
            .then(result => {
                document.querySelector(".loading").classList.add("hideloader");
                if (result.isSuccess) {
                    setStatusErp(result.message)
                }
            })
            .catch(error => {
                console.log('error', error)
            });
    }


    const Send = (e) => {
        e.preventDefault();
        let headerLicense = new Headers();
        headerLicense.append("Content-Type", "application/json");
        headerLicense.append("Authorization", `Bearer ${token}`);
        let requestOpasdasdtions = {
            method: 'POST',
            headers: headerLicense,
            redirect: 'follow',
            body: JSON.stringify({
                "licenseType": 1,
                "protectionSerial": codeUnlockSecurity,
                "customerName": customerName,
                "notes": notes
            })
        };
        fetch(`${mainUrl}/AfakyLicense/RegisterLicenseERP`, requestOpasdasdtions)
            .then(response => response.json())
            .then(result => {
                if (result.isSuccess) {
                    alert(result.message);
                    window.location.replace(window.location.origin + "/");
                } else {
                    alert("فشلت عملية الترخيص");
                    window.location.replace(window.location.origin + "/");
                }
            })
            .catch(error => {
                console.log('error', error)
            });
    }




    return (
        <Layout>
            <div className="license">
                <div className="company text-right font-ar companyReciptVoucher px-0 py-5" style={{ maxWidth: "1170px" }}>
                    <div className="container-fluid AccountStatement">
                        <div className={`row ${i18n.language == "en" ? "lefting" : "text-right"} my-5`}>
                            <form>
                                <div className={`col-12 col-md-10 mx-auto ${i18n.language == "en" ? "lefting" : "text-right"}`}>
                                    <label htmlFor="" className='mb-2'> {t('versionNumber')} </label>
                                    <input type="text" disabled className={`form-control mb-2 ${i18n.language == "en" ? "lefting" : "text-right"} input`} value={versionErp} />
                                </div>

                                <div className="col-12 col-md-10 mx-auto">
                                    <label htmlFor="" className='mb-2'> {t('licensestatus')} </label>
                                    <input type="text" disabled className={`form-control ${i18n.language == "en" ? "lefting" : "text-right"} mb-2 input`} value={statusErp} />
                                </div>


                                <div className="col-12 col-md-10 mx-auto">
                                    <label htmlFor="" className='mb-2'> {t('securityCode')} </label>
                                    <input type="text" disabled className={`form-control mb-2 ${i18n.language == "en" ? "lefting" : "text-right"} input`} value={codeSecurity} />
                                </div>

                                <div className="col-12 col-md-10 mx-auto">
                                    <label htmlFor="" className='mb-2'> {t('securityUnLoakCode')} </label>
                                    <input type="text" className={`form-control mb-2 ${i18n.language == "en" ? "lefting" : "text-right"} input`} value={codeUnlockSecurity} onChange={e => setCodeUnlockSecurity(e.target.value)} />
                                </div>




                                <div className="col-12 col-md-10 mx-auto">
                                    <label htmlFor="" className='mb-2'> {t('customerName')} </label>
                                    <input type="text" className={`form-control ${i18n.language == "en" ? "lefting" : "text-right"} mb-2 input`} value={customerName} onChange={e => setCustomerName(e.target.value)} />
                                </div>
                                <div className="col-12 col-md-10 mx-auto">
                                    <label htmlFor="" className='mb-2'> {t('Notes')} </label>
                                    <input
                                        type="text"
                                        className={`form-control ${i18n.language == "en" ? "lefting" : "text-right"} mb-2 input`}
                                        value={notes}
                                        onChange={e => setNotes(e.target.value)}
                                    />
                                </div>
                                <div className="col-12 col-md-10 mx-auto">
                                    <label htmlFor="" className='mb-2'> رقم النسخة </label>
                                    <input
                                        type="text"
                                        disabled
                                        className={`form-control ${i18n.language == "en" ? "lefting" : "text-right"} mb-2 input`}
                                        value={"286.4"}
                                    />
                                </div>

                                <div className="col-12 col-md-10 mx-auto mt-3">
                                    <button type='button' className='btn btn-danger mx-3' onClick={() => window.location.replace(window.location.origin + "/")}> {t('cancel')} </button>
                                    <input type="submit" className='btn btn-success px-3' value={t('Send')} onClick={(e) => Send(e)} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </Layout >
    )
}

export default License