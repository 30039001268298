import React, { useContext, useState } from 'react'
import Layout from "../../Layout";
import { Link } from "react-router-dom"
import { ProductContext } from '../../context/context';

function AddCustomers() {
    const { addSupplier } = useContext(ProductContext);
    const [category, setCategory] = useState([]);
    const [code, setCode] = useState('');
    const [name_ar, setName_Ar] = useState('');
    const [name_en, setName_EN] = useState('');
    const [cat, setCat] = useState('');
    const [checked, setChecked] = useState(false);

    

    const Categories = (e) => {
        e.preventDefault();

        if(name_ar == "") {
            alert('من فضلك اكمل البيانات بشكل صحيح')
        }else {
            if (!checked){
                if(cat == ""){
                    alert('اختار القسم')
                }else {
                    addSupplier({id: Math.random() * 10, name_ar,name_en,code, cat, checked: checked})
                    let Cat = [...category, {id: Math.random() * 10 ,name_ar,name_en,code, cat, checked: checked}];
                    setName_Ar("")
                    setName_EN("")
                    setCode("")
                    setCategory(Cat)
                    setCat('')
                    setChecked(false)
                }
            } else {
                let Cat = [...category, {id: Math.random() * 10 ,name_ar,name_en,code, cat, checked: checked}];
                addSupplier({id: Math.random() * 10, name_ar,name_en,code, cat, checked: checked})
                setName_Ar("")
                setName_EN("")
                setCode("")
                setCategory(Cat)
                setChecked(false)
                setCat('')
            }
        }

    }


    return (
        <Layout>
            <div className="company text-right font-ar">
                <div className="container-fluid py-4">
                    <div className="row mx-0">
                        <div className="col-12 col-md-10 mx-auto px-0">
                            <form dir="rtl">

                                <div className="row py-2">
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label>الكود</label>
                                        <input type="number" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto">
                                        <div className="form-group">
                                            <label> المجموعة </label>
                                            <select className="font-ar form-control">
                                                <option> </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row py-2">
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label>الاسم بالعربي</label>
                                        <input type="text" value={name_ar} className="form-control" onChange={(e) => setName_Ar(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label>الاسم بالانجليزي</label>
                                        <input type="text" value={name_en} className="form-control" onChange={(e) => setName_EN(e.target.value)} />
                                    </div>
                                </div>

                                <ul className="nav nav-tabs mx-0 px-0 my-4" id="myTabSuppliers" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" id="home-tab-suppliers" data-toggle="tab" href="#home-suppliers" role="tab" aria-controls="home-suppliers" aria-selected="true"> بيانات الاتصال </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="profile-tab-supplier" data-toggle="tab" href="#balance-supplier" role="tab" aria-controls="balance-supplier" aria-selected="false">الرصيد الافتتاحي</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="contact-tab-bill-supplier" data-toggle="tab" href="#contact-bill-supplier" role="tab" aria-controls="contact-bill-supplier" aria-selected="false">تكامل الفاتورة الضريبية</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="more-tab-supplier" data-toggle="tab" href="#more-supplier" role="tab" aria-controls="more-supplier" aria-selected="false"> المزيد </a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home-suppliers" role="tabpanel" aria-labelledby="home-tab-suppliers">
                                        
                                        <div className="row py-2">
                                            <div className="col-12 col-md-6 mx-auto">
                                                <label> رقم التسجيل بضريبة القيمة المضافة </label>
                                                <input type="number" value={name_ar} className="form-control" onChange={(e) => setName_Ar(e.target.value)} />
                                            </div>
                                            <div className="col-12 col-md-6 mx-auto">
                                                <label>تلفون</label>
                                                <input type="number" value={name_en} className="form-control" onChange={(e) => setName_EN(e.target.value)} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="balance-supplier" role="tabpanel" aria-labelledby="profile-tab-supplier">...</div>
                                    <div className="tab-pane fade" id="contact-bill-supplier" role="tabpanel" aria-labelledby="contact-tab-bill-supplier">...</div>
                                    <div className="tab-pane fade" id="more-supplier" role="tabpanel" aria-labelledby="more-tab-supplier">...</div>
                                </div>

                                {/* <div className="form-check">
                                    <input className="form-check-input" checked={checked} type="checkbox" onClick={() => setChecked(!checked)}  id="checkParent" />
                                    <label className="form-check-label mr-4" for="checkParent">
                                        متفرع
                                    </label>
                                </div> */}


                                

                                <div className="submiting d-flex mt-4">
                                    <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => Categories(e)}>حفظ</button>
                                    <Link to="/customers" className="btn btn-primary mx-2 px-4">تراجع</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AddCustomers
