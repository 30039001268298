import React, { useState } from 'react'
import Select from 'react-select'
import DatePicker from "react-datepicker";

function PaymentDetails({ paymentDetails = [], addPayment, DelPayment, typePayment = [] }) {

    const [date, setDate] = useState(new Date());
    const handelPayment = (el, id) => {
        let item = paymentDetails.find(item => item.id == id);
        item.payment = el.value;
        item.paymentId = el.id;
        item.date = date;
    }
    const handelAmount = (val, id) => {
        let item = paymentDetails.find(item => item.id == id);
        item.amount = val;
    }
    const handelDate = (date, id) => {
        let item = paymentDetails.find(item => item.id == id);
        item.date = date;
    }
    const handelPaper = (val, id) => {
        let item = paymentDetails.find(item => item.id == id);
        item.paper = val;
    }

    const handelBank = (el, id) => {
        let item = paymentDetails.find(item => item.id == id);
        item.bank = el.value;
        item.bankId = el.id;
    }
    const handelRecet = (el, id) => {
        let item = paymentDetails.find(item => item.id == id);
        item.recet = el.value;
        item.resetId = el.id;
    }

    return (
        <div className="payment-details py-3 mb-4">
            <table class="table table-striped" dir="rtl">
                <thead>
                    <tr>
                        <th className="th_invoice_sale"> م </th>
                        <th className="th_invoice_sale"> طريقة الدفع </th>
                        <th className="th_invoice_sale"> اسم الحساب</th>
                        <th className="th_invoice_sale"> المبلغ </th>
                        <th className="th_invoice_sale"> تاريخ الاستحقاق </th>
                        <th className="th_invoice_sale"> رقم الورقة </th>
                        <th className="th_invoice_sale"> البنك </th>
                        <th className="th_invoice_sale"> المستلم </th>
                        <th className="th_invoice_sale">
                            <button type="button" onClick={() => addPayment()} className="btn btn-success"> + </button>
                        </th>
                    </tr>
                </thead>
                <tbody className="body-details-sales">
                    {paymentDetails.map(({ id }, i) => {
                        return (
                            <tr key={id}>
                                <td className="th_invoice_sale"> {i + 1} </td>
                                <td className="th_invoice_sale">
                                    <Select options={typePayment} onChange={e => handelPayment(e, id)} placeholder="طريقة الدفع" />
                                </td>
                                <td className="th_invoice_sale">
                                    <Select options={[]} placeholder="اختر الحساب" />
                                </td>
                                <td className="th_invoice_sale">
                                    <input type="number" onChange={e => handelAmount(e.target.value, id)} className="form-control" />
                                </td>
                                <td className="th_invoice_sale">
                                    <DatePicker style={{ width: "100%" }} className="form-control mt-1 formatDate" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={date} onChange={(date) => handelDate(date, id)} />
                                </td>
                                <td className="th_invoice_sale">
                                    <input type="number" onChange={e => handelPaper(e.target.value, id)} className="form-control" />
                                </td>
                                <td className="th_invoice_sale">
                                    <Select options={[]} onChange={e => handelBank(e, id)} />
                                </td>
                                <td className="th_invoice_sale">
                                    <Select options={[]} onChange={e => handelRecet(e, id)} />
                                </td>
                                <td className="th_invoice_sale"> <button type="button" className="btn btn-danger" onClick={() => DelPayment(id)}> x </button> </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default PaymentDetails