import React from 'react'
import * as StyleSummary from '../../SummaryReports/SummaryReports.module.css';
import Pdf from "react-to-pdf";


const ref = React.createRef();

export default function GeneratePDF({ data = [] }) {

    // const [data, setData] = useState([])

    return (
        <div className="App">
            <Pdf targetRef={ref} filename="SummaryReports.pdf">
                {({ toPdf }) => <button className='btn btn-primary' onClick={toPdf}> PDF </button>}
            </Pdf>
            <div ref={ref} className={`${StyleSummary.table_wrapper_scroll_PDF}`}>

                <div className="col-12 mx-auto px-0" style={{ width: "100%" }}>
                    <table className="table table-bordered table-hover table-striped mb-0 text-center" dir='rtl'>
                        <thead>
                            <tr className='head-bg text-light'>
                                <th> الكود </th>
                                <th> التاريخ </th>
                                <th> الصنف </th>
                                <th> السعر </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => {
                                return (
                                    <tr>
                                        <td> {item.itemCode} </td>
                                        <td> {item.invoiceDate} </td>
                                        <td> {item.itemName} </td>
                                        <td> {item.netPrice} </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
