import React , { useContext, useEffect, useState } from "react";
import Tabs  from "./Tabs";
import Layout from "../../Layout";
import { Link } from "react-router-dom";
import { ProductContext } from "../../context/context";
import defautImg from "../../images/image.png"


function Company(props) {
    const { newCompany , Companines = [] } = useContext(ProductContext);

    const [code, setCode] = useState(''); // الكود
    const [nameAr, setNameAr] = useState(''); // الاسم بالعربي
    const [nameEn, setNameEN] = useState(''); // الاسم بالانجليزي
    const [taxNumber, setTaxNumber] = useState(''); // الرقم الضريبي
    const [VATnumber, setVATnumber] = useState(''); // رقم التسجيل بضريبة القيمة المضافة
    const [dataCreated, setDataCreated] = useState(''); // تاريخ الانشاء
    const [commercialNumber, setCommercialNumber] = useState(''); // الرقم التجاري
    const [addres, setAddres] = useState(''); // العنوان
    const [phone1, setPhone1] = useState(''); // موبيل 1
    const [phone2, setPhone2] = useState(''); // موبيل 2
    const [typeExport, setTypeExport] = useState(''); // نوع التوريد
    const [cellPhone, setCellPhone] = useState(''); // جول
    const [email, setEmail] = useState(''); // البريد الالكتروني
    const [fax, setFax] = useState(''); // فاكس
    const [webSite, setWebSite] = useState(''); // ويب
    const [notes, setNotes] = useState(''); // ويب


    let company = new Object();
    if(props.match.params.id){
        company = Companines.find(item => item.companyId == props.match.params.id);
    };

    const handelChangeImage = () => {
        const imgFile = document.querySelector("#fileLogo").files[0];
        const defImg = document.getElementById("defImg");
        const reader = new FileReader();
        reader.addEventListener("load",function () {
            defImg.src = reader.result;
        },false);
        if (imgFile) {
            reader.readAsDataURL(imgFile);
        }

        console.log(document.getElementById('defImg').src);
    };

    
    return (
        <Layout>
            <div className="company text-right font-ar">
                <div className="container-fluid py-4">
                    <div className="row mx-0">
                        <div className="col-12 col-md-10 mx-auto px-0">
                            <form dir="rtl">
                                <div className="row py-2">
                                    <div className="col-12 col-md-12 mx-auto text-center my-3">
                                        <img src={defautImg} width="100" height="100" id="defImg" className="d-block mx-auto" /> 
                                        <input type="file" onChange={handelChangeImage} className="" id="fileLogo" placeholder="sdkmkls" />
                                    </div>
                                    <div className="col-12 col-md-2 mx-auto">
                                        <label htmlFor="codeCompany">كود الشركة</label>
                                        <input type="number" disabled className="form-control" value={Object.keys(company).length != 0 ?  company.companyId : code} onChange={e => setCode(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-md-5 mx-auto">
                                        <label htmlFor="nameAr">الاسم بالعربي</label>
                                        <input type="text" className="form-control" value={company.nameA ? company.nameA : nameAr} id="nameAr" onChange={e => setNameAr(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-md-5 mx-auto">
                                        <label htmlFor="nameEn">الاسم بالانجليزي</label>
                                        <input type="text" className="form-control" value={company.nameA ? company.nameE : nameEn} id="nameEn" onChange={e => setNameEN(e.target.value)} />
                                    </div>
                                </div>

                                <hr className="my-4" />
                                <Tabs 
                                    taxNumber={taxNumber}
                                    VATnumber={VATnumber}
                                    dataCreated={dataCreated}
                                    commercialNumber={commercialNumber}
                                    addres={addres}
                                    phone1={phone1}
                                    phone2={phone2}
                                    typeExport={typeExport}
                                    cellPhone={cellPhone}
                                    email={email}
                                    fax={fax}
                                    webSite={webSite}
                                    notes={notes}
                                    setTaxNumber={setTaxNumber}
                                    setVATnumber={setVATnumber}
                                    setDataCreated={setDataCreated}
                                    setCommercialNumber={setCommercialNumber}
                                    setAddres={setAddres}
                                    setPhone1={setPhone1}
                                    setPhone2={setPhone2}
                                    setTypeExport={setTypeExport}
                                    setCellPhone={setCellPhone}
                                    setEmail={setEmail}
                                    setFax={setFax}
                                    setWebSite={setWebSite}
                                    setNotes={setNotes}
                                />

                                <div className="submiting d-flex">
                                    <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => newCompany(e,{"nameA":nameAr,"nameE":nameEn,"taxNumber":taxNumber,"insuranceNumber":VATnumber,"address":addres}, setNameAr, setNameEN)}>حفظ</button>
                                    <Link to="/companies" className="btn btn-primary mx-2 px-4 text-light"> تراجع </Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Company;
