import React, { useContext, useEffect, useState } from "react";
import { ProductContext } from "../../context/context";
import Layout from "../../Layout";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import text from '../../context/super.txt'

function PointSettings() {
    const { config, token } = useContext(ProductContext);
    const [dataValidation, setDataValidation] = useState({});
    const { t } = useTranslation();
    const getToken = () => localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : '';

    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        if (dataValidation.rPrinterType === 1) {
            $("#exampleRadios1").attr('checked', true);
        }
        if (dataValidation.rPrinterType === 2) {
            $("#exampleRadios2").attr('checked', true);
        }
        if (dataValidation.rPrinterType === 3) {
            $("#exampleRadios3").attr('checked', true);
        }
        if (dataValidation.alphabeticalSortedN === 0) {
            $("#Es2").attr('checked', true);
        }
        if (dataValidation.alphabeticalSortedN === 1) {
            $("#Es3").attr('checked', true);
        }
        if (dataValidation.alphabeticalSortedN === 2) {
            $("#Es4").attr('checked', true);
        }
    }, [dataValidation])


    const getData = () => {
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            fetch(`${result}/PosForm/PosPrinterSettingGetAll`, {
                                method: 'get',
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": `Bearer ${getToken()}`
                                },
                            })
                                .then(response => response.json())
                                .then(result => {
                                    if (result.isSuccess) {
                                        setDataValidation(result.data[0])
                                    }
                                })
                        })
                        .catch(error => console.log('error', error));
                }

                if (!isCheck) {


                    fetch(`${text}/PosForm/PosPrinterSettingGetAll`, {
                        method: 'get',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${getToken()}`
                        },
                    })
                        .then(response => response.json())
                        .then(result => {
                            if (result.isSuccess) {
                                setDataValidation(result.data[0])
                            }
                        })


                }
            })

    }

    const saveSetting = () => {
        let printofsale = document.querySelector('.printofsale').value; // اعداد طابعة نقطة البيع
        let prescriptionPrinter = document.querySelector('.prescriptionPrinter').value; // اعدادات طابعة الروشتة
        let activeres = document.getElementById('activeres').checked ? true : false; // تنشيط اعداد المطعم
        let showpriceninres = document.getElementById('showpriceninres').checked ? true : false; // عرض السعر في فاتورة المطبخ
        let nameGroup = document.getElementById('nameGroup').checked ? true : false; // المجموعة الرئيسية
        let type = ''; // شاشة العرض هذة الطابعة or مجموعة الصنف or الصنف
        let type2 = ''; // شاشة العرض هذة الطابعة or مجموعة الصنف or الصنف
        let numRunText = document.getElementById('numRun').value; // هذة الطابعة text
        let assemblePrinterName = document.getElementById('assemblePrinterName').value; // الطابعة   text المجمعة
        let assemblePrinter = document.getElementById('assemblePrinter').checked ? true : false; // الطابعة المجمعة
        let printFromFrontEnd = document.getElementById('printFromFrontEnd').checked ? true : false; //
        let posPintAfterSave = document.getElementById('posPintAfterSave').checked ? true : false; // 


        let rScreenActive = document.getElementById('rScreenActive').checked ? true : false; // 
        let Ip = document.getElementById('ip').value; // Ip

        document.querySelectorAll('.radiob').forEach(item => {
            if (item.checked) {
                type = item.value
            }
        })
        document.querySelectorAll('.radiob2').forEach(item => {
            if (item.checked) {
                type2 = item.value
            }
        })

        fetch(`${config}/PosForm/PosPrinterSettingSave`, {
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                computerName: "",
                posPrinter: printofsale,
                prescriptionPrinter: prescriptionPrinter,
                rPrinterActive: activeres,
                showPrices: showpriceninres,
                rPrinterType: +type,
                rPrinterName: numRunText,
                mainGroup: nameGroup,
                assemblePrinter: assemblePrinter,
                assemblePrinterName: assemblePrinterName,
                posPintAfterSave: posPintAfterSave,
                printFromFrontEnd: printFromFrontEnd,
                alphabeticalSortedN: +type2,
                rScreenActive,
                rsip: Ip
            }),
        })
            .then(response => response.json())
            .then(result => {
                alert(result.message)
            })
            .catch(error => {
                alert("حدث خطا !")
            });
    }

    return (
        <Layout>
            <div className="company text-right font-ar companyReciptVoucher px-0 py-5" style={{ maxWidth: "1170px" }}>
                <div className="container-fluid AccountStatement">
                    <div className="container-fluid">
                        <form className="row mx-0">
                            <div className="col-11 mx-auto my-3 text-center">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="text-right">
                                            <label htmlFor="" className="text-right"> {t('Prescriptionprintsettings')} </label>
                                            <input type="text" className="form-control text-right prescriptionPrinter" defaultValue={dataValidation.prescriptionPrinter} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="text-right">
                                            <label htmlFor="" className="text-right" > {t('settingPOSPrint')} </label>
                                            <input type="text" className="form-control text-right printofsale" defaultValue={dataValidation.posPrinter} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div class="col-12 col-md-6 mb-3 mt-4 form-check px-0">
                                        <h6 className="text-right mb-4">
                                            {/* {t('display')} */}
                                        </h6>
                                        <div className="">
                                            <div className="form-group d-flex align-items-center my-2">
                                                <input className="form-control w-75" type="text" name="numrun" id="numRun" defaultValue={dataValidation.rPrinterName} />
                                                <div className="form-check d-flex flex-row-reverse w-25">
                                                    <input className="form-check-input radiob" type="radio" style={{ margin: "auto 0" }} name="exampleRadios" id="exampleRadios1" value="1" />
                                                    <label className="form-check-label " for="exampleRadios1">
                                                        {t('Thisprinter')}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-check text-right my-2 FormStyle">
                                                <label className="form-check-label pr-4" style={{ margin: "0 33px" }} for="exampleRadios2">
                                                    {t('Itemgroup')}
                                                </label>
                                                <input className="form-check-input radiob" type="radio" name="exampleRadios" id="exampleRadios2" value="2" />
                                            </div>
                                            <div className="form-check text-right my-2 FormStyle">
                                                <label className="form-check-label pr-4" style={{ margin: "0 33px" }} for="exampleRadios3">
                                                    {t('Item')}
                                                </label>
                                                <input className="form-check-input radiob" type="radio" name="exampleRadios" id="exampleRadios3" value="3" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div class="mb-3 mt-4 form-check  text-right px-3">
                                            <h6 className="text-right mb-4">
                                                {t('restaurantprintersettings')}
                                            </h6>
                                            <div className="">
                                                <div class="form-check my-2 FormStyle">
                                                    <label class="form-check-label" style={{ margin: "0 33px" }} for="activeres">
                                                        {t('active')}
                                                    </label>
                                                    <input type="checkbox" class="form-check-input ml-2" id="activeres" defaultChecked={dataValidation.rPrinterActive} />
                                                </div>
                                                <div class="form-check my-2 FormStyle">
                                                    <label class="form-check-label" style={{ margin: "0 33px" }} for="showpriceninres">
                                                        {t('Showthepriceinthekitchenbill')}
                                                    </label>
                                                    <input type="checkbox" class="form-check-input ml-2" id="showpriceninres" defaultChecked={dataValidation.showPrices} />
                                                </div>

                                                <div class="form-check my-2 FormStyle">
                                                    <label class="form-check-label" style={{ margin: "0 33px" }} for="nameGroup">
                                                        {t('groupMain')}
                                                    </label>
                                                    <input type="checkbox" class="form-check-input ml-2" id="nameGroup" defaultChecked={dataValidation.mainGroup} />
                                                </div>

                                                <div class="form-check my-2 FormStyle">
                                                    <label class="form-check-label" style={{ margin: "0 33px" }} for="posPintAfterSave">
                                                        {t('printaftersaving')}
                                                    </label>
                                                    <input type="checkbox" class="form-check-input ml-2" id="posPintAfterSave" defaultChecked={dataValidation.posPintAfterSave} />
                                                </div>
                                                <div class="form-check my-2 FormStyle">
                                                    <label class="form-check-label" style={{ margin: "0 33px" }} for="printFromFrontEnd">
                                                        {t('printfromweb')}
                                                    </label>
                                                    <input type="checkbox" class="form-check-input ml-2" id="printFromFrontEnd" defaultChecked={dataValidation.printFromFrontEnd} />
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6 mb-3 mt-4 form-check px-0">
                                        <div className="">
                                            <div className="form-group d-flex align-items-center">
                                                <input className="form-control w-50" type="text" name="assemblePrinterName" id="assemblePrinterName" defaultValue={dataValidation.assemblePrinterName} />
                                                <div className="form-check d-flex flex-row-reverse w-50">
                                                    <input className="form-check-input" type="checkbox" name="assemblePrinter" id="assemblePrinter" value="1" />
                                                    <label className="form-check-label mr-4" style={{ margin: "0 33px" }} for="assemblePrinter">
                                                        {t('Combinedprinter')}
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row flex-row-reverse">
                                    <div class="col-12 col-md-6 mb-3 mt-4 form-check px-0">
                                        <h6 className="text-right mb-4"> {t('arrangeGroupsAndItems')} </h6>
                                        <div className="">
                                            <div className="form-check text-right my-2 FormStyle">
                                                <label className="form-check-label pr-4" style={{ margin: "0 33px" }} for="Es2"> {t('input')} </label>
                                                <input className="form-check-input radiob2" type="radio" name="Es" id="Es2" value="0" />
                                            </div>
                                            <div className="form-check text-right my-2 FormStyle">
                                                <label className="form-check-label pr-4" style={{ margin: "0 33px" }} for="Es3"> {t('alphabetic')} </label>
                                                <input className="form-check-input radiob2" type="radio" name="Es" id="Es3" value="1" />
                                            </div>
                                            <div className="form-check text-right my-2 FormStyle">
                                                <label className="form-check-label pr-4" style={{ margin: "0 33px" }} for="Es4"> {t('Code')} </label>
                                                <input className="form-check-input radiob2" type="radio" name="Es" id="Es4" value="2" />
                                            </div>
                                        </div>
                                    </div>



                                    <div class="col-12 col-md-6 mb-3 mt-4 form-check px-0">
                                        <h6 className="text-right mb-4"> {t('RestaurantScreenSettings')} </h6>
                                        <div className="">
                                            <div className="form-check text-right my-2 FormStyle">
                                                <label className="form-check-label pr-4" htmlFor="rScreenActive" style={{ margin: "0 33px" }} > {t('activate')} </label>
                                                <input type="checkbox" class="form-check-input ml-2" id="rScreenActive" defaultChecked={dataValidation.rScreenActive} />

                                            </div>
                                            <div className="form-check text-right my-2 FormStyle">
                                                <input className="form-control" type="text" name="ip" id="ip" defaultValue={dataValidation.rsip} />
                                                <label className="form-check-label pr-4" style={{ margin: "0 33px" }}> {t('IPAdress')} </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 text-right">
                                        <button className="btn btn-primary px-5" type="button" onClick={() => saveSetting()}> {t('save')} </button>
                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default PointSettings;
