import React, { useContext, Suspense, useState, useEffect } from "react";
import { FaBars, FaShoppingCart, FaExchangeAlt } from "react-icons/fa";
import { ProductContext } from "../context/context";
import logo from "../context/main.png";
import logo2 from "../images/assets/logo.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import $ from "jquery";

import iconHeaderShop from '../images/assets/16.png'
import transformation from "../images/assets/back.png";
import listIcon from "../images/assets/List.png";


export default function Navbar() {
    const [ListData, setListData] = useState([]);
    const { t, i18n } = useTranslation();


    const { handelSidebar, GelAllCompanies = [], token, user, config, myControll, lang, onChangeLanguage, onChangeFinicalYears, allRefrence = [], getCompanies } = useContext(ProductContext);
    const history = useHistory();



    useEffect(() => {
        if (GelAllCompanies.length !== 0) {
            setListData(GelAllCompanies);
        }
    }, [GelAllCompanies]);

    const openSalesValue = () => {
        window.open(
            window.location.origin + "/SaleInvoice",
            "_blank",
            "width=" + window.screen.width + ",height=" + window.screen.height
        );
    };


    const GetAll = () => {
        fetch(`${config}/UserCbf/GetAll`, {
            method: "post",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userId: user.userId,
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data.status == 200) {
                    let active = data.data.find((item) => item.active === true);
                    localStorage.removeItem("ControllAfaky");
                    localStorage.setItem(
                        "ControllAfaky",
                        JSON.stringify(active)
                    );
                    window.location.reload();
                }
            })
            .catch((err) => {
                console.log("error:", err);
            });
    };

    const SaveFinical = () => {
        $("input[type=radio]").each(function () {
            if (this.checked) {
                fetch(`${config}/UserCbf/Save`, {
                    method: "post",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: user.userId,
                        companyId: $(this).attr("data-comid"),
                        branchId: $(this).attr("data-brid"),
                        financialYearId: $(this).attr("data-ftid"),
                    }),
                })
                    .then((res) => res.json())
                    .then((data) => data.status === 200 ? GetAll() : alert(data.message))
                    .catch((err) => {
                        console.log("error:", err);
                    });
            }
        });
    };

    const getModal = () => {
        getCompanies()
    }



    return (
        <Suspense fallback="Loading ...">
            <div className="nav-bar-top">
                <div className={`nav-center py-2 nav-roteded ${lang == "ar" ? "flex-row-reverse" : ""}`}>
                    <div>
                        <button
                            className="btn py-1 px-2 mx-2"
                            onClick={() => openSalesValue()}
                            disabled={allRefrence.length != 0 ? allRefrence.find((item) => item.mName == "TsmOpenPOS").showForm ? "" : true : true}>
                            <img src={iconHeaderShop} className="iconMax" alt="" />
                            {/* <FaShoppingCart className="text-light btn btn-primary p-0" onClick={() => openSalesValue()} /> */}
                        </button>
                        {/* <FaBars onClick={handelSidebar} className="icon-nav text-muted" /> */}
                        <img src={listIcon} onClick={handelSidebar} className="iconMax" style={{ cursor: "pointer" }} alt="" />
                    </div>
                    <img src={logo || logo2} width="100" height="50" style={{ cursor: "pointer" }} onClick={() => history.push("/")} alt="" />
                    <div className="hashRoute">
                        <div className="d-flex form-group font-ar py-0 my-0">
                            <div className="d-flex">
                                <select className="form-control" disabled={allRefrence.length != 0 ? allRefrence.find((item) => item.mName == "TsmLangauges").showForm ? "" : true : true} id="languages" onChange={(e) => onChangeLanguage(e, i18n)} >
                                    <option value="ar" id="ar" selected={i18n.language == "ar" ? "selected" : ""}>
                                        اللغة العربية
                                    </option>
                                    <option value="en" id="en" selected={i18n.language == "en" ? "selected" : ""}>
                                        English
                                    </option>
                                    <option value="fr" id="fr" selected={i18n.language == "fr" ? "selected" : ""}>
                                        French
                                    </option>
                                </select>
                            </div>

                            <div className={`parent-showing d-flex ${i18n.language == "ar" ? "flex-row-reverse" : ""}`}>
                                <input type="text" className="form-control mx-2 text-center" style={{ maxWidth: "100px" }} disabled value={user.userName} />
                                <input type="text" className="form-control mx-2 text-center" style={{ maxWidth: "120px" }} disabled value={myControll.companyNameA} />
                                <input type="text" className="form-control mx-2 text-center" style={{ maxWidth: "100px" }} disabled value={myControll.branchNameA} />
                                <input type="text" className="form-control mx-2 text-center" style={{ maxWidth: "100px" }} disabled value={myControll.financialCode} />
                            </div>



                            <div className="icon-change-modalFinincalYear">
                                <button
                                    type="button" disabled={allRefrence.length != 0 ? allRefrence.find((item) => item.mName == "TsmUserCBF").showForm ? "" : true : true}
                                    className="btn p-0"
                                    title="تحديد السنة الفعالة"
                                    onClick={() => getModal()}
                                    data-toggle="modal" data-target="#modalFinincalYear" data-backdrop="static" data-keyboard="false"
                                >
                                    {/* <FaExchangeAlt /> */}
                                    <img src={transformation} className="iconMax" alt="" />
                                </button>

                                <div className="modal fade overlay" id="modalFinincalYear">
                                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: "60%" }}>
                                        <div className="modal-content" style={{ backgroundColor: "#a9becc" }}>
                                            <div className="modal-header flex-row-reverse">
                                                <h5 className="modal-title">
                                                    {t("effectiveyear")}
                                                </h5>
                                                <button type="button" style={{ backgroundColor: "#a9becc" }} className="close m-0 p-0" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">
                                                        &times;
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="fy-sy d-flex justify-content-between flex-row-reverse">
                                                    <table className="table text-center" dir="rtl">
                                                        <thead>
                                                            <tr className="text-center">
                                                                <th scope="col"> {t('company')} </th>
                                                                <th scope="col"> {t('branches')} </th>
                                                                <th scope="col"> {t('fiscal_years')} </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center">
                                                            {ListData.map((item, i) => {
                                                                if (ListData.length != 0) {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <th> {item.companyNameA} </th>
                                                                            <td> {item.branchNameA} </td>
                                                                            <td>
                                                                                <div className="d-flex flex-row-reverse justify-content-between align-items-center">
                                                                                    <input
                                                                                        type="radio"
                                                                                        defaultChecked={item.active}
                                                                                        ftId={item.financialCode}
                                                                                        class="form-check-input form-check-input-btn"
                                                                                        value={item.financialYearId}
                                                                                        data-brId={item.branchId}
                                                                                        data-comId={item.companyId}
                                                                                        data-ftId={item.financialYearId}
                                                                                        name="ComABrAFy"
                                                                                    />
                                                                                    <button className="btn-war-fn">
                                                                                        <span>
                                                                                            {item.financialCode}
                                                                                        </span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                } else {
                                                                    return "";
                                                                }
                                                            })}
                                                        </tbody>
                                                    </table>

                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                                    {t("cancel")}
                                                </button>
                                                <button type="button" className="btn btn-primary" onClick={() => SaveFinical()}>
                                                    {t("save")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Suspense>
    );
}
