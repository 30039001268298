import React, { useEffect, useState } from "react";
import moment from "moment";
import logo from "../context/image.png"
import logo2 from "../images/assets/image.png"


function AccountStatementSmall() {


    useEffect(() => {
        document.querySelector(".loading").classList.add("hideloader");
    }, []);

    const [company, setCompany] = useState("");
    const [branch, setBranch] = useState("");
    const [list, setList] = useState([]);
    const [customer, setCustomer] = useState("");
    const [start, setStart] = useState("");
    const [user, setUser] = useState({});
    const [end, setEnd] = useState("");

    useEffect(() => {
        if (window.parameters) {
            let items = JSON.parse(window.parameters);
            if (items) {
                // console.log(items);
                setUser(items.user)
                setList(items.data);
                setCompany(items.user.companyNameA);
                setBranch(items.user.branchNameA);
                setCustomer(items.customer);
                setStart(items.datefrom);
                setEnd(items.dateto);
            }
        }
    }, []);

    useEffect(() => {
        const printData = () => {
            window.focus();
            window.print();
        };
        printData();
    }, []);

    let totaling = 0;
    let totalingDebit = 0;
    let totalingCredit = 0;

    {/* <img src={logo} width="400" height="auto" alt="" /> */ }

    return (
        // <div className="container-fluid">
        //     <div className="details-invoice-account-statement">
        //         <div className="col-12 mx-auto text-center my-4">
        <div className="" style={{ width: "100%" }}>
            <div className="col-print col-print-account-statment mx-auto text-center my-1">
                <div className="details-invoice my-4">
                    <div className="details-invoice my-4">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <h6 className="fontGlobal"> {company.companyNameE} </h6>
                            <img src={logo2} width="80" height="60" alt="logo company" />
                            <h6 className="fontGlobal"> {company.companyNameA} </h6>
                        </div>
                        <h6
                            className="text-center mb-1 fontGlobal"
                            style={{ textDecoration: "underline" }}
                        >
                            تقرير كشف حساب
                        </h6>
                        <div className="d-flex justify-content-end">
                            <p className="px-3 fontGlobal">
                                {user.companyNameA}
                            </p>
                            <h6 className="fontGlobal"> : اسم الشركة </h6>
                        </div>


                        <div className="d-flex justify-content-end">
                            <p className="px-3 fontGlobal">
                                {customer}
                            </p>
                            <h6 className="fontGlobal"> : العميل </h6>
                        </div>


                        <div className="d-flex justify-content-end">
                            <p className="px-3 fontGlobal">
                                {moment(start).format("YYYY/MM/DD")}
                            </p>
                            <h6 className="fontGlobal"> : من تاريخ </h6>
                        </div>
                        <div className="d-flex justify-content-end">
                            <p className="px-3 fontGlobal">
                                {moment(end).format("YYYY/MM/DD")}
                            </p>
                            <h6 className="fontGlobal"> : الي تاريخ </h6>
                        </div>
                        <hr />
                        <table className="table table-striped tableAccount" dir="rtl">
                            <thead>
                                <tr className="trAccount">
                                    <th className="fontGlobal tdAccount"> التاريخ </th>
                                    <th className="fontGlobal tdAccount"> المصدر </th>
                                    <th className="fontGlobal tdAccount"> رقم المصدر </th>
                                    <th className="fontGlobal tdAccount"> مدين </th>
                                    <th className="fontGlobal tdAccount"> داين </th>
                                    <th className="fontGlobal tdAccount"> الرصيد </th>
                                </tr>
                            </thead>
                            <tbody className="font-small-responsive">
                                {list.map(({ journalVoucherTypeName, jvDate, jvId, sourceNo, credit, debit, debitLocal, source, creditLocal, description, dtlDescription }, idx) => {
                                    if (source == 0) {
                                        if (debitLocal) {
                                            debit = debitLocal;
                                        }
                                        if (creditLocal) {
                                            credit = creditLocal;
                                        }
                                    }
                                    if (debit != 0) {
                                        totaling += +debit;
                                        totalingDebit += debit;
                                    }
                                    if (credit != 0) {
                                        totaling -= credit;
                                        totalingCredit += credit;
                                    }

                                    return (
                                        <tr className="text-center trAccount" key={idx}>

                                            <td className="fontGlobal tdAccount"> {moment(jvDate).format('YYYY-DD-MM H:M a')} </td>
                                            <td className="fontGlobal tdAccount"> {journalVoucherTypeName} </td>
                                            <td className="fontGlobal tdAccount"> {sourceNo} </td>
                                            <td className="fontGlobal tdAccount"> {+debit.toFixed(2)} </td>
                                            <td className="fontGlobal tdAccount"> {+credit.toFixed(2)} </td>
                                            <td className={totaling >= 0 ? "text-success fontGlobal tdAccount" : "text-danger fontGlobal tdAccount"}>
                                                {+totaling.toFixed(2)}
                                            </td>
                                        </tr>
                                    );
                                }
                                )}
                                <tr className="text-center trAccount">
                                    <th className="fontGlobal tdAccount"> الاجمالي </th>
                                    <td className="fontGlobal tdAccount"></td>
                                    <td className="fontGlobal tdAccount"></td>
                                    <td className="fontGlobal tdAccount"> {totalingDebit} </td>
                                    <td className="fontGlobal tdAccount"> {totalingCredit} </td>
                                    <td className={totaling >= 0 ? "text-success fontGlobal tdAccount" : "text-danger fontGlobal tdAccount"}>
                                        {+totaling.toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="footerinvoceprintaccountststaemnet" style={{ width: "100%" }} >
                            <hr />
                            <div className="d-flex justify-content-center fontGlobal">
                                <p className="px-3 fontGlobal">
                                    {moment().format("DD/MM/yyyy h:mm:ss a")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
}

export default AccountStatementSmall;
