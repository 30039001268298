import React, { useContext, useState } from 'react'
import Layout from "../../Layout";
import { Link } from "react-router-dom"
import { ProductContext } from '../../context/context';
// import { BiShow } from "react-icons/bi"


function AddUser() {
    const [name_ar, setName_Ar] = useState('');
    const [name_en, setName_EN] = useState('');
    const [numberUser, setNumberUser] = useState('');
    const [checked, setChecked] = useState("");

    

    const SaveUser = (e) => {
        e.preventDefault();
        console.log({
            name_ar,
            name_en,
            numberUser,
            type: checked
        });

    }



    const changePassOne = () => {
        let pa = document.getElementById('pass1');
        if(pa.type == "text"){
            pa.type = "password"
        }else {
            pa.type = "text"
        }
    }
    const changePassConfirm = () => {
        let pa = document.getElementById('pass2');
        if(pa.type == "text"){
            pa.type = "password"
        }else {
            pa.type = "text"
        }
    }

    return (
        <Layout>
            <div className="company text-right font-ar">
                <div className="container-fluid py-4">
                    <div className="row mx-0">
                        <div className="col-12 col-md-10 mx-auto px-0">
                            <form dir="rtl">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="userType" onChange={(e) => setChecked(e.target.value)} id="mangement" value="mangement user"  />
                                    <label className="form-check-label mr-4" for="mangement">
                                        مدير ادارة
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="userType" onChange={(e) => setChecked(e.target.value)} id="normalUser" value="normal user"  />
                                    <label className="form-check-label mr-4" for="normalUser" style={{fontSize: "18px"}}>
                                        مستخدم عام
                                    </label>
                                </div>

                                <div className="row py-2 mt-4">
                                    <div className="col-12 col-md-6">
                                        <label> رقم المستخدم </label>
                                        <input type="number" onChange={ (e) => setNumberUser(e.target.value) } value={numberUser} className="form-control" />
                                    </div>
                                </div>

                                <div className="row py-2">
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label>الاسم بالعربي</label>
                                        <input type="text" value={name_ar} className="form-control" onChange={(e) => setName_Ar(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label>الاسم بالانجليزي</label>
                                        <input type="text" value={name_en} className="form-control" onChange={(e) => setName_EN(e.target.value)} />
                                    </div>
                                </div>

                                <div className="row py-2">
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label>كلمة المرور</label>
                                        <div className="d-flex align-items-center pointerPassword">
                                            <input type="password" id="pass1" className="form-control" />
                                            {/* <BiShow className="text-muted icon-pass" onChange={() => changePassOne()} style={{fontSize: "20px", cursor:"pointer"}} /> */}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto">
                                        <div className="form-group">
                                            <label> تاكيد كلمة المرور </label>
                                            <div className="d-flex align-items-center pointerPassword">
                                                <input type="password" className="form-control" id="pass2" />
                                                {/* <BiShow className="text-muted icon-pass" onChange={() => changePassConfirm()} style={{fontSize: "20px", cursor:"pointer"}} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                <div className="submiting d-flex mt-4">
                                    <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => SaveUser(e)}>حفظ</button>
                                    <Link to="/users" className="btn btn-primary mx-2 px-4">تراجع</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AddUser;