import React from 'react'

function BasicData(props) {
    let { 
            taxNumber,
            VATnumber,
            dataCreated,
            commercialNumber,
            addres,
            phone1,
            phone2,
            typeExport,
            cellPhone,
            email,
            fax,
            webSite,
            notes,
            setTaxNumber, 
            setVATnumber,
            setDataCreated,
            setCommercialNumber,
            setAddres,
            setPhone1,
            setPhone2,
            setTypeExport,
            setCellPhone,
            setEmail,
            setFax,
            setWebSite,
            setNotes
    } = props;

    return (
        <div className="py-4">
            <div className="row py-2">
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="taxNumber" style={{fontSize:"14px"}}>الرقم الضريبي</label>
                    <input type="number"  value={taxNumber} onChange={e => setTaxNumber(e.target.value)} className="form-control" />
                </div>
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="valueAdded" style={{fontSize:"14px"}}>رقم التسجيل بضريبة القيمة المضافة</label>
                    <input type="number" value={VATnumber} onChange={e => setVATnumber(e.target.value)} className="form-control" />
                </div>
            </div>

            <div className="row py-2">
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="dateCreated" style={{fontSize:"14px"}}>تاريخ الانشاء</label>
                    <input type="date" dir="ltr" value={dataCreated} onChange={e => setDataCreated(e.target.value)} className="form-control" />
                </div>
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="commercialNumber" style={{fontSize:"14px"}}>الرقم التجاري</label>
                    <input type="number" value={commercialNumber} onChange={e => setCommercialNumber(e.target.value)} className="form-control" />
                </div>
            </div>

            <div className="form-group pt-3">
                <label htmlFor="addres" style={{fontSize:"14px"}}>العنوان</label>
                <input type="addres" value={addres} onChange={e => setAddres(e.target.value)} className="form-control" />
            </div>
            <div className="row py-2">
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="phone1" style={{fontSize:"14px"}}>موبيل</label>
                    <input type="number" value={phone1} onChange={e => setPhone1(e.target.value)} className="form-control" />
                </div>
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="phone2" style={{fontSize:"14px"}}>موبيل</label>
                    <input type="number" value={phone2} onChange={e => setPhone2(e.target.value)} className="form-control" id="phone2" />
                </div>
            </div>

            

            <div className="row py-2">
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="typeExport" style={{fontSize:"14px"}}>نوع التوريد</label>
                    <input type="text" value={typeExport} onChange={e => setTypeExport(e.target.value)} className="form-control" id="typeExport" />
                </div>
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="cellPhone" style={{fontSize:"14px"}}>جوال</label>
                    <input type="number" value={cellPhone} onChange={e => setCellPhone(e.target.value)} className="form-control" id="cellPhone" />
                </div>
            </div>


            <div className="row">
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="emailCompany" style={{fontSize:"14px"}}>البريد الالكتروني</label>
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} className="form-control" id="emailCompany" />
                </div>
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="fax" style={{fontSize:"14px"}}>فاكس</label>
                    <input type="number" value={fax} onChange={e => setFax(e.target.value)} className="form-control" id="fax" />
                </div>
            </div>

            <div className="row py-2">
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="website" style={{fontSize:"14px"}}>موقع الانترنت</label>
                    <input type="url" value={webSite} onChange={e => setWebSite(e.target.value)} className="form-control" id="website" />
                </div>
                <div className="col-12 col-md-6 mx-auto">
                    <label htmlFor="topic" style={{fontSize:"14px"}}>ملاحظات</label>
                    <textarea type="url" value={notes} onChange={e => setNotes(e.target.value)} className="form-control" id="topic"></textarea>
                </div>
            </div>

        </div>
    )
}

export default BasicData
