import React, { useRef, useState, useContext, useMemo, useEffect } from "react";
import Table from "./Table";
import TablePay from "../../components/POSTables/TablePay";
import PayTabs from "../../components/POSTables/PayTabs";
import { ProductContext } from "../../context/context";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import ListMainGroups from "../../components/POSTables/ListMainGroups";
import ItemsMainGroups from "../../components/POSTables/ItemsMainGroups";
import { firstModal, getDeverOrder, getTableOrder, handelDeverOrder, handelTable, handeltables, printDeverOrder } from "./FunctionsPOS";


export default function POSTables() {
    const { t, i18n } = useTranslation();
    const { DefinitionItemsT, CashCollection, mostData = [], user, token, config, HoldInvoice, getMostData } = useContext(ProductContext);

    const inputRef = useRef();
    const userRef = useRef();

    useEffect(() => {
        document.getElementById("mostClick").addEventListener("click", (e) => {
            if (e.target.id == "openUser") {
                if (document.querySelector(".list-group-filter-search-1")) {
                    document
                        .querySelector(".list-group-filter-search-1")
                        .classList.remove("d-none");
                    document
                        .querySelector(".list-group-filter-search-2")
                        .classList.remove("d-none");
                }
            } else {
                if (document.querySelector(".list-group-filter-search-1")) {
                    document
                        .querySelector(".list-group-filter-search-1")
                        .classList.add("d-none");
                    document
                        .querySelector(".list-group-filter-search-2")
                        .classList.add("d-none");
                }
            }
        });
    }, []);


    // terms
    const [typePrint, setTypePrint] = useState(false);
    const [posPintAfterSave, setPosPintAfterSave] = useState(false);
    const [Dates, setDates] = useState({});
    const [modify, setModify] = useState(false);
    const [openPOS, setOpenPOS] = useState(false);
    const [modifyPrice, setModifyPrice] = useState(false);
    const [modifyDiscount, setModifyDiscount] = useState(false);
    const [modifyQuantity, setModifyQuantity] = useState(false);
    const [returned, setReturned] = useState(false);
    const [changeAccount, setChangeAccount] = useState(false);
    const [deleteAddedRow, setDeleteAddedRow] = useState(false);
    const [invoiceHold, setInvoiceHold] = useState(false);
    const [cancelCheque, setCancelCheque] = useState(false);
    const [stores, setStores] = useState([]);
    const [storesId, setStoresId] = useState(1);
    const [close, setClose] = useState(false);
    const [settingCompany, setSettingCompany] = useState({});



    // Holds invoice 
    const [AllHolds, setAllHolds] = useState([]);



    const [search, setSearch] = useState("");
    const [searchId, setSearchId] = useState("");



    const [MainGroups, setMainGroups] = useState([]);
    const [ItemsGroupsFilter, setItemsGroupsFilter] = useState([]);
    const [ChildGroupsFilter, setChildGroupsFilter] = useState([]);
    const [ChildGroupsFiltering, setChildGroupsFiltering] = useState([]);
    const [POSTables, setPOSTables] = useState([]);
    const [POSTablesCheck, setPOSTablesCheck] = useState(false);


    const [taxSourceRate, setTaxSourceRate] = useState(0); // ضريبة المنبع

    window.onload = function () {
        getMostData();
        window.document.body.classList.add('overwrite')
    }

    useEffect(() => {
        if (mostData.length !== 0) {
            document.querySelector(".loading").classList.add("hideloader");
            let userD = mostData.customerList.find(customer => customer.posDefaultCusCash == true);
            if (userD) {
                setSearch(userD.customerName)
                setSearchId(userD.id)
            }
            if (mostData.itemsGroupMainList) {
                setMainGroups(mostData.itemsGroupMainList)
                setChildGroupsFilter(mostData.itemsGroupChildList)
            }
            setModify(mostData.modifyItem)
            setPOSTables(mostData.posInvoiceTypeList);
            setOpenPOS(mostData.openPOS)
            setModifyPrice(mostData.modifyPrice)
            setModifyQuantity(mostData.modifyQuantity)
            setReturned(mostData.returned)
            setChangeAccount(mostData.changeAccount)
            setDeleteAddedRow(mostData.deleteAddedRow)
            setInvoiceHold(mostData.invoiceHold)
            setCancelCheque(mostData.cancelCheque)
            setAllHolds(mostData.invoiceHodeList)
            setModifyDiscount(mostData.modifyDiscount)
            setStores(mostData.storesList)
            setStoresId(mostData.storesList.find(st => st.defaultStore) ? mostData.storesList.find(st => st.defaultStore).storeId : 1);
            setClose(false)
            setSettingCompany(mostData.companyList[0])
            setTaxSourceRate(mostData.companyList[0].taxSourceRate)
            setTypePrint(mostData.printFromFrontEnd)
            setPosPintAfterSave(mostData.posPintAfterSave)
            setDates(mostData)
        } else {
            setClose(true)
        }
    }, [mostData])



    useEffect(() => {
        if (!POSTablesCheck && Dates.posInvoiceTypeId) {
            $(`option#idT${Dates.posInvoiceTypeId}`).attr('selected', true);
            setPOSTablesCheck(true)
        }


    }, [POSTables])

    const [taxTableRate, setTaxTableRate] = useState(0);
    const [falseed, setFalseed] = useState(false);


    const [returnBtn, setReturnBtn] = useState(false);
    const [returnBtnHold, setReturnBtnHold] = useState(false);
    const [typeInvoice, setTypeInvoice] = useState(10);
    const [PriceAfterDiscount, setPriceAfterDiscount] = useState(0);
    const [total, setTotal] = useState(0);
    const [totalCheck, setTotalCheck] = useState(0);
    const [list, setList] = useState([]);
    const [options, setOptions] = useState([]);
    // vise
    const [cach, setCach] = useState("");
    const [visa, setVisa] = useState("");
    const [masterCard, setMasterCard] = useState(0);
    const [credit, setCredit] = useState("");
    const [totalCredit, setTotalCredit] = useState("");
    const [totalMatserCard, setTotalMatserCard] = useState(0);
    const [listPayments, setListPayments] = useState([]);

    // add customer state
    const [userCustomer, setUserCustomer] = useState("");
    const [mobilsUser, setMobilsUser] = useState("");
    // posInvoiceTypeId
    const [posInvoiceTypeId, setPosInvoiceTypeId] = useState(null);

    const [totalDiscount, setTotalDiscount] = useState(0); //  اجمالي الخصم 
    const [totalBeforeTax, setTotalBeforeTax] = useState(0); // اجمالي السعر قبل الضريبة
    const [totalTaxAd, setTotalTaxAd] = useState(0); // اجمالي ضريبة القيمة المضافة
    const [totalTaxAdPresent, setTotalTaxAdPresent] = useState(0); // اجمالي ضريبة القيمة المضافة

    // tables
    const [dataDelevery, setDataDelevery] = useState([]);
    const [TOD, setTOD] = useState(0);

    // CheckedBox
    const [printVars, setPrintVars] = useState(true);
    const [printInvoiceKitchen, setPrintInvoiceKitchen] = useState(true);
    const [printInvoice, setPrintInvoice] = useState(false);

    const countries = useMemo(() => {
        if (!search) return [];
        return mostData.customerList.filter((country) => {
            return country.customerName.toLowerCase().includes(search.toLowerCase());
        });
    }, [search, mostData]);

    // add item to list
    const AddList = async (automaticDiscount, itemId, itemCode, tatolPrice = 0, unitId, discount = 0, salesDiscountType, taxRate, tableTaxRate) => {

        let Check = list.find((item) => item.itemId === itemId);
        let Check31 = list.filter((item) => item.itemId === itemId);
        let Check33 = Check31.find((item) => item.unitId === unitId);
        let CheckLast1 = list.filter((item) => item.itemId === itemId);
        let CheckLast = CheckLast1.find((item) => item.unitId === unitId);
        if (automaticDiscount === false) {
            discount = 0;
        } else {
            if (salesDiscountType === 2) {
                discount = (tatolPrice * discount) / 100
            }
        }

        if (Check) {
            if (Check.unitId == unitId) {
                console.log(Check);
                console.log("Check33",Check33);
                Check33.quantity += 1;
                let vallll = 0; // ضريبة القيمة المضافة
                let vallll2 = 0; // الجدول
                if (settingCompany.useTaxSales == true) {
                    vallll = (((+Check33.quantity * +Check33.price) - +Check33.discount1) * (Check33.taxRate / 100));
                    // let vallllNew = Math.floor(+vallll).toFixed(4);
                    Check33.taxValue += vallll;
                    let total78 = ((+Check33.quantity * +Check33.price) - +Check33.discount1); // اجمالي الصنف بعد ض ج 
                    Check33.tatolItem = total78 + vallll; // ض ق الجديدة 
                }
                if (settingCompany.useTableTaxSales == true) {
                    vallll2 = (((+Check33.quantity * +Check33.price) - +Check33.discount1) * (Check33.tableTaxPercent / 100));
                    Check33.tableTaxValue += vallll2;
                }
                if (settingCompany.nTaxTFees) { // done new version
                    Check33.tatolItem = ((+Check33.quantity * +Check33.price) - +Check33.discount1) + vallll + vallll2;
                }
                if (settingCompany.nTaxTFees == false) {
                    let ss2 = ((+Check33.quantity * +Check33.price) - +Check33.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
                    let newsss = ss2 * (taxRate / 100); // ض ق الجديدة 
                    Check33.tatolItem = ss2 + newsss; // ض ق الجديدة 
                }

                Check33.total = (Check33.price * Check33.quantity);
                Check33.netPrice = (Check33.price * Check33.quantity);
                Check33.totalAfterDiscount1 = Check33.netPrice;
                Check33.totalAfterDiscount2 = Check33.netPrice;
                Check33.totalAfterDiscount3 = Check33.netPrice;
                Check33.discountPercent1 = (Check33.discount * 100) / Check33.price;
                Check33.discountPercent2 = (Check33.discount * 100) / Check33.price;
                Check33.discountPercent3 = (Check33.discount * 100) / Check33.price;
                TotaBeforeTaxAdFun();
                setTotalTaxAdPresent(state => state + Check33.taxRate)
                TotaBill()
            } else {
                // console.log("here 2");
                if (!CheckLast) {
                    console.log("here 4");
                    let Items = DefinitionItemsT.filter((item) => item.itemId == itemId);
                    let Item = Items.find((item) => item.unitId == unitId);
                    let ItemCreate = {
                        itemId: Item.itemId,
                        itemCode,
                        unitId,
                        name: Item.itemName,
                        quantity: 1,
                        price: +tatolPrice,
                        discount: +discount,
                        discount1: +discount,
                        tatolItem: 0,
                        unitName: Item.unitName,
                        storeId: storesId,
                        taxValue: 0,
                        invoiceType: 10,
                        id: 0,
                        total: tatolPrice,
                        discountPercent1: (discount * 100) / tatolPrice,
                        totalAfterDiscount1: tatolPrice,
                        discount2: 0,
                        discountPercent2: 0,
                        totalAfterDiscount2: tatolPrice,
                        discount3: 0,
                        discountPercent3: 0,
                        totalAfterDiscount3: tatolPrice,
                        netPrice: tatolPrice,
                        description: "",
                        totalQuantity: 0,
                        taxRate: taxRate,
                        taxValue: 0,
                        tableTaxPercent: tableTaxRate,
                        tableTaxValue: 0,
                        additionalCost: 0,
                        freeQuantity: 0,
                        sectionId: 0,
                        colorId: 0,
                        sortId: 0,
                        cost: 0,
                        productionDate: "2021-10-11T14:46:13.985Z",
                        expiryDate: "2021-10-11T14:46:13.985Z",
                        serial: "",
                        bodyNo: "",
                        model: "",
                        boardNo: "",
                        motorNo: "",
                        jobNo: "",
                        guaranteeDate: "2021-10-11T14:46:13.985Z",
                        psalesPrice: 0,
                        pmsalesPrice: 0,
                        salesManId: 0,
                        publicPrice: 0,
                        taxValueBefore: 0,
                        cubic: 0,
                        hidePrice: false,
                        height: 0,
                        width: 0,
                        heaving: 0,
                        icount: 0,
                        dayUseId: 0,
                        todayDosesId: 0,
                        id1: 0,
                        idDTL: 0
                    };

                    // 
                    let vallll = 0; // ضريبة القيمة المضافة
                    let vallll2 = 0; // الجدول

                    if (settingCompany.useTaxSales == true) {
                        vallll = (((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) * (taxRate / 100));
                        let vallllNew = Math.floor(+vallll).toFixed(4);
                        ItemCreate.taxValue = vallllNew;
                        console.log("ضريبة قيمة مضافة");
                    }
                    if (settingCompany.useTableTaxSales == true) {
                        vallll2 = (((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) * (tableTaxRate / 100));
                        ItemCreate.tableTaxValue = vallll2;
                        console.log("ضريبة [جدول]");
                    }

                    if (settingCompany.nTaxTFees) { // done new version
                        ItemCreate.tatolItem = ((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) + vallll + vallll2;
                    } // done new version


                    if (settingCompany.nTaxTFees == false) {
                        let ss2 = ((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
                        let newsss = ss2 * (taxRate / 100); // ض ق الجديدة 
                        ItemCreate.tatolItem = ss2 + newsss; // ض ق الجديدة 
                    }
                    // 
                    ItemCreate.total = (+ItemCreate.quantity * +ItemCreate.price);
                    ItemCreate.netPrice = (+ItemCreate.quantity * +ItemCreate.price);
                    // ItemCreate.netPrice = ItemCreate.tatolItem; 
                    ItemCreate.totalQuantity = ItemCreate.quantity;
                    ItemCreate.totalAfterDiscount1 = (+ItemCreate.quantity * +ItemCreate.price) - ItemCreate.discount1;
                    ItemCreate.totalAfterDiscount2 = (+ItemCreate.quantity * +ItemCreate.price) - ItemCreate.discount1;
                    ItemCreate.totalAfterDiscount3 = (+ItemCreate.quantity * +ItemCreate.price) - ItemCreate.discount1;
                    setList((state) => [...state, ItemCreate]);
                    setTotalCheck((state) => state + +ItemCreate.tatolItem)
                    setTaxTableRate(satte => satte + +vallll2)
                    setPriceAfterDiscount((satte) => satte + ItemCreate.price);
                    setTotalDiscount(state => state + +ItemCreate.discount1)
                    setTotalBeforeTax(state => state + ((+ItemCreate.quantity * +ItemCreate.price) - ItemCreate.discount1))
                    setTotalTaxAd(state => state + vallll)
                    setTotalTaxAdPresent(state => state + taxRate)
                    if (list.length == 0) {
                        console.log("list 0");
                        if (settingCompany.useTaxSourceSales == false) {
                            setTotal((ItemCreate.total - ItemCreate.discount1) + vallll + vallll2);
                            // console.log(ItemCreate.total);
                        } else {
                            setTotal((ItemCreate.total - ItemCreate.discount1) - (ItemCreate.total * taxSourceRate / 100) + vallll + vallll2);
                            // console.log(total);
                        }

                    } else {

                        setTotal(state => state + (ItemCreate.total - ItemCreate.discount1));
                    }


                }
                else {
                    console.log("here 5");
                    CheckLast.quantity += 1;
                    // 
                    let vallll = 0; // ضريبة القيمة المضافة
                    let vallll2 = 0; // الجدول
                    if (settingCompany.useTaxSales == true) {
                        vallll = (((+CheckLast.quantity * +CheckLast.price) - +CheckLast.discount1) * (taxRate / 100));
                        let vallllNew = Math.floor(+vallll).toFixed(4);
                        CheckLast.taxValue = vallllNew;
                        console.log("ضريبة قيمة مضافة");
                    }
                    if (settingCompany.useTableTaxSales == true) {
                        vallll2 = (((+CheckLast.quantity * +CheckLast.price) - +CheckLast.discount1) * (tableTaxRate / 100));
                        CheckLast.tableTaxValue = vallll2;
                        console.log("ضريبة [جدول]");
                    }
                    if (settingCompany.nTaxTFees) { // done new version
                        CheckLast.tatolItem = ((+CheckLast.quantity * +CheckLast.price) - +CheckLast.discount1) + vallll + vallll2;
                    } // done new version
                    if (settingCompany.nTaxTFees == false) {
                        let ss2 = ((+CheckLast.quantity * +CheckLast.price) - +CheckLast.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
                        let newsss = ss2 * (taxRate / 100); // ض ق الجديدة 
                        CheckLast.tatolItem = ss2 + newsss; // ض ق الجديدة 
                    }
                    // 

                    CheckLast.tatolItem = ((+CheckLast.quantity * +tatolPrice) - (CheckLast.discount * CheckLast.quantity)) + vallll + vallll2;
                    CheckLast.total = (+CheckLast.quantity * +tatolPrice);
                    CheckLast.netPrice = (+CheckLast.quantity * +tatolPrice);
                    CheckLast.totalAfterDiscount1 = ((+CheckLast.quantity * +tatolPrice) - (CheckLast.discount * CheckLast.quantity)) + vallll + vallll2;
                    CheckLast.totalAfterDiscount1 = CheckLast.netPrice;
                    CheckLast.totalAfterDiscount2 = CheckLast.netPrice;
                    CheckLast.totalAfterDiscount3 = CheckLast.netPrice;
                    setList((state) => [...state]);
                    TotaBill()
                    setTotalCheck(total + +CheckLast.netPrice)
                    setPriceAfterDiscount((satte) => satte + CheckLast.price);
                }
            }
        }

        else {
            // console.log("here 1");
            let Items = DefinitionItemsT.filter((item) => item.itemId === itemId);
            let Item = Items.find((item) => item.unitId === unitId);

            let ItemCreate = {
                itemId: Item.itemId,
                itemCode,
                unitId,
                name: Item.itemName,
                quantity: 1,
                price: +tatolPrice,
                discount: +discount,
                discount1: +discount,
                tatolItem: 0,
                unitName: Item.unitName,
                storeId: storesId,
                tableTaxValue: 0,
                invoiceType: 10,
                id: 0,
                total: 0,
                discountPercent1: (discount * 100) / tatolPrice,
                totalAfterDiscount1: tatolPrice,
                discount2: 0,
                discountPercent2: 0,
                totalAfterDiscount2: tatolPrice,
                discount3: 0,
                discountPercent3: 0,
                totalAfterDiscount3: tatolPrice,
                netPrice: tatolPrice,
                description: "",
                totalQuantity: 1,
                taxRate: taxRate,
                taxValue: 0,
                tableTaxPercent: tableTaxRate,
                additionalCost: 0,
                freeQuantity: 0,
                sectionId: 0,
                colorId: 0,
                sortId: 0,
                cost: 0,
                productionDate: "2021-10-11T14:46:13.985Z",
                expiryDate: "2021-10-11T14:46:13.985Z",
                serial: "",
                bodyNo: "",
                model: "",
                boardNo: "",
                motorNo: "",
                jobNo: "",
                guaranteeDate: "2021-10-11T14:46:13.985Z",
                psalesPrice: 0,
                pmsalesPrice: 0,
                salesManId: 0,
                publicPrice: 0,
                taxValueBefore: 0,
                cubic: 0,
                hidePrice: false,
                height: 0,
                width: 0,
                heaving: 0,
                icount: 0,
                dayUseId: 0,
                todayDosesId: 0,
                id1: 0,
                idDTL: 0
            };

            let vallll = 0; // ضريبة القيمة المضافة
            let vallll2 = 0; // الجدول

            if (settingCompany.useTaxSales === true) {
                vallll = (((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) * (taxRate / 100));
                ItemCreate.taxValue = vallll;
                // console.log("ضريبة قيمة مضافة");
            }
            if (settingCompany.useTableTaxSales === true) {
                vallll2 = (((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) * (tableTaxRate / 100));
                ItemCreate.tableTaxValue = vallll2;
                // console.log("ضريبة [جدول]");
            }

            if (settingCompany.nTaxTFees) { // done new version
                ItemCreate.tatolItem = ((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) + vallll + vallll2;
            } // done new version

            if (settingCompany.nTaxTFees === false) {
                let ss2 = ((+ItemCreate.quantity * +ItemCreate.price) - +ItemCreate.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
                let newsss = ss2 * (taxRate / 100); // ض ق الجديدة 
                ItemCreate.tatolItem = ss2 + newsss; // ض ق الجديدة 
            }

            ItemCreate.total = (+ItemCreate.quantity * +ItemCreate.price);
            ItemCreate.netPrice = (+ItemCreate.quantity * +ItemCreate.price);
            ItemCreate.totalQuantity = ItemCreate.quantity;
            ItemCreate.totalAfterDiscount1 = (+ItemCreate.quantity * +ItemCreate.price) - ItemCreate.discount1;
            ItemCreate.totalAfterDiscount2 = (+ItemCreate.quantity * +ItemCreate.price) - ItemCreate.discount1;
            ItemCreate.totalAfterDiscount3 = (+ItemCreate.quantity * +ItemCreate.price) - ItemCreate.discount1;

            setList((state) => [...state, ItemCreate]);
            setTotalCheck((state) => state + +ItemCreate.tatolItem)
            setTaxTableRate(satte => satte + +vallll2)
            setPriceAfterDiscount((satte) => satte + ItemCreate.price);
            setTotalDiscount(state => state + +ItemCreate.discount1)
            setTotalBeforeTax(state => state + ((+ItemCreate.quantity * +ItemCreate.price)))
            setTotalTaxAd(state => state + vallll)
            setTotalTaxAdPresent(state => state + taxRate)
            if (list.length === 0) {
                if (settingCompany.useTaxSourceSales === false) {
                    setTotal((ItemCreate.total - ItemCreate.discount1) + vallll + vallll2);
                } else {
                    setTotal((ItemCreate.total - ItemCreate.discount1) - (ItemCreate.total * taxSourceRate / 100) + vallll + vallll2);
                }
            } else {
                setTotal(state => state + (ItemCreate.total - ItemCreate.discount1) + vallll + vallll2);
            }

        }
    }; // add item to list

    // delete item from list
    const DelItem = (code, unitId, idx) => {
        list.splice(idx, 1);
        setList(list);
        setTotalCheck(total);
        TotaTaxTableRate()
        TotaDiscountBill()
        TotaBeforeTaxAdFun()
        TotaBill()
    }; // delete item from list

    // increment qunatity item 
    const AddNumber = (code, tatolItem, unitId) => {
        let Items = list.filter((item) => item.itemId == code);
        let Item = Items.find((item) => item.unitId == unitId);
        if (Item) {
            Item.quantity += 1;
            let vallll = 0; // ضريبة القيمة المضافة
            let vallll2 = 0; // الجدول

            if (settingCompany.useTaxSales == true) {
                vallll = (((+Item.quantity * +Item.price) - +Item.discount1) * (Item.taxRate / 100));
            }
            if (settingCompany.useTableTaxSales == true) {
                vallll2 = (((+Item.quantity * +Item.price) - +Item.discount1) * (Item.tableTaxRate / 100));
            }

            if (settingCompany.nTaxTFees) { // done new version
                Item.tatolItem = ((+Item.quantity * +Item.price) - +Item.discount1) + vallll + vallll2;
            } // done new version


            if (settingCompany.nTaxTFees == false) {
                let ss2 = ((+Item.quantity * +Item.price) - +Item.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
                let newsss = ss2 * (Item.taxRate / 100); // ض ق الجديدة 
                Item.tatolItem = ss2 + newsss; // ض ق الجديدة 
            }
            console.log("val is ");
            Item.total = (Item.price * Item.quantity);
            Item.netPrice = (Item.price * Item.quantity);

            Item.discountPercent1 = ((Item.discount1 * 100) / Item.price);
            Item.totalAfterDiscount1 = Item.netPrice;
            Item.totalAfterDiscount2 = Item.netPrice;
            Item.totalAfterDiscount3 = Item.netPrice;

            setPriceAfterDiscount((state) => state + Item.quantity * Item.price);
            let Data = [...list];
            setList(Data);
            TotaBeforeTaxAdFun()
            TotaDiscountBill()
            TotaBill();
            // console.log(Item);
        }
    };// increment qunatity item 

    // decrement qunatity item 
    const MinusNumber = (code) => {
        let Item = list.find((item) => item.itemId == code);

        if (Item.quantity != 1) {
            Item.quantity -= 1;
            let vallll = 0; // ضريبة القيمة المضافة
            let vallll2 = 0; // الجدول
            if (settingCompany.useTaxSales == true) {
                vallll = (((+Item.quantity * +Item.price) - +Item.discount1) * (Item.taxRate / 100));
            }
            if (settingCompany.useTableTaxSales == true) {
                vallll2 = (((+Item.quantity * +Item.price) - +Item.discount1) * (Item.tableTaxRate / 100));
            }
            if (settingCompany.nTaxTFees) { // done new version
                Item.tatolItem = ((+Item.quantity * +Item.price) - +Item.discount1) + vallll + vallll2;
            } // done new version
            if (settingCompany.nTaxTFees == false) {
                let ss2 = ((+Item.quantity * +Item.price) - +Item.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
                let newsss = ss2 * (Item.taxRate / 100); // ض ق الجديدة 
                Item.tatolItem = ss2 + newsss; // ض ق الجديدة 
            }


            Item.total = (Item.price * Item.quantity);
            Item.netPrice = (Item.price * Item.quantity);
            // Item.netPrice = Item.tatolItem;
            Item.discountPercent1 = ((Item.discount1 * 100) / Item.price);
            Item.totalAfterDiscount1 = Item.netPrice;
            Item.totalAfterDiscount2 = Item.netPrice;
            Item.totalAfterDiscount3 = Item.netPrice;

            setPriceAfterDiscount((state) => state + Item.quantity * Item.price);
            let Data = [...list];
            setList(Data);
            TotaBill();
        }

        // TotaBeforeTaxFun();
        TotaBeforeTaxAdFun()
        TotaDiscountBill()
    }; // decrement qunatity item 

    // تعليق
    const Hold = (InvoicedIdList, returnBtn, searchId, list, setList, total, PriceAfterDiscount, setTotal, setPriceAfterDiscount, setSearch, inputRef) => {
        HoldInvoice(InvoicedIdList, returnBtn, searchId, list, setList, total, PriceAfterDiscount, setTotal, setPriceAfterDiscount, setSearch, inputRef);
    };

    // الغاء
    const Cancel = () => {
        userRef.current.value = "";
        // setPOSTables([{ default: true, value: "---", id: "--" }, ...mostData.posInvoiceTypeList]);
        document.querySelector('#OrderNoCounter').value = "";
        setOptions([]);
        setList([]);
        setTotal(0);
        setTotalCheck(0);
        setCach(0);
        setVisa(0);
        setMasterCard(0);
        setReturnBtn(false)
        setRunPrint(false)
        setTaxTableRate(0)
        setTotalTaxAdPresent(0)
        setTotalBeforeTax(0)
        setTotalTaxAd(0)
        setTotalDiscount(0)
        setCredit('')
        setTotalMatserCard(0);
        $(`option#idT1`).attr('selected', false);
        $(`option#idT2`).attr('selected', false);
        $(`option#idT3`).attr('selected', false);
        $(`option#idT4`).attr('selected', false);
        $(`option#idT${Dates.posInvoiceTypeId ? Dates.posInvoiceTypeId : 4}`).attr('selected', true);


        $('#masterCardFromPay').val(0)
        $('#visaFromPay').val(0)
        $('#creditFromPay').val(0)
        $('#cachFromPay').val(0)
    };

    // value cach
    const onChangeCach = (e) => {

        let num = +e.target.value;
        document.getElementById('ChachId').innerHTML = +num;
        let obj = {
            "paymentId": 5,
            "paymentType": 5,
            "paymentValue": +num,
            "accountId": ""
        }
        if (masterCard == 0) {
            if (e.target.value == 0) {
                setListPayments(listPayments.filter(payment => payment.paymentId != 5))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 5);
                if (tests) {
                    tests.paymentValue = +e.target.value;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        } else {
            if (e.target.value == 0) {
                setMasterCard(0);
                setListPayments(listPayments.filter(payment => payment.paymentId != 5))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 5);
                if (tests) {
                    tests.paymentValue = +num;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        }
        CalculationValues()

    }; // value cach

    // value visa
    const onChangeVisa = (e) => {
        let num = +e.target.value;
        document.getElementById('visaId').innerHTML = +num;
        let obj = {
            "paymentId": 3,
            "paymentType": 3,
            "paymentValue": +num,
            "accountId": ""
        }
        if (masterCard == 0) {
            if (e.target.value == 0) {
                setListPayments(listPayments.filter(payment => payment.paymentId != 3))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 3);
                if (tests) {
                    tests.paymentValue = +e.target.value;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        } else {
            if (e.target.value == 0) {
                setMasterCard(0);
                setListPayments(listPayments.filter(payment => payment.paymentId != 3))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 3);
                if (tests) {
                    tests.paymentValue = +num;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        }
        // ****
        CalculationValues()
    };

    const onChangeMasterCard = (e) => {
        let num = +e.target.value;
        document.getElementById('masterCardIdId').innerHTML = +num;

        let obj = {
            "paymentId": 2,
            "paymentType": 2,
            "paymentValue": +num,
            "accountId": ""
        }
        if (masterCard == 0) {
            if (e.target.value == 0) {
                setListPayments(listPayments.filter(payment => payment.paymentId != 2))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 2);
                if (tests) {
                    tests.paymentValue = +e.target.value;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        } else {
            if (e.target.value == 0) {
                setListPayments(listPayments.filter(payment => payment.paymentId != 2))
            } else {
                let testKda = [...listPayments];
                let tests = testKda.find(payment => payment.paymentId == 2);
                if (tests) {
                    tests.paymentValue = +e.target.value;
                    setListPayments(testKda)
                } else {
                    testKda.push(obj);
                    setListPayments(testKda)
                }
            }
        }
        // ****
        CalculationValues()
    };// value visa

    // credit
    const onChangeCredit = (e) => {
        let num = e.target.value;
        document.getElementById('creditId').innerHTML = +num;

        let obj = {
            "paymentId": 6,
            "paymentType": 6,
            "paymentValue": +num,
            "accountId": ""
        }

        if (e.target.value == "") {
            setTotalCredit(totalCheck)
            setCredit("");
            setListPayments(listPayments.filter(payment => payment.paymentId != 6))
        } else {
            setCredit(e.target.value);
            let newTotal = total - +e.target.value;
            setTotalCredit(newTotal)
            let testKda = [...listPayments];
            let tests = testKda.find(payment => payment.paymentId == 6);
            if (tests) {
                tests.paymentValue = +e.target.value;
                setListPayments(testKda)
            } else {
                testKda.push(obj);
                setListPayments(testKda)
            }
        }
        CalculationValues()
    };// value credit

    const CalculationValues = () => {
        let cash = document.getElementById('ChachId').innerHTML;
        let visa = document.getElementById('visaId').innerHTML;
        let masterCard = document.getElementById('masterCardIdId').innerHTML;
        let credit = document.getElementById('creditId').innerHTML;
        let total = +cash + +visa + +masterCard + +credit;
        setMasterCard(total);
    }

    const onSaveCredit = () => {
        CashCollection(
            Cancel,
            totalTaxAdPresent,
            totalTaxAd,
            0,
            returnBtn ? true : false,
            searchId,
            list,
            total,
            listPayments,
            2,
            typeInvoice,
            typePrint,
            posPintAfterSave,
            storesId,
            posInvoiceTypeId
        )
        //    listPaymentsUpdate, 2, typeInvoice, typePrint, posPintAfterSave, storesId, posInvoiceTypeId
    }

    // model new customer
    const NewUserCustomer = () => {
        setSearch(userCustomer);
        let namear = document.querySelector('.customerNewNamear').value;
        let nameen = document.querySelector('.customerNewNameen').value;
        let txtFile1 = document.querySelector('.txtFile1').value;
        let txtFile2 = document.querySelector('.txtFile2').value;
        let mobileFile = document.querySelector('.mobileFile').value;
        let fax = document.querySelector('.faxFile').value;
        let emailFile = document.querySelector('.emailFile').value;
        let siteFile = document.querySelector('.siteFile').value;
        let addressFile = document.querySelector('.addressFile').value;
        let NumTax = document.querySelector('.NumTax').value;
        // let fileNum = document.querySelector('.fileNum').files[0];
        let fileNum = document.querySelector('.fileNum').value;
        let NumTaxPlus = document.querySelector('.NumTaxPlus').value;
        let notesFile = document.querySelector('.notesFile').value;

        let dataUser = {
            companyId: user.companyId,
            id: 0,
            formType: 51,
            code: "",
            groupId: 0,
            nameA: namear,
            nameE: nameen,
            tel1: txtFile1,
            tel2: txtFile2,
            mobile: mobileFile,
            fax: fax,
            email: emailFile,
            site: siteFile,
            address: addressFile,
            accountId: "",
            notes: notesFile,
            countryId: 0,
            cityId: 0,
            regionId: 0,
            workDate: "2022-01-05T14:47:17.820Z",
            workDateHj: "",
            iscustSupp: false,
            isDelegate: false,
            allowCreditLimit: true,
            creditLimit: 0,
            status: false,
            currencyId: 0,
            delegateId: 0,
            responsiblePerson: "",
            accountIdLoan: "",
            accountIdPettyCash: "",
            openingBalance: 0,
            debitCredit: true,
            dueDate: "2022-01-05T14:47:17.820Z",
            dueDateHj: "string",
            cpid: 0,
            taxRegistrationNo: NumTax,
            fileNo: fileNum,
            taxesNature: 0,
            posdefaultCusCash: false,
            discountPercent: 0,
            cpidSales: 0,
            dueDays: 0,
            mainCustomerId: 0,
            salesPolicyId: 0,
            region: "",
            floorNo: "",
            apartment: "",
            storeId: 0,
            itemsMaximumId: 0,
            complyMaximumItems: false,
            paperCatch: "",
            paperPay: "",
            workingHours: 0,
            numberId: "",
            sourceId: "",
            jobId: 0,
            issueDateId: "2022-01-05T14:47:17.820Z",
            issueDateHjid: "",
            expiryDateId: "2022-01-05T14:47:17.820Z",
            expiryDateHjid: "",
            numberP: "",
            sourceP: "",
            jobP: 0,
            "issueDateP": "2022-01-05T14:47:17.820Z",
            "issueDateHjp": "",
            "expiryDateP": "2022-01-05T14:47:17.820Z",
            expiryDateHjp: "",
            numberWc: "",
            sourceWc: "",
            jobWc: 0,
            "issueDateWc": "2022-01-05T14:47:17.820Z",
            "issueDateHjwc": "",
            "expiryDateWc": "2022-01-05T14:47:17.820Z",
            expiryDateHjwc: "",
            numberSi: "",
            sourceSi: "",
            jobSi: 0,
            "issueDateSi": "2022-01-05T14:47:17.820Z",
            "issueDateHjsi": "",
            "expiryDateSi": "2022-01-05T14:47:17.820Z",
            expiryDateHjsi: "",
            numberDl: "",
            sourceDl: "",
            licenseIdDl: 0,
            "issueDateDl": "2022-01-05T14:47:17.820Z",
            "issueDateHjdl": "",
            "expiryDateDl": "2022-01-05T14:47:17.820Z",
            expiryDateHjdl: "",
            allowancesValue: 0,
            departmentId: 0,
            locationtId: 0,
            employeeJobId: 0,
            religionId: 0,
            statusId: 0,
            gender: 0,
            maritalStatus: 0,
            nationalityId: 0,
            "birthDate": "2022-01-05T14:47:17.820Z",
            birthDateHj: "",
            branchId: 0,
            educationalLevelId: 0,
            bloodTypeId: 0,
            bankId: 0,
            subscribeTypeId: 0,
            workNatureId: 0,
            sponsorsId: 0,
            directManagerId: 0,
            "appointmentDate": "2022-01-05T14:47:17.820Z",
            "appointmentDateHj": "",
            "decadeEndDate": "2022-01-05T14:47:17.820Z",
            decadeEndDateHj: "",
            certification: "",
            patchy: false,
            patchyNotes: "",
            annualLeave: 0,
            emergencyLeave: 0,
            airlinesId: 0,
            linesTravelId: 0,
            degreesTicketsId: 0,
            haveTicket: false,
            ticketCount: 0,
            employeeMachineNo: 0,
            identityPersonalityId: 0,
            personalityId: "",
            pcityId: 0,
            "pissueDate": "2022-01-05T14:47:17.820Z",
            pissueDateHj: "",
            "pexpiryDate": "2022-01-05T14:47:17.820Z",
            pexpiryDateHj: "",
            drivingLicensesId: 0,
            licenseId: "",
            lcityId: 0,
            "lissueDate": "2022-01-05T14:47:17.820Z",
            lissueDateHj: "",
            "lexpiryDate": "2022-01-05T14:47:17.820Z",
            lexpiryDateHj: "",
            guarantorName: "",
            guarantorComputerId: "",
            guarantorTel: "",
            sponsorsId1: 0,
            sponsorsId2: 0,
            driverValue: 0,
            csourceId: 0,
            csnotes: "",
            cpidCoupon: 0,
            cpidSalesCoupon: 0,
            delegateIdC: 0,
            automaticDiscountP: false,
            automaticDiscountS: false,
            paperBounce: "",
            vatno: NumTaxPlus,
            eftspercent: 0,
            efts: false,
            dcontributionTax: false,
            street: "",
            buildingNumber: "",
            postalCode: "",
            room: "",
            bfloor: "",
            landmark: "",
            additionalInformation: "",
            typeTax: ""
        };
        fetch(`${config}/SupCusEmp/SupplierCustomerQuickAdd`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(dataUser),
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                if (result.isSuccess) {
                    setUserCustomer("");
                    setMobilsUser("");
                    mostData.customerList = [...mostData.customerList, result.data];
                    setSearch()
                    setSearch(result.data.customerName);
                    setSearchId(result.data.id);

                    document.querySelector('.customerNewNamear').value = "";
                    document.querySelector('.customerNewNameen').value = "";
                    document.querySelector('.txtFile1').value = "";
                    document.querySelector('.txtFile2').value = "";
                    document.querySelector('.mobileFile').value = "";
                    document.querySelector('.faxFile').value = "";
                    document.querySelector('.emailFile').value = "";
                    document.querySelector('.siteFile').value = "";
                    document.querySelector('.addressFile').value = "";
                    document.querySelector('.NumTax').value = "";
                    document.querySelector('.fileNum').value = "";
                    document.querySelector('.NumTaxPlus').value = "";
                    document.querySelector('.notesFile').value = "";
                    alert(result.message);
                    // getMostData()
                } else {
                    alert(result.message)
                }
            })
            .catch(error => console.log('error', error));




    }; // model new customer

    // on change qunatity from tabel list
    const changeCountItem = (id, qun, unitId) => {
        let lists = [...list];
        let listItemsLists = lists.filter((item) => item.itemId == id);
        let listItems = listItemsLists.find((item) => item.unitId == unitId);
        listItems.quantity = +qun;
        let vallll = 0; // ضريبة القيمة المضافة
        let vallll2 = 0; // الجدول
        if (settingCompany.useTaxSales == true) {
            vallll = (((+listItems.quantity * +listItems.price) - +listItems.discount1) * (listItems.taxRate / 100));
        }
        if (settingCompany.useTableTaxSales == true) {
            vallll2 = (((+listItems.quantity * +listItems.price) - +listItems.discount1) * (listItems.tableTaxPercent / 100));
        }
        if (settingCompany.nTaxTFees) { // done new version
            listItems.tatolItem = ((+listItems.quantity * +listItems.price) - +listItems.discount1) + vallll + vallll2;
        } // done new version
        if (settingCompany.nTaxTFees == false) {
            let ss2 = ((+listItems.quantity * +listItems.price) - +listItems.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
            let newsss = (ss2 * listItems.taxRate) / 100; // ض ق الجديدة 
            listItems.tatolItem = ss2 + newsss; // ض ق الجديدة 
        }

        listItems.taxValue = vallll;
        listItems.total = (listItems.price * listItems.quantity);
        listItems.netPrice = (listItems.price * listItems.quantity) - listItems.discount1;

        listItems.discountPercent1 = ((listItems.discount1 * 100) / listItems.price);
        listItems.totalAfterDiscount1 = listItems.netPrice;
        listItems.totalAfterDiscount2 = listItems.netPrice;
        listItems.totalAfterDiscount3 = listItems.netPrice;
        setList(lists);
        TotaTaxTableRate()
        TotaBeforeTaxFun()
        TotaDiscountBill()
        TotaBeforeTaxAdFun();
        TotaBeforeTaxAdFun()
        TotaBill()
    }; // on change qunatity from tabel list

    // on change price from tabel list
    const changePriceItem = (id, qun, unitId) => {
        let lists = [...list];
        let listItemsLists = lists.filter((item) => item.itemId == id);
        let listItems = listItemsLists.find((item) => item.unitId == unitId);
        listItems.price = +qun;
        let vallll = 0; // ضريبة القيمة المضافة
        let vallll2 = 0; // الجدول

        if (settingCompany.useTaxSales == true) {
            vallll = (((+listItems.quantity * +listItems.price) - +listItems.discount1) * (listItems.taxRate / 100));
        }
        if (settingCompany.useTableTaxSales == true) {
            vallll2 = (((+listItems.quantity * +listItems.price) - +listItems.discount1) * (listItems.tableTaxRate / 100));
        }
        if (settingCompany.nTaxTFees) { // done new version
            listItems.tatolItem = ((+listItems.quantity * +listItems.price) - +listItems.discount1) + vallll + vallll2;
        } // done new version
        if (settingCompany.nTaxTFees == false) {
            let ss2 = ((+listItems.quantity * +listItems.price) - +listItems.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
            let newsss = (ss2 * listItems.taxRate) / 100; // ض ق الجديدة 
            listItems.tatolItem = ss2 + newsss; // ض ق الجديدة 
        }

        listItems.total = (listItems.price * listItems.quantity);
        listItems.taxValue = vallll;
        listItems.netPrice = (listItems.price * listItems.quantity) - listItems.discount1;

        listItems.discountPercent1 = ((listItems.discount1 * 100) / listItems.price);
        listItems.totalAfterDiscount1 = listItems.netPrice;
        listItems.totalAfterDiscount2 = listItems.netPrice;
        listItems.totalAfterDiscount3 = listItems.netPrice;


        setList(lists);
        TotaDiscountBill()
        TotaTaxTableRate()
        TotaBeforeTaxAdFun();
        TotaBill()
    }; // on change price from tabel list

    // on change discount from tabel list
    const changeDiscountItem = (id, qun, unitId) => {
        let lists = [...list];
        let listItemsLists = lists.filter((item) => item.itemId == id);
        let listItems = listItemsLists.find((item) => item.unitId == unitId);
        listItems.discount1 = +qun;
        listItems.discount = +qun;
        let vallll = 0; // ضريبة القيمة المضافة
        let vallll2 = 0; // الجدول
        if (settingCompany.useTaxSales == true) {
            vallll = (((+listItems.quantity * +listItems.price) - +listItems.discount1) * (listItems.taxRate / 100));
            let total1 = ((+listItems.quantity * +listItems.price) - +listItems.discount1); // اجمالي الصنف بعد ض ج 
            listItems.tatolItem = total1 + vallll; // ض ق الجديدة 

        } else if (settingCompany.useTableTaxSales == true) {
            vallll2 = (((+listItems.quantity * +listItems.price) - +listItems.discount1) * (listItems.tableTaxRate / 100));
        } else if (settingCompany.nTaxTFees) { // done new version

            listItems.tatolItem = ((+listItems.quantity * +listItems.price) - +listItems.discount1) + vallll + vallll2;
        } else if (settingCompany.nTaxTFees == false) {

            let ss2 = ((+listItems.quantity * +listItems.price) - +listItems.discount1) + vallll2; // اجمالي الصنف بعد ض ج 
            let newsss = ss2 * (listItems.taxRate / 100); // ض ق الجديدة 
            listItems.tatolItem = ss2 + newsss; // ض ق الجديدة 
        }



        listItems.total = (listItems.price * listItems.quantity);
        listItems.taxValue = vallll;
        listItems.netPrice = (listItems.price * listItems.quantity) - listItems.discount1;



        listItems.discountPercent1 = ((listItems.discount1 * 100) / listItems.price);
        listItems.totalAfterDiscount1 = listItems.netPrice;
        listItems.totalAfterDiscount2 = listItems.netPrice;
        listItems.totalAfterDiscount3 = listItems.netPrice;


        setList(lists);
        TotaDiscountBill()
        TotaTaxTableRate()
        TotaBeforeTaxAdFun();
        TotaBill()
    }; // on change price from tabel list

    const [pointer, setPointer] = useState("barCodeSearch");

    // cancel returned
    const ReturnedCancel = () => {
        Cancel()
    } // cancel returned

    // ReturnedHoldInvoice
    const ReturnedHoldInvoice = invoiceholdId => {
        setReturnBtnHold(true);
        if (list.length != 0) {
            Hold()
        }
        fetch(`${config}/PosForm/ReturnInvoiceHold`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "invoiceId": invoiceholdId,
                "invoiceCode": ""
            }),
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                if (result.isSuccess) {
                    setList(result.data)
                    getMostData();
                } else {
                    alert(result.message)
                }
                return result.data
            }).then((data) => {
                let totalDataItems = data.map((item) => {
                    return item.netPrice;
                })
                    .reduce((acc, curr) => {
                        return acc + curr;
                    }, 0);
                setTotal(totalDataItems);
            })
            .catch(error => console.log('error', error));
    }

    // calculation total from list
    const TotaBill = () => {
        list.forEach((el) => {
            el.discount = (el.quantity * el.price) * (el.discountPercent1 || 0) / 100;
            el.discount1 = (el.discount || 0);
            el.totalAfterDiscount1 = (el.quantity * el.price) - el.discount;
            el.totalAfterDiscount2 = el.totalAfterDiscount1;
            el.totalAfterDiscount3 = el.totalAfterDiscount1;
            el.netPrice = el.totalAfterDiscount1;
            el.taxValue = (el.netPrice * el.taxRate) / 100;
            el.tableTaxValue = (el.netPrice * el.tableTaxPercent) / 100;
            el.tatolItem = el.totalAfterDiscount1 + el.tableTaxValue + el.taxValue;
        });
        let netTotalList = list.map((item) => {
            return item.netPrice;
        }).reduce((acc, curr) => {
            return acc + curr;
        }, 0);


        // اجمالي ضريبة القيمة المضافة
        let taxSalesList = list.map((item) => {
            return item.taxValue;
        }).reduce((acc, curr) => {
            return acc + curr;
        }, 0);
        // اجمالي ضريبة الجدول
        let taxValueTable = list.map((item) => {
            return item.tableTaxValue;
        }).reduce((acc, curr) => {
            return acc + curr;
        }, 0);

        // الاجمالي قبل الضريبة
        let totalBeforeDiscountAndTax = list.map((item) => {
            return item.netPrice;
        }).reduce((acc, curr) => {
            return acc + curr;
        }, 0);

        let totalItemsDiscount = list.map((item) => {
            return item.discount1 * item.quantity;
        }).reduce((acc, curr) => {
            return acc + curr;
        }, 0);
        setTotalDiscount(totalItemsDiscount)
        setTaxTableRate(+taxValueTable);
        if (settingCompany.useTaxSourceSales === false) {
            let sd = netTotalList + taxSalesList + taxValueTable;
            setTotal(sd);
        } else {
            let ggg = netTotalList - (netTotalList * taxSourceRate / 100) + taxSalesList + taxValueTable;
            setTotal(ggg);
        }
        setTotalBeforeTax(totalBeforeDiscountAndTax);

    }; // calculation total from list

    // calculation total from list
    const TotaDiscountBill = () => {
        let total = list
            .map((item) => {
                return item.discount1;
            })
            .reduce((acc, curr) => {
                return acc + curr;
            }, 0);
        let totalNew = Math.floor(+total).toFixed(4);
        setTotalDiscount(totalNew);
    }; // 

    // calculation total from list
    const TotaBeforeTaxFun = () => {
        let total = list
            .map((item) => {
                return (item.quantity * item.price) - item.discount1;
            })
            .reduce((acc, curr) => {
                return acc + curr;
            }, 0);
        let totalNew = Math.floor(+total).toFixed(4);

        setTotalBeforeTax(totalNew);
    }; // 

    // calculation total from TotaBeforeTaxAdFun
    const TotaBeforeTaxAdFun = () => {
        let total = list
            .map((item) => {
                // console.log(item);
                return (((+item.quantity * +item.price) - +item.discount1) * (item.taxRate / 100));
            })
            .reduce((acc, curr) => {
                return acc + curr;
            }, 0);

        // let totalNew = Math.floor(+total).toFixed(4);

        setTotalTaxAd(total);
    }; // 

    // calculation total from list
    const TotaTaxTableRate = () => {
        let total = list
            .map((item) => {
                return item.tableTaxPercent
            })
            .reduce((acc, curr) => {
                return acc + curr;
            }, 0);
        let totalNew = Math.floor(+total).toFixed(4);
        setTaxTableRate(totalNew);
    }; // calculation total from list


    useEffect(() => {
        window.addEventListener("keydown", (event) => {
            if (event.keyCode == 119) {
                saveF8();
            }
        });
    }, [window]);

    if (falseed) {
        CashCollection(
            Cancel,
            totalTaxAdPresent,
            totalTaxAd,
            0,
            returnBtn ? true : false,
            searchId,
            list,
            total,
            listPayments,
            1,
            typeInvoice,
            typePrint,
            posPintAfterSave,
            storesId,
            posInvoiceTypeId
        )
        setFalseed(false);
    }
    const saveF8 = () => {
        setFalseed(true)
    }

    const Agl = () => {
        let listPaymentsUpdate = [{
            "paymentId": 6,
            "paymentType": 6,
            "paymentValue": +total,
            "accountId": ""
        }]
        CashCollection(Cancel, totalTaxAdPresent, totalTaxAd, 0, returnBtn ? true : false, searchId, list, total, listPaymentsUpdate, 2, typeInvoice, typePrint, posPintAfterSave, storesId, posInvoiceTypeId)
    }

    const keyCutomer = e => {
        document.querySelector('.customerNewNameen').value = e.target.value;
    }

    const [tablesPos, setTablesPos] = useState([]);
    const [DeleverOrdersPos, setDeleverOrdersPos] = useState([]);
    const [RunPrint, setRunPrint] = useState(false);

    const actionPOS = (id, end = false) => {
        let OrderInvoice = document.querySelector('#OrderNoCounter').value; // orderNum
        if (!OrderInvoice) OrderInvoice = 0;
        if (OrderInvoice != 0) {
            if (list.length != 0 && list.length > 0) {
                setRunPrint(true);
                document.querySelector('#valueHidden').value = id;
                // console.log("id is:", id);
                // $(`option#idT1`).attr('selected', false);
                // $(`option#idT2`).attr('selected', false);
                // $(`option#idT3`).attr('selected', false);
                // $(`option#idT4`).attr('selected', false);
                // $(`option#idT${id}`).attr('selected', true);
            }
            if (list.length == 0) {
                document.querySelector('#OrderNoCounter').value = '';
                // $(`option#idT1`).attr('selected', false);
                // $(`option#idT2`).attr('selected', false);
                // $(`option#idT3`).attr('selected', false);
                // $(`option#idT4`).attr('selected', false);
                // $(`option#idT${id}`).attr('selected', true);
            }

        } else {
            if (!end) {
                $(`option#idT1`).attr('selected', false);
                $(`option#idT2`).attr('selected', false);
                $(`option#idT3`).attr('selected', false);
                $(`option#idT4`).attr('selected', false);
                $(`option#idT${id}`).attr('selected', true);
            } else {
                if (list.length == 0) {
                    alert('من فضلك اكمل البيانات');
                } else {
                    CashCollection(
                        Cancel,
                        totalTaxAdPresent,
                        totalTaxAd,
                        0,
                        returnBtn ? true : false,
                        searchId,
                        list,
                        total,
                        listPayments,
                        1,
                        typeInvoice,
                        typePrint,
                        posPintAfterSave,
                        storesId,
                        id,
                        POSTables,
                        setRunPrint,
                        end
                    )
                }
            }

        }

    }

    useEffect(() => {
        if (RunPrint) {
            printDeverOrder({ TOD: TOD, list, data: mostData, dataDelevery, setDataDelevery, ClearFunction: Cancel, setRunPrint, clearList: true, changeList: setList })
        }
    }, [RunPrint])

    const closeModal = () => {
        let pop = document.getElementById('Setting');
        pop.classList.remove('show');
        pop.style.display = 'none';
    }


    const handelChangeVar = () => {
        if (printInvoiceKitchen) {
            setPrintVars(!printVars)
        } else {
            alert('لا يمكن طباعة المتغيرات فقط يجب طباعة المطبخ اولا')
        }
    }

    return (
        <div className="container-fluid px-0 voiceSale" id="mostClick" style={{ overflow: "hidden" }} >

            <div className={`row mt-2 ${i18n.language == "en" ? "" : "flex-row-reverse"}`}>

                <div className="col-4 mx-auto SalePostionSearch tabelScroll" style={{ minHeight: "100vh", maxHeight: "100vh", overflowY: "auto" }}>
                    <ListMainGroups
                        ListMainGroups={MainGroups}
                        setItemsGroupsFilter={setItemsGroupsFilter}
                        setChildGroupsFilter={setChildGroupsFilter}
                        ChildGroupsFilter={ChildGroupsFilter}
                        setChildGroupsFiltering={setChildGroupsFiltering}
                    />
                </div>


                <div className="col-4 mx-auto SalePostionSearch tabelScroll" style={{ minHeight: "100vh", maxHeight: "100vh", overflowY: "auto" }}>
                    <ItemsMainGroups ChildGroupsFiltering={ChildGroupsFiltering} ItemsGroupsFilter={ItemsGroupsFilter} AddList={AddList} ChildGroupsFilter={ChildGroupsFilter} setItemsGroupsFilter={setItemsGroupsFilter} />
                </div>

                <input className="d-none" id="valueHidden" />

                <div className="col-4 mx-auto setting-salles py-0 section-table">
                    <div className="text-center">
                        <div className="d-none d-md-block">
                            <div className="userVoice">
                                {/* نوع الفاتورة */}
                                <div className="d-flex justify-content-between flex-row-reverse">
                                    <div className="w-50">
                                        <select className="form-control" dir="rtl" id="typeIdInvoice" disabled onChange={(e) => handelTable(e, setPosInvoiceTypeId)} >
                                            {POSTables.map((el, idx) => <option key={idx} id={`idT${el.posInvoiceTypeId}`} value={el.posInvoiceTypeId}> {el.posInvoiceTypeName} </option>)}
                                        </select>
                                    </div>

                                    <select className="form-control mb-3 store-input-voice thTablePos w-50" id="sourceId" onChange={e => setStoresId(+e.target.value)}>
                                        {stores.map((store, ids) => {
                                            return <option key={ids} value={store.storeId} selected={store.storeId === storesId ? true : false} > {store.storeName} </option>
                                        })}
                                    </select>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center w-50">
                                        <input
                                            type="search"
                                            autoComplete="off"
                                            style={{ width: "90%" }}
                                            className="form-control search-input-voice1 thTablePos p-1"
                                            disabled={changeAccount ? false : true}
                                            ref={userRef}
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            onClick={() => setPointer("openUser")}
                                            id="openUser"
                                        />
                                        <button className="btn btn-blue mx-2 py-1 thTablePos" disabled={close} data-toggle="modal" data-target="#modalAddUser">
                                            <BsFillPersonPlusFill className="add-user thTablePos" />
                                        </button>
                                        <ul className="list-group list-group-filter-search list-group-filter-search-1 text-left d-none">
                                            {countries.map((country, idx) => (
                                                <button key={idx} className="list-group-item list-group-item-action list-group-item-primary" onClick={() => {
                                                    setSearch(country.customerName);
                                                    setSearchId(country.id);
                                                }}
                                                >
                                                    <div className="col pt-2">
                                                        {country.customerName}
                                                    </div>
                                                </button>
                                            ))}
                                        </ul>
                                    </div>
                                    <input type="text" className="form-control w-50 thTablePos" placeholder={t('description')} id="descInvoice" />
                                </div>


                                {/* الوصف */}
                                <div className="my-1">
                                    {/* <input type="text" className="form-control w-25" placeholder="الوصف" id="descInvoice" /> */}
                                    <div className="d-flex flex-row-reverse align-items-center">
                                        <label htmlFor="OrderNoCounter" className="w-25 thTablePos">  {t('orderNum')} </label>
                                        <input type="number" className="form-control w-75 thTablePos text-center" disabled id="OrderNoCounter" />
                                    </div>
                                </div>
                            </div>

                            <div className="returnedV">
                                <div className="setting-pos d-flex justify-content-between align-items-center my-1 thTablePos">
                                    {/* **** */}
                                    <div className="form-check my-2">
                                        <label className="form-check-label" htmlFor="posPintAfterSave">
                                            {t('printaftersaving')}
                                        </label>
                                        <input type="checkbox" className="form-check-input ml-2" onChange={() => setPosPintAfterSave(!posPintAfterSave)} id="posPintAfterSave"
                                            defaultChecked={Dates.posPintAfterSave}
                                            checked={posPintAfterSave}
                                        />
                                    </div>

                                    <div>
                                        <button type="button" className="btn btn-secondary btn-sm px-4" onClick={() => actionPOS(2)}> {t('delevery')} </button>
                                        <button type="button" className="btn btn-info btn-sm mx-2" onClick={() => actionPOS(3)}>{t('local')}</button>
                                        <button type="button" className="btn btn-primary btn-sm mx-2" onClick={() => actionPOS(4)}>{t('safary')}</button>
                                    </div>

                                </div>
                            </div>
                        </div>


                        {/*  */}

                        <div className="d-block d-md-none">
                            <div className="userVoice d-flex align-items-center">
                                <input type="search" autoComplete="off" style={{ width: "90%" }} className="form-control search-input-voice thTablePos" disabled={changeAccount ? false : true} ref={userRef} value={search} onChange={(e) => setSearch(e.target.value)} onClick={() => setPointer("openUser")} id="openUser" />
                                <button className="btn btn-primary mx-2 py-2" data-toggle="modal" data-target="#modalAddUser">
                                    <BsFillPersonPlusFill className="add-user thTablePos" />
                                </button>
                                <ul className="list-group list-group-filter-search  list-group-filter-search-2 text-left d-none ">
                                    {countries.map((country, idx) => (
                                        <button key={idx} className="list-group-item list-group-item-action list-group-item-primary" onClick={(e) => { setSearch(country.customerName); setSearchId(country.id); }}>
                                            <div className="col pt-2">
                                                {country.customerName}
                                            </div>
                                        </button>
                                    ))}
                                </ul>
                            </div>

                        </div>


                        {/* List */}
                        <Table
                            key={Math.random() * 110}
                            returnBtnHold={returnBtnHold}
                            returnBtn={returnBtn}
                            changeDiscountItem={changeDiscountItem}
                            modifyDiscount={modifyDiscount}
                            changePriceItem={changePriceItem}
                            TotaBill={TotaBill}
                            modifyPrice={modifyPrice}
                            modifyQuantity={modifyQuantity}
                            list={list}
                            DelItem={DelItem} AddNumber={AddNumber} MinusNumber={MinusNumber} changeCountItem={changeCountItem}
                        />


                        <div className="setting-salles-btns setting-salles-btns-one">
                            {/* <div className="returnedV">
                                <PrintNumber changePrinter={changePrinter} />
                            </div> */}
                            <div className="">
                                <div className="d-felx justify-content-between font-ar my-3 setting-salles-btns-actione">
                                    <button disabled={close}
                                        style={{ background: "green", color: "#fff" }}
                                        className="btn btn-resposive px-1 mx-1 py-2  thTablePos"
                                        onClick={() => handeltables({ fun: setTablesPos })}
                                        data-toggle="modal" data-target="#Tablesss"
                                    >
                                        {t('tables')}
                                    </button>
                                    <button disabled={close}
                                        className="btn btn-resposive px-1 py-2  mx-1 text-light thTablePos btn-sm"
                                        data-toggle="modal" data-target="#Deleverr"
                                        onClick={() => handelDeverOrder({ fun: setDeleverOrdersPos })}
                                        style={{ backgroundColor: "#4839eb" }}>
                                        {t('ordersDevelery')}
                                    </button>
                                </div>


                                <div className="d-felx justify-content-between font-ar my-3 setting-salles-btns-actione">
                                    <button
                                        disabled={close}
                                        style={{ background: "green", color: "#fff" }}
                                        className="btn btn-resposive btn-sm px-1 mx-1 py-2 classCachWeb thTablePos"
                                        onClick={() => CashCollection(Cancel, totalTaxAdPresent, totalTaxAd, 0, returnBtn ? true : false, searchId, list, total, listPayments, 1, typeInvoice, typePrint, posPintAfterSave, storesId, 0, POSTables, setRunPrint, "")}>
                                        {t('cashcollection')}
                                    </button>
                                    <button disabled={close} className="btn btn-resposive px-1 py-2  mx-1 text-light btn-sm thTablePos" data-toggle="modal" data-target="#modal-pay" style={{ backgroundColor: "#4839eb" }}>
                                        {t('pay')}
                                    </button>
                                    <button className="btn btn-resposive px-1 py-2 text-light mx-1 returnedV thTablePos btn-sm" disabled={invoiceHold ? false : true} onClick={() => Hold(0, returnBtn ? true : false, searchId, list, setList, total, PriceAfterDiscount, setTotal, setPriceAfterDiscount, setSearch, inputRef)} style={{ backgroundColor: "#9aa9b7" }} >
                                        {t('Suspension')}
                                    </button>
                                </div>
                                <div className="d-felx justify-content-between my-3">
                                    <button className="btn btn-resposive btn-danger mx-1 px-1 py-2 thTablePos btn-sm" disabled={cancelCheque ? false : true} onClick={() => Cancel()} >  {t('cancel')} </button>
                                    {returnBtn
                                        ?
                                        <button className="btn btn-resposive btn-danger mx-1 px-1 py-2 thTablePos btn-sm" onClick={() => ReturnedCancel()}>
                                            {t('saleinvoice')}
                                        </button>
                                        :
                                        <button disabled={returned ? false : true} onClick={() => firstModal()} className="btn btn-resposive btn-success mx-1 px-1 py-2 thTablePos"> {t('print')} </button>
                                    }

                                    <button className="btn btn-resposive text-light mx-1 px-0 py-2 returnedV thTablePos btn-sm" disabled={invoiceHold ? false : true} data-toggle="modal" data-target="#modal-holdes" style={{ backgroundColor: "#9aa9b7" }} >
                                        {t('Pendingorders')}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* MODELS */}


            {/* MODELS */}
            <div className="modal fade" id="Tablesss" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "70%" }}>
                    <div className="modal-content">
                        <h4 className="text-center py-3"> {t('tables')} </h4>
                        <div className="list-groups flex-row-reverse px-2">
                            {tablesPos.map(({ tableName, statusId, rTablesId }, idx) => {
                                return (
                                    <button key={idx} data-dismiss="modal" className={`child-list ${statusId == 1 ? "btn-success" : "btn-danger"}`} onClick={() => {
                                        getTableOrder({ changeTOD: setTOD, oldTdd: TOD, TOD: 1, changeType: setPOSTables, type: POSTables, changeDatDelevery: setDataDelevery, id: rTablesId, changeList: setList, items: mostData.itemsList, list, data: mostData, dataDelevery, ClearFunction: Cancel, setRunPrint })
                                    }}>
                                        <h6 className="title-list">{tableName}</h6>
                                    </button>
                                )
                            })}

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal"> {t('cancel')} </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="Deleverr" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "70%" }}>
                    <div className="modal-content">
                        <h4 className="text-center py-3"> {t('ordersDevelery')} </h4>
                        <div className="list-groups flex-row-reverse px-2">
                            {DeleverOrdersPos.map(({ tableName, tableNumber }, idx) => {
                                return (
                                    <button key={idx} className={`child-list`} data-dismiss="modal" onClick={() =>
                                        getDeverOrder({ changeTOD: setTOD, oldTdd: TOD, TOD: 2, changeType: setPOSTables, type: POSTables, changeDatDelevery: setDataDelevery, id: tableNumber, changeList: setList, items: mostData.itemsList, list, data: mostData, dataDelevery, ClearFunction: Cancel, setRunPrint })}>
                                        <h6 className="title-list"> {tableName} </h6>
                                    </button>
                                )
                            })}

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal"> {t('cancel')} </button>
                        </div>
                    </div>
                </div>
            </div>



            <div className="modal fade" id="Setting" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "70%" }}>
                    <div className="modal-content">
                        <h4 className="text-center py-3"> {t('printerSettings')} </h4>
                        <div className="list-groups flex-row-reverse px-2">

                            <div className="container">
                                <div className="row">
                                    <div className="col-10 mx-auto text-center">
                                        <div className="d-flex flex-row-reverse align-items-center">
                                            <div className="form-check my-2 FormStyle w-25">
                                                <label className="form-check-label" style={{ margin: "0 33px" }} for="printInvoice">
                                                    {t('invoicePrinting')}
                                                </label>
                                                <input type="checkbox" checked={printInvoice} onChange={() => setPrintInvoice(!printInvoice)} className="form-check-input ml-2" id="printInvoice" />
                                            </div>
                                            <div className="form-group my-2 FormStyle w-50">
                                                <input type="number" className="form-control ml-2" id="numPaper" defaultValue={1} />
                                                <label className="form-label" style={{ margin: "0 10px", width: "100px" }} for="numPaper">
                                                    {t('numberOfCopies')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-check my-2 FormStyle">
                                            <label className="form-check-label" style={{ margin: "0 33px" }} for="printInvoiceKitchen">
                                                {t('kitchenPrint')}
                                            </label>
                                            <input type="checkbox" checked={printInvoiceKitchen} onChange={() => setPrintInvoiceKitchen(!printInvoiceKitchen)} className="form-check-input ml-2" id="printInvoiceKitchen" />
                                        </div>
                                        <div className="form-check my-2 FormStyle">
                                            <label className="form-check-label" style={{ margin: "0 33px" }} for="printVars">
                                                {t('onlyPrintVariables')}
                                            </label>
                                            <input type="checkbox" checked={printVars} onChange={() => handelChangeVar()} className="form-check-input ml-2" id="printVars" />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => printDeverOrder({ printVars, setPrintVars, printInvoiceKitchen, printInvoiceKitchenVar: printInvoiceKitchen, printVars: printVars, printInvoiceKitchen: printInvoiceKitchen, setPrintInvoiceKitchen, setPrintVars, setPrintInvoice, Dates, TOD: TOD, list, data: mostData, dataDelevery, setDataDelevery, ClearFunction: Cancel, setRunPrint, changeList: setList, defaultType: Dates.posInvoiceTypeId, main: Dates.printFromFrontEnd })}> معاينة الطباعة </button>
                            <button type="button" className="btn btn-danger" onClick={() => closeModal()}> {t('cancel')} </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="modal-pay" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-pay">
                    <div className="modal-content" style={{ minHeight: "80vh" }}>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-5">
                                    <TablePay list={list} />
                                </div>
                                <div className="col-12 col-md-7">
                                    <PayTabs
                                        totalCredit={totalCredit}
                                        total={total}
                                        list={list}
                                        onSaveCredit={onSaveCredit}
                                        cach={cach}
                                        masterCard={masterCard}
                                        visa={visa}
                                        onChangeCach={onChangeCach}
                                        onChangeVisa={onChangeVisa}
                                        onChangeMasterCard={onChangeMasterCard}
                                        credit={credit}
                                        onChangeCredit={onChangeCredit}
                                        totalMatserCard={totalMatserCard}
                                        Agl={Agl}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            <h5 id="totalAllSeles" className="text-muted">
                                {total}
                            </h5>
                            <button type="button" className="btn bg-addition font-ar text-light px-3" data-dismiss="modal">
                                {t('Retreat')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modal-holdes" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-holdes">
                    <div className="modal-content" style={{ minHeight: "80vh" }}>
                        <div className="modal-body">

                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col"> {t('code')} </th>
                                        <th scope="col"> {t('recovery')} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {AllHolds.map((invoiceHold, indexItem) => {
                                        return (
                                            <tr key={indexItem}>
                                                <th scope="row">{invoiceHold.invoiceCode}</th>
                                                <td> <button className="btn btn-success" onClick={() => ReturnedHoldInvoice(invoiceHold.invoiceId)} data-dismiss="modal"> {t('recovery')} </button> </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>


                        </div>
                        <div className="modal-footer d-flex justify-content-between" data-dismiss="modal">
                            <button className="btn btn-danger"> {t('cancel')} </button>
                        </div>
                    </div>
                </div>
            </div>


            {/* modal add customer */}
            <div className="modal fade" id="modalAddUser" aria-labelledby="addCustomerModal" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "800px" }}>
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <div className=" text-center">
                                <h5 className="modal-title text-center" id="addCustomerModal"> {t('addcustomernew')} </h5>
                            </div>
                        </div>
                        <div className="modal-body my-4">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <div className="form-group text-right">
                                            <label className="px-2"> {t('usernameCustomeren')} </label>
                                            <input type="text" className="form-control text-right customerNewNameen" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2">{t('usernameCustomerar')}</label>
                                        <input type="text" className="form-control customerNewNamear text-right" onKeyUp={e => keyCutomer(e)} />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('Telephone')} </label>
                                        <input type="number" dir="rtl" className="form-control text-right txtFile1" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('Telephone')} </label>
                                        <input type="number" dir="rtl" className="form-control text-right txtFile2" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('mobile')} </label>
                                        <input type="number" dir="rtl" className="form-control text-right mobileFile" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('Fax')} </label>
                                        <input type="text" dir="rtl" className="form-control text-right faxFile" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('Email')} </label>
                                        <input type="email" className="form-control text-right emailFile" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('webSite')} </label>
                                        <input type="text" className="form-control text-right siteFile" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('address')} </label>
                                        <input type="text" className="form-control text-right addressFile" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2">{t('tax_registration_number')}</label>
                                        <input type="text" className="form-control text-right NumTax" />
                                    </div>

                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('File_No_Commercial_Registry')} </label>
                                        <input type="text" className="form-control text-right fileNum" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('VAT_registration_number')} </label>
                                        <input type="text" className="form-control text-right NumTaxPlus" />
                                    </div>

                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group text-right">
                                        <label className="px-2"> {t('Notes')} </label>
                                        <textarea className="form-control text-right notesFile"></textarea>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-secondary font-ar" data-dismiss="modal"> {t('cancel')} </button>
                            <button type="button" className="btn btn-primary font-ar" data-dismiss="modal" onClick={(e) => NewUserCustomer()}> {t('save')} </button>
                        </div>
                    </div>
                </div>
            </div>


            {/* EMd MODELS */}
        </div>
    );
}
