import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Roadmap from '../../components/Roadmap';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import Alerts from './Alerts';
import Collection from './Collection';
import Tax from './Tax';
import TypeTecnical from './TypeTecnical';
import Groups from './Groups';
import Electroic from './Electroic';
import More from './More';
import TableItems from './TableItems';

function AddItem() {

    const { DefinitionItems = [], addItemDefintion } = useContext(ProductContext);

    // first root
    const [code, setCode] = useState(0);
    const [name_ar, setName_Ar] = useState('');
    const [name_en, setName_EN] = useState('');
    const [category, setCategory] = useState(''); // category التنصنيف
    const [itemType, setItemType] = useState(''); // item type نوع الصنف
    const [costType, setCostType] = useState(''); // cost type نوع التكلفة
    const [groupId, setGroupId] = useState(''); // cost type نوع التكلفة
    const [notes, setNotes] = useState(''); // ملاحظات
    // second root
    const [unit, setUnit] = useState('') // الوحدة
    const [smallUnit, setSmallUnit] = useState('') // اصغر وحدة
    const [rate, setRate] = useState('') // معدل
    const [defaultM, setDefaultM] = useState(false) // افتراضي مشتريات
    const [checkedM, setCheckedM] = useState(false) // افتراضي مبيعات
    const [Barcode, setBarCode] = useState('') // باركود
    const [salePolicy, setSalePolicy] = useState(false) // سياسة البيع 
    const [valSalePolicy, setValSalePolicy] = useState('') // قيمة سياسة البيع
    const [minimumSalePrice, setMinimumSalePrice] = useState('') // الحد الادني لسعر البيع
    const [salesV1, setSalesV1] = useState(0); // قيمة 1
    const [salesV2, setSalesV2] = useState(0); // قيمة 2
    const [salesV3, setSalesV3] = useState(0); // قيمة 3
    const [salesV4, setSalesV4] = useState(0); // قيمة 4
    const [salesV5, setSalesV5] = useState(0); // قيمة 5
    const [salesDiscount, setSalesDiscount] = useState(0); // sales discount خصم مبيعات
    const [salesDiscountVal1, setSalesDiscountVal1] = useState(0); // خصم مبيعات قيمة 1
    const [salesDiscountVal2, setSalesDiscountVal2] = useState(0); // خصم مبيعات قيمة 2

    const [purchasePolicy, setPurchasePolicy] = useState(0); // purchase policy سياسة الشراء
    const [purchasePolicyVal, setPurchasePolicyVal] = useState(0); // سياسة الشراء قيمة

    const [purchaseDiscount, setPurchaseDiscount] = useState(0); // purchase discount  خصم مشتريات 
    const [purchaseDiscountVal, setPurchaseDiscountVal] = useState(0); //  خصم مشتريات القيمة 
    const [suggestedSellingPrice, setSuggestedSellingPrice] = useState(''); //  سعر البيع المقترح


    const  history = useHistory();

    // Store 
    const [val, setVal] = useState('')
    const [defaultPurchases, setDefaultPurchases] = useState(false)
    const [defaultSales, setDefaultSales] = useState(false)
    const [salesValue, setSalesValue] = useState(0);

    // alert
    const [alert1, setAlert1] = useState(0);
    const [alert2, setAlert2] = useState(0);
    const [alert3, setAlert3] = useState(0);
    // electromic
    const [typeCode, setTypeCode] = useState("");
    const [codeElec, setCodeElec] = useState("");

    const [List, setList] = useState([
        {
            id: 1, // 
            unit, // الوحدة
            smallUnit, // اصغر وحدة
            rate, // المعدل
            Barcode, // باركود
            salePolicy, // سياسة البيع
            valSalePolicy, // قيمة سياسة البيع
            minimumSalePrice, // الحد الادني سياسة البيع
            salesV1,
            salesV2,
            salesV3,
            salesV4,
            salesV5,
            salesDiscount, //خصم مبيعات
            salesDiscountVal1, // خصم مبيعات قيمة 1
            salesDiscountVal2, // خصم مبيعات قيمة 2
            purchasePolicy, // سياسة الشراء
            purchasePolicyVal, // قيمة سياسة الشراء
            purchaseDiscount, //خصم مشتريات
            purchaseDiscountVal, // خصم مشتريات قيمة
            suggestedSellingPrice, // سعر البيع المقترح
            dis: false
        }
    ])

    const addItem = (id) => {
        if (id == 1) {
            List.map(item => {
                item.unit = unit;
                item.smallUnit = smallUnit;
                item.rate = rate;
                item.Barcode = Barcode;
                item.salePolicy = salePolicy;
                item.valSalePolicy = valSalePolicy;
                item.minimumSalePrice = minimumSalePrice;
                item.salesV1 = salesV1;
                item.salesV2 = salesV2;
                item.salesV3 = salesV3;
                item.salesV4 = salesV4;
                item.salesV5 = salesV5;
                item.salesDiscount = salesDiscount;
                item.salesDiscountVal1 = salesDiscountVal1;
                item.salesDiscountVal2 = salesDiscountVal2;
                item.purchasePolicy = purchasePolicy;
                item.purchasePolicyVal = purchasePolicyVal;
                item.purchaseDiscount = purchaseDiscount;
                item.purchaseDiscountVal = purchaseDiscountVal;
                item.suggestedSellingPrice = suggestedSellingPrice;
                item.dis = true;
                return item;
            });
            setBarCode('')
            setRate('')
            setSalePolicy('')
            setVal('')
            setDefaultPurchases(false)
            setDefaultSales(false)
            setDefaultSales(false)
            setSalesDiscount('')
            setPurchasePolicy('')
            setPurchaseDiscount('')
            return setList(state => [...state, {
                id: id + 1, // 
                unit: '', // الوحدة
                smallUnit: '', // اصغر وحدة
                rate: '', // المعدل
                Barcode: '', // باركود
                salePolicy: '', // سياسة البيع
                valSalePolicy: '', // قيمة سياسة البيع
                minimumSalePrice: '', // الحد الادني سياسة البيع
                salesV1: 0,
                salesV2: 0,
                salesV3: 0,
                salesV4: 0,
                salesV5: 0,
                salesDiscount: '', //خصم مبيعات
                salesDiscountVal1: 0, // خصم مبيعات قيمة 1
                salesDiscountVal2: 0, // خصم مبيعات قيمة 2
                purchasePolicy: '', // سياسة الشراء
                purchasePolicyVal: 0, // قيمة سياسة الشراء
                purchaseDiscount: "", //خصم مشتريات
                purchaseDiscountVal: 0, // خصم مشتريات قيمة
                suggestedSellingPrice: 0, // سعر البيع المقترح
                dis: false
            },
            ])
        } else {
            List.map(item => {
                if (item.id == id) {
                    item.unit = unit;
                    item.smallUnit = smallUnit;
                    item.rate = rate;
                    item.Barcode = Barcode;
                    item.salePolicy = salePolicy;
                    item.valSalePolicy = valSalePolicy;
                    item.minimumSalePrice = minimumSalePrice;
                    item.salesV1 = salesV1;
                    item.salesV2 = salesV2;
                    item.salesV3 = salesV3;
                    item.salesV4 = salesV4;
                    item.salesV5 = salesV5;
                    item.salesDiscount = salesDiscount;
                    item.salesDiscountVal1 = salesDiscountVal1;
                    item.salesDiscountVal2 = salesDiscountVal2;
                    item.purchasePolicy = purchasePolicy;
                    item.purchasePolicyVal = purchasePolicyVal;
                    item.purchaseDiscount = purchaseDiscount;
                    item.purchaseDiscountVal = purchaseDiscountVal;
                    item.suggestedSellingPrice = suggestedSellingPrice;
                    item.dis = true;
                }
                return item;
            });
            setBarCode('')
            setRate('')
            setSalePolicy('')
            setVal('')
            setDefaultPurchases(false)
            setDefaultSales(false)
            setDefaultSales(false)
            setSalesDiscount('')
            setPurchasePolicy('')
            setPurchaseDiscount('')
            return setList(state => [...state, {
                id: id + 1, // 
                unit: '', // الوحدة
                smallUnit: '', // اصغر وحدة
                rate: '', // المعدل
                Barcode: '', // باركود
                salePolicy: '', // سياسة البيع
                valSalePolicy: '', // قيمة سياسة البيع
                minimumSalePrice: '', // الحد الادني سياسة البيع
                salesV1: 0,
                salesV2: 0,
                salesV3: 0,
                salesV4: 0,
                salesV5: 0,
                salesDiscount: '', //خصم مبيعات
                salesDiscountVal1: 0, // خصم مبيعات قيمة 1
                salesDiscountVal2: 0, // خصم مبيعات قيمة 2
                purchasePolicy: '', // سياسة الشراء
                purchasePolicyVal: 0, // قيمة سياسة الشراء
                purchaseDiscount: "", //خصم مشتريات
                purchaseDiscountVal: 0, // خصم مشتريات قيمة
                suggestedSellingPrice: 0, // سعر البيع المقترح
                dis: false
            },
            ])
        }

    }

    const onSaveing = (e) => {
        e.preventDefault();
        // alert('جاري العمل علي الاضافة')
        let { id } = List[List.length - 1];

        List.map(item => {
            if (item.id == id) {
                item.unit = unit;
                item.unitId = unit;
                item.mainUnit = smallUnit == 1 ? true : false;
                item.rate = rate;
                item.barCode = Barcode;
                item.salesPolicy = salePolicy;
                item.salesValue = valSalePolicy;
                item.minimumSalePrice = minimumSalePrice;
                item.salesV1 = salesV1;
                item.salesV2 = salesV2;
                item.salesV3 = salesV3;
                item.salesV4 = salesV4;
                item.salesV5 = salesV5;
                item.defaultUnit = defaultM == "true" ? true : false;
                item.defaultUnitSales = salesValue == "true" ? true : false;

                item.salesDiscountType = +salesDiscount;
                item.salesDiscountValue = +salesDiscountVal1;
                item.salesDiscountVal2 = +salesDiscountVal2;
                item.purchasePolicy = purchasePolicy;
                item.purchaseValue = purchasePolicyVal;
                item.purchaseDiscountType = +purchaseDiscount;
                item.purchaseDiscountVal = +purchaseDiscountVal;

                item.suggestedSellingPrice = suggestedSellingPrice;
            }
            return item;
        });
        let obj = {
            code,
            name_ar,
            name_en,
            category,
            itemType,
            costType,
            notes,
            groupId,
            list: List,
        }
        console.log("List", List);
        addItemDefintion(obj, history)
    }

    const { t } = useTranslation();

    const delItem = id => {
        console.log(id);
        let newList = List.filter(item => item.id != id);
        setList(newList)
        console.log(List);
    }

    return (
        <Layout>
            <div className="company text-right font-ar container companyReciptVoucher py-5" style={{ maxWidth: "1100px" }}>
                <div className="container py-4">
                    <div className="row mx-0">
                        <Roadmap name={t('define_item')} child={"اضافة صنف"} childTo={{ pathname: '/DefinitionItems' }} />
                        <div className="col-12 col-md-11 mx-auto px-0">
                            <form dir="rtl">

                                <div className="row py-2">
                                    <div className="col-12 col-md-6 mx-auto">
                                        <select className="form-control my-3"  onChange={e => setGroupId(e.target.value)}>
                                            <option> المجموعة </option>
                                            {DefinitionItems ? DefinitionItems.itemGroup.map(unit => <option value={unit.groupId}> {unit.itemGroupName} </option>) : ''}

                                        </select>
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto">
                                        <input type="number" className="form-control mt-3" placeholder="كود" onChange={(e) => setCode(e.target.value)} />
                                    </div>
                                </div>

                                <div className="row py-2">
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label style={{ fontSize: "14px" }}>الاسم بالعربي</label>
                                        <input type="text" className="form-control" onChange={(e) => setName_Ar(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label style={{ fontSize: "14px" }} label>الاسم بالانجليزي</label>
                                        <input type="text" className="form-control" onChange={(e) => setName_EN(e.target.value)} />
                                    </div>
                                </div>


                                <div className="row py-2">
                                    <div className="col-12 col-md-6 mx-auto my-3">
                                        <label>نوع الصنف</label>
                                        <select className="form-control" onChange={e => setItemType(e.target.value)}>
                                            <option value="..."> ... </option>
                                            <option value="1"> مخزنى </option>
                                            <option value="2"> خدمى </option>
                                            <option value="3"> صنف مجمع </option>
                                            <option value="4"> صنف مصنع </option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto my-3">
                                        <label>التصنيف</label>
                                        <select className="form-control" onChange={e => setCategory(e.target.value)}>
                                            <option value="..."> ... </option>
                                            <option value="1"> عام  </option>
                                            <option value="2"> وسيط  </option>
                                            <option value="3"> تام  </option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row py-2">
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label style={{ fontSize: "14px" }}>نوع التكلفة</label>
                                        <select className="form-control" onChange={e => setCostType(e.target.value)}>
                                            <option value="..."> ... </option>
                                            <option value="1"> الوارد اولا صادر اولا </option>
                                            <option value="2"> الوارد اخير صادر اولا   </option>
                                            <option value="3"> متوسط التكلفة </option>
                                            <option value="4 ">اخر فاتورة مشتريات</option>
                                            <option value="5"> التسلسل  </option>
                                            <option value="6"> رقم الهيكل </option>
                                        </select>
                                        {/* <input type="number" className="form-control" onChange={(e) => setCode(e.target.value)} /> */}
                                    </div>
                                    <div className="col-12 col-md-6 mx-auto">
                                        <label style={{ fontSize: "14px" }}> ملاحظات </label>
                                        <textarea type="url" className="form-control" onChange={e => setNotes(e.target.value)}></textarea>
                                    </div>
                                </div>


                                <ul className="nav nav-tabs p-0 my-3" id="myTabItems" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" id="pricing_policy_tab" data-toggle="tab" href="#pricing_policy" role="tab" aria-controls="home" aria-selected="true"> الوحدات وسياسة التسعير </a>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="collection-tab-items" data-toggle="tab" href="#Collection" role="tab" aria-controls="profile" aria-selected="false">تفاصيل التجميع والتصنيع</a>
                                    </li> */}
                                    {/* <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="profile-tab-items" data-toggle="tab" href="#TypeTecnical" role="tab" aria-controls="profile" aria-selected="false">الموصفات الفنية</a>
                                    </li> */}
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="tax-tab-items" data-toggle="tab" href="#Tax" role="tab" aria-controls="profile" aria-selected="false">الضرائب</a>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="Groups-items" data-toggle="tab" href="#Groups" role="tab" aria-controls="profile" aria-selected="false">مستويات المجموعات</a>
                                    </li> */}
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="alerts-items" data-toggle="tab" href="#Alerts" role="tab" aria-controls="profile" aria-selected="false">التنبيهات</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="Electroic-items" data-toggle="tab" href="#Electroic" role="tab" aria-controls="profile" aria-selected="false">تكامل الفاتورة الالكترونية</a>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="More-items" data-toggle="tab" href="#More" role="tab" aria-controls="profile" aria-selected="false">المزيد</a>
                                    </li> */}
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="pricing_policy" role="tabpanel" aria-labelledby="pricing_policy_tab">
                                        <TableItems
                                            List={List}
                                            setRate={setRate}
                                            setList={setList}
                                            setSalePolicy={setSalePolicy}
                                            setVal={setVal}
                                            setBarCode={setBarCode}
                                            addItem={addItem}
                                            setDefaultPurchases={setDefaultPurchases}
                                            setDefaultSales={setDefaultSales}
                                            DefinitionItems={DefinitionItems}
                                            checkedM={checkedM}
                                            setCheckedM={setCheckedM}
                                            setDefaultM={setDefaultM}
                                            defaultM={defaultM}
                                            salesValue={salesValue}
                                            setSalesValue={setSalesValue}
                                            salesV2={salesV2} setSalesV2={setSalesV2}
                                            salesV3={salesV3} setSalesV3={setSalesV3}
                                            salesV4={salesV4} setSalesV4={setSalesV4}
                                            salesV5={salesV5} setSalesV5={setSalesV5}
                                            setSmallUnit={setSmallUnit}
                                            setUnit={setUnit}
                                            delItem={delItem}
                                            setSalesDiscount={setSalesDiscount}
                                            setPurchasePolicy={setPurchasePolicy}
                                            setPurchaseDiscount={setPurchaseDiscount}
                                            setSalesV1={setSalesV1}
                                            setValSalePolicy={setValSalePolicy}
                                            setMinimumSalePrice={setMinimumSalePrice}
                                            setSalesDiscountVal1={setSalesDiscountVal1}
                                            setSalesDiscountVal2={setSalesDiscountVal2}
                                            setPurchasePolicyVal={setPurchasePolicyVal}
                                            setPurchaseDiscountVal={setPurchaseDiscountVal}
                                            setSuggestedSellingPrice={setSuggestedSellingPrice}
                                        />
                                    </div>
                                    <div className="tab-pane fade" id="Collection" role="tabpanel" aria-labelledby="tax-tab-items">
                                        <Collection />
                                    </div>
                                    <div className="tab-pane fade" id="TypeTecnical" role="tabpanel" aria-labelledby="tax-tab-items">
                                        <TypeTecnical />
                                    </div>
                                    <div className="tab-pane fade" id="Groups" role="tabpanel" aria-labelledby="Groups-items">
                                        <Groups />
                                    </div>
                                    <div className="tab-pane fade" id="Alerts" role="tabpanel" aria-labelledby="alerts-items">
                                        <Alerts data={{alert1, alert2, alert3, setAlert1,setAlert2,setAlert3}} />
                                    </div>
                                    <div className="tab-pane fade" id="Tax" role="tabpanel" aria-labelledby="tax-tab-items">
                                        <Tax />
                                    </div>
                                    <div className="tab-pane fade" id="Electroic" role="tabpanel" aria-labelledby="Electroic-items">
                                        <Electroic data={{typeCode, codeElec, setTypeCode, setCodeElec}}  />
                                    </div>
                                    <div className="tab-pane fade" id="More" role="tabpanel" aria-labelledby="More-items">
                                        <More />
                                    </div>
                                </div>

                                <hr />
                                <div className="submiting d-flex mt-5">
                                    <button type="submit" className="btn btn-primary mx-2 px-4" onClick={e => onSaveing(e)}>حفظ</button>
                                    <Link to={{ pathname: '/DefinitionItems', params: { status: 2 } }}  className="btn btn-primary mx-2 px-4">تراجع</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}





export default AddItem;