import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Roadmap from '../../components/Roadmap';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import Stand from '../ComponentsInheritanc/Stand';


function CreateSupplierGroup() {
    const { createSupplierGroup } = useContext(ProductContext);

    const { t } = useTranslation();

    const [category, setCategory] = useState([]);
    const [code, setCode] = useState('');
    const [name_ar, setName_Ar] = useState('');
    const [name_en, setName_EN] = useState('');
    const [notes, setNotes] = useState('');
    const [checked, setChecked] = useState(false);
    const [each, setEach] = useState(true);
    const [byAdminstrator, setByAdminstrator] = useState(false);
    const [texCode, setTexCode] = useState('');

    const history = useHistory();

    const Categories = (e) => {
        e.preventDefault();
        if (name_ar == "" || name_en == "") {
            alert('من فضلك تاكد من البيانات');
        } else {
            createSupplierGroup(notes, name_ar, name_en, checked, setName_Ar, setName_EN, setCategory, setNotes, byAdminstrator, setByAdminstrator, history)
        }
    }

    return (
        <Layout>
            <div className="company text-right font-ar container companyReciptVoucher py-5" style={{ maxWidth: "1100px" }}>
                <div className="container py-4">
                    <Roadmap name={t('SupplierGroup')} child={t('CreateSupplierGroup')} childTo={{ pathname: '/suppliersGroups', params: { status: 2 } }} />
                    <div className="row mx-0">
                        <Stand
                            setCode={setCode}
                            name_ar={name_ar}
                            setName_Ar={setName_Ar}
                            name_en={name_en}
                            setName_EN={setName_EN}
                            texCode={texCode}
                            setTexCode={setTexCode}
                            notes={notes}
                            setNotes={setNotes}
                            onSubmit={Categories}
                            useTaxCode={false}
                            linkReturn={{ pathname: '/suppliersGroups', params: { status: 2 } }}
                            each={each}
                            setEach={setEach}
                        >
                            <div className="row">
                                <div className="col-12 col-md-6 my-2">
                                    <input type="checkbox" className="mx-2" id="adminstrator" checked={byAdminstrator} onChange={() => setByAdminstrator(!byAdminstrator)} />
                                    <label htmlFor='adminstrator'> {t('byadministrator')} </label>
                                </div>
                            </div>
                        </Stand>
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default CreateSupplierGroup
