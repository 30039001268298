const ENGLISH = {
    afakyProgram: "AFAKY ERP SYSTEM",
    afakyDescription: "An easy Arabic accounting program to adjust accounting and inventory, produced by Afaky Company for Information Technology. It works with all international currencies. Anyone can learn it in less than two hours without previous experience in financial accounting. It gives very diverse reports. It is the result of a long experience and the first version of it is in 2008 and is updated Constantly relying on the company's accumulated experience and taking into account the requests of customers",
    afakyMainSite: "Main Site",
    license: "System license",
    help: "System Help",
    versionNumber: "Version Number",
    customerName: "customerName",
    securityCode: "Security Code",
    securityUnLoakCode: "Security Unlock Code",
    m: "m",
    licensestatus: "License Status",
    Send: "Send",

    hello: "Hello",
    nameSystem: "Afaky",
    nameTag: "Afaky Web",
    descTag: "One of the products of Afaky Information Technology Company",
    username: 'Username',
    pass: 'Password',
    remember: "Remember Me",
    login: "Login to the system",
    system: "system",
    company: "company",
    branches: "branches",
    branche: "branche",
    fiscal_years: "fiscal years",
    period: "period",
    curreny: "curreny",
    country: "country",
    cities: "cities",
    regions: "regions",
    nationality: "nationality",
    suppliers_group: "suppliers group",
    suppliers: "suppliers",
    customers_groups: "customers groups",
    customers: "customers",
    sponsors: "sponsors",
    employees: "employees",
    employee: "employee",

    change_employee_branch: "change employee branch",
    send_SMS: "send SMS",
    fiscal_years: "fiscal years",
    users: "users",
    alarms_setting: "alarms setting",
    alarms_today: "alarms today",
    Database: "Database",
    alarms_setting: "alarms setting",
    log_out: "Log out",
    exit: "Exit",
    setting: "setting",
    general_settings: "general settings",
    PC_setting: "PC setting",
    accounts: "Accounts",
    counting: "counting",
    journal_voucher_daily: "journal voucher daily",
    voucher_daily: "voucher daily",
    Financial_transactions: "Financial transactions",
    cash: "cash",
    alcash: "Casher",
    Banks: "Bank",
    receipt_voucher: "receipt voucher",
    transfer_voucher: "Transfer Voucher",
    reports: "reports",
    account_statement: "account statement",
    paper_catch_and_pay: "Paper catch and pay",
    paper_catch: "paper catch",
    paper_pay: "paper pay",
    paper_catch_change_status: "paper catch change status",
    bank: "bank",
    paper_status: "paper status",
    paper_catch_report: "paper catch report",
    paper_pay_report: "paper pay report",
    stores: "stores",
    definition_stores: "definition stores",
    definition_items: "definition items",
    stores_opening_balance: "stores opening balance",
    assemble_item: "assemble item",
    packing_item: "packing item",
    receipt_goods: "receipt goods",
    exchange_goods: "exchange goods",
    transfer_of_goods: "transfer of goods",
    transfer_of_goods_linked_to_receipt: "transfer of goods - linked to receipt",
    receipt_of_goods_transfer: "receipt of goods transfer",
    execution_of_goods: "execution of goods",
    inventory_minutes: "inventory minutes",
    stocktaking: "stocktaking",
    reserve_quantities: "reserve quantities",
    release_quantities: "release quantities",
    item_balance: "item balance",
    follow_cost: "follow cost",
    item_serial_query: "item serial query",
    barcode_print: "barcode print",
    modify_items_prices: "modify items prices",
    adoption: "adoption",
    Purchase: "Purchase",
    Purchase_orders: "Purchase orders",
    Purchase_deals: "Purchase deals",
    Purchase_invoice: "Purchase invoice",
    returned_purchase: "returned Purchase",
    notices_supplier: "notices supplier",
    sales: "sales",
    Retur: "Returns",
    commissioms_policy: "commissioms policy",
    commissioms_salesman: "commissioms salesman",
    quotations: "quotations",
    sales_orders: "sales orders",
    sales_invoice: "sales invoice",
    returned_sales: "returned sales",
    customer_notices: "customer notices",
    review_invoices: "review invoices",
    item_price_query: "Item Price Query",
    offers: "offers",
    moreData: "more data",
    define_units: "define units",
    define_groups: "define groups",
    define_item: "define items",
    POS: "POS",
    POSPoint: "Open Point Of Sale ",
    POSPointR: "Open a Restaurant Point Of Sale",
    sellOrder: "sell order",
    point_of_sale_settings: "point of sale settings",
    Cost_Center_Directory: "Cost Center Directory",
    definition_stores: "definition stores",
    journalVoucherDaily: "journal voucher daily",
    accounSstatement: "Account Statement",
    reports: "reports",
    debtRecoveryCustomer: "Debt Recovery - Customer",
    debtRecoverySuppliers: "Debt Recovery - Supplier",
    categoryCard: "Category Card",
    mainunit: "Main Unit",
    defaultunitpurchase: "Default Unit Purchase",
    defaultunitpurchase: "Default Unit Sales",
    SalesInvoiceSummary: "Sales Invoice Summary",
    PurchaseInvoiceSummary: "Purchase Invoice Summary",

    PurchaseInvoice: "Purchase invoice",
    PurchaseReturned: "Returned Purchase",
    SalesInvoice: "Sales Invoice ",
    SalesReturned: "Returned Sales",

    numJV: 'MV Num',

    receipt: "Catch Receipt",
    transfer: "Receipt",

    // point of sales
    name: "Name",
    code: "Code",
    unit: "Unit",
    number: "Number",
    qtn: "Quantity",
    price: "Price",
    discount: "Discount",
    vat: "VAT",
    total: "Total",
    setting: "Setting",
    nettotal: 'Net Total',
    totalbeforetax: "Total Before Tax",
    discountitems: 'Discount Items',
    Accountstatement: "Account statement",
    customerPos: "Customer",
    customerNamePos: "Customer Name",
    beginning: "The beginning of history",
    endofhistory: "End Of History",
    print: "Print",
    save: "Save",
    edit: "Edit",

    view: "View",
    history: "History",
    source: "Source",
    Nusource: "Source Number",
    Debtor: "Debtor",
    Creditor: "Creditor",
    Balance: "Balance",
    previousbalance: "Previous Balance",
    amountPaid: "Amount Paid",
    amount: "Amount ",

    Residual: "Residual",
    description: "Description",
    cashcollection: "Cash Collection",
    pay: "Pay",
    Pendingorders: "Pending Orders",
    cancel: "Cancel",
    Suspension: "Suspension",
    saleinvoice: "Sale Invoice",
    Returns: "Returns",
    Retreat: "Retreat",
    movementtype: "Movement Type",
    independentmovement: "independent movement",
    recovery: "Recovery",
    date: "Date",
    Check: "Check",
    addcustomernew: "Add New Customer",
    mobile: "Mobile",
    add: "Add",
    status: "Status",
    box: "Account",
    boxnum: "Account Name",
    registrationNumber: "Registration Number",
    num: "Number",
    accountName: "Account Name",
    certified: "Supported",
    notsupported: "Not Supported",
    currenyType: "Curreny",
    sy: "S.Y",
    bondowner: "Bond Owner",
    salesRepresentative: "Sales Representative",
    type: 'Type',
    barcode: "barcode",

    costCenter: "Cost Center",
    dateInvoice: "Date Invoice",
    numebrInvoice: "Number Invoice",
    Advanceamount: "Advance Amount",
    duedate: "Due Date",
    Notdisplayingzerobalanceaccounts: "Not displaying zero balance accounts",
    Accumulatingtheopening: "Accumulating the opening balance if more than one branch is selected",
    all: "All",
    settingPOSPrint: "Point Of Sale Print Settings",
    Prescriptionprintsettings: "Prescription print settings",
    display: "Display",
    Thisprinter: "This Printer ",
    Itemgroup: "Item Group",
    Item: " Item",
    restaurantprintersettings: "Restaurant Printer Settings",
    active: "Active",
    Showthepriceinthekitchenbill: "Show the price in the kitchen bill",
    groupMain: "Group Main",
    printaftersaving: "Print After Saving",
    printfromweb: "Print From WebSite",
    Combinedprinter: "Combined printer",

    effectiveyear: "Determine the effective year",
    accountReports: "Account Reports",
    usernameCustomerar: "Name Customer ( Arabich )",
    usernameCustomeren: "Name Customer ( English )",


    cachPayment: "Cach",
    visaPayment: "Visa",
    mastercardPayment: "MasterCard",
    aglPayment: "Credit",
    SummaryReports: "Invoices summary",
    ItemsBalanceReports: "ItemsBalanceReports",
    Reports: "Reports",
    stockValuation: "Stock Valuation",
    Telephone: "Telephone",
    Fax: "Fax",
    Email: "Email",
    address: "Addess",
    tax_registration_number: "tax registration number",
    VAT_registration_number: "VAT registration number",
    Notes: "Notes",
    webSite: "web Site",
    File_No_Commercial_Registry: "File No. - Commercial Registry",
    amount: "Amount",
    simplified_tax_invoice: "simplified tax invoice",
    companyS: "company",
    Tax_Number: "Tax Number",
    Commercial_Register: "Commercial Register",
    customer: "customer",
    vat2: "Value Added Tax",
    Totalaftertax: "Total after tax",
    Thanks: "Thanks for visiting.. See you soon",
    thenumberofpieces: "the number of pieces",
    amountamount: "amount",

    CashReceiptVoucher: "Cash Receipt Voucher",
    CashPaymentVoucher: "Cash Payment Voucher",

    BankReceiptVoucher: "Bank Receipt Voucher",
    BankPaymentVoucher: "Bank Payment Voucher",

    itemCard: "Item Card",
    itemCardW: "Item Card Without Cost",

    vatTable: "table tax",

    // 

    print: "print",
    ordersDevelery: "Delivery orders",
    tables: "Tables",
    orderNum: "Order number",
    printerSettings: "Printer Settings",
    invoicePrinting: "Invoice Printing",
    numberOfCopies: "Number of copies",
    kitchenPrint: "kitchen Print",
    onlyPrintVariables: "Only print variables",
    // 
    arrangeGroupsAndItems: "Arrange groups and items in the opening point of sale screen",
    input: "input",
    alphabetic: "alphabetic",
    Code: "Code",
    // 

    customerGroup: "Customer Group",
    SupplierGroup: "Supplier Group",
    viewall: "View All",
    salesAndReturns: "Sales and returns",
    store: "Store",
    group: "Group",
    mainGroup: "main Group",
    groupOfItems: "Group of items",
    items: "Items",
    ItemCompanies: "Item Companies",
    Itemcode: "Item code",
    Itemname: "Item Name",
    thesmallestunit: "the smallest unit",
    defaultPurchases: "Default Purchases",
    defaultSales: "Default Sales",

    AllitemsbalanceZero: "All items - item balance zero",
    AllitemsbalanceNotZero: "All items - item balance is not zero",
    Negativeitemsonly: "Negative items only",
    Positiveitemsonly: "Positive items only",
    costType: "Cost Type",
    cost: "Cost",
    totalCost: "Total Cost",
    movementsduring: "Movements During The Period",
    ChooseItem: "Choose Item",

    local: "Local",
    delevery: "Delivery",
    safary: "Out",

    RestaurantScreenSettings: "Restaurant screen settings",
    activate: "Activate",
    IPAdress: "Ip Address",


    arabic: "Arabic",
    english: "English",
    french: "French",

    // 
    definctionUnit: "Units",
    codeTax: "Tax code",
    namear: "Name Arabic",
    nameen: "Name English",

    
    transactionDate: "transaction Date",
    transactionTypeName: "transaction Type Name",
    byadministrator: "by system administrator",
    followeUser: "Follow users",

    WriteEnglishArabic: "Write the name in English like the name in Arabic",
    CreateSupplierGroup: "create supplier group",
    CreateCustomerGroup: "create customer group",
    createUnit: "create Unit",


};

export default ENGLISH;