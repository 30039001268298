import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Roadmap from '../../components/Roadmap';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import Stand from '../ComponentsInheritanc/Stand';



function AddDefinitionUnit() {
    const { SaveUnits } = useContext(ProductContext)
    const [code, setCode] = useState('');
    const [name_ar, setName_Ar] = useState('');
    const [each, setEach] = useState(true);
    const [name_en, setName_EN] = useState('');
    const [notes, setNotes] = useState('');
    const [texCode, setTexCode] = useState('');
    const { t } = useTranslation();
    let history = useHistory();

    const SaveUnit = (e) => {
        e.preventDefault();
        if (name_ar == "" || name_en == "") {
            alert('من فضلك تاكد من البيانات');
        } else {
            SaveUnits(code, name_ar, name_en, notes, texCode, setName_EN, setName_Ar, setNotes, setTexCode, history)
        }
    }

    return (
        <Layout>
            <div className="company text-right font-ar container companyReciptVoucher py-5" style={{ maxWidth: "1100px" }}>
                <div className="container py-4">
                    <div className="row mx-0">
                        <Roadmap name={t('define_units')} child={t('createUnit')} childTo={{ pathname: '/Units', params: { status: 2 } }} />
                        <Stand
                            setCode={setCode}
                            name_ar={name_ar}
                            setName_Ar={setName_Ar}
                            name_en={name_en}
                            setName_EN={setName_EN}
                            texCode={texCode}
                            setTexCode={setTexCode}
                            notes={notes}
                            setNotes={setNotes}
                            onSubmit={SaveUnit}
                            useTaxCode={true}
                            linkReturn={{ pathname: '/Units', params: { status: 2 } }}
                            each={each}
                            setEach={setEach}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AddDefinitionUnit
