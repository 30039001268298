import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";
import { useHistory } from 'react-router-dom';



function Update(props) {
    let id = props.match.params.id;
    const { Companines = [], UpdateCompany } = useContext(ProductContext);

    useEffect(() => {
        let company = Companines.find(item => item.companyId == id);
        setCode(company.companyId)
        setNameAr(company.nameA)
        setNameEN(company.nameE)
    }, [])
    const [code, setCode] = useState(''); // الكود
    const [nameAr, setNameAr] = useState(''); // الاسم بالعربي
    const [nameEn, setNameEN] = useState(''); // الاسم بالانجليزي

    return (
        <Layout>
            <div className="company text-right font-ar">
                <div className="container-fluid py-4">
                    <div className="row mx-0">
                        <div className="col-12 col-md-10 mx-auto px-0">
                            <form dir="rtl">
                                <div className="row py-2">
                                    <div className="col-12 col-md-2 mx-auto">
                                        <label htmlFor="codeCompany">كود الشركة</label>
                                        <input type="number" disabled className="form-control" value={code} onChange={e => setCode(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-md-5 mx-auto">
                                        <label htmlFor="nameAr">الاسم بالعربي</label>
                                        <input type="text" className="form-control" value={nameAr} onChange={e => setNameAr(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-md-5 mx-auto">
                                        <label htmlFor="nameEn">الاسم بالانجليزي</label>
                                        <input type="text" className="form-control" value={nameEn} onChange={e => setNameEN(e.target.value)} />
                                    </div>
                                </div>

                                <div className="submiting d-flex my-3">
                                    <button type="submit" className="btn btn-primary mx-2 px-4" onClick={(e) => {
                                        UpdateCompany(e, {nameAr,nameEn,id});
                                    }}> تعديل </button>
                                    <Link to="/companies" className="btn btn-primary mx-2 px-4 text-light"> تراجع </Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Update
