import React, { useEffect, useState } from "react";
import { useBarcode } from "react-barcodes";
import moment from "moment";
import { AiFillPrinter } from "react-icons/ai";
import * as printerStyleInvoice from "../../styles/printInvoice.module.css";

import { Invoice } from '@axenda/zatca';
import logo from '../../context/image.png'
import { useTranslation } from "react-i18next";

// const imageData = await invoice.render();


function PrintVoice() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        document.querySelector(".loading").classList.add("hideloader");
    }, []);

    const [qr, setQr] = useState('');


    const [voiceId, setVoiceId] = useState(0);
    const [company, setCompany] = useState("");
    const [nowDate, setNowDate] = useState("");
    const [branch, setBranch] = useState("");
    const [total, setTotal] = useState(0);
    const [netTotal, setNetTotal] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [NuPrinter, setNuPrinter] = useState(1);
    const [dateNow, setDateNow] = useState('');


    const [commercialTaxNumber, setCommercialTaxNumber] = useState("");
    const [taxNumber, settaxNumber] = useState("");
    const [totalTaxAd, settotalTaxAd] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerAddress, setCustomerAddress] = useState("");
    const [customerTaxRegistrationNo, setCustomerTaxRegistrationNo] = useState("");
    const [warrantyList, setWarrantyList] = useState([]);
    const [address, setAddress] = useState("");
    const [typeInvoice, setInvoice] = useState(0);


    const [tafkit, setTafkit] = useState("");



    const [list, setList] = useState([]);

    useEffect(() => {
        if (window.parameters) {
            let items = JSON.parse(window.parameters);
            if (items) {
                console.log(items);
                if (items.posprint == true) {
                    if (items.posprint == 1 || items.posprint == 2) {
                        setInvoice(items.typePrint)
                    } else {
                        setInvoice(0)
                    }
                }
                // setNuPrinter(2)
                setNuPrinter(items.posReportNo)

                setVoiceId(items.result.invoiceId);
                setTafkit(items.result.tafkit)
                setList(items.list);
                setNetTotal(items.total);
                setTotal(items.total22);
                setTotalDiscount(items.totaldiscounting);
                setCompany(items.user.companyNameA);
                setBranch(items.user.branchNameA);
                setCommercialTaxNumber(items.info.companyInfoList[0].commercialTaxNumber);
                settaxNumber(items.info.companyInfoList[0].taxNumber)
                setAddress(items.info.companyInfoList[0].address)
                settotalTaxAd(items.totalTaxAd)
                let da = moment().format("d-mm-yyyy");
                let Customer = items.info.customerList.find(cus => cus.id == items.sceId);
                console.log(Customer);
                setCustomerAddress(Customer.address)
                setCustomerTaxRegistrationNo(Customer.taxRegistrationNo)
                setCustomerName(Customer.customerName)
                setNowDate(da);
                setWarrantyList(items.info.warrantyList);
                setDateNow(items.result.invoiceDate);

                const imageData = async () => {
                    const invoice = new Invoice({
                        sellerName: Customer.customerName,
                        vatRegistrationNumber: items.info.companyInfoList[0].taxNumber,
                        invoiceTimestamp: items.result.invoiceDate,
                        invoiceTotal: items.total,
                        invoiceVatTotal: items.totalTaxAd,
                    });
                    const qr = await invoice.render();
                    setQr(qr);
                }
                imageData();
            }
        }
    }, []);


    useEffect(() => {
        const printData = () => {
            window.focus();
            window.print();
        };
        printData();
    }, []);

    const PintWihtPrinter = () => {
        window.focus();
        window.print();
    };



    let diving = '';
    if (NuPrinter === 0) {
        diving = <h6>loading</h6>;

    } else if (NuPrinter === 1) {
        return <PrintDesiginOne
            company={company}
            taxNumber={taxNumber}
            commercialTaxNumber={commercialTaxNumber}
            branch={branch}
            customerName={customerName}
            dateNow={dateNow}
            list={list}
            customerAddress={customerAddress}
            total={total}
            typeInvoice={typeInvoice}
            totalTaxAd={totalTaxAd}
            netTotal={netTotal}
            warrantyList={warrantyList}
            qr={qr}
            PintWihtPrinter={PintWihtPrinter}
            voiceId={voiceId}
            tafkit={tafkit}
            customerTaxRegistrationNo={customerTaxRegistrationNo}
        />
    } else if (NuPrinter === 2) {
        return <PrintDesiginTwo
            company={company}
            taxNumber={taxNumber}
            commercialTaxNumber={commercialTaxNumber}
            branch={branch}
            customerName={customerName}
            dateNow={dateNow}
            list={list}
            typeInvoice={typeInvoice}
            customerAddress={customerAddress}
            total={total}
            totalTaxAd={totalTaxAd}
            netTotal={netTotal}
            tafkit={tafkit}
            warrantyList={warrantyList}
            qr={qr}
            PintWihtPrinter={PintWihtPrinter}
            address={address}
            voiceId={voiceId}
        />
    }

    return (
        <div>
            <div>
                {diving}
            </div>
        </div>
    )


}

export default PrintVoice;


const PrintDesiginOne = ({ tafkit, company, taxNumber, customerAddress, typeInvoice, commercialTaxNumber, branch, customerName, dateNow, list, total, totalTaxAd, netTotal, warrantyList, qr, PintWihtPrinter, voiceId, customerTaxRegistrationNo }) => {

    const { t, i18n } = useTranslation();

    return (

        <div className={`${printerStyleInvoice.content} d-flex px-0 `}>
            <div className={`${printerStyleInvoice.contentFlex}`}>

                <div className="col-printSalseVoive mx-auto text-center mt-4 my-2">
                    {/* <img src={logo} width="100" height="auto" alt="" /> */}
                    <div className="text-center">
                        <img src={logo} width="120" height="auto" alt="" />
                    </div>
                    <div className="details-invoice-printSalseVoive my-4">

                        <div className={`${printerStyleInvoice.riginth}`}>

                            <div className="d-flex justify-content-between">
                                <p className={`${printerStyleInvoice.TdInvoice}`}>  </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice}`}> {t('simplified_tax_invoice')} </h6>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className={`${printerStyleInvoice.TdInvoice}`}> {company} </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice}`}> {t('companyS')} </h6>
                            </div>


                            <div className="d-flex justify-content-between">
                                <p className={`${printerStyleInvoice.TdInvoice}`}> {taxNumber} </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice}`}> {t('Tax_Number')} </h6>
                            </div>


                            <div className="d-flex justify-content-between">
                                <p className={`${printerStyleInvoice.TdInvoice}`}> {commercialTaxNumber} </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice}`}>{t('Commercial_Register')} </h6>
                            </div>

                            <div className="d-flex justify-content-between">
                                <p className={`${printerStyleInvoice.TdInvoice}`}> {branch} </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice}`}> {t('branche')} </h6>
                            </div>

                            <div className="d-flex justify-content-between">
                                <p className={`${printerStyleInvoice.TdInvoice}`}> {customerName} </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice}`}> {t('customer')} </h6>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className={`${printerStyleInvoice.TdInvoice}`}> {customerAddress} </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice}`}> العنوان </h6>
                            </div>

                            <div className="d-flex justify-content-between">
                                <p className={`${printerStyleInvoice.TdInvoice}`}> {customerTaxRegistrationNo} </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice}`}> {t('Tax_Number')} </h6>
                            </div>

                            <div className="d-flex justify-content-between">
                                <p className={`${printerStyleInvoice.TdInvoice}`}>
                                    {moment(dateNow).format("DD-MM-YYYY h:mm")}
                                </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice}`}> {t('dateInvoice')} </h6>
                            </div>

                            <div className="d-flex justify-content-between">
                                <p className={`${printerStyleInvoice.TdInvoice}`}>
                                    {voiceId | 0}
                                </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice}`}> {t('numebrInvoice')} </h6>
                            </div>

                            <div className={`${typeInvoice == 0 ? "d-none" : 'd-flex justify-content-between'}`}>
                                <p className={`${printerStyleInvoice.TdInvoice}`}>
                                    {typeInvoice == 1 ? "طاولة" : ""}
                                    {typeInvoice == 2 ? "توصيل خارجي" : ""}
                                </p>
                                <h6 className={`${printerStyleInvoice.TdInvoice}`}> نوع الفاتورة </h6>
                            </div>


                        </div>


                        <hr />
                        <table>
                            <thead>
                                <tr>
                                    <th className={`${printerStyleInvoice.element}`}>{t('total')}</th>
                                    <th className={`${printerStyleInvoice.element}`}>{t('vat')}</th>
                                    <th className={`${printerStyleInvoice.element}`}>{t('price')}</th>
                                    <th className={`${printerStyleInvoice.element}`}>{t('name')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map(({ name, netPrice, tatolItem, quantity, price, discount1, taxRate }, idx) => {
                                    let newNum = (((+quantity * +price) - +discount1) * (taxRate / 100));
                                    return (
                                        <React.Fragment key={idx}>
                                            <tr className="text-center">
                                                <td className={`${printerStyleInvoice.element} text-right px-3`} colSpan={4}> {name} </td>
                                            </tr>
                                            <tr className="text-center">
                                                <td className={`${printerStyleInvoice.element}`}> {+tatolItem.toFixed(2)} </td>
                                                <td className={`${printerStyleInvoice.element}`}> {+newNum.toFixed(4)}  </td>
                                                <td className={`${printerStyleInvoice.element}`}> {+netPrice.toFixed(2)} </td>
                                                <td className={`${printerStyleInvoice.element}`}>  x {t('amount')} {quantity} </td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                }
                                )}
                            </tbody>
                        </table>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <p className={`${printerStyleInvoice.TdInvoice}`}> {+total.toFixed(2)} </p>
                            <h6 className={`${printerStyleInvoice.TdInvoice} mb-0 my-0 py-0`}>: {t('totalbeforetax')} </h6>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className={`${printerStyleInvoice.TdInvoice}`}> {totalTaxAd ? +totalTaxAd.toFixed(4) : 0} </p>
                            <h6 className={`${printerStyleInvoice.TdInvoice} mb-0 my-0 py-0`}>: {t('vat2')} </h6>
                        </div>


                        <hr />

                        <div className="d-flex justify-content-between">
                            <p className={`${printerStyleInvoice.TdInvoice}`}> {+netTotal.toFixed(2)} </p>
                            <h6 className={`${printerStyleInvoice.TdInvoice}`}>: {t('Totalaftertax')} </h6>
                        </div>
                        <p className={`${printerStyleInvoice.TdInvoice}`}>{tafkit}</p>
                        <hr />
                        <div></div>

                        {warrantyList.map((item, idx) => {
                            return (
                                <div className="wrapper my-3" key={idx}>
                                    <h6 className={`${printerStyleInvoice.TdInvoice} text-right TdInvoice`}>  {item.warrantyName} - </h6>
                                </div>
                            )
                        })}

                        <img src={qr} className={`${printerStyleInvoice.imgFluidPrintQr}`} alt="Invoice QR Code" />
                        <div className="w-100 d-block">
                            <button className="btn btn-primary btn-block font-main my-3" style={{ fontSize: "20px" }} onClick={() => PintWihtPrinter()}>
                                Print <AiFillPrinter />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PrintDesiginTwo = ({ tafkit, company, taxNumber, customerAddress, typeInvoice, commercialTaxNumber, branch, customerName, dateNow, list, total, totalTaxAd, netTotal, warrantyList, qr, PintWihtPrinter, address, voiceId }) => {
    const { t, i18n } = useTranslation();
    let totalQtn = 0;
    let discounts = 0;

    const { inputRef } = useBarcode({
        value: voiceId,
        options: {
            displayValue: false,
            width: 3,
        },
    });


    return (

        <div className={`${printerStyleInvoice.content} d-flex px-0`}>
            <div className={`${printerStyleInvoice.contentFlex}`}>

                <div className="text-center my-2">
                    <img src={logo} width="120" height="auto" alt="" />
                </div>

                <div className="details-invoice-printSalseVoive my-4">
                    <div className={`${printerStyleInvoice.riginth}`}>
                        <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {company} </p>
                        <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {branch} </p>
                        <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {moment(dateNow).format("DD-MM-YYYY h:mm")} </p>
                        <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {customerName} </p>
                        <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {customerAddress} </p>
                        <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}> {voiceId ? '#' + voiceId : 0} </p>
                        <p className={`${printerStyleInvoice.TdInvoice} text-right w-100`}>
                            {typeInvoice == 1 ? "طاولة" : ""}
                            {typeInvoice == 2 ? "توصيل خارجي" : ""}
                        </p>




                    </div>

                    <table>
                        <thead>
                            <tr style={{ borderTop: "2px solid #000", borderBottom: "2px solid #000" }}>
                                <th className={`${printerStyleInvoice.element}`}> {t('total')} </th>
                                <th className={`${printerStyleInvoice.element}`}> {t('price')} </th>
                                <th className={`${printerStyleInvoice.element}`}>  {t('amount')} </th>
                                <th className={`${printerStyleInvoice.element}`}> {t('name')} </th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map(({ name, netPrice, tatolItem, quantity, discount1 }, idx) => {
                                totalQtn += quantity;
                                discounts += discount1;
                                return (
                                    <tr key={idx} style={{ borderBottom: "2px solid #000" }}>
                                        <td className={`${printerStyleInvoice.element}`}> {+tatolItem.toFixed(3)} </td>
                                        <td className={`${printerStyleInvoice.element}`}> {+netPrice.toFixed(3)} </td>
                                        <td className={`${printerStyleInvoice.element}`}> {quantity} </td>
                                        <td className={`${printerStyleInvoice.element}`}> {name} </td>
                                    </tr>
                                );
                            }
                            )}
                            <hr />
                            <tr className="my-3" style={{ borderTop: "2px dashed #000", borderBottom: "5px dashed #000" }}>
                                <td className={`${printerStyleInvoice.element}`}> {+total.toFixed(3)} </td>
                                <td className={`${printerStyleInvoice.element}`}> {t('total')} </td>
                                <td className={`${printerStyleInvoice.element}`}> {totalQtn} </td>
                                <td className={`${printerStyleInvoice.element}`}> {t('thenumberofpieces')} </td>
                            </tr>
                            <hr />
                        </tbody>
                    </table>

                    <div className="d-flex justify-content-between pt-0 mt-2">
                        <p className={`${printerStyleInvoice.TdInvoice}`}> {+totalTaxAd.toFixed(4)} </p>
                        <p className={`${printerStyleInvoice.TdInvoice2} mb-0 my-0 py-0`}>: {t('vat2')} </p>
                    </div>

                    <div className="d-flex justify-content-between">
                        <p className={`${printerStyleInvoice.TdInvoice}`}> {+discounts.toFixed(3)} </p>
                        <p className={`${printerStyleInvoice.TdInvoice2}  mb-0 my-0 py-0`}>: {t('discount')} </p>
                    </div>

                    <div className="d-flex justify-content-between">
                        <p className={`${printerStyleInvoice.TdInvoice}`}> {+netTotal.toFixed(2)} </p>
                        <p className={`${printerStyleInvoice.TdInvoice2}  mb-0 my-0 py-0`}>: {t('nettotal')} </p>
                    </div>


                    <hr />

                    <div className="d-flex justify-content-between " style={{ borderTop: "2px dashed #000", borderBottom: "2px dashed #000" }}>
                        <p className={`${printerStyleInvoice.TdInvoice} pt-2`}> {+netTotal.toFixed(4)} </p>
                        <p className={`${printerStyleInvoice.TdInvoice} pt-2`}>{tafkit}</p>

                        <p className={`${printerStyleInvoice.TdInvoice2} pt-2`}>: {t('amountPaid')} </p>
                    </div>
                    <hr />
                    <div></div>

                    <div className="text-center">
                        <img ref={inputRef} className={`${printerStyleInvoice.imgFluidPrint}`} alt="barcode" />
                    </div>
                    <div></div>
                    <div className={`${printerStyleInvoice.contentFooter}`}>
                        <p className={`${printerStyleInvoice.TdInvoice}`}>فاتورة مبيعات - {moment(dateNow).format("DD-MM-YYYY h:mm")}</p>
                        <div></div>
                        <p className={`${printerStyleInvoice.TdInvoice}`}> {t('Thanks')}</p>
                        <hr />
                        <div></div>
                        <p className={`${printerStyleInvoice.TdInvoice}`}> {address} </p>
                    </div>

                    <div className={`w-100 d-block ${printerStyleInvoice.footerBtn}`}>
                        <button className="btn btn-primary btn-block font-main my-3" style={{ fontSize: "20px" }} onClick={() => PintWihtPrinter()}>
                            Print <AiFillPrinter />
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}