import MaterialTable from "material-table"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";




function DataTable() {

    useEffect(() => {
        const getData = () => {
            fetch("https://jsonplaceholder.typicode.com/users")
                .then(response => response.json())
                .then(json => {
                    setUsers(json)
                });
        };

        getData();
    }, []);

    const [users, setUsers] = useState([]);

    const [columns, setColumns] = useState([
        { title: 'م', field: 'id',cellStyle: { textAlign: "center"}},
        { title: 'الاسم', field: 'name', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'نوع المستخدم', field: 'username', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'تعديل', field: 'username',  title: 'تعديل',
        render: rowData => <Link to={`user/edit/${rowData.id}`} className="btn btn-info px-2 py-1 font-ar"> صلاحيات المستخدم </Link>  },
        // render: rowData => <Link to={`/users`} className="btn btn-info px-5 py-1"> Edit </Link>  },
        
    ])

    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={columns}
                data={users}
                title={null}
                options={{
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    }
                }}
                options={{
                    actionsColumnIndex: -1
                }}
                // options={{
                //     paging:true,
                //     pageSize: 5,    // make initial page size
                //     emptyRowsWhenPaging: true,   //to make page size fix in case of less data rows
                //     pageSizeOptions:[6,12,20,50],    // rows selection options
                // }}
            />
        </div>
    )
}

export default DataTable
