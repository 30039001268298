import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Layout from "../../Layout";
import DataTableMatruial from './DataTableMatruialFin';
import { ProductContext } from '../../context/context';

function FiscalYears() {


    const { allRefrence = [] } = useContext(ProductContext);

    let financialYearRefrence = {};
    if(allRefrence.length != 0){
        financialYearRefrence = allRefrence.find(item => item.mName == "TsmFinancialYear") ? allRefrence.find(item => item.mName == "TsmFinancialYear") : false; 
    }


    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-11 mx-auto my-3 text-center">
                        <div className="text-right mb-2"> 
                            {financialYearRefrence.chkAdd 
                                ? <Link to="/fiscal_years/add-fiscal_years" className="text-light btn btn-primary py-0 font-ar"> اضافة </Link> 
                                : <button disabled className="text-light btn btn-primary py-0 font-ar"> اضافة </button>
                            }
                        </div>
                        <DataTableMatruial financialYearRefrence={financialYearRefrence} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default FiscalYears;