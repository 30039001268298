import React, { useEffect, useState } from 'react'
import Layout from '../../Layout';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import text from "../../context/super.txt"
import Select from 'react-select';
import * as StockValuationStyles from "./StockValuation.module.css";
import TableStock from './TableStock';
import moment from 'moment';
import logo from "../../context/image.png"
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function StockValuation() {
    const { t, i18n } = useTranslation();
    const [branchList, setbranchList] = useState([]);
    const [Branches, setBranches] = useState([]);

    const [stores, setStores] = useState([]);
    const [storesSend, setStoresSend] = useState([]);

    const [compaines, setCompanies] = useState([]);
    const [compainesSend, setCompaniesSend] = useState([]);

    const [itemList, setItemList] = useState([]);
    const [itemListSend, setItemListSend] = useState([]);

    const [itemGroupList, setItemGroupList] = useState([]);
    const [itemGroupListSend, setItemGroupListSend] = useState([]);

    const [Data, setData] = useState([]);


    const [StartDataCustomerStatement, setStartDataCustomerStatement] = useState(new Date());
    const [EndDataCustomerStatement, setEndDataCustomerStatement] = useState(new Date());

    const [unitType, setUnitType] = useState(0);
    const [balanceType, setBalanceType] = useState(0);
    const [orderType, setOrderType] = useState(0);

    useEffect(() => {
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/StoreFormBalanceReport/GetDefault`, {
                                method: "POST",
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                                    "Content-Type": "application/json",
                                },
                                redirect: "follow",
                                body: JSON.stringify({
                                    companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : 1,
                                    formName: "TsmStockAssessment"
                                }),
                            })
                                .then((response) => response.json())
                                .then((result) => {
                                    if (result.status === 200) {
                                        // console.log(result);
                                        setbranchList(result.data.branchList)
                                        setStores(result.data.storeInList)
                                        setItemList(result.data.itemList)
                                        setCompanies(result.data.itemCompanyList)
                                        setItemGroupList(result.data.itemGroupList)
                                        setUnitType(result.data.unitType)
                                        setBalanceType(result.data.balanceType)
                                        setOrderType(result.data.orderType)
                                    } else {
                                        alert(result.message)
                                    }

                                }).catch((error) => {
                                    console.log("data", error)
                                });
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${text}/StoreFormBalanceReport/GetDefault`, {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                            "Content-Type": "application/json",
                        },
                        redirect: "follow",
                        body: JSON.stringify({
                            companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : 1,
                            formName: "TsmStockAssessment"
                        }),
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status === 200) {
                                // console.log(result);
                                setbranchList(result.data.branchList)
                                setStores(result.data.storeInList)
                                setItemList(result.data.itemList)
                                setCompanies(result.data.itemCompanyList)
                                setItemGroupList(result.data.itemGroupList)
                                setUnitType(result.data.unitType)
                                setBalanceType(result.data.balanceType)
                                setOrderType(result.data.orderType)
                            } else {
                                alert(result.message)
                            }

                        }).catch((error) => {
                            console.log("data", error)
                        });
                }




            });
    }, [])


    if (branchList.length !== 0) {
        branchList.forEach(item => {
            item.value = item.branchName;
            item.label = item.branchName;
            item.id = item.branchId;
        })
    }
    if (stores.length !== 0) {
        stores.forEach(item => {
            item.value = item.storeName;
            item.label = item.storeName;
            item.id = item.storeId;
        })
    }
    if (itemList.length !== 0) {
        itemList.forEach(item => {
            item.value = item.itemName;
            item.label = item.itemName;
            item.id = item.itemId;
        })
    }

    if (itemGroupList.length !== 0) {
        itemGroupList.forEach(item => {
            item.value = item.groupName;
            item.label = item.groupName;
            item.id = item.groupId;
        })
    }
    if (compaines.length !== 0) {
        compaines.forEach(item => {
            item.value = item.itemCompanyName;
            item.label = item.itemCompanyName;
            item.id = item.icId;
        })
    }

    const onChangeBranches = (e) => {
        setBranches(e);
    }

    const onChangeStore = (e) => {
        setStoresSend(e);
    }

    const onChangeCompany = (e) => {
        setCompaniesSend(e);
    }

    const onChangesetItemGroupList = (e) => {
        setItemGroupListSend(e);
    }

    const onChangeItems = (e) => {
        setItemListSend(e);
    }

    const onViewPrint = () => {
        if (Data.length > 0) {
            let parms = { data: Data, stores, storesSend, start: StartDataCustomerStatement, end: EndDataCustomerStatement };
            let handle = window.open(window.location.origin + '/StockValuationTablePrint', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
            handle.window.parameters = JSON.stringify(parms);
        } else {
            alert("لا يوجد بيانات للطباعة")
        }

    }

    const onSubmitView = () => {
        document.querySelector(".loading").classList.remove("hideloader");
        let data = {
            companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : "",
            formName: "TsmStockAssessment",
            beginDate: StartDataCustomerStatement,
            endDate: EndDataCustomerStatement,
            branchList: Branches.length == 0 ? branchList : Branches,
            itemGroupList: itemGroupListSend.length == 0 ? itemGroupList : itemGroupListSend,
            itemCompanyList: compainesSend.length == 0 ? compaines : compainesSend,
            storeInList: storesSend.length == 0 ? stores : storesSend,
            itemList: itemListSend.length == 0 ? itemList : itemListSend,
            checkAllItems: true,
            unitType: unitType,
            orderType: orderType,
            balanceType: 1,
            balanceFrom: 0,
            balanceTo: 0
        }


        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/StoreFormBalanceReport/GetAll`, {
                                method: 'POST',
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(data),
                                redirect: 'follow'
                            })
                                .then(response => response.json())
                                .then(result => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    if (result.status === 200) {
                                        setData(result.data)
                                        CalculationTotal(result.data)
                                        if (result.data.length == 0) {
                                            alert('لا يوجد بيانات')
                                        }
                                    } else {
                                        alert(result.message)
                                    }

                                })
                                .catch(error => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    console.log('error', error)
                                });
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${text}/StoreFormBalanceReport/GetAll`, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                        redirect: 'follow'
                    })
                        .then(response => response.json())
                        .then(result => {
                            document.querySelector(".loading").classList.add("hideloader");
                            if (result.status === 200) {
                                setData(result.data)
                                CalculationTotal(result.data)
                                if (result.data.length == 0) {
                                    alert('لا يوجد بيانات')
                                }
                            } else {
                                alert(result.message)
                            }

                        })
                        .catch(error => {
                            document.querySelector(".loading").classList.add("hideloader");
                            console.log('error', error)
                        });
                }




            });

    };

    const CalculationTotal = (data = []) => {
        // console.log("data is", data);
        let totalItems = data.reduce((total, item) => {
            total += +item.mTotalCost;
            return total;

        }, 0);
        let DataNew = [...data, { mTotalCost: totalItems, mCost: "الاجمالي", footer: true }]
        setData(DataNew);
    }

    return (
        <Layout>
            <div className="company text-right font-ar container companyReciptVoucher py-5 px-0" style={{ maxWidth: "1170px", overflow: "hidden" }}>
                <div className="container-fluid AccountStatement" style={{ minHeight: "70vh" }}>
                    <div className="container py-5">
                        <div className="row px-0">
                            <div className="col-12 col-md-11 mx-auto">
                                <form dir={i18n.language === "en" ? "ltr" : "rtl"} className={i18n.language === "en" ? "text-left col-12" : "text-right col-12"} onSubmit={e => e.preventDefault()}>

                                    <div className="row">
                                        <div className="col-12 col-md-6 mx-auto">
                                            <label className="label-account-statement"> {t('beginning')} </label>
                                            <DatePicker style={{ width: "100%" }} className="form-control started" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={StartDataCustomerStatement} onChange={(date) => setStartDataCustomerStatement(date)} />
                                        </div>

                                        <div className="col-12 col-md-6 mx-auto">
                                            <label className="label-account-statement"> {t('endofhistory')} </label>
                                            <DatePicker style={{ width: "100%" }} className="form-control ended" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={EndDataCustomerStatement} onChange={(date) => setEndDataCustomerStatement(date)} />
                                        </div>


                                    </div>

                                    <div className="row my-3 align-items-center">
                                        <div className="col-12 col-md-6 mx-auto account-statement-container">
                                            <label className="label-account-statement"> {t('branches')} </label>
                                            <Select options={branchList} isMulti onChange={e => onChangeBranches(e)} placeholder={t('all')} />
                                        </div>


                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement"> {t('groupOfItems')} </label>
                                            <Select options={itemGroupList} isMulti placeholder={t('all')} onChange={e => onChangesetItemGroupList(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement"> {t('ItemCompanies')} </label>
                                            <Select options={compaines} isMulti placeholder={t('all')} onChange={e => onChangeCompany(e)} />
                                        </div>


                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement"> {t('stores')} </label>
                                            <Select options={stores} isMulti placeholder={t('all')} onChange={e => onChangeStore(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement">  {t('items')} </label>
                                            <Select options={itemList} isMulti placeholder={t('all')} onChange={e => onChangeItems(e)} />
                                        </div>


                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <select className='form-control'>
                                                <option value="1" selected={orderType === 1 ? true : false}> {t('Itemcode')} </option>
                                                <option value="2" selected={orderType === 2 ? true : false}> {t('Itemname')} </option>
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <select className='form-control'>
                                                <option value="1" selected={unitType === 1 ? true : false} > {t('thesmallestunit')} </option>
                                                <option value="2" selected={unitType === 2 ? true : false} > {t('defaultPurchases')} </option>
                                                <option value="3" selected={unitType === 3 ? true : false} > {t('defaultSales')} </option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className={` ${i18n.language === "en" ? "text-left" : "text-right"} `}>
                                        <button className="btn btn-primary py-1 my-4 mx-0 px-5" type="button" onClick={() => onSubmitView()} > {t('view')} </button>
                                        <button className="btn btn-primary py-1 my-4 mx-1 px-4" type="button" onClick={() => onViewPrint()}>   {t('print')} </button>

                                        <ExcelFile name="Summary Reports" element={<button className='btn btn-primary mx-1 px-4'> Execl</button>}>
                                            <ExcelSheet data={Data} name="Summary Reports">
                                                <ExcelColumn label="كود الصنف" value="itemCode" />
                                                <ExcelColumn label="اسم الصنف" value="itemName" />
                                                <ExcelColumn label="نوع التكلفة" value="costTypeName" />
                                                <ExcelColumn label="الوحدة" value="unitName" />
                                                <ExcelColumn label="الكمية" value="mQuantity" />
                                                <ExcelColumn label="التكلفة" value="mCost" />
                                                <ExcelColumn label="اجمالي التكلفة" value="mTotalCost" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </div>

                                </form>
                            </div>


                            <div class="tableHeheheh" style={{ marginBottom: "40px" }}>
                                <table className={` ${StockValuationStyles.tableStock} table table-bordered table-hover table-striped mb-0 text-center`} dir='rtl'>
                                    <thead className={`${StockValuationStyles.theadStock}`}>
                                        <tr className='head-bg text-light'>
                                            <th> {t('Itemcode')} </th>
                                            <th> {t('Itemname')} </th>
                                            <th> {t('costType')}  </th>
                                            <th> {t('unit')} </th>
                                            <th> {t('amount')} </th>
                                            <th> {t('cost')} </th>
                                            <th> {t('totalCost')} </th>
                                        </tr>
                                    </thead>
                                    <tbody className={StockValuationStyles.tbodyStock}>
                                        {Data.map((item, index) => <TableStock key={index} data={item} />)}
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}


export const StockValuationTablePrint = () => {
    const [Items, setItems] = useState([]);
    const [end, setEnd] = useState([]);
    const [Stores, setSotres] = useState([]);
    const [AllStores, setAllStores] = useState([]);
    useEffect(() => {
        if (window.parameters) {
            let data = JSON.parse(window.parameters);
            if (data) {
                setSotres(data.storesSend);
                setAllStores(data.stores);
                setItems(data.data);
                CalculationTotal(data.data);
                setEnd(data.end);
            }
        }
    }, []);

    useEffect(() => {
        const printData = () => {
            window.focus();
            window.print();
        };
        printData();
    }, []);
    const CalculationTotal = (data = []) => {
        let totalItems = data.reduce((total, item) => {
            total += +item.mTotalCost;
            return total;

        }, 0);
        return totalItems;
    }

    return (
        <div className={`${StockValuationStyles.containerHeigth} p-0 mx-0`}>

            <div className="row mx-auto p-0">
                <div className="col-12 mx-auto head my-3 px-0">

                    <div className="text-center">
                        <img src={logo} width="100" height="100" alt="logo company" />

                    </div>
                    <div className="text-center d-flex justify-content-center my-2">
                        <h5 className={`text-center mb-3 ${StockValuationStyles.h1Font}`} style={{ textDecoration: "underline" }}> تقرير تقييم المخزون </h5>
                    </div>

                    <div className="d-flex flex-row-reverse mb-2">
                        <h6 className={` ${StockValuationStyles.h6Font}`}> :  الي تاريخ   </h6>
                        <h6 className={`mx-2 ${StockValuationStyles.h6Font}`}> {moment(end).format('DD MM YYYY, h:mm:ss a')} </h6>
                    </div>

                    <div className="d-flex flex-row-reverse">
                        <h6 className={` ${StockValuationStyles.h6Font}`}> :  المخزن  </h6>
                        <h6 className={`mx-2 ${StockValuationStyles.h6Font}`}>
                            {Stores.length !== 0
                                ? Stores.length === AllStores.length
                                    ? "كل المخازن"
                                    :
                                    `( ${Stores.map(
                                        (row) => row.storeName
                                    )} )`
                                : "كل المخازن"}
                        </h6>
                    </div>
                </div>
                <div className="col-12 mx-auto px-0">
                    <table className={`table ${StockValuationStyles.tablePadding} table-striped text-center px-0 mx-0`} dir='rtl'>
                        <thead>
                            <tr>
                                <th className={`TdInvoice ${StockValuationStyles.fontTable}`}> كود الصنف </th>
                                <th className={`TdInvoice ${StockValuationStyles.fontTable}`} style={{ width: "200px" }}> اسم الصنف </th>
                                <th className={`TdInvoice ${StockValuationStyles.fontTable}`}> نوع التكلفة</th>
                                <th className={`TdInvoice ${StockValuationStyles.fontTable}`} style={{ width: "50px" }}> الوحدة </th>
                                <th className={`TdInvoice ${StockValuationStyles.fontTable}`} style={{ width: "50px" }}> الكمية </th>
                                <th className={`TdInvoice ${StockValuationStyles.fontTable}`}> التكلفة </th>
                                <th className={`TdInvoice ${StockValuationStyles.fontTable}`}> اجمالي التكلفة </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Items.map(({ itemName, mQuantity, costTypeName, itemCode, unitName, mCost, mTotalCost }, idx) => {
                                return (
                                    <tr key={idx} className={StockValuationStyles.tr_table}>
                                        <td className={`TdInvoice ${StockValuationStyles.fontTable}`}> {itemCode} </td>
                                        <td className={`TdInvoice ${StockValuationStyles.fontTable}`} style={{ width: "200px" }}> {itemName} </td>
                                        <td className={`TdInvoice ${StockValuationStyles.fontTable}`}> {costTypeName} </td>
                                        <td className={`TdInvoice ${StockValuationStyles.fontTable}`} style={{ width: "50px" }}> {unitName || ""} </td>
                                        <td className={`TdInvoice ${StockValuationStyles.fontTable}`} style={{ width: "50px" }}> {mQuantity} </td>
                                        <td className={`TdInvoice ${StockValuationStyles.fontTable}`}> {mCost} </td>
                                        <td className={`TdInvoice ${StockValuationStyles.fontTable}`}> {+mTotalCost.toFixed(2)} </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>

                    {/* <div className="my-3 px-5">
                        <h6 className={`text-right ${StockValuationStyles.h6Font}`}> {total} : اجمالي الكل </h6>
                    </div> */}

                </div>

            </div>
        </div>
    )
}

