import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ProductContext } from '../../context/context';
import Layout from "../../Layout";


function UpdateBranch(props) {

    let id = props.match.params.id;
    const { Branches = [], UpdateBranch } = useContext(ProductContext);
    const [code, setCode] = useState('');
    const [nameAr, setNameAr] = useState('');
    const [nameEn, setNameEN] = useState('');
    const [addres, setAddres] = useState('');
    const [comId, setComId] = useState('');

    useEffect(() => {
        let branch = Branches.find(item => item.branchId == id);
        setCode(branch.branchId)
        setNameAr(branch.nameA)
        setNameEN(branch.nameE)
        setAddres(branch.address)
        setComId(branch.companyId)
    }, [])


    return (
        <Layout>
        <div className="company text-right font-ar">
            <div className="container-fluid py-4">
                <div className="row mx-0">
                    <div className="col-12 col-md-10 mx-auto px-0">
                        <form dir="rtl">
                            <div className="row py-2">
                                <div className="col-12 col-md-2 mx-auto">
                                    <label htmlFor="codeBranch">كود الفرع</label>
                                    <input type="number" value={code} disabled onChange={e => setCode(e.target.value)} className="form-control" id="codeBranch" />
                                </div>
                                <div className="col-12 col-md-5 mx-auto">
                                    <label htmlFor="nameArB">الاسم بالعربي</label>
                                    <input type="text" value={nameAr} onChange={e => setNameAr(e.target.value)} className="form-control" id="nameArB" />
                                </div>
                                <div className="col-12 col-md-5 mx-auto">
                                    <label htmlFor="nameEnB">الاسم بالانجليزي</label>
                                    <input type="text" value={nameEn} onChange={e => setNameEN(e.target.value)} className="form-control" id="nameEnB" />
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="row py-2">
                                    <div className="col-12 mx-auto">
                                        <label style={{fontSize:"14px"}}> العنوان </label>
                                        <input type="text" value={addres} onChange={e => setAddres(e.target.value)} className="form-control" />
                                    </div>
                                </div>

                            </div>
                            {/* End of Branches */}



                            <div className="submiting d-flex mt-4">
                                <button type="submit" className="btn btn-primary mx-2 px-4" onClick={e => UpdateBranch(e, {comId,nameAr,nameEn,addres,id} )}> تعديل </button>
                                <Link type="button" to="/branches" className="btn btn-primary mx-2 px-4">تراجع</Link>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </Layout>
    )
}

export default UpdateBranch
