import React, { useContext, useEffect, useRef, useState } from 'react'
import Layout from '../../Layout';
import text from "../../context/super.txt";
import { useTranslation } from "react-i18next";

import Doms from "../../images/effects/success.mp3";
import fluid from "../../images/dom_Trim.mp4";

function Know() {

    const [token, setToken] = useState(false);
    const [Items, setItems] = useState([]);
    const [barcode, setBarcode] = useState('');

    const [name, setName] = useState('');
    const [unit, setUnit] = useState('');
    const [qtn, setQtn] = useState('');
    const [price, setPrice] = useState('');

    const { t, i18n } = useTranslation();


    const input = useRef();

    useEffect(() => {
        // input.current.focus();
    }, [])

    let active = localStorage.getItem("afakyLogin")
        ? JSON.parse(localStorage.getItem("afakyLogin"))
        : false;

    useEffect(() => {
        const getToken = () => localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : false;

        setToken(getToken())
        if (token) {
            getMostData()
        }
    }, [token])


    const getMostData = () => {
        document.querySelector(".loading").classList.remove("hideloader");
        let MostData = new Headers();
        MostData.append("Content-Type", "application/json");
        MostData.append("Authorization", `Bearer ${token}`);
        let requestOpasdasdtions = {
            method: 'GET',
            headers: MostData,
            redirect: 'follow'
        };
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            text = result;
                        })
                        .catch(error => console.log('error', error));
                }

                if (active) {
                    fetch(`${text}/PosForm/GetAll`, requestOpasdasdtions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.isSuccess) {
                                document.querySelector(".loading").classList.add("hideloader");
                                setItems(result.data[0].itemsList)
                            }
                        })
                        .catch(error => {
                            console.log('error', error)
                        });
                }
            });
    }

    const Show = (e) => {
        e.preventDefault();
        let auido = new Audio(Doms);
        let fauild = new Audio(fluid);
        let item = Items.find(item => item.itemCode == barcode);

        let inputs = document.querySelectorAll('.input');

        if (item) {
            auido.play().then(() => {
                setBarcode('')
                setName(item.itemName)
                setQtn(1)
                setPrice(item.salesValue)
                setUnit(item.unitName)
            })

            for (const input of inputs) {
                input.classList.remove('border-danger')
                input.classList.add('border-success')
            }

        } else {
            fauild.play().then(() => {
                setBarcode('')
                setName('')
                setQtn('')
                setPrice("")
                setUnit("")
                for (const input of inputs) {
                    input.classList.remove('border-success')
                    input.classList.add('border-danger')
                }
            })
            // alert('لا يوجد بيانات');

        }
    }

    return (
        <Layout>
            <div className="company text-right font-ar companyReciptVoucher px-0 py-5" style={{ maxWidth: "1170px" }}>
                <div className="container-fluid AccountStatement">
                    <div className={`row ${i18n.language == "en" ? "text-left" : "text-right"} my-5`}>
                        <form>
                            <div className="col-12 col-md-10 mx-auto">
                                <label htmlFor="" className='mb-2'>{t('barcode')}</label>
                                <input type="text" ref={input} className={`form-control mb-2 ${i18n.language == "en" ? "text-left" : "text-right"} input`} value={barcode} onChange={e => setBarcode(e.target.value)} />
                            </div>

                            <div className="col-12 col-md-10 mx-auto">
                                <label htmlFor="" className='mb-2'> {t('Item')} </label>
                                <input type="text" disabled className={`form-control ${i18n.language == "en" ? "text-left" : "text-right"} mb-2 input`} value={name} />
                            </div>

                            <div className="col-12 col-md-10 mx-auto">
                                <label htmlFor="" className='mb-2'> {t('unit')} </label>
                                <input type="text" disabled className={`form-control ${i18n.language == "en" ? "text-left" : "text-right"} mb-2 input`} value={unit} />
                            </div>
                            <div className="col-12 col-md-10 mx-auto">
                                <label htmlFor="" className='mb-2'>{t('amount')}</label>
                                <input type="text" disabled className={`form-control ${i18n.language == "en" ? "text-left" : "text-right"} mb-2 input`} value={qtn} />
                            </div>
                            <div className="col-12 col-md-10 mx-auto">
                                <label htmlFor="" className='mb-2'>{t('price')}</label>
                                <input type="text" disabled className={`form-control ${i18n.language == "en" ? "text-left" : "text-right"} mb-2 input`} value={price} />
                            </div>
                            <div className="col-12 col-md-10 mx-auto">
                                <input type="submit" className='btn btn-success px-3' value={t('view')} onClick={(e) => Show(e)} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
  
        </Layout >
    )
}

export default Know