import React from "react";

function TableCategory({ List = [] }) {
    return (
        <div className="row">
            <div className="col-11 mx-auto">
                <table className="table text-center table-bordered table-new" dir="rtl">
                    <thead>
                        <tr>
                            <th className="border-0"></th>
                            <th className="border-0"></th>
                            <th className="border-0"></th>
                            <th className="border-0"></th>
                            <th scope="col" colspan="3"> الوارد </th>
                            <th scope="col" colspan="3"> الصادر </th>
                            <th scope="col" colspan="3"> الرصيد </th>
                        </tr>
                        <tr className="text-center">
                            <th scope="col">م</th>
                            <th scope="col"> النوع </th>
                            <th scope="col"> الرقم </th>
                            <th scope="col"> التاريخ </th>
                            <th>التكلفة</th>
                            <th>الكمية</th>
                            <th>اجمالي التكلفة</th>
                            {/*  */}
                            <th>التكلفة</th>
                            <th>الكمية</th>
                            <th>اجمالي التكلفة</th>
                            {/*  */}
                            <th>التكلفة</th>
                            <th>الكمية</th>
                            <th>اجمالي التكلفة</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                           

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TableCategory;
