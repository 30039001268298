import MaterialTable from 'material-table';
import React, { useContext, useEffect, useState } from 'react'
import { ProductContext } from '../../context/context';

function DataTable() {
    const { customers } = useContext(ProductContext)

    const [columns, setColumns] = useState([
        { title: 'الاسم', field: 'name_ar',cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'المجموعة الرئيسبة', field: 'cat',cellStyle: {  color: '#000', textAlign: "center"} },
        
    ])

    const edit = user => {
        window.location.replace(window.location.origin + `/supplier/edit/${user.id}`)
    }

    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={columns}
                data={customers}
                title={null}
                options={{
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    }
                }}
                actions={
                    [
                        {
                            icon: 'edit',
                            tooltip: 'تعديل',
                            onClick: (e, rowData) => edit(rowData)
                        },
                        {
                            icon: 'delete',
                            tooltip: 'حذف',
                        },
                    ]}
                    options={{
                        actionsColumnIndex: -1
                    }}
            />
        </div>
    )
}

export default DataTable;