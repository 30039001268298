import MaterialTable from "material-table"
import React, { useState, useEffect } from "react"
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";




function VoucherDailyTable() {
    useEffect(() => {
        const getData = () => {
            fetch("https://jsonplaceholder.typicode.com/users")
                .then(response => response.json())
                .then(json => {
                    setUsers(json)
                });
        };
        getData();
    }, []);

    const [users, setUsers] = useState([]);

    const [columns, setColumns] = useState([
        {  title: 'اعدادات', field: 'edit', cellStyle: {  color: '#000', textAlign: "center"}, render: rowData => {
            return (
                <React.Fragment>
                    <button className="btn btn-danger py-1 mx-2 px-2"> <MdDelete /> </button> 
                    <button className="btn btn-info py-1 mx-2 px-2" > <FiEdit /> </button>
                </React.Fragment>
            )
        }},
        { title: 'الحالة', field: 'username', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'الوصف', field: 'username', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'داين', field: 'username', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'مدين', field: 'username', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'رقم المصدر', field: 'username', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'المصدر', field: 'username', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'التاريخ', field: 'username', cellStyle: {  color: '#000', textAlign: "center"} },
        { title: 'م', field: 'id',cellStyle: { textAlign: "center"}}  
        
    ])

    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={columns}
                data={users}
                title={null}
                options={{
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    }
                }}
                options={{
                    actionsColumnIndex: -1,
                    paginationType:"stepped",
                    showFirstLastPageButtons:false,
                    addRowPosition:"first"
                }}
            />
        </div>
    )
}

export default VoucherDailyTable
