import React from 'react'

export default function ItemsBalanceReportTable({ data }) {
    return (
        <tr>
            <td> {data.itemName} </td>
            <td> {data.mQuantity} </td>
            <td> {data.storeName || ""} </td>
            <td> {data.itemCode} </td>
            <td> {data.itemGroupName} </td>
            <td> {data.itemGroupNameParent} </td>
        </tr>
    )
}
