import React from 'react'
import { Link } from 'react-router-dom';
import Layout from "../../Layout";
import DataTable from './DataTable';

function CostCenterDirectory() {
    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-11 mx-auto my-3 text-center">
                        <div className="text-right mb-2"> 
                            <Link to="/costCenterDirectory/add" className="text-light btn btn-primary py-0"> New </Link>
                        </div>
                        <DataTable />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CostCenterDirectory
