import React, { useEffect, useState } from "react";
import moment from "moment";
import logo from "../context/image.png"

function AccountStatementPrinter() {
    useEffect(() => {
        document.querySelector(".loading").classList.add("hideloader");
    }, []);

    const [company, setCompany] = useState("");
    const [account, setAccount] = useState("");
    const [list, setList] = useState([]);
    const [ArrayBranches, setArrayBranches] = useState([]);
    const [allBarnches, setAllBarnches] = useState([]);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");

    useEffect(() => {
        if (window.parameters) {
            let items = JSON.parse(window.parameters);
            if (items) {
                setList(items.data);
                setCompany(items.user);
                setAccount({ num: items.AccountId, name: items.nameCustomer, });
                setAllBarnches(items.allBarnches);
                setArrayBranches(items.ArrayBranches);
                setStart(items.datefrom);
                setEnd(items.dateto);
                let name = JSON.parse(localStorage.getItem('afakyUser'));
                // console.log(name.companyNameA);
            }
        }
    }, []);

    useEffect(() => {
        const printData = () => {
            window.focus();
            window.print();
        };
        printData();
    }, []);

    let totaling = 0;
    let totalingDebit = 0;
    let totalingCredit = 0;


    return (

        <div className="" style={{ width: "100%" }}>
            <div className="col-print col-print-account-statment mx-auto text-center my-1">
                <div className="details-invoice my-4">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <h6 className="fontGlobal"> {company.companyNameE} </h6>
                        <img src={logo} width="80" height="60" alt="logo company" />
                        <h6 className="fontGlobal"> {company.companyNameA} </h6>
                    </div>
                    <div className="d-flex justify-content-end mb-1">
                        <h6 className="fontGlobal">
                            {ArrayBranches.length != 0
                                ? ArrayBranches.length ==
                                    allBarnches.length
                                    ? "كل الفروع"
                                    : `( ${ArrayBranches.map(
                                        (row) => row.branchName
                                    )} )`
                                : ""}
                        </h6>
                    </div>

                    <h6
                        className="text-center mb-1 fontGlobal"
                        style={{ textDecoration: "underline" }}
                    >
                        تقرير كشف حساب - عملة محلية
                    </h6>

                    <div className="d-flex justify-content-end">
                        <p className="px-3 fontGlobal">

                            {/* {moment(start).format("YYYY/MM/DD")} */}
                            <span className="d-inline-block mx-1">{moment(start).format('A')}</span>
                            <span>{moment(start).format('hh:mm d-mm-yyyy')}</span>
                        </p>
                        <h6 className="fontGlobal"> : من تاريخ </h6>
                    </div>
                    <div className="d-flex justify-content-end">
                        <p className="px-3 fontGlobal">

                            {/* {moment(end).format("YYYY/MM/DD")} */}
                            <span className="d-inline-block mx-1">{moment(end).format('A')}</span>
                            <span>{moment(end).format('hh:mm d-mm-yyyy')}</span>
                        </p>
                        <h6 className="fontGlobal"> : الي تاريخ </h6>
                    </div>


                    <div className="d-flex justify-content-end">
                        <p className="px-3 fontGlobal">
                            {account.name}
                        </p>
                        <h6 className="fontGlobal"> : اسم الحساب </h6>
                    </div>
                    <div className="d-flex justify-content-end">
                        <p className="px-3 fontGlobal">
                            {account.num}
                        </p>
                        <h6 className="fontGlobal"> : رقم الحساب </h6>
                    </div>




                    <hr />

                    <table className="table table-striped tableAccount" dir="rtl">
                        <thead>
                            <tr className="trAccount">
                                <th scope="col" className="fontGlobal tdAccount"> الفرع </th>
                                <th scope="col" className="fontGlobal tdAccount" style={{ width: "90px" }}>رقم القيد</th>
                                <th scope="col" className="fontGlobal tdAccount"> التاريخ </th>
                                <th scope="col" className="fontGlobal tdAccount"> المصدر </th>
                                <th scope="col" className="fontGlobal tdAccount">الوصف</th>
                                <th scope="col" className="fontGlobal tdAccount"> مدين </th>
                                <th scope="col" className="fontGlobal tdAccount"> داين </th>
                                <th scope="col" className="fontGlobal tdAccount"> الرصيد </th>
                            </tr>
                        </thead>
                        <tbody className="font-small-responsive">
                            {list.map(({ journalVoucherTypeName, jvDate, jvId, branchId, credit, debit, debitLocal, source, creditLocal, description, dtlDescription }, idx) => {
                                if (source == 0) {
                                    if (debitLocal) {
                                        debit = debitLocal;
                                    }
                                    if (creditLocal) {
                                        credit = creditLocal;
                                    }
                                }
                                if (debit != 0) {
                                    totaling += +debit;
                                    totalingDebit += debit;
                                }
                                if (credit != 0) {
                                    totaling -= credit;
                                    totalingCredit += credit;
                                }

                                return (
                                    <tr className="text-center trAccount" key={idx}>
                                        <td className="fontGlobal tdAccount">
                                            {branchId == 0 ? branchId : allBarnches.find((branch) => branch.branchId == branchId).branchName}
                                        </td>
                                        <td className="fontGlobal tdAccount" style={{ width: "90px" }}> {jvId} </td>
                                        <td className="fontGlobal tdAccount">
                                            <div className="d-flex">
                                                <span className="d-inline-block mx-1">{moment(jvDate).format('A')}</span>
                                                <span>{moment(jvDate).format('hh:mm d-mm-yyyy')}</span>
                                            </div>
                                        </td>
                                        <td className="fontGlobal tdAccount">
                                            {journalVoucherTypeName}
                                        </td>
                                        <td className="fontGlobal tdAccount">{dtlDescription}</td>
                                        <td className="fontGlobal tdAccount"> {+debit.toFixed(2)} </td>
                                        <td className="fontGlobal tdAccount"> {+credit.toFixed(2)} </td>
                                        <td className={totaling >= 0 ? "text-success fontGlobal tdAccount" : "text-danger fontGlobal tdAccount"}>
                                            {+totaling.toFixed(2)}
                                        </td>
                                    </tr>
                                );
                            }
                            )}
                            <tr className="text-center">
                                <th className="fontGlobal tdAccount"> الاجمالي </th>
                                <td className="tdAccount" style={{ width: "90px" }}></td>
                                <td className="tdAccount"></td>
                                <td className="tdAccount"></td>
                                <td className="tdAccount"></td>
                                <td className="fontGlobal tdAccount"> {+totalingDebit.toFixed(2)} </td>
                                <td className="fontGlobal tdAccount"> {+totalingCredit.toFixed(2)} </td>
                                <td className={totaling >= 0 ? "text-success fontGlobal tdAccount" : "text-danger fontGlobal tdAccount"}>
                                    {+totaling.toFixed(2)}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    {/* <div className="footerinvoceprintaccountststaemnet" style={{ width: "100%" }} >
                        <hr />
                        <div className="d-flex justify-content-center">
                            <p className="px-3">
                                {moment().format("DD/MM/yyyy h:mm:ss a")}
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default AccountStatementPrinter;
