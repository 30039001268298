import React from 'react'

function Alerts(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-8 mx-auto my-5">
                    <form>
                      
                        <div className="my-3 d-flex align-items-center">
                            <label className='d-inline-block w-25'> المستوي 1 </label>
                            <input type="number" className="form-control w-50" value={props.data.alert1} onChange={(event) => props.data.setAlert1(event.target.value)} />
                            <label className='d-inline-block w-25 mx-2'> يوم </label>
                        </div>
                         <div className="my-3 d-flex align-items-center">
                            <label className='d-inline-block w-25'> المستوي 2 </label>
                            <input type="number" className="form-control w-50" value={props.data.alert2} onChange={(event) => props.data.setAlert2(event.target.value)} />
                            <label className='d-inline-block w-25 mx-2'> يوم </label>
                        </div>
                         <div className="my-3 d-flex align-items-center">
                           <label className='d-inline-block w-25'> المستوي 3 </label>
                            <input type="number" className="form-control w-50"  value={props.data.alert3} onChange={(event) => props.data.setAlert3(event.target.value)} />
                           <label className='d-inline-block w-25 mx-2'> يوم </label>
                        </div>

                       
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Alerts