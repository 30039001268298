import React, { useEffect, useState } from "react";
import logo from "../context/image.png"
import moment from "moment";
import * as printerStyle from "../styles/print.module.css";
import { useTranslation } from "react-i18next";


function PrintReceiptVoucher() {
    useEffect(() => {
        document.querySelector(".loading").classList.add("hideloader");
    }, []);
    const { t } = useTranslation();

    const [desc, setDesc] = useState("");
    const [company, setCompany] = useState("");
    const [branch, setBranch] = useState("");
    const [BalanceRemaining, setBalanceRemaining] = useState(0);
    const [balancePromiss, setBalancePromiss] = useState(0);
    const [customerId, setCustomerId] = useState(0);
    const [viewBalanceRemaining, setViewBalanceRemaining] = useState(0);

    useEffect(() => {
        if (window.parameters) {
            let items = JSON.parse(window.parameters);
            if (items) {
                console.log(items);
                // setList(items.data);
                setDesc(items.descriptionVoucher);
                setBalanceRemaining(items.BalanceRemaining);
                setBalanceRemaining(items.BalanceRemaining);
                setBalancePromiss(items.balancePromiss);
                setCustomerId(items.customerId);
                setViewBalanceRemaining(items.viewBalanceRemaining);
                setCompany(items.user.companyNameA);
                setBranch(items.user.branchNameA);
            }
        }
    }, []);

    // useEffect(() => {
    //     const printData = () => {
    //         window.focus();
    //         window.print();
    //     };
    //     printData();
    // }, []);

    const printData = () => {
        window.focus();
        window.print();
    };

    return (
        <div className="" style={{ width: "100%" }}>
            <div className="col-print col-print-account-statment mx-auto text-center my-1">
                <div className="details-invoice my-4">
                    <img src={logo} width="80" height="60" alt="logo company" />
                    <div className="details-invoice1 my-4">
                        <div className="d-flex justify-content-end align-items-center my-3">
                            <p className="fontGlobal mb-0 pb-0">
                                {moment().format(
                                    "dddd, MMMM Do YYYY, h:mm:ss a"
                                )}
                            </p>
                            <h6 className="fontGlobal mb-0 pb-0 ml-3"> <span className="b-inline-block mx-2">:</span> {t('history')} </h6>

                        </div>
                       <div className="d-flex justify-content-end align-items-center my-3">
                            <p className="fontGlobal"> {company} </p>
                            <h6 className="fontGlobal mb-0 pb-0 ml-3"> <span className="b-inline-block mx-2">:</span> {t('companyS')} </h6>
                        </div>
                       <div className="d-flex justify-content-end align-items-center my-3">
                            <p className="fontGlobal"> {branch} </p>
                            <h6 className="fontGlobal mb-0 pb-0 ml-3"> <span className="b-inline-block mx-2">:</span> {t('branche')} </h6>
                        </div>
                        <hr />
                        <table className="table table-striped tableAccount" dir="rtl">
                            <thead>
                                <tr className="trAccount  trAccount">
                                    <th className="fontGlobal tdAccount"> العميل </th>
                                    <th className="fontGlobal tdAccount"> الرصيد السابق </th>
                                    <th className="fontGlobal tdAccount"> المبلغ المدفوع </th>
                                    <th className="fontGlobal tdAccount"> المتبقي </th>
                                    <th className="fontGlobal tdAccount"> الوصف </th>
                                </tr>
                            </thead>
                            <tbody className="font-small-responsive">
                                <tr className="trAccount  trAccount">
                                    <th className="tr-print-account1 fontGlobal tdAccount">
                                        {customerId}
                                    </th>
                                    <td className="tr-print-account1 fontGlobal tdAccount">
                                        {balancePromiss}
                                    </td>
                                    <td className="tr-print-account1 fontGlobal tdAccount">
                                        {BalanceRemaining}
                                    </td>
                                    <td className="tr-print-account1 fontGlobal tdAccount">
                                        {viewBalanceRemaining}
                                    </td>
                                    <td className="tr-print-account1 fontGlobal tdAccount">
                                        {desc}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button
                        className="btn btn-primary w-100"
                        onClick={() => printData()}
                    >
                        Print
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PrintReceiptVoucher;
