import React, { useContext, useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { ProductContext } from "../../context/context";
import MultiGroup from "./MultiGroup";
import accountsReports from '../../images/assets/accounts reports.png'
import financialTransactions from '../../images/assets/Financial transactions.png'
import POSLogo from '../../images/assets/POS.png'
import reportLogo from '../../images/assets/report.png'
import KeyLogo from '../../images/assets/Key.png'
import StoresLogo from '../../images/assets/Stores.png'
import SystemLogo from '../../images/assets/System.png'

function Group({ linkOld, i }) {
    const { lang, handelSidebar } = useContext(ProductContext);
    const dropItems = (id) => {
        // document.getElementById(id).classList.toggle('dropRoted')
    };

    let items = '';
    useEffect(() => {
        if (window.parameters) {
            items = JSON.parse(window.parameters);
            if (items) {
                if (items.idG) {
                    document.querySelectorAll(`.${items.idG}`).forEach(row => {
                        row.classList.contains('show');
                        if (row.classList.contains('show')) {
                            row.classList.add('show')
                        } else {
                            row.classList.add('show')
                        }
                    });
                }

                if (items.idGM && items.idG) {
                    document.querySelectorAll(`.${items.idGM}`).forEach(row2 => {
                        row2.classList.contains('show');
                        if (row2.classList.contains('show')) {
                            row2.classList.add('show')
                        } else {
                            row2.classList.add('show')
                        }
                    });
                }
            }
        }

    }, [document])

    const NotificationClose = (idG, idGM, idx) => {
        let obj = { idG, idGM, idx }
        window.parameters = JSON.stringify(obj);
    }

    const openSalesValue = (path) => {
        window.open(window.location.origin + `${path}`, '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
    }

    if (linkOld.refrence == false || linkOld.refrence == undefined) {
        return "";
    } else {
        return (
            <React.Fragment key={i}>
                <li onClick={() => dropItems(`pro-` + i)} className="sidebar-item sidebar-item-drop sidebar-item-drop-v font-ar" data-toggle="collapse" data-target={`.collapseGroup` + i} href="#collapseGroup" role="button" >
                    <p className={`nav-link d-flex justify-content-between align-items-center font-ar a-link py-1 mb-2 a-link-p ${lang == "ar" ? "flex-row-reverse text-right" : ""} `} >
                        <div className="align-content-center">
                            <span className="font-ar" style={{ display: "inline-block", paddingRight: "10px" }}>{linkOld.text} </span>
                            {linkOld.id == 1 ? <span> <img src={financialTransactions} className="iconMax2" />  </span> : ''}
                            {linkOld.id == 2 ? <span> <img src={accountsReports} className="iconMax2" /> </span> : ''}
                            {linkOld.id == 3 ? <span> <img src={POSLogo} className="iconMax2" /> </span> : ''}
                            {linkOld.id == 4 ? <span> <img src={reportLogo} className="iconMax2" />  </span> : ''}
                            {linkOld.id == 5 ? <span> <img src={KeyLogo} className="iconMax2" />  </span> : ''}
                            {linkOld.id == 6 ? <span> <img src={StoresLogo} className="iconMax2" />  </span> : ''}
                            {linkOld.id == 7 ? <span> <img src={SystemLogo} className="iconMax2" />  </span> : ''}

                        </div>
                        <IoIosArrowForward id={`pro-` + i} className="font-weight-bold text-muted" style={{ transition: "0.3s all ease" }} />
                    </p>
                </li>
                <div className={`collapse ${`collapseGroup` + i}`} id="collapseGroup" >
                    {linkOld.child.map((route, idx) => {
                        return route.group ? (
                            <MultiGroup key={idx} route={route} link={linkOld} i={idx} dropItems={dropItems} routeGroup={i} NotificationClose={NotificationClose} />
                        ) : route.refrence ? (
                            <li key={idx} className={`sidebar-item group-o pl-3 py-2 font-ar ${window.location.pathname == route.path ? "link-activite" : ""} `} onClick={() => {
                                handelSidebar()
                                NotificationClose(`collapseGroup` + i, undefined, `pro-` + i)
                            }}>
                                {/* open POS */}
                                {route.type == 4 ?
                                    <button className={`btn text-light w-100 ml-auto nav-link py-1 font-ar a-link text-right elevent ${lang == "ar" ? "flex-row-reverse text-right" : "mohsem en"}`} onClick={() => openSalesValue(route.path)}  >
                                        {route.text}
                                    </button> :
                                    <Link to={route.path} className={`nav-link py-1 font-ar a-link ${lang == "ar" ? "flex-row-reverse text-right" : "mohsem en"} `}>
                                        <span className="mx-2"> {route.text} </span>
                                    </Link>
                                }

                            </li>
                        ) : (
                            ""
                        );
                    })}
                </div>

            </React.Fragment>
        );
    }
}

export default Group;
