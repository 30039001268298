import React from 'react'
import styled from 'styled-components'
export default function Hero({ title, max, children }) {
    return (
        <HeroWrapper max={max} >
            <div className="banner">
                <div className="container-fluid ReceiptVoucher">
                    <div className="row mx-0">
                        <div className="col-12 col-md-11 mx-auto my-3 text-center px-0">
                            
                        </div>
                    </div>
                </div>
                {/* <h1 className="title">{title}</h1> */}
                {children}
            </div>
        </HeroWrapper>
    )
}

const HeroWrapper = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${props => props.max ? "100vh" : "80vh"};
    background: linear-gradient(var(--primaryRGBA), var(--primaryRGBA)),
    color: #fff;
    .title {
    padding-bottom: 2rem;
        font-size: 3.5rem;
        text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.3);
        text-transform: uppercase;
        letter-spacing: var(--mainSpacing);
    }
`
