import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import text from "../../context/super.txt"
import DatePicker from "react-datepicker";
import Layout from '../../Layout';
import Select from 'react-select';
import ReactExport from "react-export-excel";
import * as ItemsBalanceReportsStyles from "./StyleItemCost.module.css";
import $ from 'jquery';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


function ItemCategoryCost() {
    const { t, i18n } = useTranslation();
    const [branchList, setbranchList] = useState([]);
    const [Branches, setBranches] = useState([]);

    const [stores, setStores] = useState([]);
    const [storesSend, setStoresSend] = useState([]);

    const [compaines, setCompanies] = useState([]);
    const [compainesSend, setCompaniesSend] = useState([]);

    const [itemList, setItemList] = useState([]);
    const [itemListSend, setItemListSend] = useState([]);

    const [itemGroupList, setItemGroupList] = useState([]);
    const [itemGroupListSend, setItemGroupListSend] = useState([]);

    const [Data, setData] = useState([]);


    const [StartDataCustomerStatement, setStartDataCustomerStatement] = useState(new Date());
    const [EndDataCustomerStatement, setEndDataCustomerStatement] = useState(new Date());

    const [unitType, setUnitType] = useState(0);
    const [balanceType, setBalanceType] = useState(0);
    const [orderType, setOrderType] = useState(0);

    useEffect(() => {
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/StoreFormBalanceReport/GetDefault`, {
                                method: "POST",
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                                    "Content-Type": "application/json",
                                },
                                redirect: "follow",
                                body: JSON.stringify({
                                    companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : 1,
                                    formName: "TsmItemCardNoCost"
                                }),
                            })
                                .then((response) => response.json())
                                .then((result) => {
                                    if (result.status === 200) {
                                        setbranchList(result.data.branchList)
                                        setStores(result.data.storeInList)
                                        let obj = {
                                            value: "كل الاصناف",
                                            label: "كل الاصناف",
                                            id: 0
                                        }
                                        let newArray = [obj, ...result.data.itemList]
                                        setItemList(newArray)
                                        setCompanies(result.data.itemCompanyList)
                                        setItemGroupList(result.data.itemGroupList)
                                        setUnitType(result.data.unitType)
                                        setBalanceType(result.data.balanceType)
                                        setOrderType(result.data.orderType)
                                    } else {
                                        alert(result.message)
                                    }

                                }).catch((error) => {
                                    console.log("data", error)
                                });
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${text}/StoreFormBalanceReport/GetDefault`, {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                            "Content-Type": "application/json",
                        },
                        redirect: "follow",
                        body: JSON.stringify({
                            companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : 1,
                            formName: "TsmItemCardNoCost"
                        }),
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status === 200) {
                                setbranchList(result.data.branchList)
                                setStores(result.data.storeInList)
                                let obj = {
                                    value: "كل الاصناف",
                                    label: "كل الاصناف",
                                    id: 0
                                }
                                let newArray = [obj, ...result.data.itemList]
                                setItemList(newArray)
                                setCompanies(result.data.itemCompanyList)
                                setItemGroupList(result.data.itemGroupList)
                                setUnitType(result.data.unitType)
                                setBalanceType(result.data.balanceType)
                                setOrderType(result.data.orderType)
                            } else {
                                alert(result.message)
                            }

                        }).catch((error) => {
                            console.log("data", error)
                        });
                }




            });
    }, [])


    if (branchList.length !== 0) {
        branchList.forEach(item => {
            item.value = item.branchName;
            item.label = item.branchName;
            item.id = item.branchId;
        })
    }
    if (stores.length !== 0) {
        stores.forEach(item => {
            item.value = item.storeName;
            item.label = item.storeName;
            item.id = item.storeId;
        })
    }
    if (itemList.length !== 0) {
        itemList.forEach(item => {
            if (item.id == 0) {

            } else {
                item.value = item.itemName;
                item.label = item.itemName;
                item.id = item.itemId;
            }
        })

    }

    if (itemGroupList.length !== 0) {
        itemGroupList.forEach(item => {
            item.value = item.groupName;
            item.label = item.groupName;
            item.id = item.groupId;
        })
    }
    if (compaines.length !== 0) {
        compaines.forEach(item => {
            item.value = item.itemCompanyName;
            item.label = item.itemCompanyName;
            item.id = item.icId;
        })
    }

    const onChangeBranches = (e) => {
        setBranches(e);
    }

    const onChangeStore = (e) => {

        setStoresSend(e);
    }

    const onChangeCompany = (e) => {
        setCompaniesSend(e);
    }

    const onChangesetItemGroupList = (e) => {
        setItemGroupListSend(e);
    }

    const onChangeItems = (e) => {
        setItemListSend(e);
    }

    const onViewPrint = () => {
        if (Data.length > 0) {
            let parms = { data: Data, stores, storesSend, start: StartDataCustomerStatement, end: EndDataCustomerStatement };
            let handle = window.open(window.location.origin + '/ItemCategoryPrintCost', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
            handle.window.parameters = JSON.stringify(parms);
        } else {
            alert("لا يوجد بيانات للطباعة")
        }

    }


    const onSubmitView = () => {
        let checkBox = document.getElementById('movements');
        let checkBoxValue = 0;
        if (checkBox.checked == true) {
            checkBoxValue = true;
        } else {
            checkBoxValue = false;
        }

        if (itemListSend.length == 0) {
            alert('من فضلك اختر الصنف ');
        } else {
            let isAll = false;
            if (itemListSend.length == 1 && itemListSend[0].id == 0) {
                isAll = true;
            } else {
                isAll = false
            }


            document.querySelector(".loading").classList.remove("hideloader");
            let data = {
                companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : "",
                formName: "TsmItemCardNoCost",
                chkPeriodMaster: checkBoxValue,
                beginDate: StartDataCustomerStatement,
                endDate: EndDataCustomerStatement,
                branchList: Branches.length == 0 ? branchList : Branches,
                itemGroupList: itemGroupListSend.length == 0 ? itemGroupList : itemGroupListSend,
                itemCompanyList: compainesSend.length == 0 ? compaines : compainesSend,
                storeInList: storesSend.length == 0 ? stores : storesSend,
                // storeInList: storesSend,
                itemList: isAll ? itemList : itemListSend,
                // itemList: itemListSend.length == 0 ? itemList : itemListSend,
                checkAllItems: false,
                unitType: unitType,
                orderType: orderType,
                balanceType: balanceType,
                balanceFrom: 0,
                balanceTo: 0
            }
            fetch(text)
                .then(r => r.text())
                .then(text => {
                    let isCheck = text.includes("Afaky#");
                    if (isCheck) {
                        let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                        let myHeaders = new Headers();
                        myHeaders.append("accept", "text/plain");

                        let requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        fetch(newUrl, requestOptions)
                            .then(response => response.text())
                            .then(resultUrl => {
                                fetch(`${resultUrl}/StoreFormBalanceReport/GetAll`, {
                                    method: 'POST',
                                    headers: {
                                        Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(data),
                                    redirect: 'follow'
                                })
                                    .then(response => response.json())
                                    .then(result => {
                                        $('#itemRowsTest').html("");
                                        document.querySelector(".loading").classList.add("hideloader");
                                        if (result.status === 200) {
                                            setData(result.data)
                                            let dataStore = [...new Set(result.data.map(item => item.storeName))]
                                            let setN = {};
                                            dataStore.forEach(ele => {
                                                setN = { ...setN, [ele]: result.data.filter(item => item.storeName === ele) };
                                            });

                                            if (Object.keys(setN).length > 0) {
                                                let title;
                                                let head;
                                                for (let item of Object.keys(setN)) {
                                                    let dataStoreGroup = [...new Set(setN[item].map(item => item.itemName))]
                                                    let netObjs = {};
                                                    dataStoreGroup.forEach(ele => {
                                                        netObjs = { ...netObjs, [ele]: setN[item].filter(item => item.itemName === ele) };
                                                    });
                                                    title = `<h6 class="text-right py-1  title223"> ${item} - </h6>`
                                                    $('#itemRowsTest').append(title);


                                                    if (Object.keys(netObjs).length > 0) {
                                                        let title2;
                                                        let hbody;
                                                        for (let item2 of Object.keys(netObjs)) {

                                                            title2 = `<h6 class="text-right px-5 py-1 title223"> ${item2} - </h6>`
                                                            $('#itemRowsTest').append(title2);
                                                            hbody = `
                                                            <table class="table table-striped text-center px-0 mx-0" dir='rtl'>
                                                                <thead>
                                                                    <tr  class='${ItemsBalanceReportsStyles.fontTable}'>
                                                                        <th style={{ width: "200px" }}> </th>
                                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'  colspan="1" > متوسط التكلفة </th>
                                                                        <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}' colspan="1" style={{ border: "1px solid #3d7fa2" }}> الوارد </th>
                                                                        <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}' colspan="1" style={{ border: "1px solid #3d7fa2" }}> الصادر </th>
                                                                        <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}' colspan="1" style={{ border: "1px solid #3d7fa2" }}> الرصيد </th>
                                                                    </tr>
                                                                    <tr class='head-bg text-light ${ItemsBalanceReportsStyles.fontTable}'>
                                                                        <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'style={{ width: "200px" }}> النوع </th>
                                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> الرقم </th>
            
                                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'>  </th>
            
                                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'>  </th>
            
                                                                        <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'>  </th>
            
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    ${(netObjs[item2].map(element2 => {
                                                                return `
                                                                                <tr class='${ItemsBalanceReportsStyles.fontTable}'>
                                                                            <td class='${ItemsBalanceReportsStyles.fontTable}' style={{ width: "200px" }}> ${element2.jvName} </td>
                                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.sourceCode} </td>
                
                                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.quantityIn.toFixed(4) || 0} </td>
                                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.quantityOut.toFixed(4) || 0} </td>
                
                                                                            <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.mQuantity.toFixed(4) || 0} </td>
                                                                        </tr>`
                                                            }).join(''))
                                                                }
                                                                </tbody>
                                                            </table > `
                                                            $('#itemRowsTest').append(hbody);

                                                        }
                                                    }

                                                }
                                            }

                                            if (result.data.length == 0) {
                                                alert('لا يوجد بيانات')
                                            }
                                        } else {
                                            alert(result.message)
                                        }

                                    })
                                    .catch(error => {
                                        document.querySelector(".loading").classList.add("hideloader");
                                        console.log('error', error)
                                    });
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        fetch(`${text}/StoreFormBalanceReport/GetAll`, {
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(data),
                            redirect: 'follow'
                        })
                            .then(response => response.json())
                            .then(result => {
                                $('#itemRowsTest').html("");
                                document.querySelector(".loading").classList.add("hideloader");
                                if (result.status === 200) {
                                    setData(result.data)
                                    let dataStore = [...new Set(result.data.map(item => item.storeName))]
                                    let setN = {};
                                    dataStore.forEach(ele => {
                                        setN = { ...setN, [ele]: result.data.filter(item => item.storeName === ele) };
                                    });

                                    if (Object.keys(setN).length > 0) {
                                        let title;
                                        let head;
                                        for (let item of Object.keys(setN)) {
                                            let dataStoreGroup = [...new Set(setN[item].map(item => item.itemName))]
                                            let netObjs = {};
                                            dataStoreGroup.forEach(ele => {
                                                netObjs = { ...netObjs, [ele]: setN[item].filter(item => item.itemName === ele) };
                                            });
                                            title = `<h6 class="text-right py-1  title223"> ${item} - </h6>`
                                            $('#itemRowsTest').append(title);


                                            if (Object.keys(netObjs).length > 0) {
                                                let title2;
                                                let hbody;
                                                for (let item2 of Object.keys(netObjs)) {

                                                    title2 = `<h6 class="text-right px-5 py-1 title223"> ${item2} - </h6>`
                                                    $('#itemRowsTest').append(title2);
                                                    hbody = `
                                                    <table class="table table-striped text-center px-0 mx-0" dir='rtl'>
                                                        <thead>
                                                            <tr  class='${ItemsBalanceReportsStyles.fontTable}'>
                                                                <th style={{ width: "200px" }}> </th>
                                                                <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'  colspan="1" > متوسط التكلفة </th>
                                                                <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}' colspan="1" style={{ border: "1px solid #3d7fa2" }}> الوارد </th>
                                                                <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}' colspan="1" style={{ border: "1px solid #3d7fa2" }}> الصادر </th>
                                                                <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}' colspan="1" style={{ border: "1px solid #3d7fa2" }}> الرصيد </th>
                                                            </tr>
                                                            <tr class='head-bg text-light ${ItemsBalanceReportsStyles.fontTable}'>
                                                                <th  class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'style={{ width: "200px" }}> النوع </th>
                                                                <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> الرقم </th>
    
                                                                <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'>  </th>
    
                                                                <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'>  </th>
    
                                                                <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'>  </th>
    
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            ${(netObjs[item2].map(element2 => {
                                                        return `
                                                                        <tr class='${ItemsBalanceReportsStyles.fontTable}'>
                                                                    <td class='${ItemsBalanceReportsStyles.fontTable}' style={{ width: "200px" }}> ${element2.jvName} </td>
                                                                    <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.sourceCode} </td>
        
                                                                    <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.quantityIn.toFixed(4) || 0} </td>
                                                                    <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.quantityOut.toFixed(4) || 0} </td>
        
                                                                    <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.mQuantity.toFixed(4) || 0} </td>
                                                                </tr>`
                                                    }).join(''))
                                                        }
                                                        </tbody>
                                                    </table > `
                                                    $('#itemRowsTest').append(hbody);

                                                }
                                            }

                                        }
                                    }

                                    if (result.data.length == 0) {
                                        alert('لا يوجد بيانات')
                                    }
                                } else {
                                    alert(result.message)
                                }

                            })
                            .catch(error => {
                                document.querySelector(".loading").classList.add("hideloader");
                                console.log('error', error)
                            });
                    }



                });
        }

    };
    return (
        <Layout>
            <div className="company text-right font-ar container companyReciptVoucher py-5 px-0" style={{ maxWidth: "1170px", overflow: "hidden" }}>
                <div className="container-fluid AccountStatement" style={{ minHeight: "70vh" }}>
                    <div className="container py-5">
                        <div className="row px-0">
                            <div className="col-12 mx-auto">
                                <form dir={i18n.language === "en" ? "ltr" : "rtl"} className={i18n.language === "en" ? "text-left col-12" : "text-right col-12"} onSubmit={e => e.preventDefault()}>
                                    <div className="row">
                                        <div className="col-12 col-md-6 mx-auto">
                                            <label className="label-account-statement"> {t('beginning')} </label>
                                            <DatePicker style={{ width: "100%" }} className="form-control started" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={StartDataCustomerStatement} onChange={(date) => setStartDataCustomerStatement(date)} />
                                        </div>

                                        <div className="col-12 col-md-6 mx-auto">
                                            <label className="label-account-statement"> {t('endofhistory')} </label>
                                            <DatePicker style={{ width: "100%" }} className="form-control ended" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={EndDataCustomerStatement} onChange={(date) => setEndDataCustomerStatement(date)} />
                                        </div>
                                    </div>

                                    <div className="row my-3 align-items-center">
                                        <div className="col-12 col-md-6 mx-auto account-statement-container">
                                            <label className="label-account-statement"> {t('branches')} </label>
                                            <Select options={branchList} isMulti onChange={e => onChangeBranches(e)} placeholder={t('all')} />
                                        </div>

                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement"> {t('groupOfItems')} </label>
                                            <Select options={itemGroupList} isMulti placeholder={t('all')} onChange={e => onChangesetItemGroupList(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement"> {t('ItemCompanies')} </label>
                                            <Select options={compaines} isMulti placeholder={t('all')} onChange={e => onChangeCompany(e)} />
                                        </div>


                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement"> {t('stors')} </label>
                                            <Select options={stores} isMulti placeholder={t('all')} onChange={e => onChangeStore(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement">  {t('items')} </label>
                                            <Select options={itemList} isMulti placeholder={t('ChooseItem')} onChange={e => onChangeItems(e)} />
                                        </div>

                                        <div className="col-12 col-md-6 my-2">
                                            <div className="d-flex flex-row-reverse justify-content-end align-items-center">
                                                <label htmlFor="movements" style={{ marginRight: "10px" }}> {t('movementsduring')} </label>
                                                <input type="checkbox" className='from-control m-0' id='movements' value="1" />
                                            </div>

                                        </div>

                                    </div>

                                    <div className={` ${i18n.language === "en" ? "text-left" : "text-right"} `}>
                                        <button className="btn btn-primary py-1 my-4 mx-0 px-3" type="button" onClick={() => onSubmitView()} > {t('view')} </button>
                                        <button className="btn btn-primary py-1 my-4 mx-1 px-4" type="button" onClick={() => onViewPrint()} >   {t('print')} </button>
                                        <ExcelFile name="ItemCategoryNotCost" element={<button className='btn btn-primary py-1 my-4 mx-0 px-2'>Download Execl</button>}>
                                            <ExcelSheet data={Data} name="Summary Reports">
                                                <ExcelColumn label="النوع" value="jvName" />
                                                <ExcelColumn label="الرقم" value="sourceCode" />
                                                <ExcelColumn label="التاريخ" value="sourceDate" />
                                                <ExcelColumn label="الوارد" value="quantityIn" />
                                                <ExcelColumn label="الصادر" value="quantityOut" />
                                                <ExcelColumn label="الرصيد" value="mQuantity" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </div>


                                </form>
                            </div>

                            <div class="tableHeheheh" id="itemRowsTest" >

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ItemCategoryCost;
