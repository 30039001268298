import React, { useContext, useState } from 'react'
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md"
import { ProductContext } from '../../context/context';
import { useTranslation } from "react-i18next";


function Table({ returnBtnHold, returnBtn, TotaBill, list, DelItem, AddNumber, MinusNumber, changeCountItem, changePriceItem, modifyQuantity, modifyPrice, modifyDiscount, changeDiscountItem }) {

    const { mostData } = useContext(ProductContext)
    const [qun, setQun] = useState(1);
    const [priceItem, setPriceItem] = useState("");
    const [discountItem, setDiscountItem] = useState("");

    const { t } = useTranslation();


    const toggleQuantity = (e, idx, itemId, check, quantity, unitId) => {
        e.preventDefault();
        let btn1 = document.getElementById(`inputQuantity${idx}`);
        let btn2 = document.getElementById(`btnQuantity${idx}`);
        btn1.classList.toggle('d-none');
        btn2.classList.toggle('d-none');
        if (check) {
            changeCountItem(itemId, qun, unitId)
            setQun(1)
            TotaBill()
        } else {
            setQun(quantity)
        }
    }

    const togglePrice = (e, idx, itemId, check, price, unitId) => {
        e.preventDefault();
        let btn1 = document.getElementById(`inputPrice${idx}`);
        let btn2 = document.getElementById(`btnPrice${idx}`);
        btn1.classList.toggle('d-none');
        btn2.classList.toggle('d-none');
        if (check) {
            changePriceItem(itemId, priceItem, unitId)
            setPriceItem(priceItem)
            TotaBill()
        } else {
            setPriceItem(price)
        }
    }

    const toggleDiscount = (e, idx, itemId, check, discount, unitId) => {
        e.preventDefault();
        let btn1 = document.getElementById(`inputDiscount${idx}`);
        let btn2 = document.getElementById(`btnDiscount${idx}`);
        btn1.classList.toggle('d-none');
        btn2.classList.toggle('d-none');
        if (check) {
            changeDiscountItem(itemId, discountItem, unitId)
            setDiscountItem(discountItem)
            TotaBill()
        } else {
            setDiscountItem(discount)
        }
    }

    if (returnBtn || returnBtnHold) {
        return (
            <div className="table-responsive-md">
                <table className="table table-striped font-ar tabel-master tabel-master-pos-tables" dir="rtl">
                    <thead>
                        <tr className="text-center bg-header-tr btn-blue">
                            <th scope="col" className="thCode"> {t('code')} </th>
                            <th scope="col" className="thName"> {t('name')} </th>
                            <th scope="col" className="thUnit"> {t('unit')} </th>
                            <th scope="col" className="thNamber"> {t('qtn')} </th>
                            <th scope="col" className="thPrice"> {t('price')} </th>
                            {/* <th scope="col" className="thDiscount"> {t('discount')} </th> */}
                            {/* <th scope="col" className="th5m"> {t('vat')} </th> */}
                            <th scope="col" className="thTotal"> {t('total')} </th>
                            <th scope="col" className="thSetting" style={{ width: "110px !important" }}> {t('setting')} </th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(({ id, unitId, itemId, netPrice, total, quantity, price, tatolItem, discount1, taxRate }, idx) => {
                            let Lists = mostData.itemsList.filter(item => item.itemId == itemId);
                            let unit = Lists.find(un => un.unitId == unitId);
                            return (
                                <tr key={idx} className="text-center">
                                    <th scope="row" className="thCode">{unit.itemCode}</th>
                                    <td className="thName">{unit.itemName}</td>
                                    <td className="thUnit"> {unit.unitName} </td>
                                    <td style={{ width: "100px" }} className="thNamber">

                                        <form id={`inputQuantity${idx}`} className="d-none tdQuantity">
                                            <input type="number" value={qun} onChange={e => setQun(e.target.value)} className="form-control mx-auto text-right" />
                                            <button type="submit" className="btn-save font-ar" onClick={modifyQuantity ? e => toggleQuantity(e, idx, itemId, true, quantity, unitId) : () => console.log("")}> حفظ </button>
                                        </form>
                                        <button className="btn" id={`btnQuantity${idx}`} onClick={modifyQuantity ? e => toggleQuantity(e, idx, itemId, false, quantity, unitId) : () => console.log("")}> {quantity} </button>
                                    </td>
                                    <td className="thPrice">

                                        <form id={`inputPrice${idx}`} className="d-none tdPrice">
                                            <input type="number" value={priceItem} onChange={e => setPriceItem(e.target.value)} className="form-control mx-auto text-right" />
                                            <button type="submit" className="btn-save font-ar" onClick={e => togglePrice(e, idx, itemId, true, price, unitId)}> حفظ </button>
                                        </form>
                                        <button className="btn" id={`btnPrice${idx}`} onClick={modifyPrice ? e => togglePrice(e, idx, itemId, false, price, unitId) : ''}> {price} </button>


                                    </td>
                                    <td className="totalSellesTableItem thTotal">
                                        {tatolItem ? tatolItem.toFixed(4) : total.toFixed(4)}
                                    </td>
                                    <td className="thSetting" style={{ width: "110px !important" }}>
                                        <div className="icons-setting-voice">
                                            <button className="btn btn-setting btn-primary py-1 px-1 btnPlus" onClick={() => AddNumber(itemId, tatolItem, unitId)}>
                                                <AiFillPlusCircle className="setting-icon-sele" />
                                            </button>
                                            <button className="btn btn-setting btn-primary py-1 px-1 btnMinus" onClick={() => MinusNumber(itemId)}>
                                                <AiFillMinusCircle className="setting-icon-sele" />
                                            </button>
                                            <button className="btn btn-setting btn-danger py-1 px-1" onClick={() => DelItem(itemId)}>
                                                <MdDelete className="setting-icon-sele" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                            // }
                        })}
                    </tbody>
                </table>
            </div>
        )
    } else {

        return (
            <div className="table-responsive-md myTablePos">
                <table className="table table-striped font-ar tabel-master" dir="rtl">
                    <thead>
                        <tr className="text-center bg-header-tr btn-blue" >
                            <th scope="col" className="thCode thTablePos"> {t('code')} </th>
                            <th scope="col" className="thName thTablePos"> {t('name')} </th>
                            <th scope="col" className="thUnit thTablePos"> {t('unit')} </th>
                            <th scope="col" className="thNamber thTablePos"> {t('qtn')}  </th>
                            <th scope="col" className="thPrice thTablePos"> {t('price')} </th>
                            <th scope="col" className="thTotal thTablePos"> {t('total')} </th>
                            <th scope="col" className="thSetting thTablePos" style={{ width: "110px !important" }}> {t('setting')} </th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(({ name, itemCode, discount1, unitName, itemId, quantity, price, tatolItem, unitId, taxRate }, idx) => {
                            return (
                                <tr key={idx} className="text-center">
                                    <th scope="row" className="thCode thTablePos">{itemCode}</th>
                                    <td className="thName thTablePos">{name}</td>
                                    <td className="thUnit thTablePos"> {unitName} </td>
                                    <td style={{ width: "100px" }} className="thNamber thTablePos">
                                        <form id={`inputQuantity${idx}`} className="d-none tdQuantity">
                                            <input type="number" value={qun} onChange={e => setQun(e.target.value)} className="form-control mx-auto text-right btnQtn" />
                                            <button type="submit" className="btn-save font-ar" onClick={e => toggleQuantity(e, idx, itemId, true, quantity, unitId)}> حفظ </button>
                                        </form>
                                        <button className="btn btnQtn thTablePos" id={`btnQuantity${idx}`} onClick={modifyQuantity ? e => toggleQuantity(e, idx, itemId, false, quantity, unitId) : ''}> {quantity} </button>
                                    </td>
                                    <td className="thPrice thTablePos">

                                        <form id={`inputPrice${idx}`} className="d-none tdPrice thTablePos">
                                            <input type="number" value={priceItem} onChange={e => setPriceItem(e.target.value)} className="form-control mx-auto text-right btnprice" />
                                            <button type="submit" className="btn-save font-ar" onClick={e => togglePrice(e, idx, itemId, true, price, unitId)}> حفظ </button>
                                        </form>
                                        <button className="btn btnprice thTablePos" id={`btnPrice${idx}`} onClick={modifyPrice ? e => togglePrice(e, idx, itemId, false, price, unitId) : ''}> {price} </button>

                                    </td>
                                    <td className="totalSellesTableItem thTotal thTablePos"> {tatolItem ? +tatolItem.toFixed(4) : 0} </td>
                                    <td className="thSetting thTablePos" style={{ width: "110px !important" }}>
                                        <div className="icons-setting-voice">
                                            <button className="btn btn-setting btn-primary py-1 px-1 btnPlus btnPos" onClick={() => AddNumber(itemId, tatolItem, unitId)}>
                                                <AiFillPlusCircle className="setting-icon-sele btnPos" />
                                            </button>
                                            <button className="btn btn-setting btn-primary py-1 px-1 btnMinus btnPos" onClick={() => MinusNumber(itemId, unitId)}>
                                                <AiFillMinusCircle className="setting-icon-sele btnPos" />
                                            </button>
                                            <button className="btn btn-setting btn-danger py-1 px-1 btnPos" onClick={() => DelItem(itemId, unitId, idx)}>
                                                <MdDelete className="setting-icon-sele btnPos" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )


    }
}

export default Table
