export const PayCashVoice = (
    $,
    list = [],
    listPayments,
    payment,
    total,
    typeInvoice,
    posPintAfterSave,
    search,
    typeInvoiceValue,
    InvoicedIdList,
    totalTaxAd,
    typePrint,
    Cancel,
    token,
    config,
    posReportNo,
    user,
    mostData,
    storesId,
    posInvoiceTypeId
) => {

    let desc = document.querySelector('#descInvoice').value;
    document.querySelector(".loading").classList.remove("hideloader");
    $('.classCachWeb').attr('disabled', true)
    $('.classCachMoblie').attr('disabled', true)
    $('.saveCredit').attr('disabled', true)
    $('#SavePayTable').attr('disabled', true)

    list.forEach((el) => {
        el.discount = (el.quantity * el.price) * el.discountPercent1 / 100;
        el.discount1 = el.discount;
        el.totalAfterDiscount1 = (el.quantity * el.price) - el.discount;
        el.totalAfterDiscount2 = el.totalAfterDiscount1;
        el.totalAfterDiscount3 = el.totalAfterDiscount1;
        el.netPrice = el.totalAfterDiscount1;
        el.taxValue = (el.netPrice * el.taxRate) / 100;
        el.tableTaxValue = (el.netPrice * el.tableTaxPercent) / 100;
        el.tatolItem = el.netPrice + el.tableTaxValue + el.taxValue;
    });
    let netDiscountsList = list.map((item) => {
        return item.discount1;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);
    let netTotalList = list.map((item) => {
        return item.tatolItem;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);
    let taxSalesList = list.map((item) => {
        return item.taxValue;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);

    let taxValueTable = list.map((item) => {
        return item.tableTaxValue;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);

    // _________

    let total22 = list.map((item) => {
        return item.price * item.quantity;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);
    let totaldiscounting = list.map((item) => {
        return item.discount1 * item.quantity;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);


    let amountPaidPrice = listPayments.map((item) => {
        return +item.paymentValue;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);
    list.forEach(item => {
        item.storeId = storesId;
    });
    let remainingAmountPrice;

    if (payment === 1) {
        remainingAmountPrice = 0;
        amountPaidPrice = total;
        listPayments = [...listPayments];
    } else {
        remainingAmountPrice = total - amountPaidPrice;
        listPayments = [...listPayments];
        let isCheck = listPayments.find(el => el.paymentId == 6);
        if (!isCheck) {
            let newObj = {
                paymentId: 6,
                paymentType: 6,
                paymentValue: +remainingAmountPrice,
                accountId: ""
            }
            listPayments = [...listPayments, newObj];
        }

    }


    let headerCachCollection = new Headers();
    headerCachCollection.append("Authorization", `Bearer ${token}`);
    headerCachCollection.append("Content-Type", "application/json");
    let rawInvoice;
    let DataInvoiceSended;
    list.forEach(item => {
        item.taxValue = +item.taxValue.toFixed(4);
        item.tatolItem = +item.tatolItem ? +item.tatolItem.toFixed(4) : +item.total.toFixed(4);
    });

    listPayments = listPayments.filter(el => el.paymentValue != 0);


    if (typeInvoice) {
        DataInvoiceSended = {
            posPintAfterSave: posPintAfterSave,
            POSInvoiceTypeId: posInvoiceTypeId,
            InvoiceType: 17,
            sceId: search,
            text: "",
            description: desc,
            currencyId: 1,
            rate: 1,
            total: total22,
            itemsDiscount: netDiscountsList,
            additionalDiscount: 0,
            additionalDiscountPercent: 0,
            totalDiscount: netDiscountsList,
            netTotal: +total.toFixed(4),
            amountPaid: amountPaidPrice > netTotalList ? netTotalList : +amountPaidPrice,
            remainingAmount: remainingAmountPrice,
            totalPayment: +total.toFixed(4),
            type: typeInvoiceValue,
            sourceId: InvoicedIdList,
            tableTaxValue: +taxValueTable,
            taxRate: 0,
            taxValue: 0,
            taxSalesValue: +(+totalTaxAd).toFixed(4),
            cashTendered: amountPaidPrice > netTotalList ? +amountPaidPrice.toFixed(4) : 0,
            delegateId: 0,
            policyId: 0,
            invoiceDetails: list,
            invoicePayment: payment == 1 ? [{
                paymentId: 1,
                paymentType: 5,
                paymentValue: total,
                accountId: ""
            }] : listPayments

        }
        rawInvoice = JSON.stringify(DataInvoiceSended);
    } else {
        DataInvoiceSended = {
            posPintAfterSave: posPintAfterSave,
            POSInvoiceTypeId: posInvoiceTypeId,
            InvoiceType: 10,
            sceId: search,
            text: typeInvoice,
            description: desc,
            currencyId: 1,
            rate: 1,
            total: total22,
            itemsDiscount: netDiscountsList,
            additionalDiscount: 0,
            tableTaxValue: +taxValueTable,
            additionalDiscountPercent: 0,
            totalDiscount: netDiscountsList,
            netTotal: +netTotalList.toFixed(4),
            amountPaid: +netTotalList.toFixed(4),
            remainingAmount: +remainingAmountPrice,
            totalPayment: +netTotalList.toFixed(4),
            type: 0,
            sourceId: 0,
            taxRate: 0,
            taxValue: 0,
            taxSalesValue: +taxSalesList.toFixed(4),
            cashTendered: 0,
            delegateId: 0,
            policyId: 0,
            invoiceDetails: list,
            invoicePayment: payment == 1 ? [{
                paymentId: 1,
                paymentType: 5,
                paymentValue: +netTotalList.toFixed(4),
                accountId: ""
            }] : listPayments
        }
        rawInvoice = JSON.stringify(DataInvoiceSended);
    }

    fetch(`${config}/PosForm/Add`, {
        method: 'POST',
        headers: headerCachCollection,
        body: rawInvoice,
        redirect: 'follow'
    })
        .then(response => response.json())
        .then(result => {
            document.querySelector(".loading").classList.add("hideloader");
            if (result.isSuccess) {
                $('.classCachWeb').attr('disabled', false)
                $('.classCachMoblie').attr('disabled', false)
                $('.saveCredit').attr('disabled', false)
                let model = document.getElementById('modal-pay');
                model.style.display = "none";
                model.classList.remove('show');
                if (posPintAfterSave) {
                    console.log("typePrint is :", typePrint);
                    if (typePrint == true) {
                        let parms = {
                            posReportNo: posReportNo,
                            typeInvoiceValue,
                            list,
                            total,
                            total22,
                            totaldiscounting,
                            user,
                            result: result.data,
                            info: mostData,
                            totalTaxAd,
                            sceId: search,
                            posprint: false,
                        };
                        let handle = window.open(window.location.origin + '/printers', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                        handle.window.parameters = JSON.stringify(parms);
                    }
                }
                Cancel()
                document.getElementById('InvoiceCodeing').value = "";
                $('.pay-credit-value').val(0)
                $('.residual-credit-value').val(0)
                $('.pay-mastercard-value').val(0)
                $('.residual-mastercard-value').val(0)
                $('#masterCardFromPay').val("")
                $('#visaFromPay').val("")
                $('#creditFromPay').val("")
                // 
                $('#creditId').html(0)
                $('#masterCardIdId').html(0)
                $('#visaId').html(0)
                $('#ChachId').html(0)
                let model2 = document.querySelector('.modal-backdrop');
                model2.classList.remove('show');
                model2.classList.add('d-none');

            } else {
                alert(result.message)
                $('.classCachWeb').attr('disabled', false)
                $('.saveCredit').attr('disabled', false)
                $('.classCachMoblie').attr('disabled', false)
                $('#SavePayTable').attr('disabled', false)
                let model2 = document.querySelector('.modal-backdrop');
                model2.classList.remove('show');
                model2.classList.add('d-none');

            }
        })
        .catch(() => {
            document.querySelector(".loading").classList.add("hideloader");
            $('.classCachWeb').attr('disabled', false)
            $('.saveCredit').attr('disabled', false)
            $('.classCachMoblie').attr('disabled', false)
            $('#SavePayTable').attr('disabled', false)
        });
}




export const PayCashVoicePOS = (props) => {
    let { $,
        list = [],
        listPayments,
        payment,
        total,
        typeInvoice,
        posPintAfterSave,
        search,
        typeInvoiceValue,
        InvoicedIdList,
        totalTaxAd,
        typePrint,
        Cancel,
        token,
        config,
        posReportNo,
        user,
        mostData,
        storesId,
        posInvoiceTypeId = 4, POSTables, setRunPrint, end = false } = props;
    let typeInvoiceId = document.querySelector('#typeIdInvoice').value; // select
    let OrderInvoice = document.querySelector('#OrderNoCounter').value; // orderNum
    if (!OrderInvoice) OrderInvoice = 0;
    document.querySelector(".loading").classList.remove("hideloader");
    $('.classCachWeb').attr('disabled', true)
    $('.classCachMoblie').attr('disabled', true)
    $('.saveCredit').attr('disabled', true)
    $('#SavePayTable').attr('disabled', true)

    list.forEach((el) => {
        el.discount = (el.quantity * el.price) * el.discountPercent1 / 100;
        el.discount1 = el.discount;
        el.totalAfterDiscount1 = (el.quantity * el.price) - el.discount;
        el.totalAfterDiscount2 = el.totalAfterDiscount1;
        el.totalAfterDiscount3 = el.totalAfterDiscount1;
        el.netPrice = el.totalAfterDiscount1;
        el.taxValue = (el.netPrice * el.taxRate) / 100;
        el.tableTaxValue = (el.netPrice * el.tableTaxPercent) / 100;
        el.tatolItem = el.netPrice + el.tableTaxValue + el.taxValue;
    });
    let netDiscountsList = list.map((item) => {
        return item.discount1;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);

    let netTotalList = list.map((item) => {
        return item.tatolItem;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);

    let taxSalesList = list.map((item) => {
        return item.taxValue;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);

    let taxValueTable = list.map((item) => {
        return item.tableTaxValue;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);

    // ____

    let netTotalPrice = list.map((item) => {
        return item.tatolItem;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);

    let total22 = list.map((item) => {
        return item.price * item.quantity;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);

    let totaldiscounting = list.map((item) => {
        return item.discount1;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);

    let amountPaidPrice = listPayments.map((item) => {
        return +item.paymentValue;
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);

    list.forEach(item => {
        item.storeId = storesId;
    });

    let remainingAmountPrice;

    if (payment === 1) {
        remainingAmountPrice = 0;
        amountPaidPrice = total22;
    } else {
        remainingAmountPrice = total22 - amountPaidPrice;
    }
    let headerCachCollection = new Headers();
    headerCachCollection.append("Authorization", `Bearer ${token}`);
    headerCachCollection.append("Content-Type", "application/json");

    let rawInvoice;
    let DataInvoiceSended;

    list.forEach(item => {
        item.taxValue = +item.taxValue.toFixed(4);
        item.tatolItem = +item.tatolItem ? +item.tatolItem.toFixed(4) : +item.total.toFixed(4);
    });
    listPayments = listPayments.filter(el => el.paymentValue != 0);

    if (typeInvoice) {
        DataInvoiceSended = {
            posPintAfterSave: posPintAfterSave,
            POSInvoiceTypeId: +typeInvoiceId,
            orderNoCounter: +OrderInvoice,
            InvoiceType: 17,
            sceId: search,
            text: "",
            tableNumber: +OrderInvoice,
            description: "",
            currencyId: 1,
            rate: 1,
            total: total22,
            itemsDiscount: +netDiscountsList,
            additionalDiscount: 0,
            additionalDiscountPercent: 0,
            totalDiscount: +netDiscountsList,
            netTotal: +netTotalPrice.toFixed(4),
            amountPaid: +netTotalList,
            remainingAmount: remainingAmountPrice,
            totalPayment: +netTotalList.toFixed(4),
            type: typeInvoiceValue,
            sourceId: InvoicedIdList,
            taxRate: 0,
            taxValue: 0,
            taxSalesValue: +taxSalesList.toFixed(4),
            cashTendered: amountPaidPrice > total22 ? +amountPaidPrice.toFixed(4) : 0,
            delegateId: 0,
            tableTaxValue: +taxValueTable,
            policyId: 0,
            invoiceDetails: list,
            invoicePayment: payment == 1 ? [{
                paymentId: 1,
                paymentType: 5,
                paymentValue: netTotalList,
                accountId: ""
            }] : listPayments

        }
        rawInvoice = JSON.stringify(DataInvoiceSended);
    } else {
        DataInvoiceSended = {
            posPintAfterSave: posPintAfterSave,
            POSInvoiceTypeId: +typeInvoiceId,
            orderNoCounter: +OrderInvoice,
            InvoiceType: 10,
            sceId: search,
            text: typeInvoice,
            tableNumber: +OrderInvoice,
            description: "",
            currencyId: 1,
            rate: 1,
            total: total22,
            itemsDiscount: netDiscountsList,
            additionalDiscount: 0,
            additionalDiscountPercent: 0,
            totalDiscount: netDiscountsList,
            netTotal: +netTotalList.toFixed(4),
            amountPaid: +netTotalList.toFixed(4),
            remainingAmount: +remainingAmountPrice,
            totalPayment: +netTotalList.toFixed(4),
            type: 0,
            sourceId: 0,
            taxRate: 0,
            tableTaxValue: +taxValueTable,
            taxValue: 0,
            taxSalesValue: +taxSalesList.toFixed(4),
            cashTendered: amountPaidPrice > total22 ? +amountPaidPrice.toFixed(4) : 0,
            delegateId: 0,
            policyId: 0,
            invoiceDetails: list,
            invoicePayment: payment == 1 ? [{
                paymentId: 1,
                paymentType: 5,
                paymentValue: +netTotalList.toFixed(4),
                accountId: ""
            }] : listPayments
        }
        rawInvoice = JSON.stringify(DataInvoiceSended);
    }

    fetch(`${config}/PosForm/AddR`, {
        method: 'POST',
        headers: headerCachCollection,
        body: rawInvoice,
        redirect: 'follow'
    })
        .then(response => response.json())
        .then(result => {
            document.querySelector(".loading").classList.add("hideloader");
            if (result.status == 200) {
                if (posPintAfterSave) {
                    // if (end) {
                    if (typePrint) {
                        let parms = {
                            posReportNo: posReportNo,
                            typeInvoiceValue,
                            list,
                            total,
                            total22,
                            totaldiscounting,
                            user,
                            result: result.data,
                            info: mostData,
                            totalTaxAd,
                            sceId: search,
                            posprint: true,
                            typePrint: typeInvoiceId
                        };
                        let handle = window.open(window.location.origin + '/printers', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
                        handle.window.parameters = JSON.stringify(parms);
                    }
                    // }

                }
                Cancel()
                $('.classCachWeb').attr('disabled', false)
                $('.saveCredit').attr('disabled', false)
                $('.classCachMoblie').attr('disabled', false)
                $('#SavePayTable').attr('disabled', false)
                // input checked


            } else {
                $('.classCachWeb').attr('disabled', false)
                $('.saveCredit').attr('disabled', false)
                $('.classCachMoblie').attr('disabled', false)
                $('#SavePayTable').attr('disabled', false)
                alert(result.message)
            }

        })
        .catch((e) => {
            document.querySelector(".loading").classList.add("hideloader");
            console.log(e);
        });




}