import React, { useEffect, useState } from 'react'
import Layout from '../../Layout';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import text from "../../context/super.txt"
import Select from 'react-select';
import * as ItemsBalanceReportsStyles from "./ItemsBalanceReports.module.css";
import ItemsBalanceReportTable from './ItemsBalanceReportTable';
import moment from 'moment';
import logo from "../../context/image.png"
import $ from "jquery";


export default function ItemsBalanceReports() {
    const { t, i18n } = useTranslation();
    const [branchList, setbranchList] = useState([]);
    const [Branches, setBranches] = useState([]);

    const [stores, setStores] = useState([]);
    const [storesSend, setStoresSend] = useState([]);

    const [compaines, setCompanies] = useState([]);
    const [compainesSend, setCompaniesSend] = useState([]);

    const [itemList, setItemList] = useState([]);
    const [itemListSend, setItemListSend] = useState([]);

    const [itemGroupList, setItemGroupList] = useState([]);
    const [itemGroupListSend, setItemGroupListSend] = useState([]);

    const [Data, setData] = useState([]);


    const [StartDataCustomerStatement, setStartDataCustomerStatement] = useState(new Date());
    const [EndDataCustomerStatement, setEndDataCustomerStatement] = useState(new Date());

    const [unitType, setUnitType] = useState(0);
    const [balanceType, setBalanceType] = useState(0);
    const [orderType, setOrderType] = useState(0);

    useEffect(() => {
        fetch(text)
            .then(r => r.text())
            .then(text => {
                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(resultUrl => {
                            fetch(`${resultUrl}/StoreFormBalanceReport/GetDefault`, {
                                method: "POST",
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                                    "Content-Type": "application/json",
                                },
                                redirect: "follow",
                                body: JSON.stringify({
                                    companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : 1,
                                    formName: "TsmIGBalanceSum"
                                }),
                            })
                                .then((response) => response.json())
                                .then((result) => {
                                    if (result.status === 200) {
                                        setbranchList(result.data.branchList)
                                        setStores(result.data.storeInList)
                                        setItemList(result.data.itemList)
                                        setCompanies(result.data.itemCompanyList)
                                        setItemGroupList(result.data.itemGroupList)
                                        setUnitType(result.data.unitType)
                                        setBalanceType(result.data.balanceType)
                                        setOrderType(result.data.orderType)
                                    } else {
                                        alert(result.message)
                                    }

                                }).catch((error) => {
                                    console.log("data", error)
                                });
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${text}/StoreFormBalanceReport/GetDefault`, {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                            "Content-Type": "application/json",
                        },
                        redirect: "follow",
                        body: JSON.stringify({
                            companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : 1,
                            formName: "TsmIGBalanceSum"
                        }),
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status === 200) {
                                setbranchList(result.data.branchList)
                                setStores(result.data.storeInList)
                                setItemList(result.data.itemList)
                                setCompanies(result.data.itemCompanyList)
                                setItemGroupList(result.data.itemGroupList)
                                setUnitType(result.data.unitType)
                                setBalanceType(result.data.balanceType)
                                setOrderType(result.data.orderType)
                            } else {
                                alert(result.message)
                            }

                        }).catch((error) => {
                            console.log("data", error)
                        });
                }


            });
    }, [])


    if (branchList.length !== 0) {
        branchList.forEach(item => {
            item.value = item.branchName;
            item.label = item.branchName;
            item.id = item.branchId;
        })
    }
    if (stores.length !== 0) {
        stores.forEach(item => {
            item.value = item.storeName;
            item.label = item.storeName;
            item.id = item.storeId;
        })
    }
    if (itemList.length !== 0) {
        itemList.forEach(item => {
            item.value = item.itemName;
            item.label = item.itemName;
            item.id = item.itemId;
        })
    }

    if (itemGroupList.length !== 0) {
        itemGroupList.forEach(item => {
            item.value = item.groupName;
            item.label = item.groupName;
            item.id = item.groupId;
        })
    }
    if (compaines.length !== 0) {
        compaines.forEach(item => {
            item.value = item.itemCompanyName;
            item.label = item.itemCompanyName;
            item.id = item.icId;
        })
    }

    const onChangeBranches = (e) => {
        setBranches(e);
    }

    const onChangeStore = (e) => {
        setStoresSend(e);
    }

    const onChangeCompany = (e) => {
        setCompaniesSend(e);
    }

    const onChangesetItemGroupList = (e) => {
        setItemGroupListSend(e);
    }

    const onChangeItems = (e) => {
        setItemListSend(e);
    }

    const onViewPrint = () => {
        if (Data.length > 0) {
            let parms = { data: Data, stores, storesSend, start: StartDataCustomerStatement, end: EndDataCustomerStatement };
            let handle = window.open(window.location.origin + '/ItemsBalanceReportsView', '_blank', "width=" + window.screen.width + ",height=" + window.screen.height)
            handle.window.parameters = JSON.stringify(parms);
        } else {
            alert("لا يوجد بيانات للطباعة")
        }

    }


    const onSubmitView = () => {
        document.querySelector(".loading").classList.remove("hideloader");
        let data = {
            companyId: localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyId : "",
            formName: "TsmIGBalanceSum",
            beginDate: StartDataCustomerStatement,
            endDate: EndDataCustomerStatement,
            branchList: Branches.length == 0 ? branchList : Branches,
            itemGroupList: itemGroupListSend.length == 0 ? itemGroupList : itemGroupListSend,
            itemCompanyList: compainesSend.length == 0 ? compaines : compainesSend,
            storeInList: storesSend.length == 0 ? stores : storesSend,
            itemList: itemListSend.length == 0 ? itemList : itemListSend,
            checkAllItems: true,
            unitType: unitType,
            orderType: orderType,
            balanceType: balanceType,
            balanceFrom: 0,
            balanceTo: 0
        }


        fetch(text)
            .then(r => r.text())
            .then(text => {

                let isCheck = text.includes("Afaky#");
                if (isCheck) {
                    let newUrl = `https://back.afakyerp.com/AfakyCom/GetBackEnd?AfakyCommunication=${text.replace("#", "%23")}`;
                    let myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(newUrl, requestOptions)
                        .then(response => response.text())
                        .then(resultUrl => {

                            fetch(`${resultUrl}/StoreFormBalanceReport/GetAll`, {
                                method: 'POST',
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(data),
                                redirect: 'follow'
                            })
                                .then(response => response.json())
                                .then(result => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    if (result.status === 200) {
                                        setData(result.data)
                                        let dataStore = [...new Set(result.data.map(item => item.storeName))]
                                        let setN = {};
                                        dataStore.forEach(ele => {
                                            setN = { ...setN, [ele]: result.data.filter(item => item.storeName === ele) };
                                        });

                                        if (result.data.length == 0) {
                                            alert('لا يوجد بيانات')
                                        }
                                    } else {
                                        alert(result.message)
                                    }

                                })
                                .catch(error => {
                                    document.querySelector(".loading").classList.add("hideloader");
                                    console.log('error', error)
                                });
                        })
                        .catch(error => console.log('error', error));
                } else {

                    fetch(`${text}/StoreFormBalanceReport/GetAll`, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("afakyToken") ? JSON.parse(JSON.stringify(localStorage.getItem("afakyToken"))) : ''}`,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                        redirect: 'follow'
                    })
                        .then(response => response.json())
                        .then(result => {
                            document.querySelector(".loading").classList.add("hideloader");
                            if (result.status === 200) {
                                setData(result.data)
                                let dataStore = [...new Set(result.data.map(item => item.storeName))]
                                let setN = {};
                                dataStore.forEach(ele => {
                                    setN = { ...setN, [ele]: result.data.filter(item => item.storeName === ele) };
                                });
                                if (result.data.length == 0) {
                                    alert('لا يوجد بيانات')
                                }
                            } else {
                                alert(result.message)
                            }

                        })
                        .catch(error => {
                            document.querySelector(".loading").classList.add("hideloader");
                            console.log('error', error)
                        });
                }



            });

    };
    return (
        <Layout>
            <div className="company text-right font-ar container companyReciptVoucher py-5 px-0" style={{ maxWidth: "1170px", overflow: "hidden" }}>
                <div className="container-fluid AccountStatement" style={{ minHeight: "70vh" }}>
                    <div className="container py-5">
                        <div className="row px-0">
                            <div className="col-12 col-md-11 mx-auto">
                                <form dir={i18n.language === "en" ? "ltr" : "rtl"} className={i18n.language === "en" ? "text-left col-12" : "text-right col-12"} onSubmit={e => e.preventDefault()}>

                                    <div className="row">
                                        <div className="col-12 col-md-6 mx-auto">
                                            <label className="label-account-statement"> {t('beginning')} </label>
                                            <DatePicker style={{ width: "100%" }} className="form-control started" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={StartDataCustomerStatement} onChange={(date) => setStartDataCustomerStatement(date)} />
                                        </div>

                                        <div className="col-12 col-md-6 mx-auto">
                                            <label className="label-account-statement"> {t('endofhistory')} </label>
                                            <DatePicker style={{ width: "100%" }} className="form-control ended" showTimeSelect dateFormat="dd/MM/yyyy h:mm:ss a" selected={EndDataCustomerStatement} onChange={(date) => setEndDataCustomerStatement(date)} />
                                        </div>


                                    </div>

                                    <div className="row my-3 align-items-center">
                                        <div className="col-12 col-md-6 mx-auto account-statement-container">
                                            <label className="label-account-statement"> {t('branches')} </label>
                                            <Select options={branchList} isMulti onChange={e => onChangeBranches(e)} placeholder={t('all')} />
                                        </div>


                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement"> {t('groupOfItems')} </label>
                                            <Select options={itemGroupList} isMulti placeholder={t('all')} onChange={e => onChangesetItemGroupList(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement"> {t('ItemCompanies')} </label>
                                            <Select options={compaines} isMulti placeholder={t('all')} onChange={e => onChangeCompany(e)} />
                                        </div>


                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement">  {t('stores')} </label>
                                            <Select options={stores} isMulti placeholder={t('all')} onChange={e => onChangeStore(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <label className="label-account-statement">  {t('items')} </label>
                                            <Select options={itemList} isMulti placeholder={t('all')} onChange={e => onChangeItems(e)} />
                                        </div>


                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <select className='form-control' onChange={(e) => setOrderType(e.target.value)}>
                                                <option value="1" selected={orderType === 1 ? true : false}> {t('Itemcode')} </option>
                                                <option value="2" selected={orderType === 2 ? true : false}> {t('Itemname')} </option>
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-6 mx-auto account-statement-container my-2">
                                            <select className='form-control' onChange={(e) => setUnitType(e.target.value)}>
                                                <option value="1" selected={unitType === 1 ? true : false} > {t('thesmallestunit')} </option>
                                                <option value="2" selected={unitType === 2 ? true : false} > {t('defaultPurchases')} </option>
                                                <option value="3" selected={unitType === 3 ? true : false} > {t('defaultSales')} </option>
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-6 account-statement-container my-2">
                                            <select className='form-control' onChange={(e) => setBalanceType(e.target.value)} >
                                                <option value="1" selected={balanceType === 1 ? true : false} > {t('AllitemsbalanceZero')} </option>
                                                <option value="2" selected={balanceType === 2 ? true : false} > {t('AllitemsbalanceNotZero')} </option>
                                                <option value="3" selected={balanceType === 3 ? true : false} > {t('Negativeitemsonly')} </option>
                                                <option value="4" selected={balanceType === 4 ? true : false} > {t('Positiveitemsonly')} </option>
                                            </select>
                                        </div>



                                    </div>

                                    <div className={` ${i18n.language === "en" ? "text-left" : "text-right"} `}>
                                        <button className="btn btn-primary py-1 my-4 mx-0 px-5" type="button" onClick={() => onSubmitView()} > {t('view')} </button>
                                        <button className="btn btn-outline-primary py-1 my-4 mx-1 px-4" type="button" onClick={() => onViewPrint()}>   {t('print')} </button>
                                    </div>

                                </form>
                            </div>

                            <div class="tableHeheheh">
                                <table className="table table-bordered table-hover table-striped mb-0 text-center" dir='rtl'>
                                    <thead>
                                        <tr className='head-bg text-light'>
                                            <th> {t('Item')} </th>
                                            <th> {t('amount')}</th>
                                            <th> {t('store')} </th>
                                            <th> {t('Code')} </th>
                                            <th> {t('group')} </th>
                                            <th> {t('mainGroup')} </th>
                                        </tr>
                                    </thead>
                                    <tbody className={ItemsBalanceReportsStyles.tbodyBalance} id="itemRowsTest">
                                        {/* {itemRows} */}
                                        {/* <h6> name </h6>
                                <div className="rowsI">
                                    <p>id</p>
                                </div> */}
                                        {Data.map((item, index) => <ItemsBalanceReportTable key={index} t={t} data={item} />)}
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
            </div >
        </Layout >
    )
}

export const ItemsBalanceReportsView = () => {
    const [Items, setItems] = useState([]);
    // const [start, setStart] = useState("");
    const [end, setEnd] = useState([]);
    const [Stores, setSotres] = useState([]);
    const [AllStores, setAllStores] = useState([]);
    const [newData, setNewData] = useState({});
    useEffect(() => {
        if (window.parameters) {
            let data = JSON.parse(window.parameters);
            if (data) {
                setSotres(data.storesSend);
                setAllStores(data.stores);
                setItems(data.data);
                // setStart(data.start);
                setEnd(data.end);
                let dataStore = [...new Set(data.data.map(item => item.storeName))]
                let setN = {};
                dataStore.forEach(ele => {
                    setN = { ...setN, [ele]: data.data.filter(item => item.storeName === ele) };
                });
                if (Object.keys(setN).length > 0) {
                    let title;
                    let head;
                    for (let item of Object.keys(setN)) {
                        let dataStoreGroup = [...new Set(setN[item].map(item => item.itemGroupName))]
                        let netObjs = {};
                        dataStoreGroup.forEach(ele => {
                            netObjs = { ...netObjs, [ele]: setN[item].filter(item => item.itemGroupName === ele) };
                        });
                        title = `<h6 class="text-right py-1  title223"> ${item} - </h6>`
                        $('#itemRows').append(title);


                        if (Object.keys(netObjs).length > 0) {
                            let title2;
                            let hbody;
                            for (let item2 of Object.keys(netObjs)) {

                                title2 = `<h6 class="text-right px-5 py-1 title223"> ${item2} - </h6>`
                                $('#itemRows').append(title2);
                                hbody = `
                                <table class="table table-striped text-center px-0 mx-0" dir='rtl'>
                                    <thead>
                                        <tr class='${ItemsBalanceReportsStyles.fontTable}'>
                                            <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> كود الصنف  </th>
                                            <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> الصنف </th>
                                            <th class='TdInvoice ${ItemsBalanceReportsStyles.fontTable}'> الكمية </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        ${(netObjs[item2].map(element2 => {
                                    return `
                                                <tr class='${ItemsBalanceReportsStyles.fontTable}'>
                                                    <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.itemCode} </td>
                                                    <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.itemName} </td>
                                                    <td class='${ItemsBalanceReportsStyles.fontTable}'> ${element2.mQuantity || ""} </td>
                                                </tr>`
                                }).join(''))
                                    }
                                    </tbody>
                                </table > `
                                $('#itemRows').append(hbody);

                            }
                        }

                    }
                }

            }
        }
    }, []);



    useEffect(() => {
        const printData = () => {
            window.focus();
            window.print();
        };
        printData();
    }, []);


    return (
        <div className={`${ItemsBalanceReportsStyles.containerHeigth} p - 0 mx - 0`}>

            <div className="row mx-auto p-0">
                <div className="col-12 mx-auto head my-3 px-0">
                    <div className="text-center">
                        <img src={logo} width="120" height="auto" alt="logo company" />
                    </div>
                    <h1 className={`text-center mb-3 ${ItemsBalanceReportsStyles.h1Font} `} style={{ textDecoration: "underline" }}> ارصدة الاصناف وفقا لمجموعات الاصناف </h1>

                    <div className="d-flex  flex-row-reverse mb-3">
                        <h6 className={` ${ItemsBalanceReportsStyles.h6Font} `}> :  الي تاريخ   </h6>
                        <h6 className={`mx-2 ${ItemsBalanceReportsStyles.h6Font} `}> {moment(end).format('DD MM YYYY, h:mm:ss a')} </h6>
                    </div>

                    <div className="d-flex  flex-row-reverse">
                        <h6 className={` ${ItemsBalanceReportsStyles.h6Font} `}> : المخزن  </h6>
                        <h6 className={`mx-2 ${ItemsBalanceReportsStyles.h6Font} `}>
                            {Stores.length !== 0
                                ? Stores.length === AllStores.length
                                    ? "كل المخازن"
                                    :
                                    `(${Stores.map(
                                        (row) => row.storeName
                                    )
                                    })`
                                : "كل المخازن"}
                        </h6>
                    </div>
                </div>
                <div className="col-12 mx-auto px-0" id="itemRows">



                    {/* <div className="my-3 px-5">
                        <h6 className={`text - right ${ ItemsBalanceReportsStyles.h6Font } `}> {total} : اجمالي الكل </h6>
                    </div> */}

                </div>

            </div>
        </div>
    )
}

