import React from 'react'

function Electroic(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-8 mx-auto my-5">
                    <form>

                        <div className="my-3 d-flex align-items-center">
                            <label className='d-inline-block w-25'> نوع التكويد </label>
                            <select className="form-control w-50"  onChange={(event) => props.data.setTypeCode(event.target.value)}>
                                <option value={1}> بدون </option>
                                <option value={2}> EGS </option>
                                <option value={3}> GS1 </option>
                            </select>
                        </div>
                        <div className="my-3 d-flex align-items-center">
                            <label className='d-inline-block w-25'> كود </label>
                            <input type="text" className="form-control w-50" value={props.data.codeElec} onChange={(event) => props.data.setCodeElec(event.target.value)} />
                        </div>


                    </form>
                </div>
            </div>
        </div>
    )
}

export default Electroic