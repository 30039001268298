import moment from 'moment';
import React, { useEffect, useState } from 'react'
import * as SummaryReports from "./SummaryReports.module.css";
import logo from "../context/image.png"

export default function TableSummaryReports({ data }) {
    return (
        <tr className={data.footer ? "head-bg text-light" : ""}>
            <td> {data.invoiceId} </td>
            <td> {data.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY H:M s a") : ""} </td>
            <td className={data.footer ? "text-light" : ""}> {data.itemName} </td>
            <td className={data.footer ? "text-light" : ""}> {data.netPrice} </td>
        </tr>
    )
}


export function PrintTableSummaryReports() {
    const [Items, setItems] = useState([]);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState([]);
    useEffect(() => {
        if (window.parameters) {
            let data = JSON.parse(window.parameters);
            if (data) {
                console.log(data);
                setItems(data.Items);
                setStart(data.start);
                setEnd(data.end);
            }
        }
    }, []);

    useEffect(() => {
        const printData = () => {
            window.focus();
            window.print();
        };
        printData();
    }, []);

    let total = 0;
    return (
        <div className={`${SummaryReports.containerHeigth} p-0 mx-0`}>

            <div className="row mx-auto p-0">
                <div className="col-12 mx-auto head my-3 px-0">
                    <div className="text-center">
                        <img src={logo} width="120" height="auto" alt="logo company" />
                    </div>
                    <h1 className={`text-right ${SummaryReports.h6Font} mb-2 `}> الشركة  : {localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).companyNameA : ""} </h1>
                    <h1 className={`text-right ${SummaryReports.h6Font} mb-2`}> الفرع : {localStorage.getItem("ControllAfaky") ? JSON.parse(localStorage.getItem("ControllAfaky")).branchNameA : ""} </h1>

                    <h1 className={`text-center ${SummaryReports.h1Font} mb-2`} style={{ textDecoration: "underline" }}> الفواتير - ملخص </h1>

                    <div className="d-flex justify-content-center flex-row-reverse py-2">
                        <h6 className={`${SummaryReports.h6Font}`}>  :  من تاريخ  </h6>
                        <h6 className={`mx-2 ${SummaryReports.h6Font}`}>  {start}  </h6>
                    </div>
                    <div className="d-flex justify-content-center flex-row-reverse">
                        <h6 className={` ${SummaryReports.h6Font}`}> :  الي تاريخ   </h6>
                        <h6 className={`mx-2 ${SummaryReports.h6Font}`}> {end} </h6>
                    </div>
                </div>

                {/* <div className="my-3 px-5">
                        <h6 className={`text-right ${SummaryReports.h6Font}`}> {total} : اجمالي الكل </h6>
                    </div> */}
                <table dir='rtl' style={{ width:"100%" }}>
                    <thead>
                        <tr>
                            <th className={`${SummaryReports.element}`}> الكود </th>
                            <th className={`${SummaryReports.element}`}> التاريخ </th>
                            <th className={`${SummaryReports.element}`}> الصنف </th>
                            <th className={`${SummaryReports.element}`}> السعر </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Items.map((item, idx) => {
                            total += item.netPrice;
                            return (
                                <tr key={idx} className={`${SummaryReports.tableTh}`}>
                                    <td className={`${SummaryReports.element}`}> {item.invoiceId} </td>
                                    <td className={`${SummaryReports.element}`}> {item.footer ? "" : moment(item.invoiceDate).format("DD-MM-YYYY H:M s a")} </td>
                                    <td className={`${SummaryReports.element}`}> {item.itemName} </td>
                                    <td className={`${SummaryReports.element}`}> {item.netPrice} </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>

            </div>
        </div>
    )
}