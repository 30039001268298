import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Layout from "../../../Layout";
import TransferVoucherTable from './TransferVoucherTable';
import { ProductContext } from '../../../context/context';
import { useTranslation } from 'react-i18next';
import addIcon from "../../../images/assets/14.png";

function TransferVoucher(props) {

    const { getGeneralVoucher } = useContext(ProductContext);
    const [details, setDetails] = useState(false);

    useEffect(() => {
        if (props.location.state != undefined) {
            if (props.location.state.detail) {
                setDetails(props.location.state.detail)
            } else {
                if (props.location.params) {
                    getGeneralVoucher(7, 2);
                } else {
                    getGeneralVoucher(7, 1);
                }
            }
        } else {
            if (props.location.params) {
                getGeneralVoucher(7, 2);
            } else {
                getGeneralVoucher(7, 1);
            }
        }
    }, []);
    const { t } = useTranslation();

    return (
        <Layout>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-12 col-md-11 mx-auto my-3 text-center px-0">
                        <div className="text-right mb-2">
                            <Link to="/transfer-voucher/create" className="text-light btn btn-danger py-1 font-ar px-4"> 
                                {t('add')} 
                                <img src={addIcon} className="iconMax" title="edit row" alt="" />
                            </Link>
                        </div>
                        <TransferVoucherTable details={details} />
                    </div>
                </div>
            </div>
        </Layout>
    )


}

export default TransferVoucher
