import React from "react";

function PrintNumber({ changePrinter }) {
    return (
        <div className="col-10 mx-auto px-0 calculation-print d-none d-md-block">
            <div className="row">
                <div className="col-4 px-1 my-1">
                    <button className="btn btn-print w-100 py-2" onClick={() => changePrinter(1)}> 1</button>
                </div>
                <div className="col-4 px-1 my-1">
                    <button className="btn btn-print w-100 py-2" onClick={() => changePrinter(2)}> 2</button>
                </div>
                <div className="col-4 px-1 my-1">
                    <button className="btn btn-print w-100 py-2" onClick={() => changePrinter(3)}> 3</button>
                </div>
            </div>
            <div className="row">
                <div className="col-4 px-1 my-1">
                    <button className="btn btn-print w-100 py-2" onClick={() => changePrinter(4)}> 4</button>
                </div>
                <div className="col-4 px-1 my-1">
                    <button className="btn btn-print w-100 py-2" onClick={() => changePrinter(5)}> 5</button>
                </div>
                <div className="col-4 px-1 my-1">
                    <button className="btn btn-print w-100 py-2" onClick={() => changePrinter(6)}> 6</button>
                </div>
            </div>
            <div className="row">
                <div className="col-4 px-1 my-1">
                    <button className="btn btn-print w-100 py-2" onClick={() => changePrinter(7)}> 7</button>
                </div>
                <div className="col-4 px-1 my-1">
                    <button className="btn btn-print w-100 py-2" onClick={() => changePrinter(8)}> 8</button> </div>
                <div className="col-4 px-1 my-1">
                    <button className="btn btn-print w-100 py-2" onClick={() => changePrinter(9)}> 9</button>
                </div>
            </div>
            <div className="row">
                <div className="col-8 px-1 my-1">
                    <button className="btn w-100 btn-print py-2" onClick={() => changePrinter(0)}> 0</button>
                </div>
                <div className="col-4 px-1 my-1">
                    <button className="btn w-100 btn-clear py-2" onClick={() => changePrinter(10)}> C</button>
                </div>
            </div>
        </div>
    );
}

export default PrintNumber;
